import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionComponent } from './permission/permission.component';
import { PolicyComponent } from './policy/policy.component';
import { SharedModule } from '../shared/shared.module';
import { PermissionManageComponent } from './permission/permission-manage/permission-manage.component';
import { PolicyUpdateComponent } from './policy/policy-update/policy-update.component';
import { GroupUpdateComponent } from './permission/group-update/group-update.component';
import { GroupUsersComponent } from './permission/permission-manage/group-users/group-users.component';
import { GroupPoliciesComponent } from './permission/permission-manage/group-policies/group-policies.component';
import { MapperComponent } from './mapper/mapper.component';
import { AppRegistrationTableComponent } from './app-registration-table/app-registration-table.component';
import { PopupModule } from '@progress/kendo-angular-popup';

@NgModule({
  declarations: [
    PermissionComponent,
    PolicyComponent,
    PermissionManageComponent,
    PolicyUpdateComponent,
    GroupUpdateComponent,
    GroupUsersComponent,
    GroupPoliciesComponent,
    MapperComponent,
    AppRegistrationTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PopupModule
  ]
})
export class AdminModule { }
