import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerEnvironment } from 'src/app/shared/models/CustomerEnvironment';
import { Observable, of } from 'rxjs';
import { CustomerEnvRequest } from 'src/app/shared/models/CustomerEnvRequest';
import { PagedCustomerEnvironment } from 'src/app/shared/models/PagedCustomerEnvironment';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError, switchMap } from 'rxjs/operators';
import { EnvironmentDetails } from 'src/app/shared/models/EnvironmentDetails';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';
import { AtTenantEx } from 'src/app/shared/models/AtTenantEx';
import { AtTenantResponse } from 'src/app/shared/models/AtTenant';

@Injectable({
  providedIn: 'root'
})
export class CustomerEnvironmentService {
  private url = `${window.location.origin}/api/ado/CustomerEnvironment`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService
  ) {
  }

  getCustomerEnvironmentRecords(request: CustomerEnvRequest): Observable<CustomerEnvironment[]> {
    return this.httpClient
      .post<PagedCustomerEnvironment>(`${this.url}/Table/Search`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerEnvironmentRecords'))
      );
  }

  refreshCustomerEnvironmentRecords(): Observable<void> {
    return this.httpClient
      .put<void>(`${this.url}/Table/Refresh`, null)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('refreshCustomerEnvironmentRecords'))
      );
  }

  getAllowedNonProdCustomerCodes(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/Table/AllowedNonProdCustomerCodes`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllowedNonProdCustomerCodes'))
      );
  }

  getMultiTenantProdCustomerIds(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/Table/ProdMTEnvCustomers`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMultiTenantProdCustomerIds'))
      );
  }

  getAllDistinctCustomers(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/Table/EnvCustomers`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllDistinctCustomers'))
      );
  }

  getAllDistinct1XCustomers(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/Table/EnvCustomers/CNC1X`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllDistinct1XCustomers'))
      );
  }

  getAllLiveMTEnvCustomers(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/Table/LiveProdMTEnvCustomers`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllLiveMTEnvCustomers'))
      );
  }

  updateAllLiveMTEnvCustomers(customer): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/Table/LiveProdMTEnvCustomers`, customer)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateAllLiveMTEnvCustomers'))
      );
  }

  updateAllEnvCustomers(customer): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/Table/EnvCustomers`, customer)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateAllEnvCustomers'))
      );
  }

  updateAllEnv1XCustomers(customer): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/Table/EnvCustomers/CNC1X`, customer)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateAllEnv1XCustomers'))
      );
  }

  getMTCustomersForDatabaseRefresh(): Observable<AtTenantEx[]> {
    return this.httpClient
      .get<AtTenantResponse>(`${this.url}/Table/MTCustomerForDbRefresh`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getTenants Prod'))
      );
  }

  addAllowedNonProdCustomerCodes(customerCode: string): Observable<void> {
    return this.httpClient
      .post<void>(`${this.url}/Table/AllowedNonProdCustomerCodes/${customerCode}`, null)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addAllowedNonProdCustomerCodes'))
      );
  }

  updateProductEnvironmentDetails(): Observable<string[]> {
    return this.httpClient
      .put<string[]>(`${this.url}/Table/ProductEnvironmentDetails`, null)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateProductEnvironmentDetails'))
      );
  }

  getCustomerEnvironmentDetails(id: number): Observable<EnvironmentDetails> {
    return this.httpClient
      .get<EnvironmentDetails>(`${this.url}/CustomerEnvDetails/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerEnvironmentDetails'))
      );
  }

  getTenantsWithMailbox(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/MailboxConfiguredTenants`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getTenantsWithMailbox'))
      );
  }

  getLastRunTimeOfSyncPipelines(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/SyncPipelines/LastRunTimes`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getLastRunTimeOfSyncPipelines'))
      );
  }

  getEnvironmentInstanceDetails(offeringType: string, region: string, isProduction: boolean, series: string): Observable<Array<string>> {
    return this.httpClient
      .get<Array<string>>(`${this.url}/Details/EnvironmentInstance/${region}?offeringType=${offeringType}&isProduction=${isProduction}&series=${series}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironmentInstanceDetails'))
      );
  }

  getSqlInstanceDetails(offeringType: string, environment: string): Observable<Array<string>> {
    return this.httpClient
      .get<Array<string>>(`${this.url}/Details/SqlInstance/${environment}?offeringType=${offeringType}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getSqlInstanceDetails'))
      );
  }

  getDistinctEnvironmentTypes(): Observable<Array<string>> {
    return this.httpClient
      .get<Array<string>>(`${this.url}/Details/EnvironmentTypes`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDistinctEnvironmentTypes'))
      );
  }

  getLatestEnvironmentInstance(region: string, type: string) {
    if (this.configService.Organization === Constants.TEST_ORG && !(this.authService.loggedIn)) {
      return of('01');
    }
    return this.httpClient
      .get(`${this.url}/Details/LatestEnvironmentInstance/${region}/${type}`, {responseType: 'text'})
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDistinctEnvironmentTypes'))
      );
  }

  getMTEnvironmentServicePrincipalDetails(environment: string, environmentType: string, region: string, instance: string, customerID: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/Table/MTEnvironmentServicePrincipalDetails/${environmentType}?region=${region}&instance=${instance}&customerID=${customerID}&environment=${environment}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMTEnvironmentServicePrincipalDetails'))
      );
  }

  getMTEnvironmentSubscriptionDetails(environment: string, region: string, instance: string, customerID: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/Table/MTEnvironmentSubscriptionDetails/${environment}?region=${region}&instance=${instance}&customerID=${customerID}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMTEnvironmentSubscriptionDetails'))
      );
  }

  getValidMTEnvironmentDetails(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/Table/MTEnvironmentSubscriptionDetails`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getValidMTEnvironmentDetails'))
      );
  }

  createEnvironmentVariableTemplateFile(newEnvironmentRequest): Observable<any> {
    return this.httpClient
      .post<any>(`${this.url}/CreateEnvVariableFile`, newEnvironmentRequest)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createEnvironmentVariableTemplateFile'))
      );
  }

  getCustomerEnvProductVersions(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/ProductVersions`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerEnvProductVersions'))
      );
  }

  getCustomerSqlServerDbs(customer): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/SqlDbNames/${customer}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerSqlServerDbs'))
      );
  }

  getCustomerEnvironment(customer): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/customerEnvironmentDetails/${customer}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerEnvironment'))
      );
  }

  getCustomerCNC1xMailboxEnv(customer): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/CustomerCNC1xMailboxEnvDetails/${customer}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerCNC1xMailboxEnv'))
      );
  }
}
