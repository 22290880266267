<div id="db-multiple-form" class="container-fluid ms-2 me-4">
  <form [formGroup]="formGroup">
    <div class="row">
      <kendo-formfield class="form-group col-4">
        <kendo-label
          [for]="scrubMail"
          class="k-checkbox-label label-width"
          text="Scrub Mail:"
        ></kendo-label>
        <input
          type="checkbox"
          name="scrubMail"
          value="true"
          #scrubMail
          formControlName="scrubMail"
          kendoCheckBox/>
      </kendo-formfield>
      <kendo-formfield class="form-group col-4">
        <kendo-label
          [for]="scrubCredit"
          class="k-checkbox-label label-width"
          text="Scrub Credit:"
        ></kendo-label>
        <input
          type="checkbox"
          name="scrubCredit"
          value="true"
          #scrubCredit
          formControlName="scrubCredit"
          kendoCheckBox/>
      </kendo-formfield>
      <kendo-formfield class="form-group col-4">
        <kendo-label
          [for]="purgeMail"
          class="k-checkbox-label label-width"
          text="Purge Mail:"
        ></kendo-label>
        <input
          type="checkbox"
          name="purgeMail"
          value="true"
          #purgeMail
          formControlName="purgeMail"
          kendoCheckBox/>
      </kendo-formfield>
    </div>
    <div formGroupName="source">
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="srcSqlServer">Source SQL Server:</kendo-label>
          <kendo-textbox
            #srcSqlServer
            id="srcSqlServer"
            class="form-control"
            formControlName="SqlServer"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="dbName">Source DB Name:</kendo-label>
          <kendo-textbox
            #dbName
            class="form-control"
            formControlName="DbName"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="srcEnvironmentType">Source Environment Type:</kendo-label>
          <kendo-dropdownlist
            #srcEnvironmentType
            id="srcEnvironmentType"
            formControlName="EnvironmentType"
            [data]="environmentTypes"
            [defaultItem]="null"
            required
          >
          </kendo-dropdownlist>
        </kendo-formfield>
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="srcRegion">Source Region:</kendo-label>
          <kendo-dropdownlist
            #srcRegion
            id="srcRegion"
            formControlName="Region"
            [data]="regions"
            [defaultItem]="null"
            required
          >
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="srcInstance">Source Instance:</kendo-label>
          <kendo-textbox
            #srcInstance
            id="srcInstance"
            class="form-control"
            formControlName="Instance"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="srcTenantCode">Source Tenant:</kendo-label>
          <kendo-textbox
            #srcTenantCode
            id="srcTenantCode"
            class="form-control"
            formControlName="TenantCode"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="srcSubscription">Source Subscription:</kendo-label>
          <kendo-textbox
            #srcSubscription
            id="srcSubscription"
            class="form-control"
            formControlName="Subscription"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
    <div class="col-12 m-5"></div>
    <div formGroupName="target">
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="tgtSqlServer">Target Sql Server:</kendo-label>
          <kendo-textbox
            #tgtSqlServer
            id="customerIdTarget"
            class="form-control"
            formControlName="SqlServer"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="targetDbName">Target DB Name:</kendo-label>
          <kendo-textbox
            #targetDbName
            class="form-control"
            formControlName="DbName"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="tgtEnvironmentType">Target Environment Type:</kendo-label>
          <kendo-dropdownlist
            #tgtEnvironmentType
            id="tgtEnvironmentType"
            formControlName="EnvironmentType"
            [data]="environmentTypes"
            [defaultItem]="null"
            required
          >
          </kendo-dropdownlist>
        </kendo-formfield>
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="tgtRegion">Target Region:</kendo-label>
          <kendo-dropdownlist
            #tgtRegion
            id="tgtRegion"
            formControlName="Region"
            [data]="regions"
            [defaultItem]="null"
            required
          >
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="tgtInstance">Target Instance:</kendo-label>
          <kendo-textbox
            #tgtInstance
            id="tgtInstance"
            class="form-control col-4"
            formControlName="Instance"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="tgtTenantCode">Target Tenant:</kendo-label>
          <kendo-textbox
            #tgtTenantCode
            id="tgtTenantCode"
            class="form-control"
            formControlName="TenantCode"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="row">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="tgtSubscription">Target Subscription:</kendo-label>
          <kendo-textbox
            #tgtSubscription
            id="tgtSubscription"
            class="form-control"
            formControlName="Subscription"
            required
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
    <div class="row mt-4 me-4">
      <div class="col-12 text-end">
        <button kendoButton
                [disabled]="disableStartDbRefresh()"
                (click)="onStartDbRefresh()"
                primary="true"
        >Start DB Refresh
        </button>
      </div>
      <div></div>
    </div>
  </form>
</div>
<div kendoDialogContainer></div>
