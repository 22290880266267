    <div class="container wrapper pt-2 row col-12 d-flex justify-content-center flex-wrap">
        <legend class="k-form-legend">{{this.title}}</legend>
    </div>
    <div>

      <div class="log-wrapper">
        <kendo-window
              title="Error"
              *ngIf="errorDetailsOpened"
              (close)="close()"
              [minWidth]="250"
              [width]="450"
            >
              <form class="k-form">
                <fieldset>
                  <legend>Error Details:</legend>

                  <label class="k-form-field">
                    <span>{{errorMessage}}</span>
                  </label>
                </fieldset>
                <div class="k-actions k-actions-end">
                  <button type="button" class="k-button" (click)="close()">
                    Close
                  </button>
                </div>
              </form>
            </kendo-window>
      </div>

        <kendo-tilelayout
        [columns]="12"
        [resizable]="true"
        [reorderable]="true"
        >
        <kendo-tilelayout-item [col]="1" [colSpan]="3">
          <kendo-tilelayout-item-body>
            <kendo-textbox placeholder="Search Term" class ="w-100 h-100" [(ngModel)]="searchTerm" (keydown)="onKeyDown($event)">
              <ng-template kendoTextBoxSuffixTemplate>
                <kendo-icon name="search"></kendo-icon>
              </ng-template>
            </kendo-textbox>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>

        <kendo-tilelayout-item [col]="4" [colSpan]="1">
          <kendo-tilelayout-item-body>
            <button kendoButton primary="true" (click)="search()" class ="w-100 h-100">Search</button>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>

        <kendo-tilelayout-item [col]="5" [colSpan]="1">
          <kendo-tilelayout-item-body>
            <button kendoButton primary="true" (click)="refresh()" class ="w-100 h-100">Refresh</button>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>


        </kendo-tilelayout>

        <kendo-tabstrip
        [tabAlignment]="alignment"
        [tabPosition]="position"
        (tabSelect)="onSelect($event)"
        [keepTabContent] = "true"
        [animate] ="false">
            <kendo-tabstrip-tab
              *ngFor="let onboardingdata of statusData; let i = index"
              [title]="onboardingdata[0].customerId"
              [selected]="i === selected"
            >
              <ng-template kendoTabContent>
            <kendo-grid
            kendoGridSelectBy="id"
            [resizable]="true"
            [selectable]="true"
            [data]="processData(onboardingdata)"
            [filter]="state.filter"
            [sort]="state.sort"
            [sortable]="true"
            filterable="menu"
            [pageable]="true"
            [pageSize]="state.take"
            [skip]="state.skip"
            (pageChange)="pageChange($event)"
            (cellClick)="cellClickHandler($event)"
            *ngIf="searchClicked"
            [rowClass]="rowCallback"
            [isDetailExpanded]="expandDetails"
            >
            <kendo-grid-column
              field="stage"
              title="Stage"
              [width]="180"
            >
            <ng-template kendoGridFilterMenuTemplate
            let-filter let-column="column" let-filterService="filterService">

            <kendo-grid-string-filter-menu
              [column]="column" [filter]="filter" [filterService]="filterService"
              [extra]="false"
            >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
            </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="name"
              title="Name"
              [width]="120"
            >
            <ng-template kendoGridFilterMenuTemplate
            let-filter let-column="column" let-filterService="filterService">

            <kendo-grid-string-filter-menu
              [column]="column" [filter]="filter" [filterService]="filterService"
              [extra]="false"
            >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
            </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="status"
              title="Status"
              [width]="120"
            >
            <ng-template kendoGridFilterMenuTemplate
            let-filter let-column="column" let-filterService="filterService">

            <kendo-grid-string-filter-menu
              [column]="column" [filter]="filter" [filterService]="filterService"
              [extra]="false"
            >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
            </ng-template>
            </kendo-grid-column>


            <kendo-grid-column
              [width]="50"
              [filterable]="false"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <button kendoButton themeColor="primary" *ngIf="dataItem.link">
                        <a target="_blank" href="{{ dataItem.link }}" *ngIf="dataItem.link">
                        Details
                        </a>
                    </button>

                  <!-- <a class="k-button k-primary" themeColor="primary" (click)="viewDetails(dataItem.id)">
                    Details
                  </a> -->
                  <!-- <button kendoButton themeColor="primary" (click)="viewDetails(dataItem.id)">Details</button>
            -->
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            [width]="50"
            [filterable]="false"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                  <button kendoButton themeColor="secondary" (click)="cancelBuild(dataItem)" *ngIf="dataItem.stage.includes('Pipeline run') && (dataItem.status.includes('In Progress') || dataItem.status.includes('Queued') || dataItem.status.includes('Awaiting Action'))">
                    Cancel
                </button>
                <button kendoButton themeColor="primary" (click)="startBuild(dataItem)" *ngIf="dataItem.stage.includes('Pipeline run') && !(dataItem.status.includes('In Progress') || dataItem.status.includes('Queued') || dataItem.status.includes('Awaiting Action'))">
                  Start
              </button>
            </ng-template>
          </kendo-grid-column>

          <ng-template
          kendoGridDetailTemplate
          let-dataItem
        >
        <section>
          <kendo-grid
          kendoGridSelectBy="id"
          [resizable]="true"
          [selectable]="true"
          [data]="processData(dataItem.childPipelines)"
          [filter]="state.filter"
          [sort]="state.sort"
          [sortable]="true"
          filterable="menu"
          [pageable]="true"
          [pageSize]="state.take"
          [skip]="state.skip"
          (pageChange)="pageChange($event)"
          (cellClick)="cellClickHandler($event)"
          *ngIf="searchClicked"
          [rowClass]="rowCallback"
          >
          <kendo-grid-column
            field="stage"
            title="Stage"
            [width]="180"
          >
          <ng-template kendoGridFilterMenuTemplate
          let-filter let-column="column" let-filterService="filterService">

          <kendo-grid-string-filter-menu
            [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false"
          >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
          </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="name"
            title="Name"
            [width]="120"
          >
          <ng-template kendoGridFilterMenuTemplate
          let-filter let-column="column" let-filterService="filterService">

          <kendo-grid-string-filter-menu
            [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false"
          >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
          </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="status"
            title="Status"
            [width]="120"
          >
          <ng-template kendoGridFilterMenuTemplate
          let-filter let-column="column" let-filterService="filterService">

          <kendo-grid-string-filter-menu
            [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false"
          >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
          </ng-template>
          </kendo-grid-column>


          <kendo-grid-column
            [width]="50"
            [filterable]="false"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <button kendoButton themeColor="primary" *ngIf="dataItem.link">
                      <a target="_blank" href="{{ dataItem.link }}" *ngIf="dataItem.link">
                      Details
                      </a>
                  </button>

                <!-- <a class="k-button k-primary" themeColor="primary" (click)="viewDetails(dataItem.id)">
                  Details
                </a> -->
                <!-- <button kendoButton themeColor="primary" (click)="viewDetails(dataItem.id)">Details</button>
          -->
            </ng-template>
          </kendo-grid-column>

          </kendo-grid>
    </section>
        </ng-template>

            </kendo-grid>
            </ng-template>
          </kendo-tabstrip-tab>
      </kendo-tabstrip>
  <!-- <div *ngFor="let onboardingdata of statusData">
    <legend class="d-flex justify-content-center flex-wrap p-4">{{onboardingdata[0].customerId}}</legend>

  </div>       -->
    </div>

