<div class="d-flex justify-content-center flex-wrap p-4">
    <form class="k-form form" [formGroup]="form">
      <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{this.title}}</legend>
        <kendo-formfield>
            <kendo-label [for]="templateType" text="Template Type: "></kendo-label>
            <kendo-dropdownlist
            defaultItem="Select template type..."
            #templateType
            formControlName="templateType"
            [data]="templateTypes"
            [valuePrimitive]="true"
            (valueChange)="onDropDownChanged($event)"
            required
          >
          </kendo-dropdownlist>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="environmentType" text="Environment Type: "></kendo-label>
            <kendo-multiselect
            [filterable]="true"
            defaultItem="Select environment types..."
            #environmentType
            formControlName="environmentType"
            [data]="environmentTypes"
            [valuePrimitive]="true"
            (filterChange)="handleFilter($event)"
            required
          >
        </kendo-multiselect>
        </kendo-formfield>

        <kendo-formfield *ngIf = "showKeyTextBox">
            <kendo-label [for]="key" text="Key:"></kendo-label>
            <kendo-textbox formControlName="key"
                   kendoTextBox
                   placeholder="Enter Key"
                   #key>
                   <ng-template kendoTextBoxSuffixTemplate>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton look="clear" class="send-button" (click)="AddKey()">Add</button>
                  </ng-template>
                </kendo-textbox>
          </kendo-formfield>

          <kendo-formfield *ngIf = "showValueTextBox">
            <kendo-label [for]="value" text="Value:"></kendo-label>
            <kendo-textbox formControlName="value"
                   kendoTextBox
                   placeholder="Enter Value"
                   #value>
                   <ng-template kendoTextBoxSuffixTemplate>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton look="clear" class="send-button" (click)="AddValue()">Add</button>
                  </ng-template>
                </kendo-textbox>
          </kendo-formfield>
  
          <div *ngIf = "showChips" class= "pt-3">
            <div class="row pb-3">
              <label>Key/Value pairs to update:</label>
            </div>
              <kendo-chip
              *ngFor="let key of selectedKeys"
              [label]="key"
              [removable]="true"
              (remove)="onRemove($event)"
            >
            </kendo-chip>
          </div>

        <div class="k-form-buttons">
          <button class="k-button" kendoButton themeColor="primary" (click)="updateTemplateFiles()" [disabled]="isSubmitDisabled()">
            {{this.buttonName}}
          </button>
        </div>
      </fieldset>
    </form>
  </div>
  
  