<div id="db-single-form-from-file" class="container-fluid">
  <form class="k-form k-form-horizontal" [formGroup]="formGroup">
    <div class="row">
      <kendo-formfield class="form-group col-6" orientation="horizontal">
        <kendo-label
          [for]="dcrc"
          class="k-checkbox-label label-width"
          text="DCRC:"
        ></kendo-label>
        <input
          type="checkbox"
          name="dcrc"
          value="true"
          #dcrc
          formControlName="dcrc"
          kendoCheckBox/>
      </kendo-formfield>
      <kendo-formfield class="form-group col-6" orientation="horizontal">
        <kendo-label
          [for]="dropTarDb"
          class="k-checkbox-label label-width"
          text="DropTarDb:"
        ></kendo-label>
        <input
          type="checkbox"
          name="dropTarDb"
          value="true"
          #dropTarDb
          formControlName="dropTarDb"
          kendoCheckBox/>
      </kendo-formfield>
    </div>
    <div class="col-12 m-5"></div>
    <div formGroupName="target">
      <kendo-formfield class="form-group col-12" orientation="horizontal">
        <kendo-label [for]="targetBkpName">Target Backup Name:</kendo-label>
        <kendo-textbox
          #targetBkpName
          id="targetBkpName"
          class="form-control"
          [formControlName]="'TargetBkpName'"
          required
        >
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield class="form-group col-12" orientation="horizontal">
        <kendo-label [for]="customerIdTarget">Target Customer Id:</kendo-label>
        <kendo-textbox
          #customerIdTarget
          id="customerIdTarget"
          class="form-control"
          formControlName="CustomerId"
          required
        >
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield class="form-group col-12" orientation="horizontal">
        <kendo-label [for]="targetDb">Target DB:</kendo-label>
        <kendo-textbox
          #targetDb
          class="form-control"
          formControlName="Db"
          required
        >
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield class="form-group col-12" orientation="horizontal">
        <kendo-label [for]="targetEnv">Target Env:</kendo-label>
        <kendo-textbox
          #targetEnv
          class="form-control"
          formControlName="Env"
          required
        >
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield class="form-group col-12" orientation="horizontal">
        <kendo-label [for]="targetServer">Target Server:</kendo-label>
        <kendo-textbox
          #targetServer
          class="form-control"
          formControlName="Server"
          required
        >
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield class="form-group col-12" orientation="horizontal">
        <kendo-label [for]="tgtMISubscription">Target MI Subscription:</kendo-label>
        <kendo-textbox
          #tgtMISubscription
          id="tgtMISubscription"
          class="form-control"
          formControlName="Subscription"
          required
        >
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="row mt-4 me-4">
      <div class="col-12 text-end">
        <button kendoButton
                [disabled]="disableStartDbRefresh()"
                (click)="onStartDbRestore()"
                primary="true"
        >Start DB Restore
        </button>
      </div>
      <div></div>
    </div>
  </form>
</div>
<div kendoDialogContainer></div>
