import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from '../../../../components/base.component';
import { Constants } from '../../../../shared/Constants';
import { Release } from '../../../../shared/models/Release';
import { DbSelectionChangeArg } from '../../../../shared/models/DbSelectionChangeArg';
import { AdminNamesMapping } from '../../../../shared/models/AdminNamesMapping';
import { ServiceConfiguration } from '../../../../shared/models/AdminTool/ServiceConfiguration';
import { DbRefreshConfirmationMultiComponent } from './db-refresh-confirmation-multi/db-refresh-confirmation-multi.component';
import { PipelineRunRequest } from '../../../models/PipelineRunRequest';
import { switchMap } from 'rxjs/operators';
import { PipelineRunResponse } from '../../../models/PipelineRunResponse';
import { DialogAction } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-db-multi-tenant-panel',
  templateUrl: './db-multi-tenant-panel.component.html',
  styleUrls: ['./db-multi-tenant-panel.component.scss']
})
export class DbMultiTenantPanelComponent extends BaseComponent implements OnInit {
  private sub: Subscription;

  private _customerTenant: string = '';

  private _scrubMail: boolean = false;
  private _scrubCredit: boolean = false;
  private _purgeMail: boolean = false;

  private _srcSqlServer: string = '';
  private _srcDbName: string = '';
  private _srcEnvironmentType: string = '';
  private _srcRegion: string = '';
  private _srcInstance: string = '';
  private _srcTenantCode: string = '';
  private _srcSubscription: string = '';

  private _tgtSqlServer: string = '';
  private _tgtDbName: string = '';
  private _tgtEnvironmentType: string = '';
  private _tgtRegion: string = '';
  private _tgtInstance: string = '';
  private _tgtTenantCode: string = '';
  private _tgtSubscription: string = '';

  environmentTypes: string[] = [];
  regions: string[] = [];

  private envNameMappings: AdminNamesMapping[] = [];

  public set Source(val: DbSelectionChangeArg) {
    this.parseSource(val);
  }

  public set Target(val: DbSelectionChangeArg) {
    this.parseTarget(val);
  }

  public set scrubMail(value: boolean) {
    this.formGroup.controls.scrubMail.setValue(value);
    this._scrubMail = value;
  }

  public get scrubMail(): boolean {
    return this.formGroup.controls.scrubMail.value;
  }

  public set scrubCredit(value: boolean) {
    this.formGroup.controls.scrubCredit.setValue(value);
    this._scrubCredit = value;
  }

  public get scrubCredit(): boolean {
    return this.formGroup.controls.scrubCredit.value;
  }

  public set purgeMail(value: boolean) {
    this.formGroup.controls.purgeMail.setValue(value);
    this._purgeMail = value;
  }

  public get purgeMail(): boolean {
    return this.formGroup.controls.purgeMail.value;
  }

  public set sourceSqlServer(value: string) {
    this.formGroup.get('source.SqlServer').setValue(value);
    this._srcSqlServer = value;
  }

  public get sourceSqlServer() {
    return this.formGroup.get('source.SqlServer').value;
  }

  public set sourceDbName(value: string) {
    this.formGroup.get('source.DbName').setValue(value);
    this._srcDbName = value;
  }

  public get sourceDbName() {
    return this.formGroup.get('source.DbName').value;
  }

  public set sourceEnvironmentType(value: string) {
    this.formGroup.get('source.EnvironmentType').setValue(value);
    this._srcEnvironmentType = value;
  }

  public get sourceEnvironmentType() {
    return this.formGroup.get('source.EnvironmentType').value;
  }

  public set sourceRegion(value: string) {
    this.formGroup.get('source.Region').setValue(value);
    this._srcRegion = value;
  }

  public get sourceRegion() {
    return this.formGroup.get('source.Region').value;
  }

  public set sourceDbInstance(value: string) {
    this.formGroup.get('source.Instance').setValue(value);
    this._srcInstance = value;
  }

  public get sourceDbInstance() {
    return this.formGroup.get('source.Instance').value;
  }

  public set sourceTenantCode(value: string) {
    this.formGroup.get('source.TenantCode').setValue(value);
    this._srcTenantCode = value;
  }

  public get sourceTenantCode() {
    return this.formGroup.get('source.TenantCode').value;
  }

  public set sourceSubscription(value: string) {
    this.formGroup.get('source.Subscription').setValue(value);
    this._srcSubscription = value;
  }

  public get sourceSubscription() {
    return this.formGroup.get('source.Subscription').value;
  }

  public set targetSqlServer(value: string) {
    this.formGroup.get('target.SqlServer').setValue(value);
    this._tgtSqlServer = value;
  }

  public get targetSqlServer() {
    return this.formGroup.get('target.SqlServer').value;
  }

  public set targetDbName(value: string) {
    this.formGroup.get('target.DbName').setValue(value);
    this._tgtDbName = value;
  }

  public get targetDbName() {
    return this.formGroup.get('target.DbName').value;
  }

  public set targetEnvironmentType(value: string) {
    this.formGroup.get('target.EnvironmentType').setValue(value);
    this._tgtEnvironmentType = value;
  }

  public get targetEnvironmentType() {
    return this.formGroup.get('target.EnvironmentType').value;
  }

  public set targetRegion(value: string) {
    this.formGroup.get('target.Region').setValue(value);
    this._tgtRegion = value;
  }

  public get targetRegion() {
    return this.formGroup.get('target.Region').value;
  }

  public set targetDbInstance(value: string) {
    this.formGroup.get('target.Instance').setValue(value);
    this._tgtInstance = value;
  }

  public get targetDbInstance() {
    return this.formGroup.get('target.Instance').value;
  }

  public set targetTenantCode(value: string) {
    this.formGroup.get('target.TenantCode').setValue(value);
    this._tgtTenantCode = value;
  }

  public get targetTenantCode() {
    return this.formGroup.get('target.TenantCode').value;
  }

  public set targetSubscription(value: string) {
    this.formGroup.get('target.Subscription').setValue(value);
    this._tgtSubscription = value;
  }

  public get targetSubscription() {
    return this.formGroup.get('target.Subscription').value;
  }

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.loadNameMappingRecords();
  }

  public formGroup: UntypedFormGroup = this.fb.group({
    scrubMail: [this._scrubMail],
    scrubCredit: [this._scrubCredit],
    purgeMail: [this._purgeMail],

    source: this.fb.group({
      SqlServer: [this._srcSqlServer, Validators.required],
      DbName: [this._srcDbName, Validators.required],
      EnvironmentType: [this._srcEnvironmentType, Validators.required],
      Region: [this._srcRegion, Validators.required],
      Instance: [this._srcInstance, Validators.required],
      TenantCode: [this._srcTenantCode, Validators.required],
      Subscription: [this._srcSubscription, Validators.required]
    }),

    target: this.fb.group({
      SqlServer: [this._tgtSqlServer, Validators.required],
      DbName: [this._tgtDbName, Validators.required],
      EnvironmentType: [this._tgtEnvironmentType, Validators.required],
      Region: [this._tgtRegion, Validators.required],
      Instance: [this._tgtInstance, Validators.required],
      TenantCode: [this._tgtTenantCode, Validators.required],
      Subscription: [this._tgtSubscription, Validators.required]
    })
  });

  disableStartDbRefresh() {
    return this.formGroup.status === 'INVALID';
  }

  onStartDbRefresh() {
    const dialogRef = this.dialogService.open({
      content: DbRefreshConfirmationMultiComponent,
    });

    const instance = dialogRef.content.instance;

    instance.Source = {
      'Server': this.sourceSqlServer,
      'DatabaseName': this.sourceDbName,
      'EnvironmentType': this.sourceEnvironmentType,
      'Region': this.sourceRegion,
      'Instance': this.sourceDbInstance,
      'Tenant': this.sourceTenantCode,
      'Subscription': this.sourceSubscription
    };

    instance.Target = {
      'Server': this.targetSqlServer,
      'DatabaseName': this.targetDbName,
      'EnvironmentType': this.targetEnvironmentType,
      'Region': this.targetRegion,
      'Instance': this.targetDbInstance,
      'Tenant': this.targetTenantCode,
      'Subscription': this.targetSubscription,
    };

    this.sub = dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        this.startDbRefresh();
      }
    });
  }

  private startDbRefresh() {
    const request: PipelineRunRequest = {
      'templateParameters': {
        'scrubmail': this.scrubMail ? 'Y' : 'N',
        'scrubcredit': this.scrubCredit ? 'Y' : 'N',
        'purgemail': this.purgeMail ? 'Y' : 'N',

        'srcsqlserver': this.sourceSqlServer,
        'srcdbname': this.sourceDbName,
        'srcenvironmenttype': this.sourceEnvironmentType,
        'srcregion': this.sourceRegion,
        'srcInstance': this.sourceDbInstance,
        'srcsubname': this.sourceSubscription,

        'tgtsqlserver': this.targetSqlServer,
        'tgtdbname': this.targetDbName,
        'tgtenvironmenttype': this.targetEnvironmentType,
        'tgtregion': this.targetRegion,
        'tgtInstance': this.targetDbInstance,
        'tgtsubname': this.targetSubscription,

        'customertenant': this.targetTenantCode,
      }
    };

    this.loaderService.start();
    this.sub = this.pipelineService.runPipeline(this.configService.MainProject, this.configService.Pipelines.MultiTenantDbRefreshPipelineId , request)
      .pipe(
        switchMap((re) => this.addReleaseToDb(re))
      )
      .subscribe({
        next: (re) => {
          this.toastr.success(`DB refresh pipeline was started successfully: ${re.name}!`, 'Success!')
            .onHidden.subscribe(() => {
            this.router.navigate([`${Constants.DB_REFRESH_LOGS}`])
              .then();
          });
          this.loaderService.stop();
          this.resetForm();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
          this.resetForm();
        }
      });
  }

  private resetForm() {
    this.scrubMail = false;
    this.scrubCredit = false;
    this.purgeMail = false;

    this.sourceSqlServer = null;
    this.sourceDbName = null;
    this.sourceEnvironmentType = null;
    this.sourceRegion = null;
    this.sourceDbInstance = null;
    this.sourceTenantCode = null;
    this.sourceSubscription = null;

    this.targetSqlServer = null;
    this.targetDbName = null;
    this.targetEnvironmentType = null;
    this.targetRegion = null;
    this.targetDbInstance = null;
    this.targetTenantCode = null;
    this.targetSubscription = null;
  }

  private parseSource(val: DbSelectionChangeArg) {
    const result = this.parseConnectionString(val);

    if (!result) {
      return;
    }

    this.sourceSqlServer = result.Server;
    this.sourceDbName = result.Database;
    this.sourceEnvironmentType = result.EnvironmentType;
    this.sourceRegion = result.Region;
    this.sourceDbInstance = result.Instance;
    this.sourceTenantCode = val?.Tenant?.Code;
    this.sourceSubscription = result.Subscription;
  }

  private parseTarget(val: DbSelectionChangeArg) {
    const result = this.parseConnectionString(val);

    if (!result) {
      return;
    }

    this.targetSqlServer = result.Server;
    this.targetDbName = result.Database;
    this.targetEnvironmentType = result.EnvironmentType;
    this.targetRegion = result.Region;
    this.targetDbInstance = result.Instance;
    this.targetTenantCode = val?.Tenant?.Code;
    this.targetSubscription = result.Subscription;
  }

  private parseConnectionString(val: DbSelectionChangeArg) {
    const sc = val.VariableGroup as ServiceConfiguration;
    const connectionString = sc?.TenantEnvironmentServiceConfiguration[0]?.SecretValue;

    if (!connectionString) {
      return null;
    }

    const kvs = connectionString.split(';');

    const server = kvs[0]?.split('=')[1]?.split(',')[0]?.split('.')[0];
    const db = kvs[1]?.split('=')[1];
    const env = server?.substring(0, 4);
    const environmentType = this.envNameMappings.find(m => m.shortName === env)?.fullName;
    const region = DbMultiTenantPanelComponent.getRegion(server);
    const instance = server?.split(',')[0]?.split('.')[0]?.split('-')[2];
    const subscription = server?.split('-')[0];

    return {
      'Server': server,
      'Database': db,
      'EnvironmentType': environmentType,
      'Region': region,
      'Instance': instance,
      'Subscription': subscription,
    };
  }

  private static getRegion(server: string) {
    if (server?.includes(Constants.REGION_US_EST)) {
      return Constants.REGION_US_EAST;
    } else if (server?.includes(Constants.REGION_US_WST)) {
      return Constants.REGION_US_WEST;
    } else {
      return Constants.REGION_US_EAST;
    }
  }

  private loadNameMappingRecords() {
    this.loaderService.start();
    this.adminService.getNameMappingRecords()
      .subscribe({
        next: (re) => {
          this.envNameMappings = re;
          this.environmentTypes = this.envNameMappings.filter(mp => mp.type === 'environment').map(m => m.fullName);
          this.regions = this.envNameMappings.filter(mp => mp.type === 'region').map(m => m.fullName);
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
          this.resetForm();
        }
      });
  }

  private addReleaseToDb(re: PipelineRunResponse): Observable<Release> {
    const user = this.msalService.instance.getActiveAccount().name;
    const release: Release = {
      'id': re.id,
      'batchId': Constants.DB_REFRESH_MULTIPLE_TENANT,
      'name': `${user}`,
      'batch': undefined,
      'projectName': this.configService.MainProject,
      'customerId': null,
      'customerName': this.sourceTenantCode,
      'stage': null,
      'createdBy': user,
      'createdDate': new Date(),
      'modifiedBy': user,
      'modifiedDate': new Date(),
      'azBuild': undefined
    };
    return this.releaseDbService.createRelease(release);
  }
}
