import { Component } from '@angular/core';
import { DialogAction, DialogCloseResult, DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { DatabaseInfo } from '../../../../../shared/models/DatabaseInfo';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constants } from '../../../../../shared/Constants';
import { ActionsLayout } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-db-restore-confirmation',
  templateUrl: './db-restore-confirmation.component.html',
  styleUrls: ['./db-restore-confirmation.component.scss']
})
export class DbRestoreConfirmationComponent extends DialogContentBase {

  private _source: DatabaseInfo;
  private _target: DatabaseInfo;

  private _sourceConfirm: boolean;
  private _targetConfirm: boolean;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    sourceConfirm: new UntypedFormControl(this.sourceConfirm),
    targetConfirm: new UntypedFormControl(this.targetConfirm)
  });

  public actionsLayout: ActionsLayout = 'center';
  private isTargetOnly = false;

  public set IsTargetOnly(value: boolean) {
    this.isTargetOnly = value
  }

  public get IsTargetOnly() {
    return this.isTargetOnly;
  }

  public get sourceConfirm(): boolean {
    return this._sourceConfirm;
  }

  get sourceConfirmControl(): UntypedFormControl {
    return this.formGroup.get('sourceConfirm') as UntypedFormControl;
  }

  public get targetConfirm(): boolean {
    return this._targetConfirm;
  }

  get targetConfirmControl(): UntypedFormControl {
    return this.formGroup.get('targetConfirm') as UntypedFormControl;
  }

  public set Source(value: DatabaseInfo) {
    this._source = value;
  }

  public get Source(): DatabaseInfo {
    return this._source;
  }

  public set Target(value: DatabaseInfo) {
    this._target = value;
  }

  public get Target(): DatabaseInfo {
    return this._target;
  }

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  public onCancelAction(): void {
    this.dialog.close(DialogCloseResult);
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Save', themeColor: Constants.THEME_PRIMARY} as DialogAction);
  }

  disableConfirm() {
    if (this.isTargetOnly) {
      return !this.targetConfirmControl.value;
    } else {
      return !this.sourceConfirmControl.value || !this.targetConfirmControl.value;
    }
  }
}
