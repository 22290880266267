<div class="text-center">
  <h4>
    <small class="text-muted">New Environment</small>
  </h4>
</div>
<kendo-tilelayout [columns]="12">
  <kendo-tilelayout-item [col]="1" [colSpan]="3" class="border containerHeight">
    <kendo-tilelayout-item-body>
      <div class="px-2 pt-2">
        <kendo-stepper
          #stepper
          [style.width.px]="100"
          [steps]="steps"
          stepType="full"
          [(currentStep)]="currentStep"
          [linear]="false"
          orientation="vertical"
        >
        </kendo-stepper>
      </div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="4" [colSpan]="9" class="border containerHeight">
    <kendo-tilelayout-item-body>
      <div class="px-2 pb-2">
        <form class="k-form" [formGroup]="form">
          <app-new-environment-details
            *ngIf="currentStep === 0"
            [form]="form"
            [environment]="environment"
            [showCustomerId]="showCustomerId"
            (formOut)="formEvent($event)"
            (showCustomerIdOut)="showCustomerIdEvent($event)"
          >
          </app-new-environment-details>
          <app-new-environment-instance-details
            *ngIf="currentStep === 1"
            [form]="form"
            (formOut)="formEvent($event)"
          >
          </app-new-environment-instance-details>
          <app-new-environment-sub-details
            *ngIf="currentStep === 2"
            [form]="form"
            [environment]="environment"
            (formOut)="formEvent($event)"
          >
          </app-new-environment-sub-details>
          <app-new-environment-product-details
            *ngIf="currentStep === 3"
            [form]="form"
            [environment]="environment"
            [selectedProducts]="selectedProducts"
            (formOut)="formEvent($event)"
            (productOut)="productEvent($event)"
          ></app-new-environment-product-details>
          <app-new-environment-summary
            *ngIf="currentStep === 4"
            [form]="form"
            [envCreateRequest]="envCreateRequest"
            [selectedProducts]="selectedProducts"
            (envCreateRequestOut)="envCreateRequestEvent($event)"
          ></app-new-environment-summary>
        </form>
      </div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
<kendo-tilelayout [columns]="12">
  <kendo-tilelayout-item [col]="1" [colSpan]="12" class="border">
    <div class="p-2">
      <div class="k-form-buttons k-buttons-end" *ngIf="currentStep !== 4 && currentStep !== 5">
        <span class="page">Step {{ currentStep + 1 }} of 5</span>
        <div>
          <button
            class="k-button prev"
            kendoButton
            themeColor="secondary"
            *ngIf="currentStep !== 0"
            (click)="prev()"
          >
            Previous
          </button>
          <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="next()"
            *ngIf="currentStep !== 5"
          >
            Next
          </button>
        </div>
      </div>
      <div class="k-form-buttons k-buttons-end" *ngIf="currentStep === 4">
        <span class="page">Step {{ currentStep + 1 }} of 5</span>
        <div>
          <button
            class="k-button prev"
            kendoButton
            themeColor="secondary"
            *ngIf="currentStep !== 0"
            (click)="prev()"
          >
            Previous
          </button>
          <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="submit()"
            *ngIf="currentStep === 4"
            [disabled]="isSubmitDisabled()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </kendo-tilelayout-item>
</kendo-tilelayout>
