import { Component, OnDestroy, OnInit } from '@angular/core';
import { Release } from '../../../../shared/models/Release';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { BaseComponent } from '../../../../components/base.component';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { BuildCancelResponse } from '../../../models/BuildCancelResponse';
import { Constants } from '../../../../shared/Constants';
import { mergeMap, switchMap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-pipeline-batch',
  templateUrl: './pipeline-batch.component.html',
  styleUrls: ['./pipeline-batch.component.scss']
})
export class PipelineBatchComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  selectedReleaseIds: number[] = [];
  releases: Release[] = [];
  releasesView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  private populateBuilds(releases: Release[]): Observable<Release[]> {
    return from(releases)
      .pipe(
        mergeMap(r => {
          return this.populateBuild(r);
        }),
        toArray()
      );
  }

  private populateBuild(release: Release): Observable<Release> {
    return this.pipelineService.getBuild(this.configService.MainProject, release.id)
      .pipe(
        switchMap(bd => {
          release.azBuild = bd;
          return of(release);
        })
      );
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeChanges();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.releasesView = process(this.releases, this.state);
  }

  private subscribeChanges() {
    this.loaderService.start()
    this.dataService.Batch
      .pipe(
        switchMap(b => {
          return this.populateBuilds(b.releases);
        })
      ).subscribe({
        next: (result) => {
          this.releases = result;
          this.releasesView = process(this.releases, this.state);
          this.loaderService.stop()
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },        
        complete: () => {
          this.loaderService.stop();
        }
      })
  }

  disableCancelButton(): boolean {
    return this.releases.filter(r => this.selectedReleaseIds.includes(r.id) && r.azBuild.status !== 'completed').length <= 0;
  }

  onCancelAction() {
    this.cancelSelectedRunningPipelines().subscribe({
      next: (result) => {
        this.toastr.success(`${result.length} pipelines are canceled successfully!`, 'Success!');
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private cancelSelectedRunningPipelines(): Observable<BuildCancelResponse[]> {
    const observables: Observable<BuildCancelResponse>[] = [];

    this.selectedReleaseIds.forEach(i => {
      observables.push(this.pipelineService.cancelRunningBuild(this.configService.MainProject, i));
    });

    return forkJoin(observables);
  }

  backToAllLogs(){
    this.router.navigate([`${Constants.PIPELINES}`])
  }
}
