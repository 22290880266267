import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { Constants } from '../../../../shared/Constants';
import { Batch } from '../../../../shared/models/Batch';
import { BatchType } from '../../../../shared/models/BatchType';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, CellClickEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { AtEnvironmentEx } from 'src/app/shared/models/AtEnvironmentEx';
import { AtTenantEx } from 'src/app/shared/models/AtTenantEx';

@Component({
  selector: 'app-cnf-upgrade',
  templateUrl: './cnf-upgrade.component.html',
  styleUrls: ['./cnf-upgrade.component.scss']
})
export class CnfUpgradeComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  constructor() {
    super();
  }

  public envValues: any = [];
  currentEnvironment: AtEnvironmentEx = null;
  environments: AtEnvironmentEx[] = [];
  filteredEnvironments: AtEnvironmentEx[] = [];
  environmentsView: GridDataResult;
  selectedEnvironments: AtEnvironmentEx[] = [];
  selectedTenants: any[] = [];
  products = [];
  addOnProducts = [];
  selectedProductIds: number[] = [];
  selectedProducts = [];
  cnfVersions = [];
  mainProductList = [];

  public state: State = {
    skip: 0,
    take: 10,

    filter: {
      logic: 'and',
      filters: [],
    },
  };


  static getTenantCodes(environment: AtEnvironmentEx) {
    return environment.Tenants
      .filter(t => t.IsSelected)
      .map(tn => tn.Code)
      .join(', ');
  }

  static getTenantSelected(environment: AtEnvironmentEx) {
    return environment.Tenants
      .filter(t => t.IsSelected);
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private loadData() {
    this.loaderService.start();
    this.sub = this.adminToolService.getEnvironments()
      .subscribe({
        next: (result) => {
          this.environments = result;
          this.filteredEnvironments = result;

          this.environmentsView = process(this.environments, this.state);
          this.loadIntegrationProducts();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });

  }

  productSelectionChange($data) {
    const availableVersions = [];
    const productSelected = this.mainProductList.find(x => x.name == $data.product.name);
    productSelected.versions.forEach(element => {
      const versionValue = element.split('-')[0];
      if ($data.AdminToolEnv == Constants.NonProd) {
        availableVersions.push(versionValue);
      } else if ($data.AdminToolEnv == Constants.Prod && element.includes(Constants.GA)) {
        availableVersions.push(versionValue);
      }
    });
    $data.product.versions = availableVersions;
    $data.productName = $data.product.name;
    // this.selectedProducts = $event.map(idx => {
    //   return this.products.find(item => item.id === idx);
    // });
  }

  private loadIntegrationProducts() {
    this.sub = this.adminService.getMappedCoreProductsForUpgrades(true)
      .subscribe({
        next: (result) => {
          this.products = result;
          this.mainProductList = (JSON.parse(JSON.stringify(this.products)));
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.environmentsView = process(this.environments, this.state);
  }

  onStartAction() {
    this.loaderService.start();
    let stopLoader = true;
    this.InitializeBatch()
      .subscribe({
        next: (result) => {
          stopLoader = false;
          this.UpdateTemplateFiles(this.selectedEnvironments, result);
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`${error}`, 'Failure!', {timeOut: 0});
        },
        complete: () => {
          if (stopLoader) {
            this.loaderService.stop();
          }
        }
      });
  }

  private RunAllPipelines(requests) {
    requests.forEach(pipelineRequest => {
      const pipelineRequests = [];
      pipelineRequests.push(pipelineRequest);
      this.pipelineService.runPipelinesOfIntegrationProductsFireAndForget(this.configService.MainProject, pipelineRequests);
    });
  }

  private UpdateTemplateFiles(environments: AtEnvironmentEx[], batch) {
    var requests: any[] = [];
    environments.forEach(en => {
      const request = {
        'environment': en.Code,
        'CnfiUpgradeTenants': CnfUpgradeComponent.getTenantSelected(en),
        'batchId': batch.id,
        'templateParameters': {
          'environmenttype': en.Code,
          'customertenants': CnfUpgradeComponent.getTenantCodes(en)
        },
        'createdName': batch.createdBy
      };
      requests.push(request);
    });


    this.pipelineService.updateTemplateFileForCnfi(requests)
      .subscribe({
        next: (result) => {
          this.RunAllPipelines(result);
          this.clearSelections();
          this.loaderService.stop();
          this.toastr.success(`Pipelines started successfully!`, 'Success!')
            .onHidden.subscribe(() => {
            this.router.navigate([`${Constants.PIPELINES}`])
              .then();
          });
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`${error}`, 'Failure!');
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  private clearSelections() {
    this.selectedProducts = [];
    this.selectedProductIds = [];
    this.selectedEnvironments = [];
  }

  disableStartButton(): boolean {
    return this.selectedEnvironments.length <= 0 || !this.selectedTenants.some(x => x.IsSelected);
  }

  InitializeBatch(): Observable<Batch> {
    const batch = new Batch();
    batch.name = `${this.AccountInfo?.name}-${moment().format()}`;
    batch.type = BatchType.Pipeline;
    batch.projectName = this.configService.MainProject;
    batch.createdBy = this.AccountInfo?.name;
    batch.createdDate = new Date();
    batch.modifiedBy = this.AccountInfo?.name;
    batch.modifiedDate = new Date();

    return this.batchService.createBatchForMTUpgrades(batch);
  }

  cellClick($event: CellClickEvent) {
    this.currentEnvironment = $event.dataItem;
  }

  filterTenantsOfProdEnvironment(environment) {
    var tempTenants: AtTenantEx[] = [];
    environment.Tenants.forEach(t => {
      var isConfigured = false;
      t.TenantEnvironments.forEach(element => {
        if (element.EnvironmentId == environment.Id) {
          isConfigured = element.IsConfigured;
        }
      });
      if (!(t.Code.includes(Constants.OBSOLETE)) && isConfigured) {
        tempTenants.push(t);
      }
    });
    environment.Tenants = tempTenants;
  }

  public rowCallback(context: RowClassArgs) {
    if (context.dataItem.AdminToolEnv == Constants.ADMIN_TOOL_ENV_PROD && context.dataItem.Code.startsWith(Constants.NON_PROD_CODE)) {   // change this condition as you need
      return {
        highlight: true
      };
    }

  }

  handleFilter(value) {
    this.filteredEnvironments = this.environments.filter(
      (s) => s.Code.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public valueChange(value: any): void {
    this.selectedEnvironments = value;
    value.forEach(element => {
      this.currentEnvironment = element;
      if (this.currentEnvironment.Tenants) {
        this.currentEnvironment.Tenants.forEach(element => {
          element.environment = this.currentEnvironment.Code;
          this.selectedTenants = this.selectedTenants.filter(item => item.Code != element.Code);
        });
        this.selectedTenants.push.apply(this.selectedTenants, this.currentEnvironment.Tenants);
        return;
      }

      this.loaderService.start();

      this.adminToolService.getEnvironmentTenants(this.currentEnvironment)
        .subscribe({
          next: (result) => {
            this.loaderService.stop();
            this.currentEnvironment.Tenants = result.flat();
            this.filterTenantsOfProdEnvironment(this.currentEnvironment);
            this.currentEnvironment.Tenants.forEach(x => {
              x.environment = this.currentEnvironment.Code;
              x.IsSelected = false;
              if (this.cnfVersions.length > 0) {
                x.version = this.cnfVersions[0];
              }
            });
            this.selectedTenants.push.apply(this.selectedTenants, this.currentEnvironment.Tenants);
          },
          error: (error) => {
            this.loaderService.stop();
            console.error(error);
          },
          complete: () => {
            this.loaderService.stop();
          }
        });
    });
  }

  public removeTag(tag) {
    this.selectedEnvironments = this.selectedEnvironments.filter((item) => item.Code !== tag.dataItem.Code);
    tag.dataItem.Tenants.forEach(element => {
      this.selectedTenants = this.selectedTenants.filter(item => item.Code != element.Code);
    });
  }
}
