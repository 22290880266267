import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-database-refresh-summary-details',
  templateUrl: './database-refresh-summary-details.component.html',
  styleUrls: ['./database-refresh-summary-details.component.scss']
})
export class DatabaseRefreshSummaryDetailsComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  @Input() public form: UntypedFormGroup;

  dbRefresh={
    source : "",
    target : "",
    scrubMail : false,
    scrubCredit: false,
    purgeMail: false,
    SqlServer: "",
    DbName: "",
    TargetSqlServer: "",
    TargetDbName: "",
  };

  ngOnInit(): void {
    var source = this.form.get("source").value
    this.dbRefresh.source = source.Code + ' ' + source.Name
    var target = this.form.get("target").value
    this.dbRefresh.target = target.Code + ' ' + target.Name
    this.dbRefresh.scrubMail = this.form.get("scrubMail").value
    this.dbRefresh.scrubCredit = this.form.get("scrubCredit").value
    this.dbRefresh.purgeMail = this.form.get("purgeMail").value
    this.dbRefresh.SqlServer = this.form.get("SqlServer").value
    this.dbRefresh.DbName = this.form.get("DbName").value
    this.dbRefresh.TargetSqlServer = this.form.get("TargetSqlServer").value
    this.dbRefresh.TargetDbName = this.form.get("TargetDbName").value
  }

}
