import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-customer-details-summary',
  templateUrl: './customer-details-summary.component.html',
  styleUrls: ['./customer-details-summary.component.scss']
})
export class CustomerDetailsSummaryComponent extends BaseComponent implements OnInit {

  @Input() public customerSummary: UntypedFormGroup;
  @Input() public instanceSummary: UntypedFormGroup;
  @Input() public environmentSummary: UntypedFormGroup;
  @Input() public oISummary: UntypedFormGroup;
  @Input() public selectedEnvironments;
  customerOnboarding = {
    customerName: '',
    offeringType: '',
    region: '',
    customerId: '',
    environments: '',
    environmentInstance: '',
    sqlInstance: '',
    graphAPIKey: '',
    syncAPIKey: ''
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.customerOnboarding.customerName = this.customerSummary.get('customerName').value;
    this.customerOnboarding.offeringType = this.customerSummary.get('offeringType').value;
    this.customerOnboarding.region = this.customerSummary.get('region').value;
    this.customerOnboarding.customerId = this.environmentSummary.get('customerId').value;
    this.selectedEnvironments.forEach(element => {
      if (this.environmentSummary.get(element.name).value) {
        this.customerOnboarding.environments = this.customerOnboarding.environments + element.displayName + ', ';
      }
    });
    if (this.customerOnboarding.environments != '') {
      this.customerOnboarding.environments = this.customerOnboarding.environments.substring(0, this.customerOnboarding.environments.length - 2);
    }

    this.customerOnboarding.environmentInstance = this.instanceSummary.get('envInstance').value;
    this.customerOnboarding.sqlInstance = this.instanceSummary.get('sqlInstance').value;
    this.customerOnboarding.graphAPIKey = this.oISummary.get('graphAPIKey').value;
    this.customerOnboarding.syncAPIKey = this.oISummary.get('syncAPIKey').value;
    this.setFormInStorage();
  }

  public setFormInStorage() {
    localStorage.setItem('CustOnBoardingForm', JSON.stringify(this.customerOnboarding));
  }

}
