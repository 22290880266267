import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DeploymentComponent } from './deployments/deployment/deployment.component';
import { EnvironmentsComponent } from './deployments/environments/environments.component';
import { EnvironmentsListComponent } from './environmentsList/environmentsList.component';
import { BatchesComponent } from './deployments/environments/batches/batches.component';
import { SharedResourceComponent } from './deployments/products/shared-resource.component';
import { Constants } from './shared/Constants';
import { BatchDetailComponent } from './deployments/environments/batches/batch-detail/batch-detail.component';
import { StageDetailComponent } from './deployments/environments/batches/stage-detail/stage-detail.component';
import { ReleaseDetailComponent } from './deployments/environments/batches/release-detail/release-detail.component';
import { TaskDetailComponent } from './deployments/environments/batches/task-detail/task-detail.component';
import { AppUpgradeComponent } from './deployments/products/app-upgrade/app-upgrade.component';
import { PipelinesComponent } from './deployments/products/pipelines/pipelines.component';
import { PipelineDetailComponent } from './deployments/products/pipelines/pipeline-detail/pipeline-detail.component';
import { PipelineBatchComponent } from './deployments/products/pipelines/pipeline-batch/pipeline-batch.component';
import { PermissionComponent } from './admin/permission/permission.component';
import { RoleGuard } from './services/msal/role.guard';
import { MsalGuard } from '@azure/msal-angular';
import { AuditLogComponent } from './tools/auditLog.component';
import { PatTokenComponent } from './shared/authorize/pat-token/pat-token.component';
import { MapperComponent } from './admin/mapper/mapper.component';
import { EnvironmentTableRefreshComponent } from './tools/environment-table-refresh/environment-table-refresh.component';
import { FhcmCustomersComponent } from './dynamics/fhcm-customers/fhcm-customers.component';
import { FhcmMainComponent } from './dynamics/fhcm-main/fhcm-main.component';
import { DbRefreshLogsComponent } from './deployments/products/db-management/db-refresh-logs/db-refresh-logs.component';
import { CustomerOnboardingComponent } from './customer-onboarding/customer-onboarding.component';
import { AppRegistrationScriptComponent } from './app-registration-script/app-registration-script.component';
import { AppUserDetailsComponent } from './customer-onboarding/app-user-details/app-user-details.component';
import { AadSettingDetailsComponent } from './customer-onboarding/aad-setting-details/aad-setting-details.component';
import { CustomerOnboardingStatusComponent } from './customer-onboarding/customer-onboarding-status/customer-onboarding-status.component';
import { NewDevTestsLabComponent } from './dev-tests-lab/new-dev-tests-lab/new-dev-tests-lab.component';
import { AppRegistrationTableComponent } from './admin/app-registration-table/app-registration-table.component';
import { DevTestsLabLogsComponent } from './dev-tests-lab/dev-tests-lab-logs/dev-tests-lab-logs.component';
import { NewEnvironmentComponent } from './new-environment/new-environment.component';
import { DbRestoreComponent } from './deployments/products/db-management/db-restore/db-restore.component';
import { ProductMappingComponent } from './admin/mapper/product-mapping/product-mapping.component';
import { DbRestoreLogsComponent } from './deployments/products/db-management/db-restore/db-restore-logs/db-restore-logs.component';
import { MailboxAppRegistrationComponent } from './mailbox-app-registration/mailbox-app-registration.component';
import { ApiPolicyMapperComponent } from './admin/mapper/api-policy-mapper/api-policy-mapper.component';
import {
  DevTestsLabDeploymentsComponent
} from './dev-tests-lab/dev-tests-lab-logs/dev-tests-lab-deployments/dev-tests-lab-deployments.component';
import { UpdateTemplateFileComponent } from './update-template-file/update-template-file.component';
import { UpdateTemplateFileLogsComponent } from './update-template-file/update-template-file-logs/update-template-file-logs.component';
import { DatabaseRefreshComponent } from './database-refresh/database-refresh.component';
import { DatabaseRefreshStatusComponent } from './database-refresh/database-refresh-status/database-refresh-status.component';
import { DatabaseRefreshStatusRunTasksComponent } from './database-refresh/database-refresh-status/database-refresh-status-run-tasks/database-refresh-status-run-tasks.component';
import { UpdateLiveProdMtEnvComponent } from './admin/update-live-prod-mt-env/update-live-prod-mt-env.component';
import { AppUpgradeDetailedLogComponent } from './deployments/products/app-upgrade/app-upgrade-detailed-log/app-upgrade-detailed-log.component';
import { AppUpdateComponent } from './deployments/products/app-update/app-update.component';
import { FinanceIntegrationAppRegComponent } from './finance-integration-app-reg/finance-integration-app-reg.component';
import { FinanceIntegrationComponent } from './finance-integration/finance-integration.component';
import { FinanceIntegrationStatusComponent } from './finance-integration/finance-integration-status/finance-integration-status.component';
import { DevTestsLabEnvsComponent } from './dev-tests-lab/dev-tests-lab-envs/dev-tests-lab-envs.component';
import { CnfUpgradeComponent } from './deployments/products/app-upgrade/cnf-upgrade/cnf-upgrade.component';
import { MailboxManagerComponent } from './mailbox-manager/mailbox-manager.component';
import { MailboxManagerStatusComponent } from './mailbox-manager/mailbox-manager-status/mailbox-manager-status.component';
import { EnableFeaturesComponent } from './admin/enable-features/enable-features.component';
import { UpdateMailboxConfiguredEnvComponent } from './admin/update-mailbox-configured-env/update-mailbox-configured-env.component';
import { PsIntegrationComponent } from './deployments/ps-integration/ps-integration.component';
import { PsIntegrationStatusComponent } from './deployments/ps-integration/ps-integration-status/ps-integration-status.component';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full', canActivate: [MsalGuard]},
  {
    path: `${Constants.PAT_TOKEN}`,
    component: PatTokenComponent,
  },
  {
    path: `${Constants.DEPLOYMENTS}`,
    component: DeploymentComponent
  },
  {
    path: `${Constants.DB_REFRESH}`,
    component: DatabaseRefreshComponent
  },
  {
    path: `${Constants.DB_RESTORE}`,
    component: DbRestoreComponent
  },
  {
    path: Constants.DB_REFRESH_LOGS,
    component: DbRefreshLogsComponent,
    children: [
      {
        path: `${Constants.STAGE_DETAIL}/:Id`,
        component: StageDetailComponent
      },
      {
        path: `${Constants.TASK_DETAIL}/:Id`,
        component: TaskDetailComponent
      },
    ]
  },
  {
    path: Constants.DB_RESTORE_LOGS,
    component: DbRestoreLogsComponent,
    children: [
      {
        path: `${Constants.STAGE_DETAIL}/:Id`,
        component: StageDetailComponent
      },
      {
        path: `${Constants.TASK_DETAIL}/:Id`,
        component: TaskDetailComponent
      },
    ]
  },
  {path: 'environments', component: EnvironmentsComponent},
  {path: 'shared-resource', component: SharedResourceComponent},
  {
    path: 'app-upgrade',
    component: AppUpgradeComponent
  },
  {
    path: 'app-update',
    component: AppUpdateComponent
  },
  {
    path: 'cnf-upgrade',
    component: CnfUpgradeComponent
  },
  {
    path: `${Constants.App_Upgrade_DetailLog}/:Id`,
    component: AppUpgradeDetailedLogComponent
  },
  {
    path: Constants.PIPELINES,
    component: PipelinesComponent,
    children: [
      {
        path: `${Constants.PIPELINE_DETAIL}/:Id`,
        component: PipelineDetailComponent
      },
      {
        path: `${Constants.PIPELINE_BATCH}/:Id`,
        component: PipelineBatchComponent
      },
      {
        path: `${Constants.STAGE_DETAIL}/:Id`,
        component: StageDetailComponent
      },
      {
        path: `${Constants.TASK_DETAIL}/:Id`,
        component: TaskDetailComponent
      },
    ]
  },
  {
      path: `${Constants.PIPELINE_DETAIL}/:Id`,
      component: PipelineDetailComponent
  },
    {
      path: `${Constants.PIPELINE_BATCH}/:Id`,
      component: PipelineBatchComponent
    },
  {
    path: Constants.BATCHES,
    component: BatchesComponent,
    children: [
      {
        path: `${Constants.BATCH_DETAIL}/:Id`,
        component: BatchDetailComponent
      },
      {
        path: `${Constants.RELEASE_DETAIL}/:Id`,
        component: ReleaseDetailComponent
      },
      {
        path: `${Constants.STAGE_DETAIL}/:Id`,
        component: StageDetailComponent
      },
      {
        path: `${Constants.TASK_DETAIL}/:Id`,
        component: TaskDetailComponent
      },
    ]
  },
  {
    path: Constants.FhcmEnvironments,
    component: FhcmMainComponent,
  },
  {
    path: `${Constants.FhcmCustomers}`,
    component: FhcmCustomersComponent,
  },
  {
    path: `${Constants.DEV_TESTS_LAB}`,
    component: NewDevTestsLabComponent,
  },
  {
    path: Constants.DEV_TESTS_LAB_LOGS,
    component: DevTestsLabLogsComponent,
    children: [
      {
        path: `${Constants.DEV_TESTS_LAB_LOGS_DEPLOYMENT}/:Id`,
        component: DevTestsLabDeploymentsComponent
      }
    ]
  },
  {
    path: Constants.DEV_TESTS_LAB_ENVS,
    component: DevTestsLabEnvsComponent
  },
  {
    path: Constants.ADMIN,
    component: PermissionComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.PERMISSION,
    component: PermissionComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {path: Constants.MULTI_RELEASES, component: DeploymentComponent},
  {path: 'environments', component: EnvironmentsComponent},
  {
    path: Constants.Environments_List,
    component: EnvironmentsListComponent
  },
  {
    path: Constants.AuditLogPath,
    component: AuditLogComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.MAPPER,
    component: MapperComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.AppRegistrationTable,
    component: AppRegistrationTableComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.EnvironmentTableRefreshPath,
    component: EnvironmentTableRefreshComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {path: Constants.CustomerOnboarding, component: CustomerOnboardingComponent},
  {path: Constants.App_Registration_Script, component: AppRegistrationScriptComponent},
  {path: Constants.Integration_App_Registration_Script, component: FinanceIntegrationAppRegComponent},
  {path: Constants.Integration, component: FinanceIntegrationComponent},
  {path: Constants.ProfessionalIntegration, component: PsIntegrationComponent},
  {path: Constants.ProfessionalIntegration_Status, component: PsIntegrationStatusComponent},
  {path:`${Constants.ProfessionalIntegration_Status}/:Id`, component:DatabaseRefreshStatusRunTasksComponent},
  {path: Constants.FinanceIntegrationStatus, component: FinanceIntegrationStatusComponent},
  {path:`${Constants.FinanceIntegrationStatus}/:Id`, component:FinanceIntegrationStatusComponent},
  {path:Constants.Mailbox_App_Registration_Script, component:MailboxAppRegistrationComponent},
  {path: Constants.MailboxManager, component: MailboxManagerComponent},
  {path: Constants.MailboxManagerStatus, component: MailboxManagerStatusComponent},
  {path:`${Constants.MailboxManagerStatus}/:Id`, component:MailboxManagerStatusComponent},
  {path: Constants.AppUserDetails, component: AppUserDetailsComponent},
  {path: Constants.AadDetails, component: AadSettingDetailsComponent},
  {path: Constants.CustomerOnboardingStatus, component:CustomerOnboardingStatusComponent},
  {path: Constants.MultiTenantEnvironmentStatus, component:CustomerOnboardingStatusComponent},
  {path:`${Constants.CustomerOnboardingStatus}/:Id`, component:CustomerOnboardingStatusComponent},
  {path: `${Constants.MultiTenantEnvironmentStatus}/:Id`, component:CustomerOnboardingStatusComponent},
  {path: Constants.NEW_ENVIRONMENT_PATH, component: NewEnvironmentComponent},
  {path: Constants.UPDATE_TEMPLATE_FILE_PATH, component: UpdateTemplateFileComponent},
  {path: Constants.UPDATE_TEMPLATE_LOG_FILE_PATH, component: UpdateTemplateFileLogsComponent},
  {path: Constants.MTDbRefresh, component:DatabaseRefreshStatusComponent},
  {path:`${Constants.MTDbRefresh}/:Id`, component:DatabaseRefreshStatusComponent},
  {path:`${Constants.MTDbRefreshTasks}/:source/:target/:Id`, component:DatabaseRefreshStatusRunTasksComponent},
  {
    path: Constants.PRODUCT_VERSION_MAPPING_PATH,
    component: ProductMappingComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.POLICY_MAPPING_PATH,
    component: ApiPolicyMapperComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.CNC_CUSTOMER_DETAILS_PATH,
    component: UpdateLiveProdMtEnvComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.MAILBOX_CUSTOMER_PATH,
    component: UpdateMailboxConfiguredEnvComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  },
  {
    path: Constants.MT_FEATURE_PATH,
    component: EnableFeaturesComponent,
    data: {
      roles: ['Admin']
    },
    canActivate: [RoleGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
