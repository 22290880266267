import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler.service';
import { Observable, of } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { catchError } from 'rxjs/operators';
import { BlobItem } from '../../shared/models/BlobItem';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class StorageAccountService {

  constructor(private httpClient: HttpClient,
              private errorHandling: ErrorHandlerService,
              private configService: AppConfigService,
              private authService: AuthService) { }


  geDatabaseBackups(accountName: string): Observable<BlobItem[]> {

    return this.httpClient
      .get<BlobItem[]>(`${window.location.origin}/api/${Constants.STORAGE_ACCOUNT}/GeDatabaseBackups/${accountName}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('geDatabaseBackups'))
      );
  }
}
