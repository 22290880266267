<form class="k-form container-fluid" [formGroup]="formGroup">
  <div class="row g-0">
    <div class="col-6">
      <kendo-formfield class="form-group">
        <kendo-label [for]="platform">Platform CNC 2.x</kendo-label>
        <kendo-dropdownlist
          #platform
          id="platform"
          formControlName="platform"
          [data]="platforms"
          [defaultItem]="null"
          required
        >
        </kendo-dropdownlist>
      </kendo-formfield>
      <div class="row" *ngIf="formGroup.controls['platform']?.value === 'Single Tenant'">
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="fromFile">From File</kendo-label>
          <input
            type="radio"
            name="restoreType"
            value="FromFile"
            #fromFile
            formControlName="restoreType"
            kendoRadioButton
          />
        </kendo-formfield>
        <kendo-formfield class="form-group col-6">
          <kendo-label [for]="fromDb">From DB</kendo-label>
          <input
            type="radio"
            name="restoreType"
            value="FromDb"
            #fromDb
            formControlName="restoreType"
            kendoRadioButton
          />
        </kendo-formfield>
      </div>
      <app-data-source-field
        #customerSource
        [hidden]="formGroup.controls['restoreType']?.value !== 'FromDb' &&
              formGroup.controls['platform']?.value !== 'Single-Tenant'"
        controlLabel="Source"
        controlName="source"
        environmentControlName="sourceEnvironment"
        (selectionChange)="onSourceSelectionChange($event)"
      ></app-data-source-field>
      <app-data-source-field
        #customerTarget
        controlLabel="Target"
        controlName="target"
        environmentControlName="targetEnvironment"
        (selectionChange)="onTargetSelectionChange($event)"
      ></app-data-source-field>
    </div>
    <app-db-single-tenant-restore-panel
      #singleTenantPanel
      *ngIf="formGroup.controls['platform'].value === 'Single Tenant'"
      class="col-6 border-start"
    ></app-db-single-tenant-restore-panel>
    <app-db-multi-tenant-panel
      #multiTenantPanel
      *ngIf="formGroup.controls['platform'].value === 'Multi-Tenant'"
      class="col-6 border-start">
    </app-db-multi-tenant-panel>
  </div>
</form>
