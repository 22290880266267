<div class="w-75 px-4">
    <ng-container [formGroup]="customerEnvironmentDetails">
        <kendo-formfield>
            <kendo-label [for]="customerId" text="Customer Id: "></kendo-label>
            <kendo-textbox
              formControlName="customerId"
              kendoTextBox
              #customerId
              required
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="CNFIversion" text="CNFI Version: "></kendo-label>
            <kendo-combobox
            #CNFIversion
            formControlName="cnfVersion"
            [data]="cnfVersions">
          </kendo-combobox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="resourceUri" text="Dynamics Resource Url: "></kendo-label>
            <kendo-textbox
              formControlName="resourceUri"
              kendoTextBox
              #resourceUri
              required
            ></kendo-textbox>
          </kendo-formfield>
    </ng-container>
</div>