import { Inject, Injectable } from '@angular/core';
import { Constants } from '../shared/Constants';
import { Observable } from 'rxjs';
import { UserDescriptor, UserProfile } from '../shared/models/userprofile';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userUrl = `${this.baseUrl}api/user`;

  private userProfile: UserProfile;

  constructor(@Inject('BASE_URL') private baseUrl: string,
              private httpClient: HttpClient,
              private configService: AppConfigService,
  ) {
  }

  login() {
    this.httpClient.get<any>(`${this.userUrl}/Authorize`).subscribe({
      next: (result) => {
        console.log(result);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  getUserProfile(): Observable<UserProfile> {
    return this.httpClient.get<any>(`${window.location.origin}/${Constants.AZURE_VSSPS_DOMAIN}/_apis/profile/profiles/Me?api-version=${Constants.API_VERSION}`);
  }

  getUserDescriptor(storageId: string): Observable<UserDescriptor> {
    return this.httpClient.get<any>(`${window.location.origin}/${Constants.AZURE_VSSPS_DEV_DOMAIN}/${this.configService.Organization}/_apis/graph/descriptors/${storageId}?api-version=${Constants.API_VERSION_PREVIEW}`);
  }

  getUserAvatar(descriptor: string): Observable<any> {
    return this.httpClient.get(
      `${window.location.origin}/${Constants.AZURE_VSSPS_DEV_DOMAIN}/${this.configService.Organization}/_apis/graph/Subjects/${descriptor}/avatars?api-version=${Constants.API_VERSION_PREVIEW}.1`,
    );
  }
}
