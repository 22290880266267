<kendo-dialog
  [width]="750"
  [height]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
        <b>Please Confirm</b>
      </div>
  </kendo-dialog-titlebar>

  <div style="font-size: 18px; line-height: 1.3em;">
    <b><span class="k-icon k-i-warning"></span> Running the customer provisioning pipelines will override/update the new app registration values in keyvault for this customer! Are you sure you want to do that?</b>
  </div>

  <div style="font-size: 18px; line-height: 1.3em;" class ="pt-4">
    App Registration changes below :
    <div class ="pt-2">
    <ul>
        <li *ngFor="let change of changeList">{{ change }}</li>
      </ul>
      </div>
  </div>

  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button kendoButton (click)="onConfirmAction()" themeColor="primary">
      Confirm
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
