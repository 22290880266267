<div class="row">
  <div class="form-group col-12">
    <kendo-label for="customerId">Customer Id</kendo-label>
    <kendo-textbox
      id="customerId"
      class="form-control"
      [(ngModel)]="infrastructure.CustomerID"
      required
    >
    </kendo-textbox>
  </div>
</div>
