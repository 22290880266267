import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DrawerComponent,
  DrawerItem,
  DrawerItemExpandedFn,
  DrawerMode,
  DrawerSelectEvent
} from '@progress/kendo-angular-layout';
import { Items } from './DrawerItems';
import { BaseComponent } from './components/base.component';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'DevOps Portal';
  public selected = 'Deployments';
  public drawerItems: Array<DrawerItem> = Items;
  mini = true;
  public mode: DrawerMode = 'push';

  public expandedIndices = [];

  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(public authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.authService.updateLoggedInStatus();
  }

  ngOnDestroy() {
    window.removeEventListener('resize', () => {
    });
  }

  public toggleDrawer(drawer: DrawerComponent): void {
    drawer.toggle();
  }

  public onSelect(ev: DrawerSelectEvent): void {
    this.selected = ev.item.text;
    const current = ev.item;

    this.expandedIndices = [];

    this.expandedIndices.push(current.id);

    let parent = this.drawerItems.find(item => item.id === current.parentId);

    do {
      if (parent) {
        this.expandedIndices.push(parent.id);
      }

      parent = this.drawerItems.find(item => item.id === parent.parentId);
    } while (parent)

    if(ev.item.path != '') {
      this.router.navigate([ev.item.path]);
    }
  }
}
