import { Injectable } from '@angular/core';
import { Constants } from '../../shared/Constants';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private authService: AuthService,
    private errorHandling: ErrorHandlerService) {
  }

  getProjects(): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/projects?stateFilter=All&api-version=${Constants.API_VERSION}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getProjects'))
      );
  }

  getProjectVariableGroups(project: string): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/projects/${project}/variablegroups?stateFilter=All&api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getProjectVariableGroups'))
      );
  }

  getProjectVariableGroupById(project: string, variableGroupId: number): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/projects/${project}/variablegroups/${variableGroupId}?stateFilter=All&api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getProjectVariableGroupById'))
      );
  }
}
