import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { BatchFilterModel } from '../../../shared/models/BatchFilterModel';
import { Batch } from '../../../shared/models/Batch';
import { SelectableSettings, SelectionChangeEvent } from '@progress/kendo-angular-treelist';
import { CompositeFilterDescriptor, filterBy, State,process } from '@progress/kendo-data-query';
import { Release } from '../../../shared/models/Release';
import { Constants } from '../../../shared/Constants';
import { Subscription } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-pipelines',
  templateUrl: './pipelines.component.html',
  styleUrls: ['./pipelines.component.scss']
})
export class PipelinesComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  showDetails = true;
  public state: State = {
    skip: 0,
    take: 10
    };

    public gridData: GridDataResult;

  batches: Batch[];
  private cache: any = new Map();

  public settings: SelectableSettings = {
    enabled: true,
    mode: 'row',
    multiple: false,
    drag: false,
    readonly: false,
  };

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
      // { field: 'type', operator: 'eq', value: 'directory' }
    ],
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadBatches();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private loadBatches() {
    this.loaderService.start();
    const filter = {'type': 'Pipeline'} as BatchFilterModel;
    this.sub = this.batchService.getBatches(filter).subscribe({
      next: (result) => {
        this.batches = result;
        this.batches.forEach(batch=>{
          let productName = '';
          batch.releases.forEach(release=>{
            const releaseDetails = release.name.split('_');
            // productName
            if(releaseDetails.length > 2 && !productName.includes(releaseDetails[1]+" "+releaseDetails[2])){
              productName += releaseDetails[1]+" "+releaseDetails[2]+", "
            }
            else if(releaseDetails.length > 1 && !productName.includes(releaseDetails[1])){
              productName += releaseDetails[1]+", "
            }
          })
          productName = productName.substring(0, productName.length - 2);
          batch.productNames = productName;
          const releaseNames: string[] = batch.releases.map(r => r.name).filter((v, i, a) => a.indexOf(v) === i);
          batch.environments = releaseNames.map(s => s.split('_')[0]).join(',');
        })
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  public hasChildren = (batch: Batch): boolean => {
    const children = this.fetchChildren(batch);
    return children && children.length > 0;
  };

  public fetchChildren = (parent?: any): any[] => {
    if (this.cache.get(parent)) {
      return this.cache.get(parent);
    }

    let result;
    const items = parent ? parent.releases : this.batches;
    if (this.filter && this.filter.filters.length && items) {
      result = filterBy(items, {
        logic: 'or',
        filters: [
          this.filter,
          {
            // matches the current filter or a child matches the filter
            operator: (item: any) => {
              if (item.releases) {
                const children = this.fetchChildren(item);
                return children && children.length;
              }
            },
          },
        ],
      });
    } else {
      result = items;
    }

    this.cache.set(parent, result);

    return result;
  };

  public onFilterChange($event: CompositeFilterDescriptor): void {
    this.filter = $event;
    this.batches = this.fetchChildren();
  }

  releaseSelectionChanged($event: SelectionChangeEvent) {
    const id = $event.items[0].dataItem.id;
    if (!$event.items[0].dataItem.releases) {
      // pipeline
      const release: Release = $event.items[0].dataItem;
      this.dataService.updateRelease(release);
      this.router.navigate([`${Constants.PIPELINE_DETAIL}/${id}`])
        .then();
    } else {
      // batch
      this.dataService.updateBatch($event.items[0].dataItem);
      this.router.navigate([`${Constants.PIPELINE_BATCH}/${id}`])
        .then();
    }
  }

  viewDetails(data,rowIndex){
    const id = data.id;
    if (!data.releases) {
      // pipeline
      const release: Release = data;
      this.dataService.updateRelease(release);
      this.router.navigate([`${Constants.PIPELINE_DETAIL}/${id}`])
        .then();
    } else {
      // batch
      //this.dataService.updateBatch(data);
      this.router.navigate([`${Constants.App_Upgrade_DetailLog}/${id}`])
        .then();
    }
  }
}
