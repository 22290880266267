import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogAction, DialogCloseResult, DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PipelineService } from '../../../services/azure/pipeline.service';
import { ReleaseService } from '../../../services/azure/release.service';
import { Subscription } from 'rxjs';
import { ReleaseDefinition } from '../../../deployments/models/ReleaseDefinition';
import { AppConfigService } from '../../../services/app-config.service';
import { Constants } from '../../../shared/Constants';
import { ActionsLayout } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-policy-update',
  templateUrl: './policy-update.component.html',
  styleUrls: ['./policy-update.component.scss']
})
export class PolicyUpdateComponent extends DialogContentBase implements OnInit, OnDestroy {
  private sub: Subscription;

  public subjects: string[] = [
    'Pipeline',
    'Release',
    'Environment',
    'Ring',
    'Feature',
    'Api',
  ];

  public actions: string[] = [
    'Read',
    'Execute',
    'POST',
    'GET',
    'PUT',
    'DELETE'
  ];

  public resources: ReleaseDefinition[] | any[] = [{
    id: 0,
    name: 'All'
  }];

  constructor(
    public dialog: DialogRef,
    private pipelineService: PipelineService,
    private releaseService: ReleaseService,
    private configService: AppConfigService
  ) {
    super(dialog);
  }

  @Input()
  public set name(value: string) {
    this.formGroup.controls.name.setValue(value);
    this._name = value;
  }

  public get name(): string {
    return this._name;
  }

  @Input()
  public set subject(value: string) {
    this.formGroup.controls.subject.setValue(value);
    this._subject = value;
  }

  public get subject(): string {
    return this._subject;
  }

  @Input()
  public set action(value: string) {
    this.formGroup.controls.action.setValue(value);
    this._action = value;
  }

  public get action(): string {
    return this._action;
  }

  @Input()
  public set resource(value: any[]) {
    this.formGroup.controls.resource.setValue(value);
    this._resource = value;
  }

  public get resource(): any[] {
    return this._resource;
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(this.name),
    subject: new UntypedFormControl(this.subject),
    action: new UntypedFormControl(this.action),
    resource: new UntypedFormControl(this.resource),
  });

  private _name: string;
  private _subject: string;
  private _action: string;
  private _resource: any[];

  public actionsLayout: ActionsLayout = 'center';

  ngOnInit(): void {
    this.subscribeValueChanges();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private subscribeValueChanges() {
    const subject = this.formGroup.get('subject');
    subject.valueChanges.subscribe(val => {
      this.loadResourceItems(val);
    });
  }

  public loadResourceItems(subjectType: string) {
    if (subjectType === 'Pipeline') {
      this.loadPipelines();
    } else if (subjectType === 'Release') {
      this.loadReleases();
    }
  }

  private loadPipelines() {
    this.sub = this.pipelineService.getPipelinesByProject(this.configService.MainProject).subscribe({
      next: (result) => {
        this.resources.push(...result.value);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private loadReleases() {
    this.sub = this.releaseService.getReleaseDefinitions(
      this.configService.MainProject,
      this.getReleaseDefSearchPaths()
    ).subscribe({
      next: (result) => {
        this.resources.push(...result.reduce((prev, next) => prev.value.concat(next.value)));
        this.resources.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public onCancelAction(): void {
    this.dialog.close(DialogCloseResult);
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Save', themeColor: Constants.THEME_PRIMARY} as DialogAction);
  }

  getReleaseDefSearchPaths() {
    return [
      '\\.MasterReleases\\**\\*',
      '\\DevOpsPortal\\**\\*'
    ];
  }
}
