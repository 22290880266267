import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-environment-sub-details',
  templateUrl: './new-environment-sub-details.component.html',
  styleUrls: ['./new-environment-sub-details.component.scss']
})
export class NewEnvironmentSubDetailsComponent extends BaseComponent implements OnInit {

  private sub: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.getSpnAndSqlDetails();
  }

  @Input() public form: UntypedFormGroup;

  @Input() public environment: string;

  @Output() formOut = new EventEmitter<any>();

  getSubscriptionDetails() {
    this.environment = this.form.get('environment') != null ? this.form.get('environment').value : '';
    const environmentType = this.form.get('environmentType') != null ? this.form.get('environmentType').value : '';
    const region = this.form.get('region') != null ? this.form.get('region').value : '';
    const instance = this.form.get('instance') != null ? this.form.get('instance').value : '';
    let customerID = this.form.get('customerID') != null ? this.form.get('customerID').value : '';
    if (this.environment != 'Enterprise') {
      customerID = '';
    }
    this.sub = this.customerEnvironmentService.getMTEnvironmentSubscriptionDetails(environmentType, region, instance, customerID)
      .subscribe({
        next: (result) => {
          this.form.controls.subscriptionID.setValue(result.subscriptionId);
          this.form.controls.azureSubscriptionID.setValue(result.azureSubscriptionId);
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  getSpnAndSqlDetails() {
    this.loaderService.start();
    this.environment = this.form.get('environment') != null ? this.form.get('environment').value : '';
    const environmentType = this.form.get('environmentType') != null ? this.form.get('environmentType').value : '';
    const region = this.form.get('region') != null ? this.form.get('region').value : '';
    const instance = this.form.get('instance') != null ? this.form.get('instance').value : '';
    let customerID = this.form.get('customerID') != null ? this.form.get('customerID').value : '';
    if (this.environment != 'Enterprise') {
      customerID = '';
    }
    this.sub = this.customerEnvironmentService.getMTEnvironmentServicePrincipalDetails(this.environment, environmentType, region, instance, customerID)
      .subscribe({
        next: (result) => {
          this.form.controls.spnObjectID.setValue(result.spnObjectId);
          this.form.controls.sqlApplicationID.setValue(result.sqlApplicationId);
          this.getSubscriptionDetails();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
        }
      });
  }
}
