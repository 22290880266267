import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() public customerDetails: UntypedFormGroup;

  //"Enterprise" to be added in next iteration
  public offeringTypes: Array<string> = ['Standard/Premium', 'Premium Plus', 'Enterprise'];
  public regionTypes: Array<string> = ['East', 'West', 'Canada Central', 'US Central'];
}
