import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewDevTestsLabComponent } from './new-dev-tests-lab/new-dev-tests-lab.component';
import { SharedModule } from '../shared/shared.module';
import { DevTestsLabLogsComponent } from './dev-tests-lab-logs/dev-tests-lab-logs.component';
import { RouterModule } from '@angular/router';
import { ProductVersionComponent } from './new-dev-tests-lab/product-version/product-version.component';
import { InfrastructureComponent } from './new-dev-tests-lab/infrastructure/infrastructure.component';
import { SummaryComponent } from './new-dev-tests-lab/summary/summary.component';
import { DevTestsLabDeploymentsComponent } from './dev-tests-lab-logs/dev-tests-lab-deployments/dev-tests-lab-deployments.component';
import { DevTestsLabEnvsComponent } from './dev-tests-lab-envs/dev-tests-lab-envs.component';

@NgModule({
  declarations: [
    NewDevTestsLabComponent,
    DevTestsLabLogsComponent,
    ProductVersionComponent,
    InfrastructureComponent,
    SummaryComponent,
    DevTestsLabDeploymentsComponent,
    DevTestsLabEnvsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ]
})
export class DevTestsLabModule { }
