import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import moment from 'moment';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-database-refresh-status-run-tasks',
  templateUrl: './database-refresh-status-run-tasks.component.html',
  styleUrls: ['./database-refresh-status-run-tasks.component.scss']
})
export class DatabaseRefreshStatusRunTasksComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(public activatedRoute:ActivatedRoute) {
    super();
  }
  onThisPage=false;
  logData = null;
  log;
  taskTitle = "";
  showTasks = true;
  source = "";
  target = "";

  @HostListener('window:beforeunload')
  async ngOnDestroy()
  {
    this.onThisPage=false;
    this.logData=null;
  }

  ngOnInit(): void {
    this.logData=null;
    this.showTasks = true
    this.onThisPage=true;
    this.buildId = this.activatedRoute.snapshot.paramMap.get('Id');
    this.source = this.activatedRoute.snapshot.paramMap.get('source');
    this.target = this.activatedRoute.snapshot.paramMap.get('target');

    var path = this.activatedRoute.snapshot.routeConfig.path

    if(this.buildId){
      this.loadLogs(this.buildId);
    }
  }

  getDuration(startTime: Date, end: Date): number {
    const duration = moment.duration(moment(end).diff(moment(startTime)));
    return Math.round(duration.asSeconds());
  }

  public expandDetails = (dataItem) => {
    return true
  };

  private sub: Subscription;
  public gridData: GridDataResult;
  public buildId = "";
  public searchClicked = false;

  public state: State = {
    skip: 0,
    take: 15
    };

    public loadLogs(buildId: string) {
      this.loaderService.start();
      this.logData=null;

      this.sub = this.pipelineService.getPipelineRunTimeline(this.configService.MainProject, buildId)
      .subscribe({
        next: (result) => {
          this.logData = result;
          this.loaderService.stop();
          this.pollData();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
    }

    public pollData(){
      this.sub = this.pipelineService.pollPipelineRunTimeline(this.configService.MainProject,this.buildId)
      .subscribe({
        next: async (result) => {
          if(result && result.length > 0  && this.onThisPage){
            this.logData = result;
            await new Promise(f => setTimeout(f, 3000));
            this.pollData();
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
        }
      });
    }

    public getLog(data){
      this.loaderService.start()
      const buildNum: number = +this.buildId;
      this.sub = this.pipelineService.getPipelineRunLog(this.configService.MainProject,buildNum,data.log.id)
      .subscribe({
        next: (result) => {
          this.taskTitle = data.name
          this.showTasks = false
          this.log = ""
          this.log += "\t\t\t" +this.taskTitle.toUpperCase( ) +"\r\n \r\n"
          this.log += result.value.join('\r\n');
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
    }

    public backToAllLogs(){
      if(this.source){
        this.router.navigate([`${Constants.MTDbRefresh}/${this.source}`])
        .then();
      }
    }

    public backToAllTasks(){
      this.loaderService.start()
      this.showTasks = true
      this.loaderService.stop()
    }

    public rowCallback(context: RowClassArgs) {
      if (context.dataItem.job?.state == "completed" && context.dataItem.job?.result == "succeeded") {   // change this condition as you need
        return {
          created: true
        };
      }
      else if (context.dataItem.state == "completed" && context.dataItem.result == "succeeded") {   // change this condition as you need
        return {
          created: true
        };
      }
      else if (context.dataItem.job?.state == "completed" && context.dataItem.job?.result == "failed") {   // change this condition as you need
        return {
          failed: true
        };
      }
      else if (context.dataItem.state == "completed" && context.dataItem.result == "failed") {   // change this condition as you need
        return {
          failed: true
        };
      }
      else if (context.dataItem.job?.state == "completed" && context.dataItem.job?.result == "skipped") {   // change this condition as you need
        return {
          awaitingaction: true
        };
      }
      else if (context.dataItem.state == "completed" && context.dataItem.result == "skipped") {   // change this condition as you need
        return {
          awaitingaction: true
        };
      }
      else if (context.dataItem.job?.state == "inProgress" ) {   // change this condition as you need
        return {
          inprogress : true
        };
      }
      else if (context.dataItem.state == "inProgress") {   // change this condition as you need
        return {
          inprogress: true
        };
      }
      else if (context.dataItem.job?.state == "pending") {   // change this condition as you need
        return {
          notstarted: true
        };
      }
      else if (context.dataItem.state == "pending") {   // change this condition as you need
        return {
          notstarted: true
        };
      }
      else{   // change this condition as you need
        return {
          failed: true
        };
      }

    }

}
