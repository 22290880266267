<div class="w-75 px-4">
    <ng-container [formGroup]="form">
        <kendo-formfield>
            <kendo-label [for]="customerId" text="Customer Id: "></kendo-label>
            <kendo-dropdownlist
            [filterable]="true"
            defaultItem="Select customer Id..."
            #customerId
            formControlName="customerId"
            [data]="filteredCustomeIds"
            [valuePrimitive]="true"
            (filterChange)="handleCustomerIdFilter($event)"
            (valueChange)="onDropDownChanged($event)"
            required
          >
          </kendo-dropdownlist>
          </kendo-formfield>
    
          <kendo-formfield>
            <kendo-label [for]="source">Source</kendo-label>
            <kendo-multicolumncombobox
              #source
              [data]="filteredSources"
              formControlName="source"
              [filterable]="true"
              (filterChange)="handleSourceFilter($event)"
              [textField]="getTextField()"
              [valueField]="getValueField()"
              (valueChange)="onSourceDropDownChanged($event)"
              required
            >
              <kendo-combobox-column field="EnvType" title="Type" [width]="60">
                <ng-template
                  kendoMultiColumnComboBoxColumnCellTemplate
                  let-dataItem
                >
                  {{ isProdMultiTenant(dataItem.Code) }}
                </ng-template>
              </kendo-combobox-column>
              <kendo-combobox-column field="Code" title="Code" [width]="80">
              </kendo-combobox-column>
              <kendo-combobox-column field="Name" title="Name" [width]="200">
              </kendo-combobox-column>
              <ng-template kendoMultiColumnComboBoxFooterTemplate>
                <strong> {{ this.sources.length }} records in total </strong>
              </ng-template>
            </kendo-multicolumncombobox>
          </kendo-formfield>
    
          <kendo-formfield>
            <kendo-label [for]="target">Target</kendo-label>
            <kendo-multicolumncombobox
              #target
              [data]="filteredTargets"
              formControlName="target"
              [filterable]="true"
              (filterChange)="handleTargetFilter($event)"
              [textField]="getTextField()"
              [valueField]="getValueField()"
              (valueChange)="onTargetDropDownChanged($event)"
              required
            >
              <kendo-combobox-column field="EnvType" title="Type" [width]="60">
                <ng-template
                  kendoMultiColumnComboBoxColumnCellTemplate
                  let-dataItem
                >
                  {{ isProdMultiTenant(dataItem.Code) }}
                </ng-template>
              </kendo-combobox-column>
              <kendo-combobox-column field="Code" title="Code" [width]="80">
              </kendo-combobox-column>
              <kendo-combobox-column field="Name" title="Name" [width]="200">
              </kendo-combobox-column>
              <ng-template kendoMultiColumnComboBoxFooterTemplate>
                <strong> {{ this.targets.length }} records in total </strong>
              </ng-template>
            </kendo-multicolumncombobox>
          </kendo-formfield>
    </ng-container>
</div>
