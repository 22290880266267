import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constants } from '../../Constants';
import { BaseComponent } from '../../../components/base.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pat-token',
  templateUrl: './pat-token.component.html',
  styleUrls: ['./pat-token.component.scss']
})
export class PatTokenComponent extends BaseComponent implements OnInit {
  testResult: string;
  private _patToken: string;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    patToken: new UntypedFormControl(this.patToken),
  });

  @Input()
  public set patToken(value: string) {
    this.formGroup.controls.patToken.setValue(value);
    this._patToken = value;
  }

  public get patToken(): string {
    return this._patToken;
  }

  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit(): void {
    this.setTokenValue();
  }

  onSaveToken() {
    this.loaderService.start();
    const patToken = this.formGroup.get('patToken');
    localStorage.setItem(Constants.PatToken, patToken.value);
    this.loaderService.stop();
    this.toastr.success('PAT token is saved successfully!', 'Success!');
  }

  onTestToken() {
    this.loaderService.start();
    this.sendTestRequest()
      .subscribe({
        next: (result) => {
          this.testResult = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.testResult = 'Invalid PAT token!';
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  private sendTestRequest(): Observable<string> {
    const patToken = this.formGroup.get('patToken');
    const testUrl = `https://dev.azure.com/${this.configService.Organization}/_apis/teams?$top=5&stateFilter=All&api-version=6.0-preview.3`;

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`:${patToken.value}`)
      }
    );

    const httpOptions = {
      headers: headers
    };

    return this.http.get<string>(testUrl, httpOptions);
  }

  private setTokenValue() {
    this.formGroup.patchValue({
      patToken: localStorage.getItem(Constants.PatToken),
    });
  }
}
