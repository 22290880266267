<div class="w-75 px-4">
    <ng-container [formGroup]="occupationInsightsForm">
        <kendo-formfield orientation="horizontal">
            <kendo-label [for]="graphAPIKey" text="Graph API Key: "></kendo-label>
            <input
              formControlName="graphAPIKey"
              kendoTextBox
              #graphAPIKey
            />
          </kendo-formfield>
    
          <kendo-formfield orientation="horizontal">
            <kendo-label [for]="syncAPIKey" text="SQL Sync API Key: "></kendo-label>
            <input
              formControlName="syncAPIKey"
              kendoTextBox
              #syncAPIKey
            />
          </kendo-formfield>
    </ng-container>
</div>
