<div class="container wrapper pt-2">
    <kendo-tabstrip
    [tabAlignment]="alignment"
    [tabPosition]="position"
    (tabSelect)="onSelect($event)">
        <kendo-tabstrip-tab
          *ngFor="let item of items; let i = index"
          [title]="item.name"
          [selected]="i === selected"
        >
          <ng-template kendoTabContent>

            <div *ngIf="showStudentAAD && !showStudentInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm">
                <fieldset class="k-form-fieldset">
                <legend class="k-form-legend">Student AAD Settings From Customer</legend>
                <div class="d-grid gap-3">
                    <div class="p-2 bg-light border">
                      <span><b>Student Tenant Id:</b> {{item.appRegDetails.studentTenantId}}</span>
                    </div>

                    <div class="p-2 bg-light border">
                        <span><b>Student Client Id:</b> {{item.appRegDetails.aadStudentClientId}}</span>

                    </div>

                    <div class="p-2 bg-light border">
                      <div>
                        <span><b>Student Client Secret:</b></span>
                      </div>
                      <div>
                          <input
                            [value]="clientSecret"
                            kendoTextBox
                            readonly
                            class="col-11"
                          />
                          <button kendoButton fillMode="clear" icon="lock" (click)="togglePassword()"></button>
                      </div>
                    </div>
                    <div class="pt-2 row" *ngIf = "showEditField">
                      <div class="col-6">
                        <button
                        class="k-button"
                        kendoButton
                        themeColor="primary"
                        (click)="toggleEditStudentSettings()"
                    >
                        Edit Student Settings
                    </button>
                      </div>
                      <div class = "col-6">
                        <button
                        class="k-button importFile"
                        kendoButton
                        themeColor="secondary"
                        icon="paste-plain-text"
                        onclick="document.getElementById('getFile').click()">Import App Registration File</button>
                        <input id="getFile" style="visibility:hidden;" type="file" (change)="fileChanged($event)">
                    </div>
              </div>
                </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showStudentAAD && showStudentInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm" [formGroup]="aadSettings">
                <fieldset class="k-form-fieldset">
                <legend class="k-form-legend">Student AAD Settings From Customer</legend>
                <div class="d-grid gap-3">
                    <!-- <div class="p-2 bg-light border">
                      <span><b>Student Tenant Id:</b> {{item.appRegDetails.tenantId}}</span>
                    </div> -->
                    <kendo-formfield orientation="horizontal" class="mt-0">
                    <kendo-label [for]="studentTenantId" text="Student Tenant Id: "></kendo-label>
                    <input
                    formControlName="studentTenant"
                      kendoTextBox
                      #studentTenantId
                      required
                    />
                  </kendo-formfield>

                    <!-- <div class="p-2 bg-light border">
                        <span><b>Student Client Id:</b> {{item.appRegDetails.aadStudentClientId}}</span>

                    </div> -->
                    <kendo-formfield orientation="horizontal" class="mt-0">
                    <kendo-label [for]="aadStudentClientId" text="Student Client Id: "></kendo-label>
                    <input
                    formControlName="studentClient"
                      kendoTextBox
                      #aadStudentClientId
                      required
                    />
                  </kendo-formfield>
                    <!-- <div class="p-2 bg-light border">
                        <span><b>Student Client Secret:</b> {{item.appRegDetails.aadStudentClientSecret}}</span>

                    </div> -->
                    <kendo-formfield orientation="horizontal" class="mt-0">
                    <kendo-label [for]="aadStudentClientSecret" text="Student Client Secret: "></kendo-label>
                    <input
                    formControlName="studentClientSecret"
                      kendoTextBox
                      #aadStudentClientSecret
                      required
                    />
                  </kendo-formfield>

                  <div class="pt-2 row">
                    <div class="col-6">
                      <button
                      class="k-button"
                      kendoButton
                      themeColor="primary"
                      (click)="addStudentSettings()"
                  >
                      Add Student Settings
                  </button>
                    </div>
                <div class = "col-6">
                  <button
                  class="k-button importFile"
                  kendoButton
                  themeColor="secondary"
                  icon="paste-plain-text"
                  onclick="document.getElementById('getFile').click()">Import App Registration File</button>
                  <input id="getFile" style="visibility:hidden;" type="file" (change)="fileChanged($event)">
              </div>
            </div>
                </div>
                </fieldset>
            </form>

            </div>


            <div *ngIf="showStaffAAD && !showStaffInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">Staff AAD Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid gap-3">
                      <div class="p-2 bg-light border">
                        <span><b>Staff Tenant Id:</b> {{item.appRegDetails.staffTenantId}}</span>
                      </div>
                      <div class="p-2 bg-light border">
                          <span><b>Staff Client Id:</b> {{item.appRegDetails.aadStaffClientId}}</span>
                      </div>
                      <div class="p-2 bg-light border">
                          <div>
                          <span><b>Staff Client Secret:</b></span>
                        </div>
                          <div>
                            <input
                              [value]="staffSecret"
                              kendoTextBox
                              readonly
                              class="col-11"
                            />
                            <button kendoButton fillMode="clear" icon="lock" (click)="toggleStaffPassword()"></button>
                          </div>
                        </div>
                      <div class="pt-2" *ngIf = "showEditField">
                        <button
                        class="k-button"
                        kendoButton
                        themeColor="primary"
                        (click)="toggleEditStaffSettings()"
                    >
                        Edit Staff Settings
                    </button>

                </div>
                    </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showStaffAAD && showStaffInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm" [formGroup]="aadSettings">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">Staff AAD Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid gap-3">
                      <!-- <div class="p-2 bg-light border">
                        <span><b>Staff Tenant Id:</b> {{item.appRegDetails.tenantId}}</span>
                      </div> -->
                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="staffTenantId" text="Staff Tenant Id: "></kendo-label>
                      <input
                      formControlName="staffTenant"
                        kendoTextBox
                        #staffTenantId
                        required
                      />
                      </kendo-formfield>

                      <!-- <div class="p-2 bg-light border">
                          <span><b>Staff Client Id:</b> {{item.appRegDetails.aadStaffClientId}}</span>
                      </div> -->

                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadStaffClientId" text="Staff Client Id: "></kendo-label>
                      <input
                      formControlName="staffClient"
                        kendoTextBox
                        #aadStaffClientId
                        required
                      />
                    </kendo-formfield>
                    <kendo-formfield orientation="horizontal">
                      <kendo-label [for]="aadStaffClientSecret" text="Staff Client Secret: "></kendo-label>
                      <input
                      formControlName="staffClientSecret"
                        kendoTextBox
                        #aadStaffClientSecret
                        required
                      />
                    </kendo-formfield>
                    <div class="pt-3">
                      <button
                      class="k-button"
                      kendoButton
                      themeColor="primary"
                      (click)="addStaffSettings()"
                  >
                      Add Staff Settings
                  </button>

              </div>
                    </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showStaffAAD2 && !showStaffInput2()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">Staff AAD Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid gap-1">
                      <div class="p-3 bg-light border">
                        <span><b>NativeApp Client Id:</b> {{item.appRegDetails.aadNativeAppClientID}}</span>
                      </div>
                      <div class="p-3 bg-light border">
                        <span><b>WorkflowWebAPI Client Id:</b> {{item.appRegDetails.aadWorkflowWebAPIClientID}}</span>
                      </div>
                      <div class="p-3 bg-light border">
                        <span><b>Mailbox Client Id:</b> {{item.appRegDetails.mailboxClientId}}</span>
                      </div>
                      <div class="pt-2" *ngIf = "showEditField">
                        <button
                        class="k-button"
                        kendoButton
                        themeColor="primary"
                        (click)="toggleEditStaffSettings()"
                    >
                        Edit Staff Settings
                    </button>

                </div>
                    </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showStaffAAD2 && showStaffInput2()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm" [formGroup]="aadSettings">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">Staff AAD Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid gap-1">
                      <!-- <div class="p-2 bg-light border">
                        <span><b>NativeApp Client Id:</b> {{item.appRegDetails.aadNativeAppClientID}}</span>
                      </div> -->

                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadNativeAppClientID" text="NativeApp Client Id: "></kendo-label>
                      <input
                      formControlName="nativeClient"
                        kendoTextBox
                        #aadNativeAppClientID
                        required
                      />
                    </kendo-formfield>
                      <!-- <div class="p-2 bg-light border">
                        <span><b>WorkflowWebAPI Client Id:</b> {{item.appRegDetails.aadWorkflowWebAPIClientID}}</span>
                      </div> -->

                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadWorkflowWebAPIClientID" text="WorkflowWebAPI Client Id: "></kendo-label>
                      <input
                      formControlName="workflowClient"
                        kendoTextBox
                        #aadNativeAppClientID
                        required
                      />
                    </kendo-formfield>
                    <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="mailboxClientID" text="Mailbox Client Id: "></kendo-label>
                      <input
                      formControlName="mailboxClient"
                        kendoTextBox
                        #mailboxClientID
                        required
                      />
                    </kendo-formfield>
                    <div class="pt-3">
                      <button
                      class="k-button"
                      kendoButton
                      themeColor="primary"
                      (click)="addStaffSettings2()"
                  >
                      Add Staff Settings
                  </button>

              </div>
                    </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showAppIdUriAAD && !showAppIdUriInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">APP ID URI Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid gap-1">
                      <div class="p-2 bg-light border">
                        <span><b>Staff App ID URI:</b> {{item.appRegDetails.aadStaffAppIDUri}}</span>
                      </div>
                      <div class="p-2 bg-light border">
                        <span><b>Student App ID URI:</b> {{item.appRegDetails.aadStudentAppIDUri}}</span>
                      </div>
                      <div class="p-2 bg-light border">
                        <span><b>AdminConsole App ID URI:</b> {{item.appRegDetails.aadAdminConsoleAppIDUri}}</span>
                      </div>
                      <div class="p-2 bg-light border">
                        <span><b>Workflow App ID URI:</b> {{item.appRegDetails.aadWorkflowWebAPIAppIDUri}}</span>
                      </div>

                      <div class="pt-3" *ngIf = "showEditField">
                        <button
                        class="k-button"
                        kendoButton
                        themeColor="primary"
                        (click)="toggleEditAppIdUriSettings()"
                    >
                        Edit AppIdUri Settings
                        </button>
                      </div>

                  </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showAppIdUriAAD && showAppIdUriInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm" [formGroup]="aadSettings">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">APP ID URI Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid">

                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadStaffAppIDUri" text="Staff App ID URI: "></kendo-label>
                      <input
                      formControlName="aadStaffAppIDUri"
                        kendoTextBox
                        #aadStaffAppIDUri
                        required
                      />
                    </kendo-formfield>

                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadStudentAppIDUri" text="Student App ID URI: "></kendo-label>
                      <input
                      formControlName="aadStudentAppIDUri"
                        kendoTextBox
                        #aadStudentAppIDUri
                        required
                      />
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadAdminConsoleAppIDUri" text="AdminConsole App ID URI: "></kendo-label>
                      <input
                      formControlName="aadAdminConsoleAppIDUri"
                        kendoTextBox
                        #aadAdminConsoleAppIDUri
                        required
                      />
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadWorkflowWebAPIAppIDUri" text="Workflow ID URI: "></kendo-label>
                      <input
                      formControlName="aadWorkflowWebAPIAppIDUri"
                        kendoTextBox
                        #aadWorkflowWebAPIAppIDUri
                        required
                      />
                    </kendo-formfield>
                    <div>
                      <button
                      class="k-button"
                      kendoButton
                      themeColor="primary"
                      (click)="addAppIdUriSettings()"
                  >
                      Add AppIdUri Settings
                  </button>

              </div>
                    </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showFbAAD && !showFbInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">APP ID URI Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid gap-2">
                      <div class="p-2 bg-light border">
                        <span><b>Fb Designer App ID URI:</b> {{item.appRegDetails.fbDesignerAppIdUri}}</span>
                      </div>
                      <div class="p-2 bg-light border">
                        <span><b>Fb Renderer App ID URI:</b> {{item.appRegDetails.fbRendererAppIdUri}}</span>
                      </div>
                      <div class="p-2 bg-light border">
                        <span><b>ConfigTool App ID URI:</b> {{item.appRegDetails.aadConfigToolAppIDUri}}</span>
                      </div>
                      <div class="pt-4" *ngIf = "showEditField">
                        <button
                        class="k-button"
                        kendoButton
                        themeColor="primary"
                        (click)="toggleEditFbSettings()"
                    >
                        Edit AppIdUri Settings
                    </button>

                      </div>
                    </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showFbAAD && showFbInput()" class="tabheight">
              <form class="k-form k-form-horizontal appUserForm" [formGroup]="aadSettings">
                <fieldset class="k-form-fieldset">
                  <legend class="k-form-legend">APP ID URI Settings From Customer</legend>
                  <div class="w-100">
                    <div class="d-grid gap-1">
                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="fbDesignerAppIdUri" text="Fb Designer App ID URI: "></kendo-label>
                      <input
                      formControlName="fbDesignerAppIdUri"
                        kendoTextBox
                        #fbDesignerAppIdUri
                        required
                      />
                    </kendo-formfield>

                      <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="fbRendererAppIdUri" text="Fb Renderer App ID URI: "></kendo-label>
                      <input
                      formControlName="fbRendererAppIdUri"
                        kendoTextBox
                        #fbRendererAppIdUri
                        required
                      />
                    </kendo-formfield>

                    <kendo-formfield orientation="horizontal" class="mt-0">
                      <kendo-label [for]="aadConfigToolAppIDUri" text="ConfigTool App ID URI: "></kendo-label>
                      <input
                      formControlName="aadConfigToolAppIDUri"
                        kendoTextBox
                        #aadConfigToolAppIDUri
                        required
                      />
                    </kendo-formfield>

                    <div>
                      <button
                      class="k-button"
                      kendoButton
                      themeColor="primary"
                      (click)="addFbSettings()"
                  >
                      Add AppIdUri Settings
                  </button>

              </div>
                    </div>
                  </div>
                </fieldset>
            </form>
            </div>

            <div *ngIf="showAPIUser" class="tabheight">
              <form class="k-form appUserForm" [formGroup]="aadApiDetails">
                <fieldset class="k-form-fieldset">
                <legend class="k-form-legend">Api User Details Per {{item.name}} Customer</legend>
                <div class="w-50">
                    <kendo-formfield>
                        <kendo-label [for]="apiUser" text="API User: "></kendo-label>
                      <input
                      formControlName="apiUser"
                      kendoTextBox
                      #apiUser
                      (input)="onValueChange($event)"
                    />

                      </kendo-formfield>

                      <kendo-formfield>
                        <kendo-label [for]="apiPwd" text="API User Password: "></kendo-label>
                        <div>
                          <input
                          formControlName="apiPwd"
                          kendoTextBox
                          type = "{{ showPassword ? 'text' : 'password' }}"
                          (input)="onValueChange($event)"
                          #apiPwd
                        />
                        <kendo-icon name="eye" class="eye" (click)="togglePasswordDisplay()"></kendo-icon>
                        </div>

                      </kendo-formfield>

                      <div class="k-form-buttons k-buttons-end w-100">
                        <div>
                          <button
                          class="k-button"
                          kendoButton
                          themeColor="primary"
                          (click)="testCredentials()"
                        >
                          Test
                        </button>
                        </div>

                      <div *ngIf = "showEditField">
                        <button
                        class="k-button"
                        kendoButton
                        themeColor="primary"
                        (click)="addCredentials(false,null)"
                      >
                        Add
                      </button>
                      </div>

                      </div>
                </div>
                </fieldset>
            </form>
            </div>

            <!-- <div class="row d-flex justify-content-center flex-wrap" *ngIf ="item.appRegDetails.tenantId == null">
              <div class="alert alert-danger" role="alert">
                <div class="row justify-content-center p-2 fw-bold">
                  App registration details not found for {{item.name}} environment!
                </div>
                <div class="row justify-content-center p-2 fw-bold">
                  Please make sure you have executed the app registration script for {{item.name}} environment!
                </div>
                <div class="row justify-content-center p-2 fw-bold">
                  Contact administrator if you are unable to solve this issue!
                </div>
              </div>
          </div> -->
          </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>

    <div *ngIf="showStudentAAD">
      <div class="k-form-buttons iconButton mt-0">
        <div class="col-5">
          <span class="page">Page 1 of 6</span></div>
          <button
          kendoButton
          fillMode="clear"
          icon="arrow-right"
          themeColor="primary"
          (click)="GoToStaffAAD()"
      >
          </button>
      </div>
    </div>
    <div *ngIf="showStaffAAD">
        <div class="k-form-buttons mt-0">
          <div class="col-4">
            <span class="page">Page 2 of 6</span>
            </div>
            <div class="row">
              <div class = "col-6 iconButton">
                <button
                class="k-button"
                kendoButton
                fillMode="clear"
                icon="arrow-left"
                themeColor="secondary"
                (click)="GoToStudentAAD()"
            >
            </button>
              </div>
          <div class = "col-6 iconButton">
            <button
            kendoButton
            fillMode="clear"
            icon="arrow-right"
            themeColor="primary"
            (click)="GoToStaffAAD2()"
        >
                </button>
          </div>
            </div>

    </div>
    </div>
    <div *ngIf="showStaffAAD2">
      <div class="k-form-buttons mt-0">
        <div class="col-4">
          <span class="page">Page 3 of 6</span>
          </div>
          <div class="row">
            <div class = "col-6 iconButton">
              <button
              class="k-button"
              kendoButton
              fillMode="clear"
              icon="arrow-left"
              themeColor="secondary"
              (click)="GoToStaffAAD()"
          >
          </button>
            </div>
        <div class = "col-6 iconButton">
          <button
          kendoButton
          fillMode="clear"
          icon="arrow-right"
          themeColor="primary"
          (click)="GoToAppIdUriAAD()"
      >
              </button>
        </div>
          </div>

      </div>
    </div>
    <div *ngIf="showAppIdUriAAD">
      <div class="k-form-buttons mt-0">
        <div class="col-4">
          <span class="page">Page 4 of 6</span>
          </div>
          <div class="row">
            <div class = "col-6 iconButton">
              <button
              class="k-button"
              kendoButton
              fillMode="clear"
              icon="arrow-left"
              themeColor="secondary"
              (click)="GoToStaffAAD2()"
          >
          </button>
            </div>
        <div class = "col-6 iconButton">
          <button
          kendoButton
          fillMode="clear"
          icon="arrow-right"
          themeColor="primary"
          (click)="GoToFbAAD()"
      >
              </button>
        </div>
          </div>

      </div>
    </div>
    <div *ngIf="showFbAAD">
      <div class="k-form-buttons mt-0">
        <div class="col-4">
          <span class="page">Page 5 of 6</span>
          </div>
          <div class="row">
            <div class = "col-6 iconButton">
              <button
              class="k-button"
              kendoButton
              fillMode="clear"
              icon="arrow-left"
              themeColor="secondary"
              (click)="GoToAppIdUriAAD()"
          >
          </button>
            </div>
        <div class = "col-6 iconButton">
          <button
          kendoButton
          fillMode="clear"
          icon="arrow-right"
          themeColor="primary"
          (click)="GoToAPIUser()"
      >
              </button>
        </div>
          </div>

      </div>
    </div>
    <div *ngIf="showAPIUser">
      <div class="k-form-buttons mt-0">
        <div class="col-4">
          <span class="page">Page 6 of 6</span>
        </div>
        <div class="iconButton">
          <button
          class="k-button"
          kendoButton
          fillMode="clear"
          icon="arrow-left"
          themeColor="secondary"
          (click)="GoToFbAAD()"
        >
        </button>
        </div>
      </div>
    </div>
</div>
