import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { first, switchMap } from 'rxjs/operators';
import { Constants } from '../../../../shared/Constants';
import { Location } from '@angular/common';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent extends BaseComponent implements OnInit {
  log = '';

  constructor(private location: Location) {
    super();
  }

  ngOnInit(): void {
    this.subscribeChanges();
  }

  private subscribeChanges() {
    this.loaderService.start();
    this.dataService.Task
      .pipe(first())
      .pipe(
        switchMap(t => {
          const url = t.logUrl.replace(
            `https://${Constants.AZURE_RM_DOMAIN}/${this.configService.Organization}`,
            `${window.location.origin}/api/ado/releases/tasklog`);
          return this.generalService.getLog(url);
        }))
      .subscribe({
        next: (l) => {
          this.log = l;
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  onGoBack() {
    this.location.back();
  }
}
