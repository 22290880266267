export class DtoBase {
    // CREATEDBY
    public createdBy: string = null;
    // CREATEDDATE
    public createdDate: Date = new Date(0);
    // MODIFIEDBY
    public modifiedBy: string = null;
    // MODIFIEDDATE
    public modifiedDate: Date = new Date(0);
}

