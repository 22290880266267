import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TabAlignment, TabPosition } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { AppRegistrationDetails } from 'src/app/shared/models/AppRegistrationDetails';

@Component({
  selector: 'app-aad-setting-details',
  templateUrl: './aad-setting-details.component.html',
  styleUrls: ['./aad-setting-details.component.scss']
})
export class AadSettingDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  customerOnboardingRequest = [];
  credentialTested = false;
  credentialValid = false;

  constructor() {
    super();
  }

  public alignment: TabAlignment = 'start';
  public position: TabPosition = 'left';
  public customerOnBoarding: any;
  public selected = 0;
  public currentTab = 0;
  public currentEnv = '';
  public appRegDetails: AppRegistrationDetails;
  public filesCreated = false;
  public clientSecretMasked = true;
  public clientSecret = '';
  public staffSecretMasked = true;
  public staffSecret = '';
  public editStudentSettings = false;
  public editStaffSettings = false;
  public editStaffSettings2 = false;
  public editFbSettings = false;
  public editAppIdUriSettings = false;
  public showPassword = false;
  public showMailboxClientIdError = false;
  public showEditField = true;
  @Output() customerOnboardingRequestEmit = new EventEmitter<any>();
  @Output() itemEmit = new EventEmitter<any>();
  public file: any;
  headers: Headers;
  customerId: any;
  // public items: any = [
  //   {
  //     disabled: false,
  //     name: "3000-Tst"
  //   },
  //   {
  //     disabled: false,
  //     name: "3000-Dev"
  //   },
  //   {
  //     disabled: false,
  //     name: "3000-Uat"
  //   },
  // ];
  public items: any = [];

  // public items: any = [
  //   {
  //     disabled: false,
  //     name: "Production"
  //   }
  // ];

  ngOnDestroy(): void {
    if (this.sub != undefined && this.sub != null) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getFormInStorage();
    var environments = this.customerOnBoarding.environments.split(',');
    var customerId = this.customerOnBoarding.customerId;
    if (customerId.startsWith('4')) {
      this.showEditField = false;
    } else {
      this.showEditField = true;
    }
    environments.forEach(element => {
      const item = {
        disabled: false,
        name: element
      };
      this.items.push(item);
    });
    const env = (this.items[0].name == 'Production' || !(this.items[0].name.includes('-'))) ? 'Prod' : this.items[0].name.split('-')[1];
    this.currentEnv = env;
    this.customerId = customerId;
    this.getAppRegistrationDetails(customerId, env, this.items[0]);
    this.itemEmit.emit(this.items);

  }

  public aadApiDetails = new UntypedFormGroup({
    apiUser: new UntypedFormControl(),
    apiPwd: new UntypedFormControl()
  });

  public aadSettings = new UntypedFormGroup({
    studentTenant: new UntypedFormControl(),
    studentClient: new UntypedFormControl(),
    studentClientSecret: new UntypedFormControl(),
    staffTenant: new UntypedFormControl(),
    staffClient: new UntypedFormControl(),
    staffClientSecret: new UntypedFormControl(),
    nativeClient: new UntypedFormControl(),
    fbDesignerAppIdUri: new UntypedFormControl(),
    fbRendererAppIdUri: new UntypedFormControl(),
    workflowClient: new UntypedFormControl(),
    aadStaffAppIDUri: new UntypedFormControl(),
    aadStudentAppIDUri: new UntypedFormControl(),
    aadAdminConsoleAppIDUri: new UntypedFormControl(),
    aadWorkflowWebAPIAppIDUri: new UntypedFormControl(),
    aadConfigToolAppIDUri: new UntypedFormControl(),
    mailboxClient: new UntypedFormControl()
  });
  showStaffAAD = false;
  showStaffAAD2 = false;
  showStudentAAD = true;
  showAppIdUriAAD = false;
  showFbAAD = false;
  showAPIUser = false;

  public GoToStudentAAD() {
    this.editStaffSettings = false;
    this.editStaffSettings2 = false;
    this.editStudentSettings = false;
    this.editFbSettings = false;
    this.editAppIdUriSettings = false;
    this.showStudentAAD = true;
    this.showAPIUser = false;
    this.showStaffAAD = false;
    this.showStaffAAD2 = false;
    this.showFbAAD = false;
    this.showAppIdUriAAD = false;
  }

  public GoToStaffAAD() {
    this.editStaffSettings = false;
    this.editStaffSettings2 = false;
    this.editStudentSettings = false;
    this.editFbSettings = false;
    this.editAppIdUriSettings = false;
    this.showStudentAAD = false;
    this.showAPIUser = false;
    this.showStaffAAD = true;
    this.showStaffAAD2 = false;
    this.showFbAAD = false;
    this.showAppIdUriAAD = false;
  }

  public GoToStaffAAD2() {
    this.editStaffSettings = false;
    this.editStaffSettings2 = false;
    this.editStudentSettings = false;
    this.editFbSettings = false;
    this.editAppIdUriSettings = false;
    this.showStudentAAD = false;
    this.showAPIUser = false;
    this.showStaffAAD = false;
    this.showStaffAAD2 = true;
    this.showFbAAD = false;
    this.showAppIdUriAAD = false;
  }

  public GoToAPIUser() {
    this.showPassword = false;
    this.editStaffSettings = false;
    this.editStaffSettings2 = false;
    this.editStudentSettings = false;
    this.editFbSettings = false;
    this.editAppIdUriSettings = false;
    this.showStudentAAD = false;
    this.showAPIUser = true;
    this.showStaffAAD = false;
    this.showStaffAAD2 = false;
    this.showFbAAD = false;
    this.showAppIdUriAAD = false;
  }

  public GoToFbAAD() {
    this.showPassword = false;
    this.editStaffSettings = false;
    this.editStaffSettings2 = false;
    this.editStudentSettings = false;
    this.editFbSettings = false;
    this.editAppIdUriSettings = false;
    this.showStudentAAD = false;
    this.showAPIUser = false;
    this.showStaffAAD = false;
    this.showStaffAAD2 = false;
    this.showFbAAD = true;
    this.showAppIdUriAAD = false;
  }

  public GoToAppIdUriAAD() {
    this.showPassword = false;
    this.editStaffSettings = false;
    this.editStaffSettings2 = false;
    this.editStudentSettings = false;
    this.editFbSettings = false;
    this.editAppIdUriSettings = false;
    this.showStudentAAD = false;
    this.showAPIUser = false;
    this.showStaffAAD = false;
    this.showStaffAAD2 = false;
    this.showFbAAD = false;
    this.showAppIdUriAAD = true;
  }

  public onSelect(data) {
    var username = this.aadApiDetails.get('apiUser').value;
    var password = this.aadApiDetails.get('apiPwd').value;
    if (username && password) {
      this.addCredentials(true, data);
    } else {
      this.switchTab(data);
    }

  }

  public isSubmitDisabled() {
    let flag = false;
    this.items.forEach(element => {
      if (element.appRegDetails == undefined || element.appRegDetails == null || element.appRegDetails.staffTenantId == null || element.appRegDetails.studentTenantId == null) {
        flag = true;
      }
    });
    return flag;
  }

  public getFormInStorage() {
    this.customerOnBoarding = JSON.parse(localStorage.getItem('CustOnBoardingForm'));
  }

  public backToApp() {
    this.router.navigate(['/CustomerOnboarding/AppUserDetails']);
  }

  public getAppRegistrationDetails(customerId, environment, item) {
    this.loaderService.start();
    this.sub = this.appRegistrationService.getAppRegistrationDetails(customerId, environment)
      .subscribe({
        next: (result) => {
          item.appRegDetails = result;
          const customerOnBoarding = JSON.parse(JSON.stringify(this.customerOnBoarding));
          customerOnBoarding.staffTenantId = item.appRegDetails.staffTenantId;
          customerOnBoarding.studentTenantId = item.appRegDetails.studentTenantId;
          customerOnBoarding.environment = environment;
          this.customerOnboardingRequest.push(customerOnBoarding);

          if (item.appRegDetails.staffTenantId != null && item.appRegDetails.studentTenantId != null) {
            this.populateClientSecret();
            this.populateStaffSecret();
          }

          this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
          this.loaderService.stop();
          this.getCredentials();
          this.initializeForm();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public populateClientSecret() {
    this.clientSecret = this.items[this.currentTab].appRegDetails.aadStudentClientSecret;
    if (this.clientSecretMasked) {
      this.clientSecret = '';
      for (var i = 0; i < this.items[this.currentTab].appRegDetails.aadStudentClientSecret.length; i++) {
        this.clientSecret += '*';
      }
    }
  }

  public populateStaffSecret() {
    this.staffSecret = this.items[this.currentTab].appRegDetails.aadStaffClientSecret;
    if (this.staffSecretMasked) {
      this.staffSecret = '';
      for (var i = 0; i < this.items[this.currentTab].appRegDetails.aadStaffClientSecret.length; i++) {
        this.staffSecret += '*';
      }
    }
  }

  public togglePassword() {
    this.clientSecretMasked = !this.clientSecretMasked;
    this.populateClientSecret();
  }

  public toggleStaffPassword() {
    this.staffSecretMasked = !this.staffSecretMasked;
    this.populateStaffSecret();
  }

  public testCredentials() {
    var username = this.aadApiDetails.get('apiUser').value;
    var password = this.aadApiDetails.get('apiPwd').value;

    this.credentialTested = false;
    this.credentialValid = false;

    this.loaderService.start();
    this.sub = this.appRegistrationService.validateCredentials(username, password, this.customerOnBoarding.customerId, this.currentEnv)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.toastr.success('Credential valid!');
          this.credentialValid = true;
          this.credentialTested = true;
          this.customerOnboardingRequest.forEach(request => {
            if (request.environment === this.currentEnv) {
              request.apiUser = username;
              request.apiPassword = password;
            }
          });
          this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
        },
        error: (error) => {
          this.loaderService.stop();
          this.credentialValid = false;
          this.credentialTested = true;
        },
        complete: () => {
          this.credentialTested = true;
          this.loaderService.stop();
        }

      });

  }

  public addCredentials(fromTabChange, data) {
    var username = this.aadApiDetails.get('apiUser').value;
    var password = this.aadApiDetails.get('apiPwd').value;
    this.loaderService.start();
    this.sub = this.appRegistrationService.addUserDetailsForAppRegstration(username, password, this.customerOnBoarding.customerId, this.currentEnv, false)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.customerOnboardingRequest.forEach(request => {
            if (request.environment === this.currentEnv) {
              request.apiUser = username;
              request.apiPassword = password;
            }
          });
          this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
          if (fromTabChange) {
            this.switchTab(data);
          }
        }

      });
  }


  private switchTab(data: any) {
    this.aadSettings.reset();
    this.currentTab = data.index;
    this.credentialTested = false;
    this.clientSecretMasked = true;
    this.staffSecretMasked = true;
    this.editStaffSettings = false;
    this.editStaffSettings2 = false;
    this.editFbSettings = false;
    this.editAppIdUriSettings = false;
    this.editStudentSettings = false;
    this.showPassword = false;
    this.aadApiDetails.reset();
    // this.showStudentAAD = false;
    // this.showAPIUser = true;
    // this.showStaffAAD = false;
    if (this.items[data.index].appRegDetails == undefined || this.items[data.index].appRegDetails == null) {
      var env = this.items[data.index].name == 'Production' ? 'Prod' : this.items[data.index].name.split('-')[1];
      this.currentEnv = env;
      this.getAppRegistrationDetails(this.customerOnBoarding.customerId, env, this.items[data.index]);
    } else {
      var env = this.items[data.index].name == 'Production' ? 'Prod' : this.items[data.index].name.split('-')[1];
      this.currentEnv = env;
      if (this.items[data.index].appRegDetails.staffTenantId != null && this.items[data.index].appRegDetails.studentTenantId != null) {
        this.populateClientSecret();
        this.populateStaffSecret();
      }

      this.initializeForm();
      this.getCredentials();
    }
    this.itemEmit.emit(this.items);
  }

  public getCredentials() {
    this.loaderService.start();
    this.sub = this.appRegistrationService.getUserDetailsForAppRegstration(this.customerOnBoarding.customerId, this.currentEnv, false)
      .subscribe({
        next: (result) => {
          if (result) {
            this.aadApiDetails.controls.apiUser.setValue(result.appRegistrationId);
            this.aadApiDetails.controls.apiPwd.setValue(result.secretKey);
            var username = this.aadApiDetails.get('apiUser').value;
            var password = this.aadApiDetails.get('apiPwd').value;
            this.customerOnboardingRequest.forEach(request => {
              if (request.environment === this.currentEnv) {
                request.apiUser = username;
                request.apiPassword = password;
              }
            });
            this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
          }
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public toggleEditStudentSettings() {
    this.aadSettings.controls.studentTenant.setValue(this.customerOnboardingRequest[this.currentTab].studentTenantId);
    this.aadSettings.controls.studentTenant.setValue(this.items[this.currentTab].appRegDetails.studentTenantId);
    this.aadSettings.controls.studentClient.setValue(this.items[this.currentTab].appRegDetails.aadStudentClientId);
    this.aadSettings.controls.studentClientSecret.setValue(this.items[this.currentTab].appRegDetails.aadStudentClientSecret);
    this.editStudentSettings = true;
  }

  public addStudentSettings() {
    this.editStudentSettings = false;
    this.customerOnboardingRequest[this.currentTab].studentTenantId = this.aadSettings.get('studentTenant').value;
    this.items[this.currentTab].appRegDetails.studentTenantId = this.aadSettings.get('studentTenant').value;
    this.items[this.currentTab].appRegDetails.aadStudentClientId = this.aadSettings.get('studentClient').value;
    this.items[this.currentTab].appRegDetails.aadStudentClientSecret = this.aadSettings.get('studentClientSecret').value;
    this.itemEmit.emit(this.items);
    this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
    this.populateClientSecret();
    this.loaderService.start();
    this.sub = this.appRegistrationService.addAppRegistrationsForStudent(this.items[this.currentTab].appRegDetails, this.customerOnBoarding.customerId, this.currentEnv)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public toggleEditStaffSettings() {
    this.aadSettings.controls.staffTenant.setValue(this.customerOnboardingRequest[this.currentTab].staffTenantId);
    this.aadSettings.controls.staffTenant.setValue(this.items[this.currentTab].appRegDetails.staffTenantId);
    this.aadSettings.controls.staffClient.setValue(this.items[this.currentTab].appRegDetails.aadStaffClientId);
    this.aadSettings.controls.staffClientSecret.setValue(this.items[this.currentTab].appRegDetails.aadStaffClientSecret);
    this.aadSettings.controls.nativeClient.setValue(this.items[this.currentTab].appRegDetails.aadNativeAppClientID);
    this.aadSettings.controls.workflowClient.setValue(this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIClientID);
    this.aadSettings.controls.mailboxClient.setValue(this.items[this.currentTab].appRegDetails.mailboxClientId);
    this.editStaffSettings = true;
    this.editStaffSettings2 = true;
  }

  public toggleEditFbSettings() {

    this.aadSettings.controls.fbDesignerAppIdUri.setValue(this.items[this.currentTab].appRegDetails.fbDesignerAppIdUri);
    this.aadSettings.controls.fbRendererAppIdUri.setValue(this.items[this.currentTab].appRegDetails.fbRendererAppIdUri);
    this.aadSettings.controls.aadConfigToolAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadConfigToolAppIDUri);
    this.editFbSettings = true;
  }

  public toggleEditAppIdUriSettings() {

    this.aadSettings.controls.aadStaffAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadStaffAppIDUri);
    this.aadSettings.controls.aadStudentAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadStudentAppIDUri);
    this.aadSettings.controls.aadAdminConsoleAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadAdminConsoleAppIDUri);
    this.aadSettings.controls.aadWorkflowWebAPIAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIAppIDUri);
    this.editAppIdUriSettings = true;
  }


  public addStaffSettings() {
    this.editStaffSettings = false;
    this.customerOnboardingRequest[this.currentTab].staffTenantId = this.aadSettings.get('staffTenant').value;
    this.items[this.currentTab].appRegDetails.staffTenantId = this.aadSettings.get('staffTenant').value;
    this.items[this.currentTab].appRegDetails.aadStaffClientId = this.aadSettings.get('staffClient').value;
    this.items[this.currentTab].appRegDetails.aadStaffClientSecret = this.aadSettings.get('staffClientSecret').value;
    this.itemEmit.emit(this.items);
    this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
    this.populateStaffSecret();
    this.loaderService.start();
    this.sub = this.appRegistrationService.addAppRegistrationsForStaff(this.items[this.currentTab].appRegDetails, this.customerOnBoarding.customerId, this.currentEnv)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public addFbSettings() {
    this.editFbSettings = false;
    this.items[this.currentTab].appRegDetails.fbRendererAppIdUri = this.aadSettings.get('fbRendererAppIdUri').value;
    this.items[this.currentTab].appRegDetails.fbDesignerAppIdUri = this.aadSettings.get('fbDesignerAppIdUri').value;
    this.items[this.currentTab].appRegDetails.aadConfigToolAppIDUri = this.aadSettings.get('aadConfigToolAppIDUri').value;
    this.itemEmit.emit(this.items);
    this.loaderService.start();
    this.sub = this.appRegistrationService.addAppRegistrationsForFb(this.items[this.currentTab].appRegDetails, this.customerOnBoarding.customerId, this.currentEnv)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public addAppIdUriSettings() {
    this.editAppIdUriSettings = false;
    this.items[this.currentTab].appRegDetails.aadStaffAppIDUri = this.aadSettings.get('aadStaffAppIDUri').value;
    this.items[this.currentTab].appRegDetails.aadStudentAppIDUri = this.aadSettings.get('aadStudentAppIDUri').value;
    this.items[this.currentTab].appRegDetails.aadAdminConsoleAppIDUri = this.aadSettings.get('aadAdminConsoleAppIDUri').value;
    this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIAppIDUri = this.aadSettings.get('aadWorkflowWebAPIAppIDUri').value;
    this.itemEmit.emit(this.items);
    this.loaderService.start();
    this.sub = this.appRegistrationService.addAppRegistrationsForAppIdUri(this.items[this.currentTab].appRegDetails, this.customerOnBoarding.customerId, this.currentEnv)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public addStaffSettings2() {
    this.editStaffSettings2 = false;
    this.items[this.currentTab].appRegDetails.staffTenantId = this.aadSettings.get('staffTenant').value;

    this.items[this.currentTab].appRegDetails.aadNativeAppClientID = this.aadSettings.get('nativeClient').value;
    this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIClientID = this.aadSettings.get('workflowClient').value;
    this.items[this.currentTab].appRegDetails.mailboxClientId = this.aadSettings.get('mailboxClient').value;
    this.itemEmit.emit(this.items);

    this.loaderService.start();
    this.sub = this.appRegistrationService.addAppRegistrationsForStaff(this.items[this.currentTab].appRegDetails, this.customerOnBoarding.customerId, this.currentEnv)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public showStudentInput() {
    return this.items[this.currentTab].appRegDetails?.studentTenantId == null ||
      this.items[this.currentTab].appRegDetails?.aadStudentClientId == null ||
      this.items[this.currentTab].appRegDetails?.aadStudentClientSecret == null ||
      this.editStudentSettings;
  }

  public showStaffInput() {
    return this.items[this.currentTab].appRegDetails.staffTenantId == null ||
      this.items[this.currentTab].appRegDetails.aadStaffClientId == null ||
      this.items[this.currentTab].appRegDetails.aadStaffClientSecret == null ||
      this.editStaffSettings;
  }

  public showFbInput() {
    return this.items[this.currentTab].appRegDetails.fbDesignerAppIdUri == null ||
      this.items[this.currentTab].appRegDetails.fbRendererAppIdUri == null ||
      this.items[this.currentTab].appRegDetails.aadConfigToolAppIDUri == null ||
      this.editFbSettings;
  }

  public showAppIdUriInput() {
    return this.items[this.currentTab].appRegDetails.aadStaffAppIDUri == null ||
      this.items[this.currentTab].appRegDetails.aadStudentAppIDUri == null ||
      this.items[this.currentTab].appRegDetails.aadAdminConsoleAppIDUri == null ||
      this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIAppIDUri == null ||
      this.editAppIdUriSettings;
  }

  public showStaffInput2() {
    return this.items[this.currentTab].appRegDetails.aadNativeAppClientID == null ||
      this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIClientID == null ||
      this.items[this.currentTab].appRegDetails.mailboxClientId == null ||
      this.editStaffSettings2;
  }

  public initializeForm() {
    this.aadSettings.controls.studentTenant.setValue(this.items[this.currentTab].appRegDetails.studentTenantId);
    this.aadSettings.controls.studentClient.setValue(this.items[this.currentTab].appRegDetails.aadStudentClientId);
    this.aadSettings.controls.studentClientSecret.setValue(this.items[this.currentTab].appRegDetails.aadStudentClientSecret);
    this.aadSettings.controls.staffTenant.setValue(this.items[this.currentTab].appRegDetails.staffTenantId);
    this.aadSettings.controls.staffClient.setValue(this.items[this.currentTab].appRegDetails.aadStaffClientId);
    this.aadSettings.controls.staffClientSecret.setValue(this.items[this.currentTab].appRegDetails.aadStaffClientSecret);
    this.aadSettings.controls.nativeClient.setValue(this.items[this.currentTab].appRegDetails.aadNativeAppClientID);
    this.aadSettings.controls.workflowClient.setValue(this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIClientID);
    this.aadSettings.controls.mailboxClient.setValue(this.items[this.currentTab].appRegDetails.mailboxClientId);
    this.aadSettings.controls.fbDesignerAppIdUri.setValue(this.items[this.currentTab].appRegDetails.fbDesignerAppIdUri);
    this.aadSettings.controls.fbRendererAppIdUri.setValue(this.items[this.currentTab].appRegDetails.fbRendererAppIdUri);
    this.aadSettings.controls.aadConfigToolAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadConfigToolAppIDUri);
    this.aadSettings.controls.aadStaffAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadStaffAppIDUri);
    this.aadSettings.controls.aadStudentAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadStudentAppIDUri);
    this.aadSettings.controls.aadAdminConsoleAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadAdminConsoleAppIDUri);
    this.aadSettings.controls.aadWorkflowWebAPIAppIDUri.setValue(this.items[this.currentTab].appRegDetails.aadWorkflowWebAPIAppIDUri);
  }

  public togglePasswordDisplay() {
    this.showPassword = !this.showPassword;
  }

  public onValueChange($event) {
    const username = this.aadApiDetails.get('apiUser').value;
    const password = this.aadApiDetails.get('apiPwd').value;

    this.customerOnboardingRequest.forEach(request => {
      if (request.environment === this.currentEnv) {
        request.apiUser = username;
        request.apiPassword = password;
      }
    });

    this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    this.headers = new Headers();
    // this.headers.set('Content-Type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('name', 'Name');
    formData.append('file', this.file);
    formData.append('customerId', this.customerId);
    formData.append('environment', this.currentEnv);
    this.loaderService.start();
    this.sub = this.appRegistrationService.importAppRegDetails(formData, this.headers)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.getAppRegistrationDetails(this.customerOnBoarding.customerId, this.currentEnv, this.items[this.currentTab]);
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
        }

      });
  }
}
