<div class="container-fluid row">
  <kendo-tilelayout [columns]="12" [rowHeight]="20">
    <kendo-tilelayout-item [col]="1" [colSpan]="3" [rowSpan]="19">
      <kendo-tilelayout-item-body class="px-2 pt-2">
        <kendo-stepper
          [style.width.px]="300"
          [steps]="steps"
          stepType="full"
          [(currentStep)]="currentStep"
          [linear]="false"
          orientation="vertical"
        >
        </kendo-stepper>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="4" [colSpan]="9" [rowSpan]="19">
      <kendo-tilelayout-item-body class="px-2 pb-2">
        <div [hidden]="currentStep !== this.Constants.STEP_INFRASTRUCTURE">
          <app-infrastructure [infrastructure]="infrastructure"></app-infrastructure>
        </div>
        <div class="row" [hidden]="currentStep !== this.Constants.STEP_PRODUCT_DEPLOYMENT">
          <app-product-version></app-product-version>
        </div>
        <div class="row" [hidden]="currentStep !== this.Constants.STEP_SUMMARY">
          <app-summary
            [infrastructure]="infrastructure"
            [productParams]="productParams">
          </app-summary>
        </div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="2">
      <div class="p-2">
        <div class="row float-end">
          <div>
            <span class="me-2">Step {{ currentStep + 1 }} of 3</span>
            <button
              class="k-button me-2"
              kendoButton
              themeColor="primary"
              *ngIf="currentStep > this.Constants.STEP_INFRASTRUCTURE"
              (click)="previousStep()"
            >
              Previous
            </button>
            <button
              class="k-button me-2"
              kendoButton
              themeColor="primary"
              (click)="nextStep()"
              *ngIf="currentStep < this.Constants.STEP_SUMMARY"
            >
              Next
            </button>
            <button
              class="k-button me-2"
              kendoButton
              (click)="onStartAction()"
              themeColor="primary"
              [disabled]="disableStartButton()"
              *ngIf="currentStep === this.Constants.STEP_SUMMARY"
            >
              Start Infrastructure Deployment
            </button>
          </div>
        </div>
      </div>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
</div>
