<kendo-grid
  [data]="releasesView"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="true"
  [pageable]="false"
  [filterable]="true"
  (dataStateChange)="dataStateChange($event)"
  kendoGridSelectBy="id"
  [selectable]="true"
  [style.height.%]="100"
>
  <kendo-grid-column
    field="description"
    title="Customer"
    [width]="150"
    [minResizableWidth]="200"
  ></kendo-grid-column>
  <kendo-grid-column
    field="createdDate"
    title="Created"
    [width]="100"
    [minResizableWidth]="100"
    [filterable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.createdOn | amTimeAgo}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="createdDate"
    title="Environments"
    [width]="300"
    [minResizableWidth]="300"
    [filterable]="false"
  >
    <ng-template kendoTooltip
                 kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <button (click)="onEnvironmentSelected(dataItem, env['Environment'])"
              *ngFor="let env of getEnvironments(dataItem)"
              class="stage-status-{{env['Environment']['status']}} me-2"
              icon="folder"
              title="{{env['Environment']['status']}}"
      >
        {{env['Name']}}
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
