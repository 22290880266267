<div class="container wrapper pt-2 row col-12 d-flex justify-content-center flex-wrap text-uppercase fs-5">
  Multi Tenant - Pipeline Logs
</div>

<div>
  <kendo-grid
    [kendoGridBinding]="batches"
    [pageable]="true"
    [pageSize]="12"
    [sortable]="true"
    [filterable]="true"
    [resizable]="true"
  >
    <kendo-grid-column
      field="id"
      title="Batch Id"
      [width]="40"
    >
      <ng-template kendoGridFilterMenuTemplate
                   let-filter let-column="column" let-filterService="filterService">

        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="createdBy"
      title="Name"
      [width]="180"
    >
      <ng-template kendoGridFilterMenuTemplate
                   let-filter let-column="column" let-filterService="filterService">

        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="environments"
      title="Environments"
      [width]="180"
    >
      <ng-template kendoGridFilterMenuTemplate
                   let-filter let-column="column" let-filterService="filterService">

        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="productNames"
      title="Product/Pipeline"
      [width]="180"
    >
      <ng-template kendoGridFilterMenuTemplate
                   let-filter let-column="column" let-filterService="filterService">

        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="createdDate"
      title="Date"
      [width]="180"
    >
      <ng-template kendoGridFilterMenuTemplate
                   let-filter let-column="column" let-filterService="filterService">

        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="command" [width]="60">
      <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
        <button kendoGridEditCommand type="button" [primary]="true" (click)="viewDetails(dataItem,rowIndex)">
          View Details
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
