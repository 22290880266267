import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-mailbox-manager-customer',
  templateUrl: './mailbox-manager-customer.component.html',
  styleUrls: ['./mailbox-manager-customer.component.scss']
})
export class MailboxManagerCustomerComponent extends BaseComponent implements OnInit {
  custTypes: any[] = ["MultiTenant","CNC 1.X"];
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.customerEnvironmentDetails.controls.custType.setValue(this.custTypes[0]);
  }
  @Input() public customerEnvironmentDetails: UntypedFormGroup;
}
