import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';
import { AppRegistrationDetails } from 'src/app/shared/models/AppRegistrationDetails';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class AppRegistrationService {

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService) {
  }


  generateAppRegistrationScript(customerId: string, query:string, diffAadTenants): any {
    return this.httpClient
      .get(`${window.location.origin}/api/ado/AppRegistration/AppRegistrationScript/${customerId}?${query}&diffAadTenants=${diffAadTenants}`,{responseType: 'blob'})
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('generateAppRegistrationScript'))
      );
  }

  generateMailboxAppRegistrationScript(customerId, query): any {
    return this.httpClient
      .get(`${window.location.origin}/api/ado/AppRegistration/MailboxAppRegistrationScript/${customerId}?${query}`,{responseType: 'blob'})
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('generateMailboxAppRegistrationScript'))
      );
  }

  generateFinanceIntegrationAppRegistrationScript(customerId, query): any {
    return this.httpClient
      .get(`${window.location.origin}/api/ado/AppRegistration/FinanceIntegrationAppRegScript/${customerId}?${query}`,{responseType: 'blob'})
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('generateFinanceIntegrationAppRegistrationScript'))
      );
  }

  getAppRegistrationDetails(customerId: string, environment:string): Observable<AppRegistrationDetails> {

    return this.httpClient
      .get<AppRegistrationDetails>(`${window.location.origin}/api/ado/AppRegistration/Records/${customerId}/Environment/${environment}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('generateAppRegistrationScript'))
      );
  }

  isAppRegistrationDetailsOverriden(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/AppRegistration/RecordsOverriden/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('generateAppRegistrationScript'))
      );
  }

  isFinanceAppRegistrationDetailsOverriden(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/AppRegistration/FinanceRecordsOverriden/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('generateAppRegistrationScript'))
      );
  }

  validateCredentials(username: string, password:string, customerId:string, environment:string = ""){
    var credential:{
      username:string,
      password:string,
      customerId:string
    }={
      username:username,
      password:password,
      customerId:customerId
    }

    return this.httpClient
      .put(`${window.location.origin}/api/ado/AppRegistration/Credential?environment=${environment}`,credential)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('validateCredentials'))
      );
  }

  addAppRegistrationsForStudent(appRegistration,customerId,environment): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/Student/${customerId}/${environment}`, appRegistration)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addAppRegistrationsForStudent'))
      );
  }

  addAppRegistrationsForFb(appRegistration,customerId,environment): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/Fb/${customerId}/${environment}`, appRegistration)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addAppRegistrationsForFb'))
      );
  }

  addAppRegistrationsForAppIdUri(appRegistration,customerId,environment): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/AppIdUri/${customerId}/${environment}`, appRegistration)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addAppRegistrationsForAppIdUri'))
      );
  }

  rollbackAppRegistrationUpdatedInKeyVaultFlag(customerId): Observable<any> {
    return this.httpClient
      .put<any>(`${window.location.origin}/api/ado/AppRegistration/Records/${customerId}`, null)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('rollbackAppRegistrationUpdatedInKeyVaultFlag'))
      );
  }

  addAppRegistrationsForStaff(appRegistration,customerId,environment): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/Staff/${customerId}/${environment}`, appRegistration)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addAppRegistrationsForStaff'))
      );
  }

  addUserDetailsForAppRegstration(username,password,customerId,environment,isAdmin): Observable<any> {
    var credential:{
      username:string,
      password:string,
      customerId:string
    }={
      username:username,
      password:password,
      customerId:customerId
    }

    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/Credential?environment=${environment}&isAdmin=${isAdmin}`,credential)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addUserDetailsForAppRegstration'))
      );
  }

  addApiUserDetailsForAppRegstration(credentials): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/ApiUserCredentials`,credentials)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addUserDetailsForAppRegstration'))
      );
  }

  getUserDetailsForAppRegstration(customerId,environment,isAdmin): Observable<any> {
    if (this.configService.Organization === Constants.TEST_ORG && !(this.authService.loggedIn)) {
      return of(null);
    }
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/AppRegistration/Credential/${customerId}?environment=${environment}&isAdmin=${isAdmin}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getUserDetailsForAppRegstration'))
      );
  }

  getFinanceIntegrationAppRegDetails(customerId): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/AppRegistration/FinanceIntegrationRecords/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getFinanceIntegrationAppRegDetails'))
      );
  }

  getFMailboxAppRegDetails(customerId): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/AppRegistration/MailboxRecords/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getFMailboxAppRegDetails'))
      );
  }

  updateFinanceIntegrationAppRegDetails(appRegDetails): Observable<any> {

    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/UpdateFinanceIntegrationAppRegRecords`,appRegDetails)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateFinanceIntegrationAppRegDetails'))
      );
  }

  updateMailboxAppRegDetails(appRegDetails): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/UpdateMailboxAppRegRecords`,appRegDetails)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateMailboxAppRegDetails'))
      );
  }

  importAppRegDetails(form, header): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/AppRegistration/ImportAppReg`,form ,
      {
          headers: header

      })
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('importAppRegDetails'))
      );
  }

}
