import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { Project } from '../models/Project';
import { VariableGroup } from '../models/VariableGroup';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Environment, ReleaseDefinition, ReleaseItem } from '../models/ReleaseDefinition';
import { switchMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { AzRelease } from '../models/AzRelease';
import { Batch } from '../../shared/models/Batch';
import { Constants } from '../../shared/Constants';
import * as moment from 'moment';
import { Release } from 'src/app/shared/models/Release';
import { BatchType } from '../../shared/models/BatchType';
import { ActionsLayout } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss']
})
export class DeploymentComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  defaultProject: Project = {
    id: null,
    name: 'Select a Project',
    description: null,
    url: null,
    state: null,
    revision: 0,
    visibility: null,
    lastUpdateTime: null
  };

  projects: Project[];
  projectsFiltered: Project[];
  selectedProject: Project;

  selectedReleaseDefinition: ReleaseDefinition;
  releaseItems: ReleaseItem[];

  environments: Environment[];
  selectedEnvironments: Environment[];

  variableGroups: VariableGroup[] = [];
  selectedVariableGroups: VariableGroup[];
  variableGroupsView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public deployActions: any[] = [
    // {
    //   actionName: 'Create Release Only',
    //   click: (dataItem) => this.CreateReleaseOnly(dataItem),
    // },
    {
      actionName: 'Create Release and Run',
      click: (dataItem) => this.CreateReleaseAndRun(dataItem),
    }
  ];

  deployDialogOpened: boolean;
  public actionsLayout: ActionsLayout = 'center';

  public expandedNodeIndices: string[] = [];

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.loadProjects();
  }

  private loadProjects() {
    this.loaderService.start();
    this.sub = this.projectService.getProjects().subscribe({
      next: (result) => {
        this.projects = result.value;
        this.projects.sort((a, b) => a.name.localeCompare(b.name));
        this.projectsFiltered = this.projects;
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  private loadProjectData() {
    if (!this.selectedProject) {
      return;
    }

    this.loadReleaseDefinitions();
    this.loadVariableGroups();
  }

  private loadVariableGroups() {
    this.loaderService.start();
    this.selectedVariableGroups = [];
    this.sub = this.projectService.getProjectVariableGroups(this.selectedProject.id).subscribe({
      next: (result) => {
        this.variableGroups = result.value;
        this.variableGroups.sort((a, b) => a.name.localeCompare(b.name));
        this.loadVariableGroupItems();
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      }
    });
  }

  private loadVariableGroupItems() {
    this.variableGroupsView = process(this.variableGroups, this.state);
  }

  private loadReleaseDefinitions() {
    this.selectedReleaseDefinition = null;
    this.selectedEnvironments = [];
    this.sub = this.releaseService.getReleaseDefinitions(
      this.selectedProject.id,
      this.getReleaseDefSearchPaths()
    ).subscribe({
      next: (result) => {
        this.releaseItems = this.buildReleaseHierarchyTree(result[0].value);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  handleProjectFilter(value) {
    this.projectsFiltered = this.projects.filter(
      (p) => p.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  projectSelectionChange($event: any) {
    this.selectedProject = $event;
    this.selectedEnvironments = [];
    this.selectedVariableGroups = [];
    this.loadProjectData();
  }

  releaseSelectionChange($event: any) {
    if ($event?.children?.length > 0) {
      this.selectedReleaseDefinition = null;
      return;
    }

    this.selectedReleaseDefinition = $event;
    this.loadReleaseDefinition();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.variableGroupsView = process(this.variableGroups, this.state);
  }

  disableDeployButton(): boolean {
    return !this.selectedProject ||
      !this.selectedReleaseDefinition ||
      this.selectedVariableGroups?.length === 0 ||
      this.selectedEnvironments?.length === 0;
  }

  variableGroupSelectionChange($event: any) {
    this.selectedVariableGroups = $event.map(idx => {
      return this.variableGroups.find(item => item.id === idx);
    });
  }

  onDeployDialogClose() {
    this.deployDialogOpened = false;
  }

  onDeployConfirm() {
    this.deployDialogOpened = false;

    const batch = new Batch();
    batch.name = `${this.AccountInfo?.name}`;
    batch.type = BatchType.Release;
    batch.projectName = this.selectedProject.name;
    batch.releaseId = this.selectedReleaseDefinition.id;
    batch.releaseName = this.selectedReleaseDefinition.name;
    batch.createdBy = this.AccountInfo?.name;
    batch.createdDate = new Date();
    batch.modifiedBy = this.AccountInfo?.name;
    batch.modifiedDate = new Date();

    this.sub = this.batchService.createBatch(batch).subscribe({
      next: (batchCreated) => {
        console.log(this.selectedVariableGroups);
        this.selectedVariableGroups.forEach(vg => {
          // send request
          console.log(vg);
          this.sub = this.createNewReleaseDefinition(vg)
            .pipe(
              switchMap(rd => this.createNewRelease(rd, vg)),
              switchMap(re => this.updateReleaseEnvironments(re)),
              switchMap(res => this.addReleaseToDb(res, batchCreated, vg))
            )
            .subscribe(re => {
              this.toastr.success(`Release ${re.name} was created successfully!`, 'Success!')
                .onHidden
                .subscribe();
            });
        });
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private createNewReleaseDefinition(vg: VariableGroup): Observable<ReleaseDefinition> {
    const releaseDef = (JSON.parse(JSON.stringify(this.selectedReleaseDefinition)));

    const customerId = vg.variables[Constants.CUSTOMER_ID] || vg.variables[Constants.CUSTOMER_ID_];
    const customerName = vg.variables[Constants.CUSTOMER_NAME] || vg.variables[Constants.CUSTOMER_NAME_];

    // Set release name and path
    const date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    releaseDef.name = `${customerId?.value}-${customerName?.value}-${moment().format()}`;
    releaseDef.path = `${this.selectedReleaseDefinition.path}/${date}`;

    // Replace variable groups
    releaseDef.variableGroups = [vg.id];

    // send request
    return this.releaseService.createReleaseDefinition(this.selectedProject.id, releaseDef);
  }

  private createNewRelease(rd: ReleaseDefinition, vg: VariableGroup) {
    const customerName = vg.variables[Constants.CUSTOMER_NAME] || vg.variables[Constants.CUSTOMER_NAME_];
    const releaseRequest = {
      'definitionId': rd.id,
      'description': `${vg.variables[customerName]?.value}`,
      'artifacts': [],
      'isDraft': false,
      'reason': 'none',
      'manualEnvironments': null,
      'variables': {}
    };
    return this.releaseService.createRelease(this.selectedProject.id, releaseRequest);
  }

  private addReleaseToDb(rd: AzRelease, batch: Batch, vg: VariableGroup): Observable<Release> {
    const customerId = vg.variables[Constants.CUSTOMER_ID] || vg.variables[Constants.CUSTOMER_ID_];
    const customerName = vg.variables[Constants.CUSTOMER_NAME] || vg.variables[Constants.CUSTOMER_NAME_];
    const release: Release = {
      'id': rd.id,
      'batchId': batch.id,
      'name': `${vg.variables[customerId]?.value}_${vg.variables[customerName]?.value}_${moment().format(Constants.TIME_STAMP_FORMAT)}`,
      'batch': undefined,
      'projectName': this.selectedProject.name,
      'customerId': vg.variables[customerId]?.value,
      'customerName': vg.variables[customerName]?.value,
      'stage': null,
      'createdBy': this.msalService.instance.getActiveAccount().name,
      'createdDate': new Date(),
      'modifiedBy': this.msalService.instance.getActiveAccount().name,
      'modifiedDate': new Date(),
      'azBuild': undefined
    };
    return this.releaseDbService.createRelease(release);
  }

  private updateReleaseEnvironments(re: AzRelease) {
    for (const e of re?.environments) {
      const updateRequest = {
        'status': 'InProgress',
        'scheduledDeploymentTime': null,
        'comment': 'Started from DevOpsPortal Tool.',
        'variables': null
      };

      if (!this.SelectedEnvironments.includes(e.name)) {
        continue;
      }

      this.releaseService.updateReleaseEnvironment(this.selectedProject.id, re.id, e.id, updateRequest)
        .subscribe((result) => {
            console.log(result);
          }
        );
    }

    return of(re);
  }

  private loadReleaseDefinition() {
    if (!this.selectedProject?.id || !this.selectedReleaseDefinition?.id) {
      return;
    }

    this.selectedEnvironments = [];
    this.sub = this.releaseService.getReleaseDefinition(this.selectedProject.id, this.selectedReleaseDefinition.id)
      .subscribe({
        next: (result) => {
          this.selectedReleaseDefinition = result;
          this.selectedReleaseDefinition.triggers = [];
          this.environments = this.selectedReleaseDefinition?.environments;
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  environmentSelectionChange($event: any) {
    this.selectedEnvironments = $event.map(idx => {
      return this.environments.find(item => item.id === idx);
    });
  }

  private CreateReleaseAndRun(actionItem) {
    this.deployDialogOpened = true;
  }

  get SelectedEnvironments(): string[] {
    return this.selectedEnvironments.filter(e => e != null).map(e => e.name);
  }

  get SelectedVariableGroups(): string[] {
    return this.selectedVariableGroups.map(e => e.name);
  }

  getReleaseDefSearchPaths() {
    return [
      ''
    ];
  }

  private buildReleaseHierarchyTree(releaseDefinitions: ReleaseDefinition[]): ReleaseItem[] {
    const items: ReleaseItem[] = [];
    const root = releaseDefinitions.reduce((previous, current) => {
      const combined = current.path.split('\\');
      combined.shift();
      combined.push(current.name);
      const final = combined.reduce((o, p) => {
        const n = p || 'root';
        let temp = (o.children = o.children || []).find(q => q.path === n);
        if (!temp) {
          o.children.push(temp = {
            id: current.id,
            name: n,
            path: n,
            children: []
          });
        }
        return temp;
      }, previous);
      return previous;
    }, {
      children: items
    });

    return root.children;
  }
}
