<div class="text-center">
    <h4>
      <small class="text-muted">Enable Features</small>
    </h4>
  </div>
<kendo-tilelayout
  [resizable]="false"
  [reorderable]="false">
    <kendo-tilelayout-item>
        <kendo-tilelayout-item-body>
            <form novalidate #myForm="ngForm">
                <kendo-grid
                [kendoGridTemplateEditing]="updateFeaturesEdit"
                [kendoGridBinding]="features"
                [filterable]="true"
                [pageable]="true" [pageSize]="6"
                >
                    <kendo-grid-column
                    field="page"
                    title="Page"
                    >
                    </kendo-grid-column>
            
                    <kendo-grid-column
                    field="feature"
                    title="Feature"
                    >
                    </kendo-grid-column>
            
                    <kendo-grid-column
                    field="enable"
                    title="Enable"
                    >
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                            <input
                            [(ngModel)]="dataItem.enable"
                            kendoGridFocusable
                            name="Enable"
                            type="checkbox"
                            />
                        </ng-template>  
                    </kendo-grid-column>
                    <kendo-grid-command-column title="command" [width]="220">
                        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                          <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                            Edit
                          </button>
                          <button
                            kendoGridSaveCommand
                            type="button"
                            [disabled]="myForm.invalid"
                            (click)="saveFeatureDetails(dataItem)"
                          >
                            {{ isNew ? "Add" : "Update" }}
                          </button>
                          <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                            {{ isNew ? "Discard changes" : "Cancel" }}
                          </button>
            
                        </ng-template>
                      </kendo-grid-command-column>
                </kendo-grid>
            </form>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>
