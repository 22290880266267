import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler.service';
import { Observable, of } from 'rxjs';
import { LcsEnvironment } from '../../shared/models/dynamics/LcsEnvironment';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class LcsEnvironmentService {

  private url = `${window.location.origin}/api/LcsEnvironment`;

  constructor(private httpClient: HttpClient,
              private errorHandling: ErrorHandlerService,
              private configService: AppConfigService,
              private authService: AuthService) { }
  

  getEnvironments(customerId: number): Observable<LcsEnvironment[]> {

    return this.httpClient.get<LcsEnvironment[]>(`${this.url}?LcsCustomerId=${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironments'))
      );
  }

  getEnvironmentById(environmentId: string): Observable<LcsEnvironment> {
    return this.httpClient.get<LcsEnvironment>(`${this.url}/${environmentId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironmentById'))
      );
  }

  addEnvironment(environment: LcsEnvironment): Observable<LcsEnvironment> {
    return this.httpClient.post<LcsEnvironment>(this.url, environment)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addEnvironment'))
      );
  }

  updateEnvironment(id: number, Environment: LcsEnvironment): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/${id}`, Environment)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateEnvironment'))
      );
  }

  deleteEnvironment(Environment: LcsEnvironment): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/${Environment.id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteEnvironment'))
      );
  }
}
