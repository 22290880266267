import { Component, ViewChild } from '@angular/core';
import { LcsCustomer } from '../../shared/models/dynamics/LcsCustomer';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { FhcmEnvironmentsComponent } from '../fhcm-evironments/fhcm-environments.component';

@Component({
  selector: 'app-fhcm-main',
  templateUrl: './fhcm-main.component.html',
  styleUrls: ['./fhcm-main.component.scss']
})
export class FhcmMainComponent {

  @ViewChild('environments') environments: FhcmEnvironmentsComponent;

  public customer: LcsCustomer = null;

  onRefreshClicked($event: LcsCustomer) {
    this.environments.RefreshCustomerEnvironments($event);
  }

  onCustomerSelectionChanged($event: SelectionEvent) {
    this.customer = $event.selectedRows[0].dataItem;
    this.environments.loadAllEnvironments(this.customer.id);
  }
}
