import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeploymentComponent } from './deployment/deployment.component';
import { EnvironmentsComponent } from './environments/environments.component';
import { BatchesComponent } from './environments/batches/batches.component';
import { SharedResourceComponent } from './products/shared-resource.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { BatchDetailComponent } from './environments/batches/batch-detail/batch-detail.component';
import { ReleaseListComponent } from './environments/batches/release-list/release-list.component';
import { StageDetailComponent } from './environments/batches/stage-detail/stage-detail.component';
import { ReleaseDetailComponent } from './environments/batches/release-detail/release-detail.component';
import { TaskListComponent } from './environments/batches/stage-detail/task-list/task-list.component';
import { TaskDetailComponent } from './environments/batches/task-detail/task-detail.component';
import { EnvironmentInfoComponent } from './environments/environment-info/environment-info.component';
import { AppUpgradeComponent } from './products/app-upgrade/app-upgrade.component';
import { PipelinesComponent } from './products/pipelines/pipelines.component';
import { PipelineDetailComponent } from './products/pipelines/pipeline-detail/pipeline-detail.component';
import { PipelineBatchComponent } from './products/pipelines/pipeline-batch/pipeline-batch.component';
import { DbRefreshComponent } from './products/db-management/db-refresh.component';
import { EnvironmentsListComponent } from '../environmentsList/environmentsList.component';
import { AuditLogComponent } from '../tools/auditLog.component';
import { WindowModule } from "@progress/kendo-angular-dialog";
import { DataSourceFieldComponent } from './products/db-management/data-source-field/data-source-field.component';
import { DbSingleTenantPanelComponent } from './products/db-management/db-single-tenant-panel/db-single-tenant-panel.component';
import { DbFreshInfoComponent } from './products/db-management/db-single-tenant-panel/db-fresh-info/db-fresh-info.component';
import { DbRefreshConfirmationComponent } from './products/db-management/db-single-tenant-panel/db-refresh-confirmation/db-refresh-confirmation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from "@angular/platform-browser";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { EnvironmentTableRefreshComponent } from '../tools/environment-table-refresh/environment-table-refresh.component';
import { DbRefreshLogsComponent } from './products/db-management/db-refresh-logs/db-refresh-logs.component';
import { AppRegistrationScriptComponent } from '../app-registration-script/app-registration-script.component';
import { CustomerOnboardingComponent } from '../customer-onboarding/customer-onboarding.component';
import { CustomerDetailsComponent } from '../customer-onboarding/customer-details/customer-details.component';
import { OccupationInsightDetailsComponent } from '../customer-onboarding/occupation-insight-details/occupation-insight-details.component';
import { InstanceDetailsComponent } from '../customer-onboarding/instance-details/instance-details.component';
import { CustomerDetailsSummaryComponent } from '../customer-onboarding/customer-details-summary/customer-details-summary.component';
import { EnvironmentCustomerDetailsComponent } from '../customer-onboarding/environment-customer-details/environment-customer-details.component';
import { AppUserDetailsComponent } from '../customer-onboarding/app-user-details/app-user-details.component';
import { AadSettingDetailsComponent } from '../customer-onboarding/aad-setting-details/aad-setting-details.component';
import { CustomerOnboardingStatusComponent } from '../customer-onboarding/customer-onboarding-status/customer-onboarding-status.component';
import { DbMultiTenantPanelComponent } from './products/db-management/db-multi-tenant-panel/db-multi-tenant-panel.component';
import { DbRefreshConfirmationMultiComponent } from './products/db-management/db-multi-tenant-panel/db-refresh-confirmation-multi/db-refresh-confirmation-multi.component';
import { DbRefreshInfoMultiComponent } from './products/db-management/db-multi-tenant-panel/db-refresh-info-multi/db-refresh-info-multi.component';
import { NewEnvironmentComponent } from '../new-environment/new-environment.component';
import { DbRestoreComponent } from './products/db-management/db-restore/db-restore.component';
import { DbSingleTenantRestoreFromFilePanelComponent } from './products/db-management/db-restore/db-single-tenant-restore-panel/db-single-tenant-restore-from-file-panel.component';
import { DbSingleTenantRestoreFromDbPanelComponent } from './products/db-management/db-restore/db-single-tenant-restore-panel/db-single-tenant-restore-from-db-panel/db-single-tenant-restore-from-db-panel.component';
import { DbSingleTenantRestorePanelComponent } from './products/db-management/db-restore/db-single-tenant-restore-panel/db-single-tenant-restore-panel.component';
import { ProductMappingComponent } from '../admin/mapper/product-mapping/product-mapping.component';
import { DbRestoreConfirmationComponent } from './products/db-management/db-single-tenant-panel/db-restore-confirmation/db-restore-confirmation.component';
import { DbRestoreLogsComponent } from './products/db-management/db-restore/db-restore-logs/db-restore-logs.component';
import { NewEnvironmentDetailsComponent } from '../new-environment/new-environment-details/new-environment-details.component';
import { NewEnvironmentInstanceDetailsComponent } from '../new-environment/new-environment-instance-details/new-environment-instance-details.component';
import { NewEnvironmentAppRegDetailsComponent } from '../new-environment/new-environment-app-reg-details/new-environment-app-reg-details.component';
import { NewEnvironmentSubDetailsComponent } from '../new-environment/new-environment-sub-details/new-environment-sub-details.component';
import { NewEnvironmentProductDetailsComponent } from '../new-environment/new-environment-product-details/new-environment-product-details.component';
import { NewEnvironmentSummaryComponent } from '../new-environment/new-environment-summary/new-environment-summary.component';
import { MailboxAppRegistrationComponent } from '../mailbox-app-registration/mailbox-app-registration.component';
import { ApiPolicyMapperComponent } from '../admin/mapper/api-policy-mapper/api-policy-mapper.component';
import { UpdateTemplateFileComponent } from '../update-template-file/update-template-file.component';
import { UpdateTemplateFileLogsComponent } from '../update-template-file/update-template-file-logs/update-template-file-logs.component';
import { DatabaseRefreshComponent } from '../database-refresh/database-refresh.component';
import { DatabaseRefreshDetailsComponent } from '../database-refresh/database-refresh-details/database-refresh-details.component';
import { DatabaseRefreshSQLDetailsComponent } from '../database-refresh/database-refresh-sqldetails/database-refresh-sqldetails.component';
import { DatabaseRefreshTargetDetailsComponent } from '../database-refresh/database-refresh-target-details/database-refresh-target-details.component';
import { DatabaseRefreshSummaryDetailsComponent } from '../database-refresh/database-refresh-summary-details/database-refresh-summary-details.component';
import { DatabaseRefreshStatusComponent } from '../database-refresh/database-refresh-status/database-refresh-status.component';
import { DatabaseRefreshStatusRunTasksComponent } from '../database-refresh/database-refresh-status/database-refresh-status-run-tasks/database-refresh-status-run-tasks.component';
import { UpdateLiveProdMtEnvComponent } from '../admin/update-live-prod-mt-env/update-live-prod-mt-env.component';
import { AppUpgradeDetailedLogComponent } from './products/app-upgrade/app-upgrade-detailed-log/app-upgrade-detailed-log.component';
import { AppUpdateComponent } from './products/app-update/app-update.component';
import { FinanceIntegrationAppRegComponent } from '../finance-integration-app-reg/finance-integration-app-reg.component';
import { FinanceIntegrationComponent } from '../finance-integration/finance-integration.component';
import { FinanceIntegrationCustomerComponent } from '../finance-integration/finance-integration-customer/finance-integration-customer.component';
import { FinanceIntegrationAADComponent } from '../finance-integration/finance-integration-aad/finance-integration-aad.component';
import { FinanceIntegrationSummaryComponent } from '../finance-integration/finance-integration-summary/finance-integration-summary.component';
import { FinanceIntegrationStatusComponent } from '../finance-integration/finance-integration-status/finance-integration-status.component';
import { AppRegistrationOverrideConfirmComponent } from '../customer-onboarding/app-registration-override-confirm/app-registration-override-confirm.component';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { CnfUpgradeComponent } from './products/app-upgrade/cnf-upgrade/cnf-upgrade.component';
import { MailboxManagerComponent } from '../mailbox-manager/mailbox-manager.component';
import { MailboxManagerAADComponent } from '../mailbox-manager/mailbox-manager-aad/mailbox-manager-aad.component';
import { MailboxManagerCustomerComponent } from '../mailbox-manager/mailbox-manager-customer/mailbox-manager-customer.component';
import { MailboxManagerStatusComponent } from '../mailbox-manager/mailbox-manager-status/mailbox-manager-status.component';
import { EnableFeaturesComponent } from '../admin/enable-features/enable-features.component';
import { UpdateMailboxConfiguredEnvComponent } from '../admin/update-mailbox-configured-env/update-mailbox-configured-env.component';
import { DropDownListFilterComponent } from '../shared/drop-down-list-filter/drop-down-list-filter.component';
import { PsIntegrationComponent } from './ps-integration/ps-integration.component';
import { PsInfrastructureComponent } from './ps-integration/ps-infrastructure/ps-infrastructure.component';
import { PsSummaryComponent } from './ps-integration/ps-summary/ps-summary.component';
import { MailboxManagerSummaryComponent } from '../mailbox-manager/mailbox-manager-summary/mailbox-manager-summary.component';
import { PsIntegrationStatusComponent } from './ps-integration/ps-integration-status/ps-integration-status.component';
import { ExcelModule } from '@progress/kendo-angular-grid';

@NgModule({
  declarations: [
    DeploymentComponent,
    EnvironmentsComponent,
    BatchesComponent,
    SharedResourceComponent,
    BatchDetailComponent,
    ReleaseListComponent,
    StageDetailComponent,
    ReleaseDetailComponent,
    TaskListComponent,
    TaskDetailComponent,
    EnvironmentInfoComponent,
    AppUpgradeComponent,
    PipelinesComponent,
    PipelineDetailComponent,
    PipelineBatchComponent,
    DbRefreshComponent,
    EnvironmentsListComponent,
    AuditLogComponent,
    DataSourceFieldComponent,
    DbSingleTenantPanelComponent,
    DbFreshInfoComponent,
    DbRefreshConfirmationComponent,
    EnvironmentTableRefreshComponent,
    DbRefreshLogsComponent,
    CustomerOnboardingComponent,
    AppRegistrationScriptComponent,
    CustomerDetailsComponent,
    InstanceDetailsComponent,
    OccupationInsightDetailsComponent,
    EnvironmentCustomerDetailsComponent,
    CustomerDetailsSummaryComponent,
    AppUserDetailsComponent,
    AadSettingDetailsComponent,
    CustomerOnboardingStatusComponent,
    DbMultiTenantPanelComponent,
    DbRefreshConfirmationMultiComponent,
    DbRefreshInfoMultiComponent,
    NewEnvironmentComponent,
    DbRestoreComponent,
    DbSingleTenantRestoreFromFilePanelComponent,
    DbSingleTenantRestoreFromDbPanelComponent,
    DbSingleTenantRestorePanelComponent,
    ProductMappingComponent,
    DbRestoreConfirmationComponent,
    DbRestoreLogsComponent,
    NewEnvironmentDetailsComponent,
    NewEnvironmentInstanceDetailsComponent,
    NewEnvironmentAppRegDetailsComponent,
    NewEnvironmentSubDetailsComponent,
    NewEnvironmentProductDetailsComponent,
    NewEnvironmentSummaryComponent,
    MailboxAppRegistrationComponent,
    ApiPolicyMapperComponent,
    UpdateTemplateFileComponent,
    UpdateTemplateFileLogsComponent,
    DatabaseRefreshComponent,
    DatabaseRefreshDetailsComponent,
    DatabaseRefreshSQLDetailsComponent,
    DatabaseRefreshTargetDetailsComponent,
    DatabaseRefreshSummaryDetailsComponent,
    DatabaseRefreshStatusComponent,
    DatabaseRefreshStatusRunTasksComponent,
    UpdateLiveProdMtEnvComponent,
    AppUpgradeDetailedLogComponent,
    AppUpdateComponent,
    FinanceIntegrationAppRegComponent,
    FinanceIntegrationComponent,
    FinanceIntegrationCustomerComponent,
    FinanceIntegrationAADComponent,
    FinanceIntegrationAppRegComponent,
    FinanceIntegrationSummaryComponent,
    FinanceIntegrationStatusComponent,
    AppRegistrationOverrideConfirmComponent,
    CnfUpgradeComponent,
    MailboxManagerComponent,
    MailboxManagerAADComponent,
    MailboxManagerCustomerComponent,
    MailboxManagerStatusComponent,
    EnableFeaturesComponent,
    UpdateMailboxConfiguredEnvComponent,
    DropDownListFilterComponent,
    PsIntegrationComponent,
    PsInfrastructureComponent,
    PsSummaryComponent,
    MailboxManagerSummaryComponent,
    PsIntegrationStatusComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        WindowModule,
        BrowserAnimationsModule,
        BrowserModule,
        TreeViewModule,
        ButtonsModule,
        DropDownsModule,
        TooltipsModule,
        ExcelModule
    ],
  exports: []
})
export class DeploymentModule {
}
