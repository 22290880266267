import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-api-policy-mapper',
  templateUrl: './api-policy-mapper.component.html',
  styleUrls: ['./api-policy-mapper.component.scss']
})
export class ApiPolicyMapperComponent extends BaseComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor() {
    super();
  }

  private sub: Subscription;

  public dataTables: Array<{ tableName: string; tableId: number }> = [
    {tableName: 'Api Policy', tableId: 1},
    {tableName: 'Api Action', tableId: 2}
  ];
  public hideIdentifier = true;
  public hideApiPolicyGrid = true;
  public hideApiActionGrid = true;
  private editedRowIndex: number;
  editMode = false;
  public coreApiPolicyMappingToBeDeleted: any;
  public coreApiPolicyMappingDialogOpened = false;

  public apiActionMappingToBeDeleted: any;
  public apiActionMappingDialogOpened = false;

  hideSelection = false;
  apiPolicies: any[];

  public gridApiPoliciesData: any[] = [];
  public gridApiActionsData: any[] = [];

  public pageNamesSource: any[] = [];
  public pageNames: any[] = [];
  public selectedActionsSource: any[] = [];
  public selectedActions: any[] = [];


  public defaultIdentifier: { identifierName: string; identifierId: number } = {
    identifierName: 'Select policy',
    identifierId: null,
  };

  public dataIdentifier: Array<{
    identifierName: string;
    identifierId: number;
    tableId: number;
  }> = [];

  public dataResultIdentifier: Array<{
    identifierName: string;
    identifierId: number;
    tableId: number;
  }>;

  public selectedTable: { tableName: string; tableId: number };
  public selectedIdentifier: { identifierName: string; identifierId: number };

  public defaultTables: { tableName: string; tableId: number } = {
    tableName: 'Select Table',
    tableId: null,
  };

  public apiPolicyToEdit: {
    policyName: string,
    screenName: string,
    apiPath: string,
    apiAction: string
  } = {
    policyName: '',
    screenName: '',
    apiPath: '',
    apiAction: ''
  };

  public apiPolicyToAdd: {
    policyName: string,
    screenName: string,
    action: string
  } = {
    policyName: '',
    screenName: '',
    action: ''
  };

  public apiActionToAdd: {
    page: string,
    action: string,
    api: string,
    apiActionType: string
  } = {
    page: '',
    action: '',
    api: '',
    apiActionType: ''
  };

  ngOnInit(): void {
    this.loadPageNames();
  }

  handleTableChange(value) {
    this.selectedTable = value;
    this.selectedIdentifier = undefined;

    if (value.tableId === this.defaultTables.tableId) {
      this.hideIdentifier = true;
      this.hideApiPolicyGrid = true;
      this.hideApiActionGrid = true;
      this.dataResultIdentifier = [];
    } else if (this.selectedTable.tableId && this.selectedTable.tableId == 1) {
      this.hideApiPolicyGrid = false;
      this.hideApiActionGrid = true;
      this.loadApiPolicyData();
    } else if (this.selectedTable.tableId && this.selectedTable.tableId == 2) {
      this.hideApiPolicyGrid = true;
      this.hideApiActionGrid = false;
      this.loadApiActionData();
    } else {
      this.hideApiPolicyGrid = true;
      this.hideApiActionGrid = true;
    }
  }

  public hideShowSelection() {
    this.hideSelection = !this.hideSelection;
  }

  addApiPolicyToTable() {
    this.loaderService.start();
    this.sub = this.policyService.addApiPolicies(this.apiPolicyToAdd)
      .subscribe({
        next: (result) => {
          if (result) {
            this.toastr.success(`Policy ${this.apiPolicyToAdd.policyName} created successfully!`, 'Success!');
          } else {
            this.toastr.error(`Policy ${this.apiPolicyToAdd.policyName} creation failed! Please check if policy for related api already exists!`, 'Failed!');
          }
          this.resetInputs();
          this.loadApiPolicyData();
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.resetInputs();
          console.error(error);
        }
      });
  }

  addApiActionToTable() {
    this.loaderService.start();
    this.sub = this.policyService.addApiAction(this.apiActionToAdd)
      .subscribe({
        next: (result) => {
          if (result) {
            this.toastr.success(`Action ${this.apiActionToAdd.action} in page ${this.apiActionToAdd.page} mapped to api ${this.apiActionToAdd.api} successfully!`, 'Success!');
          } else {
            this.toastr.error(`Unable to add new row!`, 'Failed!');
          }
          this.resetInputs();
          this.loadApiActionData();
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.resetInputs();
          console.error(error);
        }
      });
  }

  loadApiPolicyData() {
    this.loaderService.start();
    this.sub = this.policyService.getAllApiPolicies()
      .subscribe({
        next: (result) => {
          this.gridApiPoliciesData = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  loadApiActionData() {
    this.loaderService.start();
    this.sub = this.policyService.getAllApiActions()
      .subscribe({
        next: (result) => {
          this.gridApiActionsData = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  resetInputs() {
    this.apiPolicyToAdd = {
      policyName: '',
      screenName: '',
      action: ''
    };

    this.apiActionToAdd = {
      page: '',
      api: '',
      action: '',
      apiActionType: ''
    };

    this.apiPolicyToEdit = {
      policyName: '',
      screenName: '',
      apiPath: '',
      apiAction: ''
    };
  }

  createPolicyEdit(): any {
    return this.apiPolicyToEdit;
  }

  createActionEdit(): any {
    return this.apiActionToAdd;
  }

  public cellClickHandler({isEdited, dataItem, rowIndex}): void {
    this.editedRowIndex = rowIndex;
  }

  edit(data, rowIndex) {
    this.editMode = true;
    this.editedRowIndex = rowIndex;
  }

  cancel(data) {
    this.editMode = false;
    this.editedRowIndex = undefined;
  }

  savePolicyMapping(dataItem) {
    this.editMode = false;
    this.loaderService.start();
    this.sub = this.policyService.editApiPolicies(dataItem)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.toastr.success(`Policy ${dataItem.policyName} with api path ${dataItem.apiPath} edited successfully!`, 'Success!');
          this.loadApiPolicyData();
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          console.error(error);
        }
      });
  }

  saveApiActionMapping(dataItem) {
    this.editMode = false;
    this.loaderService.start();
    this.sub = this.policyService.editApiAction(dataItem)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.toastr.success(`Action ${dataItem.action} in page ${dataItem.page} mapped to api ${dataItem.api} successfully!`, 'Success!');
          this.loadApiActionData();
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          console.error(error);
        }
      });
  }

  deleteApiPolicyMappingDialogOpen(dataItem) {
    this.coreApiPolicyMappingToBeDeleted = dataItem;
    this.coreApiPolicyMappingDialogOpened = true;
  }

  public deleteApiPolicyFromTable(value) {
    this.loaderService.start();
    this.sub = this.policyService.deleteApiPolicy(value.id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success(`policy ${this.coreApiPolicyMappingToBeDeleted.policyName} with api path ${this.coreApiPolicyMappingToBeDeleted.apiPath} deleted successfully!`, 'Success!');
          this.loadApiPolicyData();
        } else {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.coreApiPolicyMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.coreApiPolicyMappingDialogOpened = false;
      }
    });
  }

  deleteApiActionMappingDialogOpen(dataItem) {
    this.apiActionMappingToBeDeleted = dataItem;
    this.apiActionMappingDialogOpened = true;
  }

  public deleteApiActionFromTable(value) {
    this.loaderService.start();
    this.sub = this.policyService.deleteApiAction(value.id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success(`Action ${value.action} in page ${value.page} mapped to api ${value.api} deleted successfully!`, 'Success!');
          this.loadApiActionData();
        } else {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.apiActionMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.apiActionMappingDialogOpened = false;
      }
    });
  }

  loadPageNames() {
    this.loaderService.start();
    this.sub = this.policyService.getAllPagesFromMapper()
      .subscribe({
        next: (result) => {
          this.pageNamesSource = result;
          this.pageNames = this.pageNamesSource;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  public close() {
    this.apiActionMappingDialogOpened = false;
    this.coreApiPolicyMappingDialogOpened = false;
  }

  onDropDownChanged($event) {
    this.loaderService.start();
    this.sub = this.policyService.getAllActionsOfPages($event)
      .subscribe({
        next: (result) => {
          this.selectedActionsSource = result;
          this.selectedActions = this.selectedActionsSource;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  handleFilter(value) {
    this.pageNames = this.pageNamesSource.filter(
      (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleActionFilter(value) {
    this.selectedActions = this.selectedActionsSource.filter(
      (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === 'TD' || element.nodeName === 'TH') &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
