import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { Release } from '../../../../shared/models/Release';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-release-detail',
  templateUrl: './release-detail.component.html',
  styleUrls: ['./release-detail.component.scss']
})
export class ReleaseDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  releases: Release[] = [];
  private sub: Subscription;

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeChanges();
  }

  private subscribeChanges() {
    this.sub = this.dataService.Release.subscribe(r => {
      this.releases = [r];
    });
  }

}
