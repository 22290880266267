<kendo-dialog
  [minWidth]="650"
  [width]="650"
  [minHeight]="400"
  [height]="400">
  <kendo-dialog-titlebar>
    <div>
      <span class="k-icon k-i-form-element"></span> AD Group
    </div>
  </kendo-dialog-titlebar>
  <div class="container">
    <div class="row">
      <label for="group">Group</label>
      <kendo-dropdownlist
        id="group"
        [filterable]="true"
        (filterChange)="handleGroupFilter($event)"
        [data]="groupsFiltered"
        [defaultItem]="{ text: 'Select Ad Group', value: null }"
        textField="displayName"
        valueField="id"
        [(ngModel)]="selectedGroup"
        required
      >
      </kendo-dropdownlist>
    </div>
    <div class="row">
      <label for="policies">Policies</label>
      <app-group-policies
        #groupPolicies
        id="Policies">
      </app-group-policies>
    </div>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button
      class="mt-2"
      kendoButton
      (click)="onConfirmAction()"
      themeColor="primary"
      [disabled]="false"
    >
      Save
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
