<kendo-card width="auto">
  <h5>Summary</h5>
  <div class="row">
    <div class="col-4">Customer Id:</div>
    <div class="col-8">{{infrastructure?.CustomerID}}</div>
  </div>
  <div class="row">
    <div class="col-4">Environment Type:</div>
    <div class="col-8">{{infrastructure?.EnvironmentType}}</div>
  </div>
  <div class="row">
    <div class="col-4">Region:</div>
    <div class="col-8">{{infrastructure?.Region}}</div>
  </div>
  <div class="row">
    <div class="col-4">Instance:</div>
    <div class="col-8">{{infrastructure?.Instance}}</div>
  </div>
</kendo-card>
<kendo-card width="auto">
  <h5>PSI Pipelines</h5>
  <kendo-grid
    [kendoGridBinding]="pipelinesView"
    kendoGridSelectBy="id"
    (selectedKeysChange)="pipelinesSelectionChange($event)"
    [pageSize]="20"
    [height]="200"
  >
    <kendo-grid-checkbox-column
      [width]="45"
      [headerClass]="{ 'text-center': true }"
      [class]="{ 'text-center': true }"
      [resizable]="false"
      [columnMenu]="false"
      [showSelectAll]="true"
    ></kendo-grid-checkbox-column>
    <kendo-grid-column field="pipelineName" title="Name" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="pipelineId" title="Id" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column
      field="queueNumber"
      title="Queue Number"
      [width]="100"
      [class]="{ 'text-center': true }"
      [resizable]="false"
    >
    </kendo-grid-column>
  </kendo-grid>
  <!--  <kendo-listview-->
  <!--    [navigable]="true"-->
  <!--    [data]="psIntegrationPipelines"-->
  <!--    containerClass="k-d-flex k-flex-col k-flex-nowrap"-->
  <!--  >-->
  <!--    <ng-template-->
  <!--      kendoListViewItemTemplate-->
  <!--      let-dataItem="dataItem"-->
  <!--    >-->
  <!--      <div class="row">-->
  <!--        <div class="col-1">-->
  <!--          <input-->
  <!--            id="chk{{dataItem.id}}"-->
  <!--            [(ngModel)]="dataItem.selected"-->
  <!--            type="checkbox"-->
  <!--            kendoCheckBox-->
  <!--          />-->
  <!--        </div>-->
  <!--        <label class="k-label col-6" for="{{dataItem.id}}-pipelineName">{{dataItem.pipelineName}}</label>-->
  <!--        <label class="k-label col-2" for="{{dataItem.id}}-pipelineId">{{dataItem.pipelineId}}</label>-->
  <!--        <label class="k-label col-2"-->
  <!--               for="{{dataItem.id}}-queueNumber">{{dataItem.queueNumber}}</label>-->
  <!--      </div>-->
  <!--    </ng-template>-->
  <!--  </kendo-listview>-->
</kendo-card>


