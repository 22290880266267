import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-mailbox-manager-summary',
  templateUrl: './mailbox-manager-summary.component.html',
  styleUrls: ['./mailbox-manager-summary.component.scss']
})
export class MailboxManagerSummaryComponent extends BaseComponent implements OnInit {

  private sub: Subscription;
  summaryDetails: any;
  editSummary = false;
  showEditSummary = false;
  @Input() public customerEnvironmentDetails: UntypedFormGroup;

  constructor() {
    super();
  }

  ngOnInit(): void {
    const customer = this.customerEnvironmentDetails.get('customerId').value;
    const custType = this.customerEnvironmentDetails.get('custType').value;
    if(custType && custType == "CNC 1.X"){
      this.showEditSummary = true;
      this.getCNC1XCustomerDetails(customer)
    }
    else{
      this.getCustomerDetails(customer)
    }
  }

  getCustomerDetails(customerId){
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getCustomerEnvironment(customerId)
      .subscribe({
        next: (result) => {
          this.summaryDetails = result;
          this.updateDataToForm();
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public getCNC1XCustomerDetails(customerId){
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getCustomerCNC1xMailboxEnv(customerId)
      .subscribe({
        next: (result) => {
          this.summaryDetails = result;
          this.updateDataToForm();
          this.loaderService.stop();

        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  updateDataToForm(){
    this.customerEnvironmentDetails.controls.instance.setValue(this.summaryDetails.instance);
    this.customerEnvironmentDetails.controls.environment.setValue(this.summaryDetails.environment);
    this.customerEnvironmentDetails.controls.region.setValue(this.summaryDetails.region);
  }

  updateEnvironmentDetials(){
    this.summaryDetails.instance = this.customerEnvironmentDetails.get("instance").value;
    this.summaryDetails.environment = this.customerEnvironmentDetails.get("environment").value;
    this.summaryDetails.region = this.customerEnvironmentDetails.get("region").value;
    this.editSummary = false;
  }

  editEnvironmentDetails(){
    this.editSummary = true;
  }
}
