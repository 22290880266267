<kendo-dialog
  [width]="1000"
  [height]="440">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <span class="k-icon k-i-warning"></span> Please double check the source and target to confirm
    </div>
  </kendo-dialog-titlebar>
  <form class="k-form k-form-horizontal row m-1" [formGroup]="formGroup">
    <app-db-refresh-info-multi
      class="col-6"
      direction="Source"
      [dbInfo]="Source"
      confirmControlName="sourceConfirm"
    ></app-db-refresh-info-multi>
    <app-db-refresh-info-multi
      class="col-6"
      direction="Target"
      [dbInfo]="Target"
      confirmControlName="targetConfirm"
    ></app-db-refresh-info-multi>
  </form>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button kendoButton (click)="onConfirmAction()" [disabled]="disableConfirm()" themeColor="primary">
      Confirm
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
