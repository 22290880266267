import { Title } from '@angular/platform-browser';
import { AppInjector } from '../shared/utils/AppInjector';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DocumentService } from '../services/document.service';
import { ProjectService } from '../services/azure/project.service';
import { PipelineService } from '../services/azure/pipeline.service';
import { ReleaseService } from '../services/azure/release.service';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../services/app-config.service';
import { BatchService } from '../services/database/batch.service';
import { ReleaseDbService } from '../services/database/release.db.service';
import { DataService } from '../services/data.service';
import { GeneralService } from '../services/azure/general.service';
import { EnvironmentDbService } from '../services/database/environment.db.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { RepositoryService } from '../services/azure/repository.service';
import { ProductService } from '../services/database/product.service';
import { ArtifactService } from '../services/azure/artifact.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AdminToolService } from '../services/admin-tool/admin-tool.service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { GroupService } from '../services/graph/group.service';
import { PolicyService } from '../services/database/policy.service';
import { GroupPolicyService } from '../services/database/group.policy.service';
import { AdGroupService } from '../services/database/ad-group.service';
import { Constants } from '../shared/Constants';
import { AuditService } from '../services/database/audit.service';
import { AdminService } from '../services/database/admin.service';
import { CustomerEnvironmentService } from '../services/database/customer-environment.service';
import { DynamicsService } from '../services/dynamics/dynamics.service';
import { AppRegistrationService } from '../services/azure/app-registration.service';
import { AppInsightsService } from '../services/app-insights.service';
import { LcsCustomerService } from '../services/dynamics/lcs-customer.service';
import { LcsEnvironmentService } from '../services/dynamics/lcs-environment.service';
import { Router } from '@angular/router';
import { GitServiceService } from '../services/azure/git-service.service';
import { StorageAccountService } from '../services/dev-tests-lab/storage.account.service';
import { SignalRService } from '../services/signalR/signal-r.service';
import { DtlDeploymentService } from '../services/database/dtl.deployment.service';
import { DtlDeploymentItemService } from '../services/database/dtl.deploymentitem.service';
import { DevTestsLabService } from '../services/dev-tests-lab/dev.tests.lab.service';

export abstract class BaseComponent {
  public currentApplicationVersion;
  protected msalService: MsalService;
  protected configService: AppConfigService;
  protected datePipe: DatePipe;
  protected httpClient: HttpClient;
  protected titleService: Title;
  protected dataService: DataService;
  protected dialogService: DialogService;
  protected documentService: DocumentService;
  protected router: Router;
  protected toastr: ToastrService;
  protected loaderService: NgxUiLoaderService;
  protected userService: UserService;
  protected generalService: GeneralService;
  protected projectService: ProjectService;
  protected repositoryService: RepositoryService;
  protected pipelineService: PipelineService;
  protected releaseService: ReleaseService;
  protected artifactService: ArtifactService;
  protected batchService: BatchService;
  protected releaseDbService: ReleaseDbService;
  protected environmentDbService: EnvironmentDbService;
  protected productService: ProductService;
  protected adminToolService: AdminToolService;
  protected graphGroupService: GroupService;
  protected policyService: PolicyService;
  protected adGroupService: AdGroupService;
  protected groupPolicyService: GroupPolicyService;
  protected auditService: AuditService;
  protected adminService: AdminService;
  protected customerEnvironmentService: CustomerEnvironmentService;
  protected dynamicsService: DynamicsService;
  protected appRegistrationService: AppRegistrationService;
  protected appInsightsService: AppInsightsService;
  protected lcsCustomerService: LcsCustomerService;
  protected lcsEnvironmentService: LcsEnvironmentService;
  protected gitService : GitServiceService
  protected storageAccountService : StorageAccountService
  public signalRService:SignalRService
  protected dtlDeploymentService: DtlDeploymentService
  protected dtlDeploymentItemService: DtlDeploymentItemService
  protected devTestsLabService: DevTestsLabService

  protected constructor() {
    try {
      this.currentApplicationVersion = environment.appVersion;
      const injector = AppInjector.getInjector();

      this.msalService = injector.get(MsalService);
      this.configService = injector.get(AppConfigService);
      this.datePipe = injector.get(DatePipe);
      this.httpClient = injector.get(HttpClient);
      this.toastr = injector.get(ToastrService);
      this.toastr.toastrConfig.timeOut = 0;
      this.loaderService = injector.get(NgxUiLoaderService);
      this.titleService = injector.get(Title);
      this.dataService = injector.get(DataService);
      this.dialogService = injector.get(DialogService);
      this.documentService = injector.get(DocumentService);
      this.router = injector.get(Router);
      this.userService = injector.get(UserService);
      this.generalService = injector.get(GeneralService);
      this.projectService = injector.get(ProjectService);
      this.repositoryService = injector.get(RepositoryService);
      this.pipelineService = injector.get(PipelineService);
      this.releaseService = injector.get(ReleaseService);
      this.artifactService = injector.get(ArtifactService);
      this.batchService = injector.get(BatchService);
      this.releaseDbService = injector.get(ReleaseDbService);
      this.environmentDbService = injector.get(EnvironmentDbService);
      this.productService = injector.get(ProductService);
      this.adminToolService = injector.get(AdminToolService);
      this.graphGroupService = injector.get(GroupService);
      this.policyService = injector.get(PolicyService);
      this.adGroupService = injector.get(AdGroupService);
      this.groupPolicyService = injector.get(GroupPolicyService);
      this.auditService = injector.get(AuditService);
      this.adminService = injector.get(AdminService);
      this.customerEnvironmentService = injector.get(CustomerEnvironmentService);
      this.dynamicsService = injector.get(DynamicsService);
      this.appRegistrationService = injector.get(AppRegistrationService);
      this.appInsightsService = injector.get(AppInsightsService);
      this.lcsCustomerService = injector.get(LcsCustomerService);
      this.lcsEnvironmentService = injector.get(LcsEnvironmentService);
      this.gitService = injector.get(GitServiceService);
      this.storageAccountService = injector.get(StorageAccountService);
      this.signalRService = injector.get(SignalRService);
      this.dtlDeploymentService = injector.get(DtlDeploymentService);
      this.dtlDeploymentItemService = injector.get(DtlDeploymentItemService);
      this.devTestsLabService = injector.get(DevTestsLabService);
    } catch (e) {
      console.error('Failed initializing dependencies', e);
    }
  }

  get Organization(): string {
    return this.configService.Organization;
  }

  get AccountInfo(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  get IsAdmin(): boolean {
    const userRoles = this.AccountInfo?.idTokenClaims['roles'];
    return userRoles?.some(r => r === Constants.ADMIN);
  }
}
