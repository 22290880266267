import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/Constants';
import { DialogAction, DialogRef, DialogCloseResult, DialogContentBase } from '@progress/kendo-angular-dialog';
import { ActionsLayout } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-app-registration-override-confirm',
  templateUrl: './app-registration-override-confirm.component.html',
  styleUrls: ['./app-registration-override-confirm.component.scss']
})
export class AppRegistrationOverrideConfirmComponent extends DialogContentBase {

  public actionsLayout: ActionsLayout = 'center';

  public changeList;

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  public set Changes(value) {
    this.changeList = value;
  }

  public get Changes() {
    return this.changeList;
  }


  public onCancelAction(): void {
    this.dialog.close(DialogCloseResult);
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Save', themeColor: Constants.THEME_PRIMARY} as DialogAction);
  }
}