import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogAction, DialogCloseResult, DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Group } from '@microsoft/microsoft-graph-types';
import { GroupService } from '../../../services/graph/group.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GroupPoliciesComponent } from '../permission-manage/group-policies/group-policies.component';
import { Constants } from '../../../shared/Constants';

@Component({
  selector: 'app-group-update',
  templateUrl: './group-update.component.html',
  styleUrls: ['./group-update.component.scss']
})
export class GroupUpdateComponent extends DialogContentBase implements OnInit {
  @ViewChild('groupPolicies') GroupPoliciesComp: GroupPoliciesComponent;

  groups: Group[] = [];
  groupsFiltered: Group[] = [];

  selectedGroup: Group;

  constructor(public dialog: DialogRef, private loaderService: NgxUiLoaderService, private graphGroupService: GroupService) {
    super(dialog);
  }

  ngOnInit(): void {
    this.loadAllAdGroups();
  }

  public onCancelAction(): void {
    this.dialog.close(DialogCloseResult);
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Save', themeColor: Constants.THEME_PRIMARY} as DialogAction);
  }

  private loadAllAdGroups() {
    this.loaderService.start();
    this.graphGroupService.getAllAdGroups().subscribe({
      next: (result) => {
        this.groups = result.value as Group[];
        this.groupsFiltered = this.groups;
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  handleGroupFilter(value) {
    this.groupsFiltered = this.groups.filter(
      (g) => g.displayName.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
