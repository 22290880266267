<div class="w-75 px-4">
    <ng-container [formGroup]="form">
        <kendo-formfield>
            <kendo-label [for]="tgtSqlServer">Target Sql Server:</kendo-label>
            <kendo-textbox
              #tgtSqlServer
              id="tgtSqlServer"
              formControlName="TargetSqlServer"
              [readonly]="true"
              required
            >
            </kendo-textbox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="dbName" text="Target DB Name: "></kendo-label>
            <kendo-dropdownlist
            defaultItem="Select Db Name..."
            #dbName
            formControlName="TargetDbName"
            [data]="DbNames"
            [valuePrimitive]="true"
            required
          >
          </kendo-dropdownlist>
          </kendo-formfield>

          <!-- <kendo-formfield>
            <kendo-label [for]="tgtEnvironmentType">Target Environment Type:</kendo-label>
            <kendo-dropdownlist
              #tgtEnvironmentType
              id="tgtEnvironmentType"
              formControlName="TargetEnvironmentType"
              [data]="environmentTypes"
              [defaultItem]="null"
              required
            >
            </kendo-dropdownlist>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="tgtRegion">Target Region:</kendo-label>
            <kendo-dropdownlist
              #tgtRegion
              id="tgtRegion"
              formControlName="TargetRegion"
              [data]="regions"
              [defaultItem]="null"
              required
            >
            </kendo-dropdownlist>
          </kendo-formfield> -->
    </ng-container>
</div>