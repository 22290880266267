import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-finance-integration-app-reg',
  templateUrl: './finance-integration-app-reg.component.html',
  styleUrls: ['./finance-integration-app-reg.component.scss']
})
export class FinanceIntegrationAppRegComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  showCheckboxError=false;
  showTextbox = false;
  public checkboxes = [
    { "selected": false, "name": "Production", "value": "Prod" },
    { "selected": false, "name": "Dev", "value": "dev" },
    { "selected": false, "name": "Test", "value": "tst" }
  ];
  envSeries:any = null;
  envProdSeries = "";
  envNonProdSeries = "";

  public appRegistration: UntypedFormGroup = new UntypedFormGroup({
    customerId: new UntypedFormControl(),
    Production: new UntypedFormControl(),
    Dev: new UntypedFormControl(),
    Test: new UntypedFormControl(),
    newCheckbox: new UntypedFormControl()
  });

  
  constructor() {
    super();
  }
  ngOnDestroy(): void {
    if(this.sub!=undefined && this.sub != null){
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getEnvSeries()
  }

  public generateScript(){
    this.showCheckboxError= false;
    this.appRegistration.markAllAsTouched();
    var customerIdToSend = this.appRegistration.get('customerId').value;

    var query=""
    this.checkboxes.forEach(ch=>{
      var checked = this.appRegistration.get(ch.name.split('(')[0]).value;
      if(checked){
        query+="environments="+ch.value+"&" 
      }
    });

    if(query!=""){
      query = query.substring(0, query.length - 1);
      this.checkboxes.forEach(ch=>{
        var checked = this.appRegistration.get(ch.name.split('(')[0]);
        checked.reset();
      });
      var customerIdControl=this.appRegistration.get('customerId');
      customerIdControl.reset();
      this.resetCheckboxNames();
    }
    else{
      this.showCheckboxError = true;
      this.checkboxes.forEach(ch=>{
        var checked = this.appRegistration.get(ch.name.split('(')[0]);
        checked.setErrors({required:true})
      });
    }

    if(customerIdToSend != null && customerIdToSend!="" && query!=""){
      this.loaderService.start();
      this.sub = this.appRegistrationService.generateFinanceIntegrationAppRegistrationScript(customerIdToSend,query)
      .subscribe({
        next: (result) => {
          let blob:any = new Blob([result], { type: 'text/json; charset=utf-8' });
          const url = window.URL.createObjectURL(blob);
          //window.open(url);
          saveAs(blob, 'CampusNexusFinanceAADAppRegistrationUsingApiURI.zip');
          this.loaderService.stop()
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        }
      });
    }

  }

  public reset(){
    this.appRegistration.reset();
  }

  public ShowTextbox(){
    this.showTextbox = true;
  }

  public Addcheckbox(){
    var newCheckbox = this.appRegistration.get('newCheckbox').value

    if(newCheckbox != null && newCheckbox != ""){
      this.appRegistration.addControl(newCheckbox,new UntypedFormControl());
      let checkBoxToAdd={ "selected": false, "name": newCheckbox, "value": newCheckbox };
      this.checkboxes.push(checkBoxToAdd);
      this.showTextbox= false;
      this.appRegistration.get('newCheckbox').reset();
      var addedCheckBox = this.appRegistration.get(newCheckbox);
      addedCheckBox.patchValue(true);
    }
  }

  public getEnvSeries(){
    this.loaderService.start();
    this.sub = this.adminService.getEnvSeriesMappings()
    .subscribe({
      next: (result) => {
        this.envSeries = result;
        this.envSeries.forEach(element => {
            if(element.environmentType == "Prod"){
              this.envProdSeries = element.series
            }
            else if(element.environmentType == "Non-Prod"){
              this.envNonProdSeries = element.series
            }
        });
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  public onCustomerIdChange(custId :any){
    this.resetCheckboxNames();
    if(custId != null && custId != ""){
      var series = custId[0]; 
      var selectedEnvSeries = null;

      this.envSeries.forEach(element => {
        if(element.series == series){
          selectedEnvSeries = element;
          return;
        }
      });

      if(selectedEnvSeries != null){
        if(selectedEnvSeries.environmentType != "Internal"){
          this.checkboxes.forEach(element =>{
            if(element.name != "Production"){
              element.name += `(${this.envNonProdSeries}${custId.substr(1)})`
            }
            else{
              element.name += `(${this.envProdSeries}${custId.substr(1)})`
            }
          })
        }
        else{
          this.checkboxes.forEach(element =>{
            element.name += `(${custId})`
          })
        }
      }
    }

  }


  private resetCheckboxNames() {
    this.checkboxes.forEach(element => {
      element.name = element.name.split('(')[0];
    });
  }

}