import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { TabAlignment, TabPosition } from '@progress/kendo-angular-layout';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-customer-onboarding-status',
  templateUrl: './customer-onboarding-status.component.html',
  styleUrls: ['./customer-onboarding-status.component.scss']
})
export class CustomerOnboardingStatusComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(public activatedRoute: ActivatedRoute) {
    super();
  }

  public alignment: TabAlignment = 'start';
  public position: TabPosition = 'top';
  public selected = 0;
  public multiTenantStatus = 'MultiTenant Status';
  public onboardingStatus = 'Customer Provisioning Status';
  public title = '';
  onThisPage = false;
  statusData = null;
  errorDetailsOpened = false;
  errorMessage = '';

  //public hubKey:Guid;

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    this.onThisPage = false;
    this.statusData = null;
    this.selected = 0;
  }


  ngOnInit(): void {
    this.selected = 0;
    this.statusData = null;
    this.onThisPage = true;
    this.searchTerm = this.activatedRoute.snapshot.paramMap.get('Id');
    const path = this.activatedRoute.snapshot.routeConfig.path;
    if (path.includes(Constants.CustomerOnboardingStatus)) {
      this.title = this.onboardingStatus;
    } else {
      this.title = this.multiTenantStatus;
    }
    if (this.searchTerm != undefined && this.searchTerm != '') {
      this.search();
    }
  }

  private sub: Subscription;
  public gridData: GridDataResult;
  public searchTerm = '';
  public searchTermEntered = '';
  public searchClicked = false;

  public state: State = {
    skip: 0,
    take: 15
  };

  public getStatus() {
    this.loaderService.start();
    this.startHubRequest();
  }

  public processData(data) {
    data.forEach(element => {
      if (element.status == 'Queued' && element.link) {
        element.status = 'Awaiting Action';
      }
    });
    return process(data, this.state);
  }

  public pollData() {
    this.sub = this.pipelineService.pollCustomerOnboardingStatus(this.searchTermEntered)
      .subscribe({
        next: async (result) => {
          if (this.onThisPage) {
            if (result.length > 0 && result[0].length > 0 && result[0][0].customerId.includes(this.searchTermEntered)) {
              this.statusData = result;
            }
            await new Promise(f => setTimeout(f, 3000));
            this.pollData();
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
        }
      });
  }

  private startHubRequest = () => {
    this.sub = this.pipelineService.getCustomerOnboardingStatus(this.searchTermEntered)
      .subscribe({
        next: (result) => {
          this.statusData = result;
          this.loaderService.stop();
          this.pollData();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  };

  public cancelBuild(data) {
    let buildId = '';
    if (data.link) {
      buildId = this.getParamValueQueryString('buildId', data.link);
    }
    this.loaderService.start();
    this.sub = this.pipelineService.cancelCustomerOnboardingBuild(buildId, data, this.searchTermEntered)
      .subscribe({
        next: (result) => {
          this.statusData = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public startBuild(data) {
    this.loaderService.start();
    this.sub = this.pipelineService.queueCustomerOnboardingBuild(data, this.searchTermEntered)
      .subscribe({
        next: (result) => {
          this.statusData = result;
          this.RunOnBoardingPipeline(data);
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public RunOnBoardingPipeline(data) {
    this.pipelineService.startCustomerOnboardingBuild(data);
    this.loaderService.stop();
  }

  getParamValueQueryString(paramName, url) {
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({fromString: url.split('?')[1]});
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  public search() {
    if (this.searchClicked) {
      this.searchLogs();
    }
    if (this.searchTerm != this.searchTermEntered) {
      this.searchClicked = true;
      this.selected = 0;
      this.searchTermEntered = this.searchTerm;
      this.statusData = null;
      this.getStatus();
    }
  }

  public searchLogs() {
    if (this.searchTerm != this.searchTermEntered) {
      this.searchClicked = true;
      this.selected = 0;
      this.searchTermEntered = this.searchTerm;

      this.statusData = null;
      this.loaderService.start();
      this.sub = this.pipelineService.getCustomerOnboardingStatus(this.searchTermEntered)
        .subscribe({
          next: (result) => {
            this.statusData = result;
            this.loaderService.stop();
          },
          error: (error) => {
            this.loaderService.stop();
            console.error(error);
          },
          complete: () => {
            this.loaderService.stop();
          }
        });
    }
  }

  public refresh() {
    if (this.searchTermEntered) {
      this.loaderService.start();
      this.sub = this.pipelineService.getCustomerOnboardingStatus(this.searchTermEntered)
        .subscribe({
          next: (result) => {
            this.statusData = result;
            this.loaderService.stop();
          },
          error: (error) => {
            this.loaderService.stop();
            console.error(error);
          },
          complete: () => {
            this.loaderService.stop();
          }
        });
    }
  }

  public onSelect(data) {
    this.selected = data.index;
  }

  public rowCallback(context: RowClassArgs) {
    if (context.dataItem.status == 'Created' || context.dataItem.status == 'succeeded' || context.dataItem.status == 'Script Executed') {   // change this condition as you need
      return {
        created: true
      };
    } else if (context.dataItem.status == 'Queued' || context.dataItem.status == 'Not Started' || context.dataItem.status == '-') {   // change this condition as you need
      return {
        notstarted: true
      };
    } else if (context.dataItem.status == 'In Progress' || context.dataItem.status == 'Script Generated') {   // change this condition as you need
      return {
        inprogress: true
      };
    } else if (context.dataItem.status == 'Awaiting Action') {   // change this condition as you need
      return {
        awaitingaction: true
      };
    } else {   // change this condition as you need
      return {
        failed: true
      };
    }

  }

  public pageChange(e): void {
    this.state.skip = e.skip;
    this.state.take = e.take;
  }

  onKeyDown(pressedKey) {
    if (pressedKey.key === 'Enter') {
      this.searchLogs();
    }
  }

  public close(): void {
    this.errorMessage = '';
    this.errorDetailsOpened = false;
  }

  public cellClickHandler(e): void {
    if (e.columnIndex == 2 && e.dataItem.status == 'Failed To Start') {
      this.errorDetailsOpened = false;
      const data = JSON.parse(e.dataItem.error);
      if (data && data.customProperties && data.customProperties.ValidationResults && data.customProperties.ValidationResults.length > 0) {
        this.errorMessage = JSON.stringify(data.customProperties.ValidationResults[0], undefined, 2);
      } else {
        this.errorMessage = JSON.stringify(data, undefined, 2);
      }
      this.errorDetailsOpened = true;
    }
  }

  public expandDetails = (dataItem) => {
    return dataItem != null && dataItem.dataItem != null && dataItem.dataItem.stage != null
      && dataItem.dataItem.stage.includes('Pipeline run') && dataItem.dataItem.childPipelines != null
      && dataItem.dataItem.childPipelines.length > 0;
  };
}
