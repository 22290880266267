import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-database-refresh-details',
  templateUrl: './database-refresh-details.component.html',
  styleUrls: ['./database-refresh-details.component.scss']
})
export class DatabaseRefreshDetailsComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  @Input() public form: UntypedFormGroup;
  @Input() public customeIds: any[];
  @Input() public filteredCustomeIds: any[];
  @Input() public variableGroupsSource: any[];
  @Input() public variableGroupsFilteredSource: any[];
  @Input() public sources: any[];
  @Input() public filteredSources: any[];
  @Input() public targets: any[];
  @Input() public filteredTargets: any[];
  @Input() public liveCustomers: any[];

  @Output() sourceList = new EventEmitter<any[]>();
  @Output() targetList = new EventEmitter<any[]>();
  @Output() filteredSourceList = new EventEmitter<any[]>();
  @Output() filteredTargetList = new EventEmitter<any[]>();


  //customeIds:any[]= [];


  ngOnInit(): void {
    this.initialise();
  }

  initialise() {
    if (this.form.get('source').value) {
      if (!this.sources) {
        this.loaderService.start();
        this.sources = [];
        this.filteredSources = [];
        var value = this.form.get('customerId').value;
        if (value.includes('-')) {
          value = value.split('-')[0];
        }
        this.variableGroupsSource.forEach(x => {
          if (x.Code.includes(value.substring(1)) && !(x.Code.includes('obsolete'))) {
            this.sources.push(x);
            this.filteredSources.push(x);
          }
        });
        this.loaderService.stop();
      }
    }

    if (this.form.get('target').value) {
      if (!this.targets) {
        this.loaderService.start();
        this.targets = [];
        this.filteredTargets = [];
        var value = this.form.get('source').value;

        this.sources.forEach(x => {
          if (x.Id != value.Id) {
            this.targets.push(x);
            this.filteredTargets.push(x);
          }
        });
        this.loaderService.stop();
      }
    }
  }

  handleCustomerIdFilter(value) {
    this.filteredCustomeIds = this.customeIds.filter(
      (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  onDropDownChanged(value) {
    this.loaderService.start();
    this.sources = [];
    this.filteredSources = [];
    // this.form.controls.target.reset()
    // this.form.controls.source.reset()

    if (value.includes('-')) {
      value = value.split('-')[0];
    }
    this.variableGroupsSource.forEach(x => {
      if (x.Code.includes(value.substring(1)) && !(x.Code.includes('obsolete'))) {
        this.sources.push(x);
        this.filteredSources.push(x);
      }
    });

    this.sourceList.emit(this.sources);
    this.filteredSourceList.emit(this.filteredSources);
    this.loaderService.stop();
  }

  onSourceDropDownChanged(value) {
    this.loaderService.start();
    this.targets = [];
    this.filteredTargets = [];
    // this.form.controls.target.reset()

    this.sources.forEach(x => {
      if (!(this.liveCustomers.some(y => y.customerCode == x.Code))) {
        this.targets.push(x);
        this.filteredTargets.push(x);
      }
    });
    this.targetList.emit(this.targets);
    this.filteredTargetList.emit(this.filteredTargets);
    this.loaderService.stop();
  }

  onTargetDropDownChanged(value) {

  }

  handleSourceFilter($event: any) {
    this.filteredSources = this.sources.filter(
      (v) => (
        v.Name.toLowerCase().indexOf($event.toLowerCase()) !== -1 ||
        v.Code.toLowerCase().indexOf($event.toLowerCase()) !== -1
      )
    );

  }

  handleTargetFilter($event: any) {
    this.filteredTargets = this.targets.filter(
      (v) => (
        v.Name.toLowerCase().indexOf($event.toLowerCase()) !== -1 ||
        v.Code.toLowerCase().indexOf($event.toLowerCase()) !== -1
      )
    );

  }

  getTextField() {
    return 'Code';
  }

  getValueField() {
    return 'Id';
  }

  isProdMultiTenant(code: string): string {
    return code.startsWith('1') ? 'Prod' : code.startsWith('3') ? 'Non-Prod' : 'Internal';
  }
}
