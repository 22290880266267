import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FhcmEnvironmentsComponent } from './fhcm-evironments/fhcm-environments.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FhcmCustomersComponent } from './fhcm-customers/fhcm-customers.component';
import { FhcmCustomerUpdateComponent } from './fhcm-customer-update/fhcm-customer-update.component';
import { FhcmMainComponent } from './fhcm-main/fhcm-main.component';

@NgModule({
  declarations: [
    FhcmEnvironmentsComponent,
    FhcmCustomersComponent,
    FhcmCustomerUpdateComponent,
    FhcmMainComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    WindowModule,
    BrowserAnimationsModule,
    BrowserModule,
    TreeViewModule,
    ButtonsModule,
    DropDownsModule
  ]
})
export class DynamicsModule { }
