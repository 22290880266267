<kendo-tilelayout
  [columns]="5"
  [resizable]="false"
  [reorderable]="false"
  [gap]="0"
  id="pipeline-batch"
>
<kendo-tilelayout-item [col]="1" [colSpan]="5">
    <kendo-tilelayout-item-body>
      <kendo-appbar positionMode="sticky" style="padding: 20px;">
        <kendo-appbar-section>
        </kendo-appbar-section>
        <kendo-appbar-section>
          <button
          kendoButton
          (click)="backToAllLogs()"
          themeColor="primary"
        >
          Back To All Logs
        </button>
        </kendo-appbar-section>
      </kendo-appbar>

    </kendo-tilelayout-item-body>
    <kendo-tilelayout-item-body>
        <kendo-grid
        [kendoGridBinding]="appUpgradeLogs"
        kendoGridSelectBy="Id"
        [style.height.%]="100"
        [pageable] = false
        kendoGridFocusable
        [kendoGridExpandDetailsBy]="expandDetailsBy"
        [(expandedDetailKeys)]="expandedDetailKeys"
        [pageSize]="10"
        [pageable]="true"
        [filterable]="false"
        [selectable]="true"
        >
        <kendo-grid-column
        field="batch"
        title="Batch Id"
        [width]="40"
        [minResizableWidth]="40"
      >
      </kendo-grid-column>

      <kendo-grid-column
        field="createdBy"
        title="Created By"
        [width]="60"
        [minResizableWidth]="60"
      >
      </kendo-grid-column>

      <kendo-grid-column
      field="product"
      title="Product/Pipeline"
      [width]="60"
      [minResizableWidth]="60"
    >
    </kendo-grid-column>

    <kendo-grid-column
    field="version"
    title="Version/PipelineId"
    [width]="60"
    [minResizableWidth]="60"
  >
  </kendo-grid-column>

  <kendo-grid-column
  field="createdDate"
  title="DateTime"
  [width]="60"
  [minResizableWidth]="60"
>
</kendo-grid-column>

    <ng-template
    kendoGridDetailTemplate
    let-dataItem
    >
    <section>
        <kendo-grid
        [kendoGridBinding]="dataItem.pipelines"
        kendoGridSelectBy="Id"
        [style.height.%]="100"
        [pageable] = false
        [rowClass]="rowCallback"
        kendoGridFocusable
        [pageSize]="10"
        [pageable]="true"
        [filterable]="false"
        [selectable]="true"
        >
        <kendo-grid-column
          field="id"
          title=""
          [width]="40"
          [minResizableWidth]="40"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="name"
          title="Pipelines"
          [headerStyle]="{ 'text-align': 'center' }"
        >
        </kendo-grid-column>

        <ng-template
        kendoGridDetailTemplate
        let-dataItem
        >
        <section>
        <kendo-grid
        [data]="dataItem.runs"
        [pageSize]="10"
        [pageable]="true"
        [filterable]="false"
        kendoGridFocusable
        [selectable]="true"
        [rowClass]="runRowCallback"
        (detailExpand)="onExpand($event)"
        >

        <kendo-grid-column
          field="name"
          title="Environment"
          [width]="70"
          [minResizableWidth]="70"
        ></kendo-grid-column>
        <kendo-grid-column
          field="result"
          title="Result"
          [width]="60"
          [minResizableWidth]="60"
        ></kendo-grid-column>

        <kendo-grid-column
        [width]="50"
        [minResizableWidth]="50"
        [filterable]="false"
        >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button kendoButton themeColor="primary" *ngIf="dataItem.link">
                <a target="_blank" href="{{ dataItem.link }}" *ngIf="dataItem.link" class= "whiteText">
                Details
                </a>
            </button>
        </ng-template>
        </kendo-grid-column>

        <ng-template
        kendoGridDetailTemplate
        let-dataItem
        >
        <section>
            <kendo-grid
            [data]="dataItem.jobs"
            [pageSize]="10"
            [pageable]="true"
            [filterable]="false"
            kendoGridFocusable
            [selectable]="true"
            [rowClass]="runRowCallback"
            >
            <kendo-grid-column
            field="name"
            title="Name"
            [width]="60"
            [minResizableWidth]="60"
          ></kendo-grid-column>
          <kendo-grid-column
          field="result"
          title="Result"
          [width]="60"
          [minResizableWidth]="60"
        ></kendo-grid-column>
        </kendo-grid>
        </section>
        </ng-template>
        </kendo-grid>
        </section>
        </ng-template>
        </kendo-grid>
    </section>
    </ng-template>
</kendo-grid>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
