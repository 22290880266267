import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class GitServiceService {

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService) {
  }


  createCustomerVariableTemplateFiles(customerOnboardingRequest): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${window.location.origin}/api/ado/Git/CustomerVariableTemplate`, customerOnboardingRequest)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createCustomerVariableTemplateFiles'))
      );
  }

  updateFinanceIntegrationCustVarTemplate(financeIntegrationRequest): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${window.location.origin}/api/ado/Git/FinanceIntegrationCustVarTemplate`, financeIntegrationRequest)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateFinanceIntegrationCustVarTemplate'))
      );
  }

  createMailboxManagerTemplate(mailboxManagerRequest): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${window.location.origin}/api/ado/Git/MailboxManagerTemplateFile`, mailboxManagerRequest)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createMailboxManagerTemplate'))
      );
  }

  updateKeyValueInTemplateFile(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${window.location.origin}/api/ado/Git/UpdateKeyValueInTemplateFile`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateKeyValueInTemplateFile'))
      );
  }

  GetTemplateFileLogs(): Observable<any> {
    
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/Git/TemplateFileLogs`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('GetTemplateFileLogs'))
      );
  }

}
