import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { BaseComponent } from '../../components/base.component';
import { MsalService } from '@azure/msal-angular';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent {
  @Input() loggedIn: boolean;
  @Output() public toggle = new EventEmitter();
  @Input() public selectedPage: string;

  get UserProfile() {
    return this._msalService.instance.getActiveAccount();
  }

  public loginActions: any[] = [
    {
      icon: 'help',
      actionName: 'Help',
      click: () => this.help()
    },
    // {
    //   icon: 'logout',
    //   actionName: 'Log Out',
    //   click: () => this.logout()
    // }
  ];

  login() {
    this.authService.login();
  }

  updatePatToken() {
    this.router.navigateByUrl(`/${Constants.PAT_TOKEN}`);
  }

  help() {
    var url = this.configService.HelpLink;
    window.open(url, '_blank').focus();
  }

  constructor(@Inject('BASE_URL') private baseUrl: string,
              private authService: AuthService,
              private _msalService: MsalService) {
    super();
  }

  public onButtonClick(): void {
    this.toggle.emit();
  }

  logout() {
    this.authService.logout();
  }

  public goHome(){
    this.router.navigate(['']).then(() => {
      window.location.reload();
    });
  }
}
