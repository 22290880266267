import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { Constants } from '../../../../shared/Constants';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { VariableGroupPair } from '../../../../shared/models/VariableGroupPair';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AtEnvironmentEx, AtEnvironmentGroup } from '../../../../shared/models/AtEnvironmentEx';
import { AtTenantEx } from '../../../../shared/models/AtTenantEx';
import { DbSelectionChangeArg } from '../../../../shared/models/DbSelectionChangeArg';
import { VariableGroup } from '../../../models/VariableGroup';
import { GetVariableValueByKey } from '../../../../shared/utils/VariableGroupUtils';
import { EnvironmentService } from '../../../../shared/models/AdminTool/EnvironmentService';
import { TenantEnvironment } from '../../../../shared/models/AdminTool/TenantEnvironment';
import { ServiceConfiguration } from '../../../../shared/models/AdminTool/ServiceConfiguration';

@Component({
  selector: 'app-data-source-field',
  templateUrl: './data-source-field.component.html',
  styleUrls: ['./data-source-field.component.scss']
})
export class DataSourceFieldComponent extends BaseComponent implements OnInit {
  private sub: Subscription;

  @ViewChild('source') sourceComp: DropDownListComponent;

  @Input() controlLabel: string;
  @Input() controlName: string;
  @Input() environmentControlName: string;

  @Output() selectionChange = new EventEmitter<DbSelectionChangeArg>();

  variableGroupsSource: any[] = [];
  variableGroupsFilteredSource: any[] = [];

  private _platform: string;
  private _selectedVariableGroup: VariableGroup | ServiceConfiguration = null;
  private _selectedTenant: AtTenantEx = null;
  private _selectedEnvironment: string = '';
  environments: string[] = [];

  public get platform() {
    return this._platform;
  }

  public set platform(value: string) {
    this._platform = value;

    if (this.isSingleTenant(value)) {
      this.loadCustomerSingleTenant();
    } else if (this.isMultiTenant(value)) {
      this.loadCustomerMultiTenant();
    }
  }

  get form(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  get control(): UntypedFormControl {
    return this.form.get(this.controlName) as UntypedFormControl;
  }

  get environmentControl(): UntypedFormControl {
    return this.form.get(this.environmentControlName) as UntypedFormControl;
  }

  get SelectedTenant(): AtTenantEx {
    return this._selectedTenant;
  }

  set SelectedTenant(val: AtTenantEx) {
    this._selectedTenant = val;
  }

  get SelectedVariableGroup(): VariableGroup | ServiceConfiguration {
    return this._selectedVariableGroup;
  }

  set SelectedVariableGroup(val: VariableGroup | ServiceConfiguration) {
    this._selectedVariableGroup = val;
  }

  get SelectedEnvironment(): string {
    return this._selectedEnvironment;
  }

  set SelectedEnvironment(val: string) {
    this._selectedEnvironment = val;
  }

  constructor(@Optional() private controlContainer: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    this.subscribeVariableGroupChange();
    this.subscribeEnvironmentChanges();
  }

  isSingleTenant(platform: string): boolean {
    return platform === Constants.PLATFORM_SINGLE_TENANT;
  }

  isMultiTenant(platform: string): boolean {
    return platform === Constants.PLATFORM_MULTIPLE_TENANT;
  }

  IsSingleTenant(): boolean {
    return this.isSingleTenant(this.platform);
  }

  IsMultiTenant(): boolean {
    return this.isMultiTenant(this.platform);
  }

  isProdSingleTenant(code: string): string {
    return code.startsWith('CMVG100') ? 'Prod' : 'Non-Prod';
  }

  isProdMultiTenant(code: string): string {
    return code.startsWith('100') ? 'Prod' : 'Non-Prod';
  }

  private loadCustomerSingleTenant() {
    this.loaderService.start();
    this.sub = this.projectService.getProjectVariableGroups(this.configService.MainProject)
      .pipe(
        switchMap(result => this.filterVariableGroups(result))
      )
      .subscribe({
        next: (result) => {
          this.variableGroupsSource = result.Productions;
          this.variableGroupsFilteredSource = this.variableGroupsSource;
          this.sourceComp.data = this.variableGroupsFilteredSource;
          this.sourceComp.valueField = 'id';
          this.sourceComp.textField = 'name';

          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  private filterVariableGroups(result: any): Observable<VariableGroupPair> {
    const productions = result.value
      .filter(v => v.name.startsWith('CMVG10') || v.name.startsWith('CMVG30'))       // including Prod
      .sort((a, b) => a.name.localeCompare(b.name));

    const nonProductions = this.IsAdmin ?
      result.value
        .filter(v => v.name.startsWith('CMVG10') || v.name.startsWith('CMVG30'))       // including Prod
        .sort((a, b) => a.name.localeCompare(b.name)) :
      result.value
        .filter(v => v.name.startsWith('CMVG30'))  // Non-Prod only
        .sort((a, b) => a.name.localeCompare(b.name));

    return of({
      Productions: productions,
      NonProductions: nonProductions
    });
  }

  private loadCustomerMultiTenant() {
    this.loaderService.start();
    this.sub = this.adminToolService.getTenantsProd()
      .subscribe(
        {
          next: (result) => {
            this.variableGroupsSource = result;
            this.variableGroupsFilteredSource = this.variableGroupsSource;
            this.sourceComp.data = this.variableGroupsFilteredSource;
            this.sourceComp.valueField = 'Id';
            this.sourceComp.textField = 'Name';

            this.loaderService.stop();
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
          }
        }
      );
  }

  private filterProdAndNonProd(envs: AtEnvironmentEx[]): Observable<AtEnvironmentGroup> {
    const productions = envs.filter(e => e.Name.startsWith('prodcust') || e.Name.startsWith('nprdcust'))
      .map(e => {
        if (e.Name.startsWith('prodcust')) {
          e.EnvType = Constants.ADMIN_TOOL_ENV_PROD;
        } else if (e.Name.startsWith('nprdcust')) {
          e.EnvType = Constants.ADMIN_TOOL_ENV_NON_PROD;
        }

        return e;
      });
    const nonProductions = this.IsAdmin ?
      envs.filter(e => e.Name.startsWith('prodcust') || e.Name.startsWith('nprdcust')) :
      envs.filter(e => e.Name.startsWith('nprdcust'));

    return of({
      Productions: productions,
      NonProductions: nonProductions
    });
  }

  private populateTenants(env: AtEnvironmentGroup): Observable<[AtTenantEx[][][], AtTenantEx[][][]]> {
    const prodTenants = this.populateEnvironmentTenants(env.Productions);
    const nprdTenants = this.populateEnvironmentTenants(env.NonProductions);

    return forkJoin([prodTenants, nprdTenants]);
  }

  private populateEnvironmentTenants(environments: AtEnvironmentEx[]): Observable<AtTenantEx[][][]> {
    const observables: Observable<AtTenantEx[][]>[] = environments.map(env => this.adminToolService.getEnvironmentTenants(env));

    return combineLatest(observables);
  }

  handleSourceFilter($event: any) {
    if (this.isSingleTenant(this.platform)) {
      this.variableGroupsFilteredSource = this.variableGroupsSource.filter(
        (v) => v.name.toLowerCase().indexOf($event.toLowerCase()) !== -1
      );
      this.sourceComp.valueField = 'id';
      this.sourceComp.textField = 'name';
    } else if (this.isMultiTenant(this.platform)) {
      this.variableGroupsFilteredSource = this.variableGroupsSource.filter(
        (v) => (
          v.Name.toLowerCase().indexOf($event.toLowerCase()) !== -1 ||
          v.Code.toLowerCase().indexOf($event.toLowerCase()) !== -1
        )
      );
      this.sourceComp.valueField = 'Id';
      this.sourceComp.textField = 'Name';
    }

    this.sourceComp.data = this.variableGroupsFilteredSource;
  }

  private subscribeVariableGroupChange() {
    this.control.valueChanges.subscribe(val => {
      if (!val) {
        return;
      }

      if (this.IsSingleTenant()) {
        this.loadVariable(val.id);
      }

      if (this.IsMultiTenant()) {
        this.loadTenantInfo(val);
      }
    });
  }

  private subscribeEnvironmentChanges() {
    this.environmentControl.valueChanges.subscribe(val => {
      if (!val) {
        return;
      }
      this.SelectedEnvironment = val;
      this.updateChangeStatus();
    });
  }

  private loadVariable(variableId: number) {
    this.loaderService.start();
    this.projectService.getProjectVariableGroupById(this.configService.MainProject, variableId)
      .subscribe(
        {
          next: (result) => {
            this.SelectedVariableGroup = result;
            this.populateEnvironmentList(this.SelectedVariableGroup);
            this.updateChangeStatus();
            this.loaderService.stop();
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
          }
        }
      );
  }

  private loadTenantInfo(tenant: AtTenantEx) {
    this.loaderService.start();
    this.SelectedTenant = tenant;
    this.adminToolService.getTenantEnvironmentsByTenantIdProd(tenant.Id).pipe(
      switchMap((te) => {
        const es = this.adminToolService.getEnvironmentServicesProd(te[0]?.EnvironmentId);
        return forkJoin([es, of(te[0])])
      }),
      switchMap((es) => DataSourceFieldComponent.findStudentWebService(es)),
      switchMap((s) => this.getDbConnectionInfo(s[0], tenant, s[1]))
    )
      .subscribe(
        {
          next: (result) => {
            const dbInfo = result[0];
            this.SelectedVariableGroup = dbInfo;
            this.environmentControl.patchValue(null);
            this.updateChangeStatus();
            this.loaderService.stop();
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
          }
        }
      );
  }

  private updateChangeStatus() {
    this.selectionChange.emit({
      VariableGroup: this.SelectedVariableGroup,
      Environment: this.SelectedEnvironment,
      Tenant: this.SelectedTenant
    });
  }

  private populateEnvironmentList(selectedVariableGroup: VariableGroup | ServiceConfiguration) {
    const variableGroup = selectedVariableGroup as VariableGroup;
    const variables = variableGroup.variables;
    const environments = GetVariableValueByKey(variables, 'AppGatewayEnvCodes');

    this.environments = environments.split(',');
  }

  resetForm() {
    this.control.patchValue(null);
    this.environmentControl.patchValue(null);
  }

  private static findStudentWebService(es: any[]): Observable<any> {
    const studentWeb = es[0]?.find(s => s.ServiceId === 4);
    return of([studentWeb, es[1]]);
  }

  private getDbConnectionInfo(es: EnvironmentService, tenant: AtTenantEx, tes: TenantEnvironment): Observable<ServiceConfiguration[]> {
    const query = {
      'ServiceId': 4,   // Student Web
      'EnvironmentServiceId': es.Id,
      'TenantEnvironmentId': tes.Id,
    }

    return this.adminToolService.getDbConfigurationProd(query)
  }

  getTextField() {
    return this.IsSingleTenant() ? 'name' : 'Name' + ' Code';
  }

  getValueField() {
    return this.IsSingleTenant() ? 'id' : 'Id';
  }
}
