import { Component, Input } from '@angular/core';
import { Infrastructure } from '../../../shared/models/devtestslab/Infrastructure';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent {
  @Input() public infrastructure: Infrastructure;
  @Input() productParams: any[] = [];

  constructor() { }

}
