<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<kendo-tilelayout
[resizable]="true"
[reorderable]="true"
>
    <kendo-tilelayout-item title="Product/Version Mapping" [col]="1" [colSpan]="3">
        <kendo-tilelayout-item-body>
            <div class="container-fluid example-wrapper pb-3">
                <div class="row pb-3">
                    <div class="col-xs-12 col-sm-4 example-col">
                      <div class="fs-6 fw-bold pt-3 pb-3">Select Product Table:</div>
                      <kendo-dropdownlist
                        [data]="dataTables"
                        [value]="selectedTable"
                        [defaultItem]="defaultTables"
                        textField="tableName"
                        valueField="tableId"
                        (valueChange)="handleTableChange($event)"
                      >
                      </kendo-dropdownlist>
                    </div>
                    <div class="col-xs-12 col-sm-4 example-col" *ngIf="!hideProduct">
                      <div class="fs-6 fw-bold pt-3 pb-3">Core Product:</div>
                      <kendo-dropdownlist
                        [hidden]="hideProduct"
                        [defaultItem]="defaultProduct"
                        [data]="dataResultProduct"
                        [value]="selectedProduct"
                        textField="productName"
                        valueField="productId"
                        (valueChange)="handleProductChange($event)"
                      >
                      </kendo-dropdownlist>
                    </div>
                    <div class="col-xs-12 col-sm-4 example-col" *ngIf="!hideVersion">
                        <div class="fs-6 fw-bold pt-3 pb-3">Core Product Version:</div>
                        <kendo-dropdownlist
                          [hidden]="hideVersion"
                          [defaultItem]="defaultVersion"
                          [data]="dataResultVersion"
                          [value]="selectedVersion"
                          textField="versionName"
                          valueField="versionId"
                          (valueChange)="handleVersionChange($event)"
                        >
                        </kendo-dropdownlist>
                    </div>
                  </div>
            </div>

            <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="hideSelection && (!hideCoreProdGrid || !hideAddOnProdGrid)">
                <kendo-tilelayout-item-body>
                  <button kendoButton (click)="hideShowSelection()" primary="true" class ="w-100 h-100" icon="arrow">Show Input Fields
                    <kendo-icon name="arrow-chevron-right"></kendo-icon>
                  </button>
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>

              <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="!hideSelection && (!hideCoreProdGrid || !hideAddOnProdGrid)">
                <kendo-tilelayout-item-body>
                  <button kendoButton (click)="hideShowSelection()" primary="true" class ="w-100 h-100" icon="arrow">Hide Input Fields
                    <kendo-icon name="arrow-chevron-down"></kendo-icon>
                  </button>
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>

              <kendo-tilelayout-item title="New Mapping" [col]="1" [colSpan]="3" *ngIf="!hideSelection && (!hideCoreProdGrid || !hideAddOnProdGrid)">
                <kendo-tilelayout-item-body class="pt-3">
                    <div class = "row pb-3" *ngIf="!hideCoreProdGrid">
                        <div class = "row pb-2">
                            <div class="col-1"></div>
                            <div class="col-4">
                                <kendo-label text="Product Name" class="px-2"></kendo-label>
                                <kendo-textbox
                                placeholder="Enter Product Name"
                                [(ngModel)]="coreProductToAdd.name">
                              </kendo-textbox>
                            </div>

                          <div class="col-4">
                            <kendo-label text="Version" class="px-2"></kendo-label>
                                <kendo-textbox
                                  placeholder="Enter Version"
                                  [(ngModel)]="coreProductToAdd.version">
                               </kendo-textbox>
                          </div>

                          <div class="col-3">
                            <kendo-label text="Integration Product" class="px-1"></kendo-label>
                            <input type="checkbox" kendoCheckBox [(ngModel)]="coreProductToAdd.forIntegration"/>
                         </div>
                        </div>

                        <div class = "row pb-2">
                          <div class="col-1"></div>
                          <div class="col-4">
                              <kendo-label text="Type" class="px-2"></kendo-label>
                            <kendo-dropdownlist
                            [data]="types"
                            placeholder="Select Type"
                            [(ngModel)]="coreProductToAdd.type"
                          >
                          </kendo-dropdownlist>
                          </div>
                      </div>

                        <div class = "row">
                            <div class="col-12">
                                <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addCoreProductToTable()">Add Row</button>
                            </div>
                        </div>
                    </div>
                    <div class = "row pb-3" *ngIf="!hideAddOnProdGrid">
                        <div class = "row pb-2">
                            <div class="col-1">
                            </div>

                            <div class="col-4">
                                <kendo-label text="Product Name" class="px-2"></kendo-label>
                                <kendo-dropdownlist
                                  id="authentication"
                                  [(ngModel)]="addOnProductToAdd.name"
                                  [data]="coreProducts"
                                  [defaultItem]="null"
                                >
                                </kendo-dropdownlist>
                            </div>

                          <div class="col-4">
                            <kendo-label text="Version" class="px-2"></kendo-label>
                                <kendo-textbox
                                  placeholder="Enter Version"
                                  [(ngModel)]="addOnProductToAdd.version">
                               </kendo-textbox>
                          </div>
                        </div>

                        <div class = "row">
                            <div class="col-12">
                                <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addAddOnProductToTable()">Add Row</button>
                            </div>
                        </div>
                    </div>
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>

              <kendo-tilelayout-item title="Existing Mapping" [col]="1" [colSpan]="3" *ngIf="!hideCoreProdGrid || !hideAddOnProdGrid">
                <kendo-tilelayout-item-body>
                    <div class ="row" *ngIf="!hideCoreProdGrid"
                    kendoTooltip
                    showOn="none"
                    [tooltipTemplate]="template"
                    filter=".k-grid td"
                    (mouseover)="showTooltip($event)"
                    >
                        <form novalidate #myForm="ngForm">
                          <kendo-grid 
                          [kendoGridTemplateEditing]="createProductEdit" 
                          [kendoGridBinding]="gridCoreProductData" 
                          (cellClick)="cellClickHandler($event)"
                          [filterable]="true">
                            <kendo-grid-column field="name" title="Product Name">
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.name"
                                  kendoGridFocusable
                                  name="Product Name"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="version" title="Version">
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.version"
                                  kendoGridFocusable
                                  name="Version"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="forIntegration" title="Integration Product" filter="boolean">
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.forIntegration"
                                  kendoGridFocusable
                                  name="Integration Product"
                                  type="checkbox"
                                />
                              </ng-template>  
                            </kendo-grid-column>
                            <kendo-grid-column field="type" title="Type">
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <kendo-dropdownlist [data]="types" [value]="dataItem.type" (valueChange)="editCoreProductType(dataItem,$event)"></kendo-dropdownlist>
                              </ng-template>  
                            </kendo-grid-column>
                          <kendo-grid-command-column title="command" [width]="220">
                            <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                              <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                                Edit
                              </button>
                              <button
                                kendoGridSaveCommand
                                type="button"
                                [disabled]="myForm.invalid"
                                (click)="saveProductMapping(dataItem)"
                              >
                                {{ isNew ? "Add" : "Update" }}
                              </button>
                              <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                                {{ isNew ? "Discard changes" : "Cancel" }}
                              </button>
                              <button kendoButton type="button" *ngIf="!editMode" (click)="deleteProductMappingDialogOpen(dataItem)">Remove</button>
                              <kendo-dialog
                              title="Please confirm"
                              *ngIf="coreProductMappingDialogOpened"
                              (close)="close()"
                              [minWidth]="250"
                              [width]="450"
                            >
                              <p style="margin: 30px; text-align: center;">
                                Are you sure you want to delete product "<b>{{this.coreProductMappingToBeDeleted.name}}</b>" with version "<b>{{this.coreProductMappingToBeDeleted.version}}</b>"?
                              </p>
                              <kendo-dialog-actions>
                                <button kendoButton (click)="close()">No</button>
                                <button kendoButton (click)="deleteCoreProductFromTable(this.coreProductMappingToBeDeleted)" primary="true">
                                  Yes
                                </button>
                              </kendo-dialog-actions>
                            </kendo-dialog>
                            </ng-template>
                          </kendo-grid-command-column>
                          </kendo-grid>
                        </form>
                      </div>
                      <div class ="row" *ngIf="!hideAddOnProdGrid"
                      kendoTooltip
                      showOn="none"
                      [tooltipTemplate]="template"
                      filter=".k-grid td"
                      (mouseover)="showTooltip($event)"
                      >
                        <form novalidate #myForm="ngForm">
                          <kendo-grid [kendoGridTemplateEditing]="createAddOnProductEdit" [kendoGridBinding]="gridAddOnProductData" (cellClick)="cellClickHandler($event)" [filterable]="true">
                            <kendo-grid-column field="name" title="Product Name">
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.name"
                                  kendoGridFocusable
                                  name="Product Name"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="version" title="Version">
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.version"
                                  kendoGridFocusable
                                  name="Version"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>

                          <kendo-grid-command-column title="command" [width]="220">
                            <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                              <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                                Edit
                              </button>
                              <button
                                kendoGridSaveCommand
                                type="button"
                                [disabled]="myForm.invalid"
                                (click)="saveAddOnProductMapping(dataItem)"
                              >
                                {{ isNew ? "Add" : "Update" }}
                              </button>
                              <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                                {{ isNew ? "Discard changes" : "Cancel" }}
                              </button>
                              <button kendoButton type="button" *ngIf="!editMode" (click)="deleteAddOnProductMappingDialogOpen(dataItem)">Remove</button>
                              <kendo-dialog
                              title="Please confirm"
                              *ngIf="addOnProductMappingDialogOpened"
                              (close)="close()"
                              [minWidth]="250"
                              [width]="450"
                            >
                              <p style="margin: 30px; text-align: center;">
                                Are you sure you want to delete product "<b>{{this.addOnProductMappingToBeDeleted.name}}</b>" with version "<b>{{this.addOnProductMappingToBeDeleted.version}}</b>"?
                              </p>
                              <kendo-dialog-actions>
                                <button kendoButton (click)="close()">No</button>
                                <button kendoButton (click)="deleteAddOnProductFromTable(this.addOnProductMappingToBeDeleted)" primary="true">
                                  Yes
                                </button>
                              </kendo-dialog-actions>
                            </kendo-dialog>
                            </ng-template>
                          </kendo-grid-command-column>
                          </kendo-grid>
                        </form>
                      </div>
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

</kendo-tilelayout>
