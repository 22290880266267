<div class="px-4">
    <div class="gridCustom">
        <kendo-grid
        [data]="products"
        kendoGridSelectBy="id"
        [selectedKeys]="selectedProductIds"
        (selectedKeysChange)="productSelectionChange($event)"
        style="height: 350px;"
        kendoGridFocusable
      >
        <kendo-grid-checkbox-column
    
          [width]="10"
          [minResizableWidth]="10"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}"
        ></kendo-grid-checkbox-column>
        <kendo-grid-column
          field="name"
          title="Product Name"
          [width]="100"
          [minResizableWidth]="100"
        ></kendo-grid-column>
        <kendo-grid-column
          title="Versions"
          [width]="100"
          [minResizableWidth]="100"
          [filterable]="false"
        >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <kendo-combobox
          [(ngModel)]="dataItem.selectedVersion"
          [data]="dataItem.versionDetails"
          textField="version"
          valueField="id"
          (valueChange)="onValueChange(dataItem)"></kendo-combobox>
          </ng-template>
        </kendo-grid-column>
        <ng-template
        kendoGridDetailTemplate
        let-dataItem
      >
      <section>
      <kendo-grid
      [data]="dataItem.addOnProducts"
      [pageSize]="10"
      [pageable]="true"
      [filterable]="false"
      kendoGridFocusable
      [style.height.%]="200"
    >
    
      <kendo-grid-column field="name" title="AddOn Product" width="80">
      </kendo-grid-column>
      <kendo-grid-column field="version" title="Version" width="80">
      </kendo-grid-column>
    </kendo-grid>
    </section>
      </ng-template>
      </kendo-grid>
    </div>
</div>
