import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { GroupUpdateComponent } from '../group-update/group-update.component';
import { AdGroupPolicy } from '../../../shared/models/AdGroupPolicy';
import { Group } from '@microsoft/microsoft-graph-types';
import { SelectablePolicy } from '../../../shared/models/SelectablePolicy';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { GroupPoliciesComponent } from './group-policies/group-policies.component';
import { switchMap } from 'rxjs/operators';
import { AdGroup } from '../../../shared/models/AdGroup';
import { Constants } from '../../../shared/Constants';
import { DialogAction } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-permission-manage',
  templateUrl: './permission-manage.component.html',
  styleUrls: ['./permission-manage.component.scss']
})
export class PermissionManageComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(GroupPoliciesComponent) GroupPoliciesComp: GroupPoliciesComponent;

  groups: any[] = [];
  selectedGroup: AdGroup;
  allPolicies: any[] = [];
  removeGroupDialogOpened = false;

  private sub: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadAllAdGroups();
  }

  onAddGroup() {
    const dialogRef = this.dialogService.open({
      content: GroupUpdateComponent
    });

    const instance = dialogRef.content.instance;

    this.sub = dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        if (this.groups.some(g => g.groupId === instance.selectedGroup.id)) {
          this.toastr.warning(`Group selected ${instance.selectedGroup.displayName} already have permission assigned!`, 'Warning!');
          return;
        }
        this.saveGroupPolicy(instance);
      }
    });
  }

  onRemoveGroup() {
    this.removeGroupDialogOpened = true
  }

  public close(){
    this.removeGroupDialogOpened = false;
  }

  confirmRemoveGroup(data){
    this.loaderService.start()
    this.sub = this.adGroupService.removeAdGroup(data).subscribe({
      next: () => {
        this.removeGroupDialogOpened = false;
        this.loadAllAdGroups();
        this.toastr.success(`Group ${data.displayName} was removed successfully!`, 'Success!');
        this.loaderService.stop();
      },
      error: (error) => {
        this.removeGroupDialogOpened = false;
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.removeGroupDialogOpened = false;
        this.loaderService.stop();
      }
    });
  }

  private loadAllAdGroups() {
    this.loaderService.start();
    this.sub = this.adGroupService.getAdGroups().subscribe({
      next: (result) => {
        this.groups = result;
        this.groups[0].selected = true
        this.selectionDefaultGroup();
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  onSelectionChanged(group: AdGroup) {
    this.selectedGroup = group;
  }

  selectionDefaultGroup() {
    if (this.groups.length <= 0) {
      return;
    }
    
    this.onSelectionChanged(this.groups[0])
  }

  private saveGroupPolicy(instance: any) {
    const policies: SelectablePolicy[] = instance.GroupPoliciesComp.allPolicies.filter(p => p.selected);
    const group: Group = instance.selectedGroup;

    this.loaderService.start();

    const newAdGroup = {
      groupId: group.id,
      displayName: group.displayName
    } as AdGroup;

    this.sub = this.adGroupService.addAdGroup(newAdGroup).pipe(
      switchMap((g) => this.addNewGroupPolicies(policies, g))
    ).subscribe({
      next: () => {
        this.loadAllAdGroups();
        this.toastr.success(`Group permission for ${group.displayName} was added successfully!`, 'Success!');
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  private addNewGroupPolicies(policies: SelectablePolicy[], group: AdGroup): Observable<AdGroupPolicy[]> {
    // this will guarantee called.
    if (policies.length <= 0) {
      return of([]);
    }

    const observables: Observable<AdGroupPolicy>[] =
      policies
        .map(p => {
            const gp: AdGroupPolicy = {
              adGroupId: group.id,
              policyId: p.id,
            } as AdGroupPolicy;

            return this.groupPolicyService.addGroupPolicy(gp);
          }
        );

    return forkJoin(observables);
  }

  private deleteGroupPolicies(policies: AdGroupPolicy[]): Observable<any[]> {
    if (policies.length <= 0) {
      return of([]);
    }

    const observables: Observable<any>[] =
      policies.map(p => this.groupPolicyService.deleteGroupPolicy({id: p.id} as AdGroupPolicy));

    return forkJoin(observables);
  }

  saveGroupPolicyChanges() {
    const selected = this.GroupPoliciesComp.allPolicies.filter(pl => pl.selected);
    const newPolicies: SelectablePolicy[] = selected
      .filter(p => !this.GroupPoliciesComp.policies.some(pl => pl.id === p.id));

    const removedPolicies: AdGroupPolicy[] = this.selectedGroup.adGroupPolicies
      .filter(p => !selected.some(pl => pl.id === p.policyId));

    const group = {
      id: this.selectedGroup.id,
      groupId: this.selectedGroup.groupId,
      displayName: this.selectedGroup.displayName
    } as AdGroup;

    this.loaderService.start();
    this.sub = this.addNewGroupPolicies(newPolicies, group)
      .pipe(
        switchMap(() => this.deleteGroupPolicies(removedPolicies))
      ).subscribe({
        next: () => {
          this.loadAllAdGroups();
          this.toastr.success(`Group permission for ${group.displayName} was updated successfully!`, 'Success!');
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public itemEmit(details){
    this.allPolicies = details;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
