<div class="w-75 px-4">
    <ng-container [formGroup]="form">
        <kendo-formfield>
            <kendo-label [for]="instance" text="Environment instance:"></kendo-label>
            <kendo-textbox formControlName="instance"
                   kendoTextBox
                   #instance
                   required>
            </kendo-textbox>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="sqlInstance" text="SQL instance:"></kendo-label>
            <kendo-textbox formControlName="sqlInstance"
                   kendoTextBox
                   #sqlInstance
                   required>
            </kendo-textbox>
        </kendo-formfield>
    </ng-container>
</div>
