import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AvatarModule, LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { HttpClientModule } from '@angular/common/http';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ToastrModule } from 'ngx-toastr';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { BrowserModule } from '@angular/platform-browser';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { MomentModule } from 'ngx-moment';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { IconsModule } from '@progress/kendo-angular-icons';
import { PatTokenComponent } from './authorize/pat-token/pat-token.component';

@NgModule({
  declarations: [
    PatTokenComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: 'toast-bottom-center',
        preventDuplicates: false,
      }
    ),
    AvatarModule,
    GridModule,
    // PDFModule,
    // ExcelModule,
    LabelModule,
    LayoutModule,
    IconsModule,
    // SchedulerModule,
    ButtonsModule,
    // EditorModule,
    FileSelectModule,
    HttpClientModule,
    // ChartsModule,
    // IntlModule,
    DateInputsModule,
    InputsModule,
    DropDownsModule,
    DropDownListModule,
    ListViewModule,
    TreeListModule,
    DialogModule,
    ProgressBarModule,
    NavigationModule,
    // RouterModule.forRoot(drawerRoutes),
    NotificationModule,
    TooltipModule,
    MomentModule,
    NgxUiLoaderModule
  ],
  exports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule,
    AvatarModule,
    GridModule,
    // PDFModule,
    // ExcelModule,
    LabelModule,
    LayoutModule,
    IconsModule,
    // SchedulerModule,
    ButtonsModule,
    // EditorModule,
    FileSelectModule,
    HttpClientModule,
    // ChartsModule,
    // IntlModule,
    DateInputsModule,
    InputsModule,
    DropDownsModule,
    DropDownListModule,
    ListViewModule,
    TreeListModule,
    DialogModule,
    ProgressBarModule,
    NavigationModule,
    NotificationModule,
    TooltipModule,
    MomentModule,
    NgxUiLoaderModule
  ],
  providers: [
    DatePipe,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // },
    {
      provide: JWT_OPTIONS, useValue: JWT_OPTIONS
    },
    JwtHelperService
  ]
})
export class SharedModule {
}
