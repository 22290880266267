<div class="w-75 px-4">
    <ng-container [formGroup]="instanceDetails">
        <kendo-formfield orientation="horizontal">
            <kendo-label [for]="envInstance" text="Environment: "></kendo-label>
            <!-- <kendo-dropdownlist
            defaultItem="Select instance..."
            #envInstance
            formControlName="envInstance"
            [data]="envInstanceTypes"
            [valuePrimitive]="true"
            (valueChange)="onEnvValueChanged($event)"
            required
          >
          </kendo-dropdownlist> -->
          <div kendoTooltip>
            <kendo-combobox
            placeholder="Select instance..."
            #envInstance
            [data]="envInstanceTypes"
            formControlName="envInstance"
            (valueChange)="onEnvValueChanged($event)"
            [allowCustom]="true"
            required
            title="Example: idevcustuest01"
          >
          </kendo-combobox>
          </div>

          </kendo-formfield>
    
          <kendo-formfield orientation="horizontal">
            <kendo-label [for]="sqlInstance" text="Sql Instance: "></kendo-label>
            <!-- <kendo-dropdownlist
            defaultItem="Select instance..."
            #sqlInstance
            formControlName="sqlInstance"
            [data]="sqlInstanceTypes"
            [valuePrimitive]="true"
            required
          >
          </kendo-dropdownlist> -->
          <div kendoTooltip>
            <kendo-combobox
            placeholder="Select instance..."
            #sqlInstance
            [data]="sqlInstanceTypes"
            formControlName="sqlInstance"
            [allowCustom]="true"
            required
            title="Example: idevcustuest01-sqlsrv-01"
          >
          </kendo-combobox>
          </div>

          </kendo-formfield>
    </ng-container>
</div>
