import { Component, Input, Optional } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatabaseInfoMulti } from '../../../../../shared/models/DatabaseInfoMulti';

@Component({
  selector: 'app-db-refresh-info-multi',
  templateUrl: './db-refresh-info-multi.component.html',
  styleUrls: ['./db-refresh-info-multi.component.scss']
})
export class DbRefreshInfoMultiComponent {

  @Input() direction: string;
  @Input() dbInfo: DatabaseInfoMulti;
  @Input() confirmControlName: string;

  get form(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  get control(): UntypedFormControl {
    return this.form.get(this.confirmControlName) as UntypedFormControl;
  }

  constructor(@Optional() private controlContainer: ControlContainer) { }
}
