import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { catchError, map } from 'rxjs/operators';
import * as YAML from 'yamljs';
import { RepoItems } from '../../deployments/models/RepoItem';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private errorHandling: ErrorHandlerService) {
  }

  getAllSharedYamls(project: string): Observable<RepoItems> {
    return this.httpClient
      .get<RepoItems>(`${window.location.origin}/${Constants.AZURE_DOMAIN}/${this.configService.Organization}/${project}/_apis/git/repositories/${Constants.REPO_CLOUD_SERVICES}/items?scopePath=${Constants.REPO_SHARED_YML_PATH}&recursionLevel=full&versionDescriptor.version=${Constants.REPO_SHARED_YML_BRANCH}&api-version=${Constants.API_VERSION}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllSharedYamls'))
      );
  }

  getPipelineYamlByPath(project: string, path: string): Observable<any> {
    const headers = new HttpHeaders();
    return this.httpClient.get<any>(`${window.location.origin}/${Constants.AZURE_DOMAIN}/${this.configService.Organization}/${project}/_apis/git/repositories/${Constants.REPO_CLOUD_SERVICES}/items?scopePath=${path}&versionDescriptor.version=${Constants.REPO_SHARED_YML_BRANCH}&api-version=${Constants.API_VERSION}`,
      {
        headers,
        observe: 'body',
        responseType: 'text' as 'json'
      })
      .pipe(
        // Map Yaml to JavaScript Object
        map(yamlString => YAML.parse(yamlString)),
        catchError(this.errorHandling.handleAllErrors<any>('getPipelineYamlByPath'))
      );
  }
}
