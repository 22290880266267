import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { MainModule } from './main/main.module';
import { AppRoutingModule } from './app-routing.module';
import { DeploymentModule } from './deployments/deployment.module';
import { AppInjector } from './shared/utils/AppInjector';
import { IPublicClientApplication } from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { AuthenticationService } from './services/msal/authentication.service';
import { AppConfigService } from './services/app-config.service';
import { AdminModule } from './admin/admin.module';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from './shared/token.interceptor';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { BrowserModule } from '@angular/platform-browser';
import { DynamicsModule } from './dynamics/dynamics.module';
import { ErrorHandlerService } from './services/error-handler.service';
import { AppInsightsService } from './services/app-insights.service';
import { DevTestsLabModule } from './dev-tests-lab/dev-tests-lab.module';
import { ErrorCatchingInterceptor } from './interceptors/error-catching.interceptor';

export function MSALInstanceFactory(authenticationService: AuthenticationService): IPublicClientApplication {
  return authenticationService.getIPublicClientApplication();
}

export function MSALInterceptorConfigFactory(authenticationService: AuthenticationService): MsalInterceptorConfiguration {
  return authenticationService.getMsalInterceptorConfiguration();
}

export function MSALGuardConfigFactory(authenticationService: AuthenticationService): MsalGuardConfiguration {
  return authenticationService.getMsalGuardConfiguration();
}

export function initializeApp(appConfigService: AppConfigService, appInsightsService: AppInsightsService) {
  const promise = appConfigService
    .loadAppConfig()
    .then((config) => {
      appInsightsService.initialize(config.AppInsights);
    });

  return () => promise;
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent
  ],
  imports: [
    // BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    DeploymentModule,
    DynamicsModule,
    DevTestsLabModule,
    AdminModule,
    MainModule,
    MsalModule,
    IconsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    TreeViewModule,
    BrowserModule
  ],
  providers: [
    AppConfigService,
    AppInsightsService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService, AppInsightsService],
      useFactory: initializeApp
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AuthenticationService, AppConfigService, HttpClient]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AuthenticationService, AppConfigService, HttpClient]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AuthenticationService, AppConfigService, HttpClient]
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
