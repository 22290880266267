<div class="px-5">
    <div class="row pt-5">
        <div class="col-6">
            <b>SUMMARY</b>
        </div>
    </div>
    <div class="row pt-2">
        <hr>
    </div>
    <div class="row pt-2">
        <div class="col-6">
        <b>Source: </b>{{this.dbRefresh.source}}
    </div>

    <div class="col-6">
        <b>Target: </b>{{this.dbRefresh.target}}
    </div>
    </div>
    
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Source SQL Server: </b>{{this.dbRefresh.SqlServer}}
        </div>
        <div class="col-6">
            <b>Target SQL Server: </b>{{this.dbRefresh.TargetSqlServer}}
        </div>
    </div>
    
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Source DB Name: </b>{{this.dbRefresh.DbName}}
        </div>

        <div class="col-6">
            <b>Target DB Name: </b>{{this.dbRefresh.TargetDbName}}
        </div>
    </div>
    <div class="row pt-2">

    </div>
    <div class="row pt-2">
        <hr>
    </div>
    <div class="row pt-2">
        <div class="col-4">
            <b>Scrub Mail: </b>{{this.dbRefresh.scrubMail ?? false}}
        </div>

        <div class="col-4">
            <b>Scrub Credit: </b>{{this.dbRefresh.scrubCredit ?? false}}
        </div>

        <div class="col-4">
            <b>Purge Mail: </b>{{this.dbRefresh.purgeMail ?? false}}
        </div>
    </div>
      
</div>
