import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Constants } from 'src/app/shared/Constants';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(private _msalService: MsalService,private configService: AppConfigService,private authService:AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if (this.configService.Organization === Constants.TEST_ORG && !(this.authService.loggedIn)) {
        return true;
      }
    
      const userRoles = (this._msalService.instance.getActiveAccount()?.idTokenClaims as any)?.roles;

    if (!userRoles) {
      return false;
    }

    const allowedRoles = route.data['roles'];
    const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));
    return matchingRoles.length > 0;
  }
}
