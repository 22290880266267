import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { Batch } from '../../../shared/models/Batch';
import { process, State } from '@progress/kendo-data-query';
import { Constants } from '../../../shared/Constants';
import { BatchFilterModel } from '../../../shared/models/BatchFilterModel';
import { DataStateChangeEvent, GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss']
})
export class BatchesComponent extends BaseComponent implements OnInit {
  batches: Batch[];
  batchesView: GridDataResult;
  releaseEnvs = {};

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadBatches();
  }

  private loadBatches() {
    this.loaderService.start();
    const filter = {'type': 'Release'} as BatchFilterModel;
    this.batchService.getBatches(filter).subscribe({
      next: (result) => {
        this.batches = result;
        this.batchesView = process(this.batches, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.batchesView = process(this.batches, this.state);
  }

  releaseSelectionChanged($event: SelectionEvent) {
    const batch = $event.selectedRows[0].dataItem;

    this.dataService.updateBatch(batch);
    this.router.navigate([`${Constants.BATCHES}/${Constants.BATCH_DETAIL}/${batch.id}`])
      .then(() => {
          console.log('success');
        }
      );
  }
}
