<kendo-card width="auto">
  <h5>Infrastructure</h5>
  <div class="row">
    <div class="col-4">Subscription:</div>
    <div class="col-8">{{infrastructure?.Subscription.Name}}</div>
  </div>
<!--  <div class="row">-->
<!--    <div class="col-4">Tier:</div>-->
<!--    <div class="col-8">{{infrastructure?.Tier.Name}}</div>-->
<!--  </div>-->
  <div class="row">
    <div class="col-4">Organization:</div>
    <div class="col-8">{{infrastructure?.Organization.Name}}</div>
  </div>
  <div class="row">
    <div class="col-4">Application Name:</div>
    <div class="col-8">{{infrastructure?.ApplicationName}}</div>
  </div>
  <div class="row">
    <div class="col-4">Environment Number:</div>
    <div class="col-8">{{infrastructure?.EnvironmentNumber}}</div>
  </div>
  <div class="row">
    <div class="col-4">Sub Net:</div>
    <div class="col-8">{{infrastructure?.SubNetName}}</div>
  </div>
  <div class="row">
    <div class="col-4">Student Database:</div>
    <div class="col-8">{{infrastructure?.StudentDatabase.name}}</div>
  </div>
  <div class="row">
    <div class="col-4">Student Portal Database:</div>
    <div class="col-8">{{infrastructure?.StudentPortalDatabase.name}}</div>
  </div>
</kendo-card>

<kendo-card width="auto">
  <h5>Product</h5>
  <div class="row" *ngFor="let product of productParams">
    <div class="col-4">{{product.Name}}:</div>
    <div class="col-8">{{product.Value}}</div>
  </div>
</kendo-card>
