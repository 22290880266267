import { Component, Input, Optional } from '@angular/core';
import { DatabaseInfo } from '../../../../../shared/models/DatabaseInfo';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-db-fresh-info',
  templateUrl: './db-fresh-info.component.html',
  styleUrls: ['./db-fresh-info.component.scss']
})
export class DbFreshInfoComponent {

  @Input() direction: string;
  @Input() dbInfo: DatabaseInfo;
  @Input() confirmControlName: string;

  get form(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  get control(): UntypedFormControl {
    return this.form.get(this.confirmControlName) as UntypedFormControl;
  }

  constructor(@Optional() private controlContainer: ControlContainer) { }
}
