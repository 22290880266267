<button
  kendoButton
  (click)="onGoBack()"
  themeColor="primary"
>
  Back
</button>
<kendo-textarea
  resizable="auto"
  [readonly]="true"
  [value]="log"
  style="width: 100%; height: 100%; overflow-y: auto;"
>
</kendo-textarea>
