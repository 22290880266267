import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Error || logLevel === LogLevel.Warning) {
    console.log(message);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private config: AppConfigService) {
  }

  getMsalGuardConfiguration(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [
          `${this.config.AzureAd.ClientId}/.default`
        ]
      },
    };
  }

  getMsalInterceptorConfiguration(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    // MS Graph
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    // protectedResourceMap.set(`/${Constants.GRAPH_DOMAIN}/`, [`${this.config.AzureAd.ClientId}/.default`]);

    // DevOpsPortal API
    protectedResourceMap.set(`/api/`, [`${this.config.AzureAd.ClientId}/.default`]);

    // Azure DevOps API
    // protectedResourceMap.set(`/${Constants.AZURE_DOMAIN}/`, [`${this.config.AzureAd.ClientId}/.default`]);
    // protectedResourceMap.set(`/${Constants.AZURE_FEED_DOMAIN}/`, [`${this.config.AzureAd.ClientId}/.default`]);
    // protectedResourceMap.set(`/${Constants.AZURE_RM_DOMAIN}/`, [`${this.config.AzureAd.ClientId}/.default`]);
    // protectedResourceMap.set(`/${Constants.AZURE_VSSPS_DOMAIN}/`, [`${this.config.AzureAd.ClientId}/.default`]);
    // protectedResourceMap.set(`/${Constants.AZURE_VSSPS_DEV_DOMAIN}/`, [`${this.config.AzureAd.ClientId}/.default`]);

    // // Admin Tool API
    // protectedResourceMap.set(`/${Constants.ADMIN_TOOL_NON_PROD}/`, [`${this.config.AzureAd.ClientId}/.default`]);
    // protectedResourceMap.set(`/${Constants.ADMIN_TOOL_PROD}/`, [`${this.config.AzureAd.ClientId}/.default`]);

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }

  getIPublicClientApplication(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: this.config.AzureAd.ClientId,
        authority: `https://login.microsoftonline.com/${this.config.AzureAd.TenantName}/`,
        redirectUri: '/',
        postLogoutRedirectUri: '/'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Error,
          piiLoggingEnabled: false
        }
      }
    });
  }
}
