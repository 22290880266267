<div class="container-fluid g-0">
  <div class="row g-0">
    <kendo-grid
      class="col-12 g-0"
      [data]="environmentsView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="true"
      [filterable]="true"
      (dataStateChange)="dataStateChange($event)"
      kendoGridSelectBy="id"
      [selectable]="true"
      [style.height.%]="100"
    >
      <kendo-grid-column
        field="name"
        title="Name"
        [width]="50"
        [minResizableWidth]="50"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <a href="javascript:void(0)" (click)="goToEnvironment(dataItem)">{{dataItem.name}}</a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="subscription"
        title="Subscription"
        [width]="200"
        [minResizableWidth]="150"
      ></kendo-grid-column>
      <kendo-grid-column
        field="resourceGroup"
        title="Resource Group"
        [width]="150"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="appName"
        title="App Name"
        [width]="50"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="requester"
        title="Created By"
        [width]="80"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="dateCreated"
        title="Date"
        [width]="80"
        [minResizableWidth]="50"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.dateCreated | date: 'MM/dd/yyyy'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="provisioningState"
        title="Status"
        [width]="70"
        [minResizableWidth]="50"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="remark"
        title="Remark"
        [width]="150"
        [minResizableWidth]="50"
      >
      </kendo-grid-column>
    </kendo-grid>
    <div class="col-7 g-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
