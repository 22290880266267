<div>
<div class="log-wrapper">
  <kendo-window
        title="Log"
        *ngIf="logDetailsOpened"
        (close)="close()"
        [minWidth]="250"
        [width]="450"
      >
        <form class="k-form">
          <fieldset>
            <legend>Log Details:</legend>

            <label class="k-form-field">
              <span><b>Name: </b></span>
              <span>{{selectedAuditLog.name}}</span>
            </label>

            <label class="k-form-field">
              <span><b>Username: </b></span>
              <span>{{selectedAuditLog.username}}</span>
            </label>

            <label class="k-form-field">
              <span><b>Api: </b></span>
              <span>{{selectedAuditLog.api}}</span>
            </label>

            <label class="k-form-field">
              <span><b>Action: </b></span>
              <span>{{selectedAuditLog.action}}</span>
            </label>

            <label class="k-form-field">
              <span><b>Parameters: </b></span>
              <span>{{selectedAuditLog.parameters}}</span>
            </label>

            <label class="k-form-field">
              <span><b>Timestamp: </b></span>
              <span>{{selectedAuditLog.timeStamp}}</span>
            </label>
          </fieldset>
          <div class="k-actions k-actions-end">
            <button type="button" class="k-button" (click)="close()">
              Close
            </button>
          </div>
        </form>
      </kendo-window>
</div>

<kendo-grid
  [selectable]="true"
  [data]="gridData"
  [filter]="state.filter"
  [sort]="state.sort"
  [sortable]="true"
  [filterable]="true"
  [pageable]="true"
  [pageSize]="state.take"
  [skip]="state.skip"
  kendoGridSelectBy [selectedKeys]="mySelection" (selectionChange)="selectionChange($event)"
  (filterChange)="filterChange($event)"
  (sortChange)="sortChange($event)"
  (pageChange)="pageChange($event)"
>
  <kendo-grid-column
    field="name"
    title="Name"
    [width]="150"
    [minResizableWidth]="200"
  >
</kendo-grid-column>

  <kendo-grid-column
    field="username"
    title="Username"
    [width]="150"
    [minResizableWidth]="200"
  ></kendo-grid-column>

  <kendo-grid-column
    field="api"
    title="Api"
    [width]="150"
    [minResizableWidth]="200"
  ></kendo-grid-column>

  <kendo-grid-column
  field="action"
  title="Action"
  [width]="150"
  [minResizableWidth]="200"
></kendo-grid-column>

<kendo-grid-column
field="parameters"
title="Parameters"
[width]="150"
[minResizableWidth]="200"
></kendo-grid-column>

<kendo-grid-column
field="timeStamp"
title="TimeStamp"
format="{0:d}"
[width]="150"
[minResizableWidth]="200"
></kendo-grid-column>

</kendo-grid>

</div>
<div kendoDialogContainer></div>
