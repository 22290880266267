import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-environment-summary',
  templateUrl: './new-environment-summary.component.html',
  styleUrls: ['./new-environment-summary.component.scss']
})
export class NewEnvironmentSummaryComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  @Input() public form: UntypedFormGroup;

  @Input() public selectedProducts: any;

  @Input() public envCreateRequest: any;

  @Output() envCreateRequestOut = new EventEmitter<any>();

  ngOnInit(): void {
    this.form.markAllAsTouched();
    this.envCreateRequest.environment = this.form.get('environment') != null ? this.form.get('environment').value : '';
    this.envCreateRequest.environmentType = this.form.get('environmentType') != null ? this.form.get('environmentType').value : '';
    this.envCreateRequest.region = this.form.get('region') != null ? this.form.get('region').value : '';
    this.envCreateRequest.instance = this.form.get('instance') != null ? this.form.get('instance').value : '';
    this.envCreateRequest.sqlInstance = this.form.get('sqlInstance') != null ? this.form.get('sqlInstance').value : '';
    this.envCreateRequest.sqlApplicationID = this.form.get('sqlApplicationID') != null ? this.form.get('sqlApplicationID').value : '';
    this.envCreateRequest.spnObjectID = this.form.get('spnObjectID') != null ? this.form.get('spnObjectID').value : '';
    this.envCreateRequest.subscriptionID = this.form.get('subscriptionID') != null ? this.form.get('subscriptionID').value : '';
    this.envCreateRequest.azureSubscriptionID = this.form.get('azureSubscriptionID') != null ? this.form.get('azureSubscriptionID').value : '';
    this.envCreateRequest.customerID = this.form.get('customerID') != null ? this.form.get('customerID').value : '';
    this.envCreateRequest.products = this.selectedProducts;
    this.envCreateRequestOut.emit(this.envCreateRequest);
  }
}
