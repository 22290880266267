export class Constants {
  // theme
  public static THEME_PRIMARY = 'primary';

  // timestamp format
  public static TIME_STAMP_FORMAT = 'YYYYMMDDHHmmss';

  // Token
  public static TOKEN_AZURE = 'tokenAzure';
  public static TOKEN_ADMIN_TOOL = 'tokenAdminTool';

  // Resource
  public static GRAPH = 'graph';
  public static AZURE_DOMAIN = 'dev.azure.com';
  public static AZURE_RM_DOMAIN = 'vsrm.dev.azure.com';
  public static AZURE_FEED_DOMAIN = 'feeds.dev.azure.com';
  public static AZURE_VSSPS_DOMAIN = 'app.vssps.visualstudio.com';
  public static AZURE_VSSPS_DEV_DOMAIN = 'vssps.dev.azure.com';
  public static ADMIN_TOOL_NON_PROD = 'AdminToolNonProd';
  public static ADMIN_TOOL_PROD = 'AdminToolProd';
  public static DYNAMICS = 'dynamics';
  public static STORAGE_ACCOUNT = 'storageaccount';
  public static API_GRAPH_VERSION = 'v1.0';
  public static API_VERSION = '6.0';
  public static API_VERSION_PREVIEW = `${Constants.API_VERSION}-preview`;
  public static CLOUD_SERVICE_PROJECT = 'CloudServices';
  public static STUDENT_PROJECT = 'Student';
  public static SYNC_PIPELINES_ORG = 'AnthologyInc-01';
  public static CNC1_PIPELINE_ID = '2020';
  public static TEST_ORG = 'AnthologyInc-tst';

  // Git Repo
  // _git/CloudServices repo
  public static REPO_CLOUD_SERVICES = '81832647-54e6-4adb-9e72-7aaabf4f600f';
  public static REPO_SHARED_YML_PATH = 'MultiTenant\\SharedResources';
  public static REPO_SHARED_YML_BRANCH = 'pmd/mtdevelopment';

  // Field Names
  public static CUSTOMER_ID = 'CustomerID';
  public static CUSTOMER_ID_ = 'Customer_ID';
  public static AZURE_SUBSCRIPTION = 'AzureSubscription';
  public static AZURE_SUBSCRIPTION_MESH = 'AzureSubscriptionMesh';
  public static CUSTOMER_NAME = 'CustomerName';
  public static CUSTOMER_NAME_ = 'Customer_Name';

  // Routing paths
  public static DEPLOYMENTS = 'Deployments';
  public static DEPLOYMENTS_UPGRADES = 'Deployments (Upgrades)';
  public static MT_UPGRADE = 'MT Upgrades';
  public static PRODUCT_UPGRADE = 'Student Upgrades';
  public static CNF_UPGRADE = 'Integration Upgrades';
  public static NEW_ENVIRONMENTS = 'New Environments';
  public static DB_REFRESH = 'Data Refresh';
  public static DB_RESTORE = 'Data Restore';
  public static DB_REFRESH_LOGS = 'DB Refresh Logs';
  public static DB_RESTORE_LOGS = 'DB Restore Logs';
  public static DbRefreshRerun = "DbRefreshRerun";
  public static DBRefreshLogs = "DB Refresh Logs";
  public static MULTI_RELEASES = 'MultiReleases';
  public static SINGLE_TENANT_MR = '1.x Releases';
  public static MULTI_TENANT_MR = '2.x Releases';
  public static FHCM_ENVIRONMENTS = 'FHCM Environments';
  public static DEV_TESTS_LAB = 'Dev Tests Lab';
  public static DEV_TESTS_LAB_LOGS = 'Dev Tests Lab Logs';
  public static DEV_TESTS_LAB_ENVS = 'Dev Tests Lab Envs';
  public static DEV_TESTS_LAB_LOGS_DEPLOYMENT = 'Dev Tests Lab Deployments';
  public static DEV_ENVIRONMENTS = 'Dev Environments';
  public static Logs = 'Logs';
  public static MULTITENANT = 'MultiTenant';
  public static NEW_ENVIRONMENT = 'New Environment';
  public static DATA_MANAGEMENT = 'Data Management';
  public static DB_SCRIPTS = 'DB Scripts';

  public static BATCHES = 'Batches';
  public static BATCHES_LOGS = 'Batches Logs';
  public static BATCH_DETAIL = 'Batch-Detail';
  public static RELEASE_DETAIL = 'Release-Detail';
  public static STAGE_DETAIL = 'Stage-Detail';
  public static TASK_DETAIL = 'Task-Detail';

  public static PIPELINES = 'Pipelines';
  public static PIPELINES_LOG = 'Pipelines Logs';
  public static PIPELINE_BATCH = 'Pipeline-Batch';
  public static PIPELINE_DETAIL = 'Pipeline-Detail';
  public static App_Upgrade_DetailLog = 'App-Upgrade-DetailLog';

  public static MT_LOGS = '2.x Logs';

  public static PAT_TOKEN = 'pat-token';

  public static ADMIN = 'Admin';
  public static PERMISSION = 'Permission';
  public static POLICY = 'Policy';

  public static ADMIN_TOOL_ENV_NON_PROD = 'Non-Prod';
  public static ADMIN_TOOL_ENV_PROD = 'Prod';
  public static NEW_ENVIRONMENT_PATH = 'NewEnvironment';

  public static PRODUCT_VERSION_MAPPING_PATH = 'ProductVersionMapping';
  public static PRODUCT_VERSION_MAPPING_NAME = 'Product/Version Mapper';
  public static PIPELINE_NAME_MAPPING_NAME = 'Pipeline/Names Mapper';
  public static POLICY_MAPPING_PATH = 'PolicyMapping';
  public static POLICY_MAPPING_NAME = 'Policy/Api Mapper';
  public static UPDATE_TEMPLATE_FILE_PATH = 'UpdateTemplateFile';
  public static UPDATE_TEMPLATE_FILE_NAME = 'Update Template File';
  public static UPDATE_TEMPLATE_LOG_FILE_PATH = 'TemplateFileLogs';
  public static UPDATE_TEMPLATE_LOG_FILE_NAME = 'Template Logs';
  public static TEMPLATE_FILE_NAME = 'Template File';
  public static CNC_CUSTOMER_DETAILS_PATH = '1XCustomerDetails';
  public static MAILBOX_CUSTOMER_PATH = 'MTCustomerDetails';
  public static MT_FEATURE_PATH = 'EnableFeature';
  public static MT_FEATURE_NAME = 'Enable Feature';

  // Platforms
  public static PLATFORM_SINGLE_TENANT = 'Single Tenant';
  public static PLATFORM_MULTIPLE_TENANT = 'Multi-Tenant';

  public static FhcmEnvironments = 'FhcmEnvironments';
  public static FhcmCustomers = 'FhcmCustomers';
  public static Environments_List = 'EnvironmentsList';
  public static Environments_Text = 'Environments';
  public static Environments_Details = 'Environment Details';
  public static SIS_Environments = 'SIS Environments';
  public static App_Registration_Script = 'Registration';
  public static Mailbox_App_Registration_Script = 'MailboxAppRegistration';
  public static Mailbox = 'Mailbox';
  public static MailboxCNC = 'Mailbox 1.X';
  public static MailboxManager = 'MailboxManager';
  public static MailboxManagerStatus = 'MailboxManagerStatus';
  public static MailboxManagerLabel = 'Mailbox Manager';
  public static MailboxManagerStatusLabel = 'Mailbox Status';
  public static Integration_App_Registration_Script = 'IntegrationAppRegistration';
  public static Integration = 'Integration';
  public static FinanceIntegrationStatus = 'FinanceIntegrationStatus';
  public static FinanceIntegrationStatusName = 'Integration Status';
  public static FinanceIntegration = 'Finance Integration';
  public static ProfessionalIntegration = 'PS Integration';
  public static ProfessionalIntegration_Status = 'PS Integration Status';
  public static APP_REGISTRATION = 'App Registration';
  public static INTEGRATION_APP_REGISTRATION = 'Integration App Reg';
  public static STUDENT_APP_REGISTRATION = 'Student';
  public static MAILBOX_APP_REGISTRATION = 'Mailbox App Reg';
  public static Environment_Details = 'environmentDetails';
  public static Tools = 'Tools';
  public static AuditLog = 'Audit Log';
  public static AuditLogPath = 'AuditLog';
  public static EnvironmentTableRefreshPath = 'EnvironmentTableRefresh';
  public static DBTableRefresh = 'Table Refresh';
  public static Inventory = 'Inventory';

  public static PatToken = 'PatToken';

  public static MAPPER = 'Mapper';
  public static AppRegistrationTable = 'AppRegTable';
  public static CncCustomerDetails = '1XCustomerInventory';
  public static MailboxCustomerDetails = 'MTCustomerInventory';

  // Variable Keys in Variable Group
  public static Customer_Id = 'CustomerID';
  public static Mesh_Id = 'MeshID';
  public static Offering = 'Offering';
  public static TimeZoneAbbreviated = 'TimeZoneAbbreviated';
  public static Instance = 'Instance';

//Environment details
  public static PLATFORM_SINGLE_TENANT_CNC1 = '1.x';
  public static PLATFORM_SINGLE_TENANT_CNC2 = '2.x';
  public static PLATFORM_MULTI_TENANT = '2.x_MT';
  public static PLATFORM_ON_PREM = 'PREM';
  public static PLATFORM_SINGLE_TENANT_CNC1_LABEL = 'CNC 1.x';
  public static PLATFORM_SINGLE_TENANT_CNC2_LABEL = 'CNC 2.x';
  public static PLATFORM_MULTI_TENANT_LABEL = 'Multitenant';
  public static PLATFORM_ON_PREM_LABEL = 'On Prem';
  public static OBSOLETE = 'obsolete';
  public static NON_PROD_CODE = '9';

  // Customer Onboarding
  public static CustomerOnboarding = 'CustomerOnboarding';
  public static CustomerOnboardingText = 'New Tenant';
  public static Onboarding = 'Onboarding';
  public static AppUserDetails = 'CustomerOnboarding/AppUserDetails';
  public static AadDetails = 'CustomerOnboarding/AadDetails';
  public static CustomerOnboardingStatus = 'CustomerOnboardingStatus';
  public static MultiTenantEnvironmentStatus = 'MultiTenantEnvironmentStatus';
  public static CustomerOnboardingStatusText = 'Tenant Status';
  public static EnvironmentStatusText = 'Environment Status';

  //DBRefresh
  public static MTDbRefresh = 'MTDbRefreshLog';
  public static MTDbRefreshTasks = 'MTDbRefreshLogTasks';

  // Region
  public static REGION_US_EAST = 'ueast';
  public static REGION_US_WEST = 'uwest';
  public static REGION_CA_CENTRAL = 'ccentral';

  public static REGION_US_EST = 'uest';
  public static REGION_US_WST = 'uwst';
  public static REGION_CA_CANC = 'canc';

  public static PROD_CUST_CODE = '1';
  public static NON_PROD_CUST_CODE = '3';

  // New devtestslab steps
  public static STEP_INFRASTRUCTURE = 0;
  public static STEP_PRODUCT_DEPLOYMENT = 1;
  public static STEP_SUMMARY = 2;

  // Batch ID
  public static DB_REFRESH_SINGLE_TENANT = -1;
  public static PS_INTEGRATION_BATCH_ID = -4;
  public static DB_REFRESH_MULTIPLE_TENANT = -3;
  public static DB_RESTORE_FROM_FILE = -2;
  public static DB_RESTORE_FROM_DB = -2;

  // Authentications
  public static SQL_AUTHENTICATION = 'SQL Authentication';
  public static ACTIVE_DIRECTORY = 'Active Directory';
  public static AZURE_ACTIVE_DIRECTORY = 'Azure Active Directory';

  //Error messsage
  public static MFA_ERROR = "Could not validate because MFA has been enabled for these credentials!";

  public static TIMEOUT_STATUSCODE = "504";

// Product Mapping
  public static PRODUCT_MAPPING = {
    '1098TShortVersion': '1098T',
    'CnfShortVersion': 'CNF',
    // 'CustomerID': {
    //   'value': '6005651'
    // },
    'FAAShortVersion': 'FAA',
    'FormsBuilderVersion': 'FormsBuilder',
    'RegShortVersion': 'Regulatory',
    'ShortVersion': 'Anthology Student',
    'StaffStsVersion': 'name', // TBD
    'WCSCShortVersion': 'name', // TBD
    'WlmWebShortVersion': 'name', // TBD
    'WorkflowShortVersion': 'name', // TBD
  };

  //Product Version
  public static GA = "GA";
  public static Internal = "Internal";
  public static NonProd = "Non-Prod";
  public static Prod = "Prod";
}
