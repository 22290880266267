<kendo-tilelayout
  [columns]="5"
  [rowHeight]="22"
  [resizable]="false"
  [reorderable]="false"
  [gap]="0"
  id="app-upgrade"
>
  <kendo-tilelayout-item [row]="1" [col]="1" [colSpan]="3" [rowSpan]="18">
    <kendo-tilelayout-item-body>
      <kendo-grid
        [data]="environmentsView"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [filter]="state.filter"
        [sortable]="true"
        [pageable]="true"
        [filterable]="true"
        (dataStateChange)="dataStateChange($event)"
        kendoGridSelectBy="Id"
        [(selectedKeys)]="selectedEnvironments"
        [selectable]="selectableSettings"
        (selectionChange)="selectedRowChangeEnvironment($event)"
        (cellClick)="cellClick($event)"
        (detailExpand)="onExpand($event)"
        [kendoGridSelectBy]="environmentKey"
        [style.height.%]="100"
        kendoGridFocusable
        [rowClass]="isDisabled"
      >
        <kendo-grid-checkbox-column

          [width]="20"
          [minResizableWidth]="20"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}"
        >
      </kendo-grid-checkbox-column>
        <kendo-grid-column
          field="Code"
          title="Code"
          [width]="60"
          [minResizableWidth]="60"
        >
    </kendo-grid-column>
        <kendo-grid-column
          field="Name"
          title="Environment Name"
          [width]="70"
          [minResizableWidth]="70"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AdminToolEnv"
          title="Type"
          [width]="60"
          [minResizableWidth]="60"
        >
        <ng-template kendoGridFilterCellTemplate let-filter>
        <app-dropdown-filter
        [filter]="filter"
        [data]="envTypes"
        textField="AdminToolEnv"
        valueField="AdminToolEnv"
      ></app-dropdown-filter>
    </ng-template>
      </kendo-grid-column>

        <ng-template
        kendoGridDetailTemplate
        let-dataItem
      >
      <section>
      <kendo-grid
      [data]="dataItem.Tenants"
      [pageSize]="10"
      [pageable]="true"
      [filterable]="false"
      kendoGridFocusable
      [selectable]="true"
      [rowClass]="rowCallback"
    >
    <kendo-grid-column
    title=""
    [width]="30"
    [minResizableWidth]="30"
    [headerStyle]="{'text-align': 'center'}"
    [style]="{'text-align': 'center'}"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
                                 <!-- [disabled]="dataItem?.AdminToolEnv === 'Prod'"-->
      <input type="checkbox"
             [(ngModel)]="dataItem.IsSelected"
             [ngModelOptions]="{standalone: true}"
             [disabled]="true"
      />
    </ng-template>
  </kendo-grid-column>
      <kendo-grid-column field="Code" title="Code" width="80">
      </kendo-grid-column>
      <kendo-grid-column field="Name" title="Tenant Name" width="80">
      </kendo-grid-column>
    </kendo-grid>
  </section>
      </ng-template>
      </kendo-grid>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [row]="1" [col]="4" [colSpan]="2" [rowSpan]="18">
    <kendo-tilelayout-item-body>
      <kendo-grid
        [data]="selectedTenants"
        kendoGridSelectBy="Id"
        [selectable]="true"
        [style.height.%]="100"
        [rowClass]="rowCallback"
      >
        <kendo-grid-column
          title=""
          [width]="10"
          [minResizableWidth]="10"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}"
        >
                           <!-- [disabled]="dataItem?.AdminToolEnv === 'Prod'"-->
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox"
                   [(ngModel)]="dataItem.IsSelected"
                   [ngModelOptions]="{standalone: true}"
                   [disabled]="true"
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="Code"
          title="Code"
          [width]="30"
          [minResizableWidth]="30"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Name"
          title="Tenant Name"
          [width]="70"
          [minResizableWidth]="70"
        ></kendo-grid-column>
      </kendo-grid>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="1" [colSpan]="12" [rowSpan]="14">
    <kendo-tilelayout-item-body class="dynamic-content-height" id="products">
      <kendo-grid
        [data]="availableProducts"
        kendoGridSelectBy="id"
        [selectedKeys]="selectedProductIds"
        (selectedKeysChange)="productSelectionChange($event)"
        [style.height.%]="100"
        kendoGridFocusable
        [ngClass]="{'k-disabled': productVersionSectionDisabled}"
        [rowClass]="isProductDisabled"
      >
        <kendo-grid-checkbox-column

          [width]="10"
          [minResizableWidth]="10"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}"
        ></kendo-grid-checkbox-column>
        <kendo-grid-column
          field="name"
          title="Product Name"
          [width]="100"
          [minResizableWidth]="100"
        ></kendo-grid-column>
        <kendo-grid-column
          title="Versions"
          [width]="100"
          [minResizableWidth]="100"
          [filterable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <kendo-combobox
              [(ngModel)]="dataItem.selectedVersion"
              [data]="dataItem.versionDetails"
              textField="version"
              valueField="id"
              (valueChange)="onValueChange(dataItem)">
              <ng-template kendoComboBoxItemTemplate let-dataItem>
                {{ dataItem.version }} ({{ dataItem.name }})
              </ng-template>
            </kendo-combobox>
          </ng-template>
        </kendo-grid-column>
        <ng-template
        kendoGridDetailTemplate
        let-dataItem
      >
      <section>
      <kendo-grid
      [data]="dataItem.addOnProducts"
      [pageSize]="10"
      [pageable]="true"
      [filterable]="false"
      kendoGridFocusable
      [style.height.%]="200"
    >

      <kendo-grid-column field="name" title="AddOn Product" width="80">
      </kendo-grid-column>
      <kendo-grid-column field="version" title="Version" width="80">
      </kendo-grid-column>
    </kendo-grid>
  </section>
      </ng-template>
      </kendo-grid>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="1" [colSpan]="5" [rowSpan]="3">
    <kendo-tilelayout-item-body class="dynamic-content-height">
      <kendo-appbar positionMode="sticky" style="padding: 20px;">
        <kendo-appbar-section>
        </kendo-appbar-section>

        <kendo-appbar-spacer></kendo-appbar-spacer>

        <kendo-appbar-section>
          <button
            kendoButton
            (click)="onStartAction()"
            themeColor="primary"
            [disabled]="disableStartButton()"
          >
            Start Deployment
          </button>
        </kendo-appbar-section>
      </kendo-appbar>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>

