import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-update-mailbox-configured-env',
  templateUrl: './update-mailbox-configured-env.component.html',
  styleUrls: ['./update-mailbox-configured-env.component.scss']
})
export class UpdateMailboxConfiguredEnvComponent extends BaseComponent implements OnInit {
  private sub: Subscription;

  customerGridData: GridDataResult;
  customers: any;
  originalCustomers: any;
  nonInternalCustomers: any = [];
  private editedRowIndex: number;
  editMode = false;

  public state: State = {
    skip: 0,
    take: 10,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public customerToEdit: {
    customerCode: string,
    platform: string,
    environmentName: string,
    mailboxManagerConfigured: string,
    hasVanityUrl: string,
    isLive: string
  } = {
    customerCode: '',
    platform: '',
    environmentName: '',
    mailboxManagerConfigured: null,
    hasVanityUrl: null,
    isLive: null
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getAllDistinctCustomers()
      .subscribe({
        next: (result) => {
          result.forEach(element => {
            element.mailboxManagerConfigured = element.mailboxManagerConfigured ?? false;
            element.isLive = element.isLive ?? false;
            element.hasVanityUrl = element.hasVanityUrl ?? false;
          });
          this.originalCustomers = JSON.parse(JSON.stringify(result));
          ;
          this.originalCustomers.forEach(element => {
            if (element.customerCode.startsWith(Constants.PROD_CUST_CODE) || element.customerCode.startsWith(Constants.NON_PROD_CUST_CODE)) {
              this.nonInternalCustomers.push(element);
            }
          });
          this.customers = JSON.parse(JSON.stringify(this.nonInternalCustomers));
          this.customerGridData = process(this.customers, this.state);
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  edit(data, rowIndex) {
    this.editMode = true;
    this.editedRowIndex = rowIndex;
  }

  cancel(data) {
    this.editMode = false;
    this.editedRowIndex = undefined;
  }

  saveCustomerDetails(dataItem) {
    this.editMode = false;
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.updateAllEnvCustomers(dataItem)
      .subscribe({
        next: (result) => {
          result.forEach(element => {
            element.mailboxManagerConfigured = element.mailboxManagerConfigured ?? false;
            element.isLive = element.isLive ?? false;
            element.hasVanityUrl = element.hasVanityUrl ?? false;
          });
          this.customers = result;
          this.customerGridData = process(this.customers, this.state);
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  updateCustomerEdit(): any {
    return this.customerToEdit;
  }

  onSwitchChange($event) {
    this.loaderService.start();
    if ($event) {
      this.customers = JSON.parse(JSON.stringify(this.originalCustomers));
    } else {
      this.customers = JSON.parse(JSON.stringify(this.nonInternalCustomers));
    }
    this.state.skip = 0;
    this.customerGridData = process(this.customers, this.state);
    this.loaderService.stop();
  }
}
