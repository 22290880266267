import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-finance-integration-aad',
  templateUrl: './finance-integration-aad.component.html',
  styleUrls: ['./finance-integration-aad.component.scss']
})
export class FinanceIntegrationAADComponent extends BaseComponent implements OnInit {

  private sub: Subscription;
  appRegDetails: any;
  clientSecret : any = ""
  public clientSecretMasked=true;
  @Input() public customerEnvironmentDetails: UntypedFormGroup;
  public editAppreg = true;
  public showEditAppReg = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    const customer = this.customerEnvironmentDetails.get('customerId').value;
    this.showEditAppReg = !customer.startsWith('4');
    this.getAppRegistrationDetails(customer)
  }

  public getAppRegistrationDetails(customerId){
    this.loaderService.start();
    this.sub = this.appRegistrationService.getFinanceIntegrationAppRegDetails(customerId)
      .subscribe({
        next: (result) => {
          this.appRegDetails = result;
          this.updateDataToForm();
          this.shouldAppRegBeEditable();
          this.populateClientSecret();
          this.loaderService.stop();

        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public populateClientSecret(){
    this.clientSecret = this.appRegDetails.secretKey
    if(this.clientSecretMasked){
      this.clientSecret = ""
      for (var i=0; i<this.appRegDetails.secretKey.length; i++) {
        this.clientSecret+="*"
      }
    }
  }

  public togglePassword(){
    this.clientSecretMasked=!this.clientSecretMasked;
    this.populateClientSecret();
  }

  public editFinanceIntegrationAppReg(){
    this.editAppreg = true
  }

  public shouldAppRegBeEditable(){
    if(this.appRegDetails.appRegistrationId ||
      this.appRegDetails.appRegistrationIdUri ||
      this.appRegDetails.secretKey ||
      this.appRegDetails.tenantId){
        this.editAppreg = false
      }
      else{
        this.editAppreg = true
      }
  }

  public updateFinanceIntegrationAppReg(){
    this.updateFormToData();
    this.loaderService.start();
    this.sub = this.appRegistrationService.updateFinanceIntegrationAppRegDetails(this.appRegDetails)
      .subscribe({
        next: (result) => {
          this.appRegDetails = result;
          this.shouldAppRegBeEditable();
          this.populateClientSecret();
          this.loaderService.stop();

        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  updateDataToForm(){
    this.customerEnvironmentDetails.controls.tenantId.setValue(this.appRegDetails?.tenantId);
    this.customerEnvironmentDetails.controls.appRegistrationId.setValue(this.appRegDetails?.appRegistrationId);
    this.customerEnvironmentDetails.controls.appRegistrationIdUri.setValue(this.appRegDetails?.appRegistrationIdUri);
    this.customerEnvironmentDetails.controls.secretKey.setValue(this.appRegDetails?.secretKey);
  }

  updateFormToData(){
    if(!this.appRegDetails){
      this.appRegDetails ={}
    }

    this.appRegDetails.tenantId = this.customerEnvironmentDetails.get("tenantId").value;
    this.appRegDetails.appRegistrationId = this.customerEnvironmentDetails.get("appRegistrationId").value;
    this.appRegDetails.appRegistrationIdUri = this.customerEnvironmentDetails.get("appRegistrationIdUri").value;
    this.appRegDetails.secretKey = this.customerEnvironmentDetails.get("secretKey").value;

    let customer = this.customerEnvironmentDetails.get('customerId').value;

    let env = '';
    if (customer.includes("-")) {
      env = customer.split("-")[1];
      customer = customer.split("-")[0];
    }
    else{
      env = "Prod";
    }

    this.appRegDetails.customerId = customer;
    this.appRegDetails.environment = env;
  }
}
