import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService) {
  }

  getLog(url): Observable<any> {
    const options  = {
      headers: new HttpHeaders({
        'Accept': 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'text/plain; charset=utf-8'
      }),
      responseType: 'text'
    };
    // @ts-ignore
    return this.httpClient.get<any>(url, options)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getLog'))
      );
  }
}
