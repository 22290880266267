<div class="px-5">
    <div class="row pt-5">
        <div class="col-6">
            <b>SUMMARY</b>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col-6">
        <b>Customer Name: </b>{{this.customerOnboarding.customerName}}
    </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Offering Type: </b>{{this.customerOnboarding.offeringType}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Region: </b>{{this.customerOnboarding.region}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Customer Id: </b>{{this.customerOnboarding.customerId}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-12">
            <b>Environments: </b>{{this.customerOnboarding.environments}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Environment Instance: </b>{{this.customerOnboarding.environmentInstance}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Sql Instance: </b>{{this.customerOnboarding.sqlInstance}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Occupational insights graph API key: </b>{{this.customerOnboarding.graphAPIKey}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Occupational insights sync API key: </b>{{this.customerOnboarding.syncAPIKey}}
        </div>
    </div>     
</div>
