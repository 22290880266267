import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-database-refresh-sqldetails',
  templateUrl: './database-refresh-sqldetails.component.html',
  styleUrls: ['./database-refresh-sqldetails.component.scss']
})
export class DatabaseRefreshSQLDetailsComponent extends BaseComponent implements OnInit {

  @Input() public form: UntypedFormGroup;
  @Input() public envNameMappings: any;
  @Input() public source:any;
  @Input() public showError;
  @Input() public DbNames;
  @Output() sourceDetails = new EventEmitter<UntypedFormGroup>();
  @Output() sourceDbDetails = new EventEmitter<UntypedFormGroup>();

  public sub:Subscription;
  constructor() {
    super();
  }

  ngOnInit(): void {
    if(this.source != this.form.get("source").value.Code){
      this.loadDbDetails()
    }
  }

  private ExtractDetailsFromServer(val) {
    var server = val.server;

    if (!server) {
      return null;
    }

    const env = server?.substring(0, 4);
    const environmentType = this.envNameMappings.find(m => m.shortName === env)?.fullName;
    const region = this.getRegion(server);
    const instance = server?.split(',')[0]?.split('.')[0]?.split('-')[0].slice(-2);
    const subscription = val.subscription;

    return {
      'Server': server,
      'EnvironmentType': environmentType,
      'Region': region,
      'Instance': instance,
      'Subscription': subscription,
    };
  }

  private getRegion(server: string) {
    if (server?.includes(Constants.REGION_US_EST)) {
      return Constants.REGION_US_EAST;
    } else if (server?.includes(Constants.REGION_US_WST)) {
      return Constants.REGION_US_WEST;
    } else if (server?.includes(Constants.REGION_CA_CANC)) {
      return Constants.REGION_CA_CENTRAL;
    } else {
      return Constants.REGION_US_EAST;
    }
  }

  private parseServer(val) {
    const result = this.ExtractDetailsFromServer(val);

    if (!result) {
      return;
    }
    this.form.controls.SqlServer.setValue(result.Server)
    this.form.controls.EnvironmentType.setValue(result.EnvironmentType)
    this.form.controls.Region.setValue(result.Region)
    this.form.controls.Instance.setValue(result.Instance)
    this.form.controls.Subscription.setValue(result.Subscription)
    this.form.controls.TenantCode.setValue(this.form.get("source")?.value?.Code)
  }

  private loadDbDetails() {
    this.loaderService.start()
    //var server = this.form.get("SqlServer").value
    this.source = this.form.get("source").value.Code
    this.sourceDetails.emit(this.source);
    this.sub = this.customerEnvironmentService.getCustomerSqlServerDbs(this.source)
      .subscribe(
        {
          next: (result) => {
            this.parseServer(result)
            this.DbNames = result.databases
            this.sourceDbDetails.emit(this.DbNames)
            this.loaderService.stop();
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
          }
        }
      );
  }
}
