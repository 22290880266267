<form class="container" [formGroup]="formGroup">
  <div class="form-group row p-3">
    <div class="col-xs-8">
      <kendo-label [for]="yamlName" [text]="'Select a Pipeline'"></kendo-label>
      <kendo-dropdownlist
        #yamlName
        formControlName="yamlName"
        [data]="filteredYamls"
        [defaultItem]="{ text: 'Select a Pipeline ', value: null }"
        [filterable]="true"
        (filterChange)="handleYamlsFilter($event)"
        (selectionChange)="yamlSelectionChange($event)"
        [textField]="'path'"
        [valueField]="'objectId'"
        class="form-control"
        required
      >
      </kendo-dropdownlist>
    </div>
  </div>
  <div class="form-group row p-3">
    <div class="col-xs-8">
      <kendo-label [for]="envName" [text]="'Select an Environment Type'"></kendo-label>
      <kendo-dropdownlist
        #envName
        formControlName="envName"
        [data]="envNames"
        class="form-control"
        required
      >
      </kendo-dropdownlist>
    </div>
  </div>
  <div class="form-group row p-3">
    <div class="col-xs-8">
      <kendo-label [for]="region" [text]="'Select a Region'"></kendo-label>
      <kendo-dropdownlist
        #region
        formControlName="region"
        [data]="regions"
        class="form-control"
        required
      >
      </kendo-dropdownlist>
    </div>
  </div>
  <div class="form-group row p-3">
    <div class="col-xs-8">
      <kendo-label [for]="instance" [text]="'Select an Instance'"></kendo-label>
      <kendo-dropdownlist
        #instance
        formControlName="instance"
        [data]="instances"
        class="form-control"
        required
      >
      </kendo-dropdownlist>
    </div>
  </div>
  <kendo-appbar positionMode="sticky" style="padding: 20px;">
    <kendo-appbar-section>
    </kendo-appbar-section>

    <kendo-appbar-spacer></kendo-appbar-spacer>

    <kendo-appbar-section>
      <button
        kendoButton
        (click)="onStartAction()"
        themeColor="primary"
        [disabled]="!formGroup.valid"
      >
        Start Deployment
      </button>
    </kendo-appbar-section>
  </kendo-appbar>
</form>

