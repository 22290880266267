<div class="text-center">
  <h4>
    <small class="text-muted">Customer Provisioning</small>
  </h4>
</div>
<kendo-tilelayout [columns]="12">
    <kendo-tilelayout-item [col]="1" [colSpan]="3"  class="border">
      <kendo-tilelayout-item-body>
        <div class="px-2 pt-2">
          <kendo-stepper
          #stepper
          [style.width.px]="100"
          [steps]="steps"
          stepType="full"
          [(currentStep)]="currentStep"
          [linear]="false"
          orientation="vertical"
        >
        </kendo-stepper>
        </div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="4" [colSpan]="9" class="border">
      <kendo-tilelayout-item-body>
        <div class="px-2 pb-2">
          <form class="k-form" [formGroup]="form">
            <app-customer-details
              *ngIf="currentStep === 0"
              [customerDetails]="currentGroup"
            >
            </app-customer-details>

            <app-environment-customer-details
            *ngIf="currentStep === 1"
            [customerEnvironmentDetails]="currentGroup"
            (newCustomerEnvironmentDetails)="updateCustomerEnvironmentDetails($event)"
            [checkboxes]="checkboxes"
            (newCheckboxes)="newCheckboxes($event)"
            [validCheckbox]="validCheckbox"
            (newValidCheckbox)="newValidCheckbox($event)"
            [showError]="showEnvError"
          >
          </app-environment-customer-details>

            <app-instance-details
              *ngIf="currentStep === 2"
              [instanceDetails]="currentGroup"
              [environmentDetails]="getGroupAt(1)"
              [customerDetails]="getGroupAt(0)"
            >
            </app-instance-details>

            <app-occupation-insight-details
              *ngIf="currentStep === 3"
              [occupationInsightsForm]="currentGroup"
            >
            </app-occupation-insight-details>

            <app-customer-details-summary
            *ngIf="currentStep === 4"
            [customerSummary]="getGroupAt(0)"
            [instanceSummary]="getGroupAt(2)"
            [environmentSummary]="getGroupAt(1)"
            [oISummary]="getGroupAt(3)"
            [selectedEnvironments]="validCheckbox"
            ></app-customer-details-summary>

            <app-aad-setting-details
            *ngIf="currentStep === 5"
            (customerOnboardingRequestEmit)="customerOnboardingRequestEmit($event)"
            (itemEmit)="itemEmit($event)"
            ></app-aad-setting-details>

            <app-app-user-details
            *ngIf="currentStep === 6"
            [appUserDetails]="getGroupAt(6)"
            [customerOnboardingRequest]="customerOnboardingRequest"
            (customerOnboardingRequestEmit)="customerOnboardingRequestEmit($event)"
            ></app-app-user-details>
          </form>
        </div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
  <kendo-tilelayout [columns]="12">
    <kendo-tilelayout-item [col]="1" [colSpan]="12" class="border">
      <div class="p-2">
        <div class="k-form-buttons k-buttons-end" *ngIf="currentStep !== 5 && currentStep !== 6">
          <span class="page">Step {{ currentStep + 1 }} of 7</span>
          <div>
            <button
              class="k-button prev"
              kendoButton
              themeColor="secondary"
              *ngIf="currentStep !== 0"
              (click)="prev()"
            >
              Previous
            </button>
            <button
              class="k-button"
              kendoButton
              themeColor="primary"
              (click)="next()"
              *ngIf="currentStep !== 6"
            >
              Next
            </button>
          </div>
        </div>

        <div class="k-form-buttons k-buttons-end" *ngIf="currentStep === 5">
          <span class="page">Step {{ currentStep + 1 }} of 7</span>

          <div>
            <button
              class="k-button prev"
              kendoButton
              themeColor="secondary"
              *ngIf="currentStep !== 0"
              (click)="prev()"
            >
              Previous
            </button>
            <button
              class="k-button"
              kendoButton
              themeColor="primary"
              (click)="next()"
              *ngIf="currentStep !== 6"
            >
              Next
            </button>
          </div>
        </div>
        <div class="k-form-buttons k-buttons-end" *ngIf="currentStep === 6">
          <span class="page">Step {{ currentStep + 1 }} of 7</span>

          <div>
            <button
              class="k-button prev"
              kendoButton
              themeColor="secondary"
              *ngIf="currentStep !== 0"
              (click)="prev()"
            >
              Previous
            </button>
            <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="submit()"
            *ngIf="currentStep === 6"
            [disabled]="isSubmitDisabled()"
          >
            Submit
          </button>
          </div>
        </div>
      </div>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
  <div kendoDialogContainer></div>
