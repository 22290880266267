<div class="w-75 px-4">
    <ng-container [formGroup]="form">
        <kendo-formfield>
            <kendo-label [for]="environment" text="Environment:"></kendo-label>
            <kendo-dropdownlist
            defaultItem="Select environment"
            #environment
            formControlName="environment"
            [data]="environments"
            [valuePrimitive]="true"
            (valueChange)="onDropDownChanged($event)"
            required >
            </kendo-dropdownlist>
        </kendo-formfield>
    
        <kendo-formfield>
            <kendo-label [for]="environmentType" text="Environment Type:"></kendo-label>
            <kendo-dropdownlist
            defaultItem="Select environment type"
            #environmentType
            formControlName="environmentType"
            [data]="environmentTypes"
            [valuePrimitive]="true"
            (valueChange)="onDropDownChanged($event)"
            required >
            </kendo-dropdownlist>
        </kendo-formfield>
    
        <kendo-formfield>
            <kendo-label [for]="region" text="Azure Region:"></kendo-label>
            <kendo-dropdownlist
            defaultItem="Select region"
            #region
            formControlName="region"
            [data]="regions"
            [valuePrimitive]="true"
            (valueChange)="onDropDownChanged($event)"
            required >
            </kendo-dropdownlist>
        </kendo-formfield>

        <kendo-formfield *ngIf="showCustomerId">
            <kendo-label [for]="customerID" text="Customer Id:"></kendo-label>
            <kendo-textbox formControlName="customerID"
                   kendoTextBox
                   #customerID>
            </kendo-textbox>
        </kendo-formfield>
    </ng-container>
</div>
