import { Inject, Injectable } from '@angular/core';
import { Constants } from '../shared/Constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of, Subject } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private jwtHelper: JwtHelperService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
  }

  public getToken() {
    const token = localStorage.getItem(Constants.TOKEN_AZURE);
    const isTokenExpired = this.jwtHelper.isTokenExpired(token);

    if (!isTokenExpired) {
      return of(token);
    }
  }

  // public getTokenExpirationDate(): Date {
  //   const token = localStorage.getItem(Constants.TOKEN_AZURE);
  //   return this.jwtHelper.getTokenExpirationDate(token);
  // }

  public isAzureAuthenticated(): boolean {
    // get the token
    const token = localStorage.getItem(Constants.TOKEN_AZURE);
    // console.log(token);
    // return a boolean reflecting
    // whether or not the token is expired
    // const decodedToken = this.jwtHelper.decodeToken(token);
    // console.log(decodedToken);
    // const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
    // console.log(expirationDate);
    // const isExpired = this.jwtHelper.isTokenExpired(token);
    // console.log(isExpired);
    return !this.jwtHelper.isTokenExpired(token);
  }

  public isAdminToolAuthenticated(): boolean {
    const token = localStorage.getItem(Constants.TOKEN_ADMIN_TOOL);
    // console.log(token);
    // return a boolean reflecting
    // whether or not the token is expired
    // const decodedToken = this.jwtHelper.decodeToken(token);
    // console.log(decodedToken);
    // const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
    // console.log(expirationDate);
    // const isExpired = this.jwtHelper.isTokenExpired(token);
    // console.log(isExpired);
    return !this.jwtHelper.isTokenExpired(token);
  }

  updateLoggedInStatus() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
      });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.loginWithPopup();
    } else {
      this.loginWithRedirect();
    }
  }

  getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private setLoggedIn() {
    this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
  }

  private loginWithPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    } else {
      this.msalService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    }
  }

  private loginWithRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  logout() {
    this.msalService.logout();
  }

  destroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
