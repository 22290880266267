import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-environment-instance-details',
  templateUrl: './new-environment-instance-details.component.html',
  styleUrls: ['./new-environment-instance-details.component.scss']
})
export class NewEnvironmentInstanceDetailsComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() public form: UntypedFormGroup;

  @Output() formOut = new EventEmitter<any>();
}
