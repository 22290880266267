<kendo-dialog
[minWidth]="650"
[width]="650"
[minHeight]="400"
[height]="400">
  <kendo-dialog-titlebar>
    <div>
      <span class="k-icon k-i-form-element"></span> Policy
    </div>
  </kendo-dialog-titlebar>
  <div class="container">
    <form [formGroup]="formGroup">
      <div class="form-group">
        <label for="name">Name</label>
        <input
          type="text"
          class="form-control"
          id="name"
          [formControl]="formGroup.get('name')"
          required
        />
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="subject">Subject</label>
          <kendo-dropdownlist
            id="subject"
            formControlName="subject"
            [data]="subjects"
            [defaultItem]="null"
            required
          >
          </kendo-dropdownlist>
        </div>
        <div class="form-group col-md-6">
          <label for="action">Action</label>
          <kendo-dropdownlist
            id="action"
            formControlName="action"
            [data]="actions"
            [defaultItem]="null"
            required
          >
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="form-group">
        <label for="resource">Resource</label>
        <kendo-multiselect
          id="resource"
          textField="name"
          valueField="id"
          formControlName="resource"
          [data]="resources"
          [allowCustom]="true"
        >
        </kendo-multiselect>
      </div>
    </form>
  </div>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button
      kendoButton
      (click)="onConfirmAction()"
      themeColor="primary"
      [disabled]="!formGroup.valid"
    >
      Save
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
