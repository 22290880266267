<div class="container-fluid g-0">
  <kendo-appbar positionMode="sticky" style="padding: 0 0;">
    <kendo-appbar-section>
      <kendo-label class="ms-3 pb-3">Batch Deployment - {{selectedProject?.name}}
        - {{selectedReleaseDefinition?.name}} {{selectedReleaseDefinition?.path}}</kendo-label>
    </kendo-appbar-section>

    <kendo-appbar-spacer></kendo-appbar-spacer>
    <kendo-dropdownbutton
      themeColor="primary"
      [data]="deployActions"
      [popupSettings]="{ popupClass: 'deployButton', animate: true }"
      textField="actionName"
      [disabled]="disableDeployButton()"
      class="me-2"
    >
      Submit Batch
    </kendo-dropdownbutton>
  </kendo-appbar>
  <div class="row g-0">
    <div class="col-3">
      <h4>Projects</h4>
      <kendo-dropdownlist
        [defaultItem]="defaultProject"
        [data]="projectsFiltered"
        [filterable]="true"
        [textField]="'name'"
        [valueField]="'id'"
        (filterChange)="handleProjectFilter($event)"
        (selectionChange)="projectSelectionChange($event)"
      >
      </kendo-dropdownlist>

      <h4>Releases</h4>
      <kendo-dropdowntree
        kendoDropDownTreeExpandable
        [(expandedKeys)]="expandedNodeIndices"
        [kendoDropDownTreeHierarchyBinding]="releaseItems"
        childrenField="children"
        [filterable]="true"
        textField="name"
        valueField="id"
        (valueChange)="releaseSelectionChange($event)"
        listHeight="850"
        style="width: 100%;"
        [popupSettings]="{
        width: 'auto',
        height: 950
      }"
      >
        <ng-template kendoDropDownTreeNodeTemplate let-dataItem>
          {{ dataItem.name }}
        </ng-template>
      </kendo-dropdowntree>

      <h4>Environments</h4>
      <kendo-grid
        *ngIf="environments && environments.length > 0"
        [data]="environments"
        (selectedKeysChange)="environmentSelectionChange($event)"
        kendoGridSelectBy="id"
        [selectable]="{enabled: true, checkboxOnly: true}"
        class="row noHeaderGrid g-0">
        <kendo-grid-checkbox-column
          showSelectAll="true"
          [width]="10"
          [minResizableWidth]="10"
        ></kendo-grid-checkbox-column>
        <kendo-grid-column field="name" title="Environments" width="40">
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <div class="col-9 container-fluid">
      <h4>Customers</h4>
      <kendo-grid
        *ngIf="variableGroups"
        [data]="variableGroupsView"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [filter]="state.filter"
        [sortable]="true"
        [pageable]="true"
        [filterable]="true"
        (dataStateChange)="dataStateChange($event)"
        (selectedKeysChange)="variableGroupSelectionChange($event)"
        kendoGridSelectBy="id"
        [selectable]="true"
        [style.height.%]="100"
      >
        <kendo-grid-checkbox-column
          showSelectAll="true"
          [width]="15"
          [minResizableWidth]="30"
        ></kendo-grid-checkbox-column>
        <kendo-grid-column
          field="name"
          title="Variable Group Name"
          [width]="250"
          [minResizableWidth]="200"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<kendo-dialog *ngIf="deployDialogOpened" (close)="onDeployDialogClose()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <span class="k-icon k-i-warning"></span> Confirm Deploy
    </div>
  </kendo-dialog-titlebar>
  <p style="margin: 20px;">
    Project: {{this.selectedProject.name}}
  </p>
  <p style="margin: 20px;">
    Release: {{selectedReleaseDefinition?.name}} {{selectedReleaseDefinition?.path}}
  </p>
  <p style="margin: 20px;">
    Variable Groups: {{this.SelectedVariableGroups | json}}
  </p>
  <p style="margin: 20px;">
    Environments: {{SelectedEnvironments | json}}
  </p>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onDeployDialogClose()">Cancel</button>
    <button kendoButton (click)="onDeployConfirm()" primary="true">
      Confirm
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
