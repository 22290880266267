import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { DtlDeployment } from '../../shared/models/DtlDeployment';
import { DtlDeploymentFilterModel } from '../../shared/models/DtlDeploymentFilterModel';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class DtlDeploymentService {
  private url = `${window.location.origin}/api/DtlDeployment`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService) {
  }

  createDtlDeployment(deployment: DtlDeployment): Observable<DtlDeployment> {
    return this.httpClient
      .post(this.url, deployment)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createDtlDeployment'))
      );
  }

  getDtlDeployments(filter: DtlDeploymentFilterModel): Observable<DtlDeployment[]> {
    return this.httpClient
      .get<DtlDeployment[]>(`${this.url}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDtlDeployments'))
      );
  }
}
