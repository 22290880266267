import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Batch } from '../../shared/models/Batch';
import { BatchFilterModel } from '../../shared/models/BatchFilterModel';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class BatchService {
  private url = `${window.location.origin}/api/batch`;

  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private authService: AuthService,
    private errorHandling: ErrorHandlerService) {
  }

  createBatch(batch: Batch): Observable<any> {
    return this.httpClient
      .post(this.url, batch)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createBatch'))
      );
  }

  createBatchForMTUpgrades(batch: Batch): Observable<any> {
    return this.httpClient
      .post(`${this.url}/MTUpgrades`, batch)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createBatch'))
      );
  }

  getBatches(filter: BatchFilterModel): Observable<Batch[]> {
    return this.httpClient
      .get<Batch[]>(`${this.url}?type=${filter.type}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getBatches'))
      );
  }
}
