<kendo-tilelayout
  [resizable]="true"
  [reorderable]="true"
>
  <kendo-tilelayout-item>
    <div *ngIf="showTasks">
      <kendo-grid
        [kendoGridBinding]="logData"
        kendoGridSelectBy="Id"
        [isDetailExpanded]="expandDetails"
        [style.height.%]="100"
        [pageable]=false
        kendoGridFocusable
        [rowClass]="rowCallback"
        [resizable]="true"
      >

        <kendo-grid-column
          field="job.name"
          title="Job"
          [width]="60"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="job.state"
          title="State"
          [width]="70"
        ></kendo-grid-column>
        <kendo-grid-column
          field="job.result"
          title="Result"
          [width]="60"
        ></kendo-grid-column>
        <kendo-grid-column
          field="job.startTime"
          title="Time"
          [width]="60"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.job.startTime && dataItem.job.finishTime">
              {{getDuration(dataItem.job.startTime, dataItem.job.finishTime)}} s
            </div>
          </ng-template>
        </kendo-grid-column>
        <ng-template
          kendoGridDetailTemplate
          let-dataItem
        >
          <section>
            <kendo-grid
              [data]="dataItem.tasks"
              [pageable]="false"
              [filterable]="false"
              kendoGridFocusable
              [selectable]="true"
              [rowClass]="rowCallback"
              [resizable]="true"
            >
              <kendo-grid-column
                field="name"
                title="Task"
                [width]="60"
              >
              </kendo-grid-column>
              <kendo-grid-column
                field="state"
                title="State"
                [width]="70"
              ></kendo-grid-column>
              <kendo-grid-column
                field="result"
                title="Result"
                [width]="60"
              ></kendo-grid-column>
              <kendo-grid-column
                field="startTime"
                title="Time"
                [width]="60"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div *ngIf="dataItem.startTime && dataItem.finishTime">
                    {{getDuration(dataItem.startTime, dataItem.finishTime)}} s
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                [width]="50"
                [filterable]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <button kendoButton themeColor="primary" *ngIf="dataItem.log" (click)="getLog(dataItem)">
                    Details
                  </button>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </section>
        </ng-template>
      </kendo-grid>
    </div>

    <div *ngIf="!showTasks">
      <kendo-tilelayout
        [resizable]="true"
        [reorderable]="true"
        [columns]="12"
      >
        <kendo-tilelayout-item [col]="5" [colSpan]="4">
          <button kendoButton (click)="backToAllTasks()" primary="true" class="w-100 h-100 px-2" icon="arrow">Back to
            Jobs/Tasks
            <kendo-icon name="arrow-chevron-left"></kendo-icon>
          </button>
        </kendo-tilelayout-item>
      </kendo-tilelayout>

      <div class="row col-12">
        <textarea *ngIf="log"
                  name="log"
                  rows="50"
                  class="p-4"
                  disabled="true"
        >
    {{log}}
  </textarea>
      </div>
    </div>
  </kendo-tilelayout-item>
</kendo-tilelayout>
