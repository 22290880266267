<div class="container-fluid g-0">
  <div class="row g-0">
    <kendo-appbar positionMode="sticky" style="padding: 0 0;">
      <kendo-appbar-spacer></kendo-appbar-spacer>
      <kendo-appbar-section>
        <button
          kendoButton
          themeColor="primary"
          (click)="onUpdateClicked(null)"
          class="me-4"
        >
          Add New
        </button>
      </kendo-appbar-section>
    </kendo-appbar>
  </div>
  <div class="row g-0">
    <kendo-grid
      [data]="customersView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="true"
      [filterable]="true"
      (dataStateChange)="dataStateChange($event)"
      (selectionChange)="selectedRowChangeCustomer($event)"
      kendoGridSelectBy="id"
      [selectable]="true"
      [style.height.%]="100"
    >
      <kendo-grid-column
        field="projectId"
        title="Project"
        [width]="50"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="cloudServiceCode"
        title="Cloud Service Code"
        [width]="50"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="customerName"
        title="Name"
        [width]="50"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="createdDate"
        title="Action"
        [width]="40"
        [minResizableWidth]="40"
        [filterable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <button kendoButton
                  class="icon-button me-2"
                  icon="delete"
                  title="Delete Customer"
                  primary="true"
                  (click)="onDeleteClicked(dataItem)"
          >
          </button>
          <button kendoButton
                  class="icon-button me-2"
                  icon="refresh"
                  title="Refresh Environments"
                  primary="true"
                  (click)="onRefreshClicked(dataItem)"
          >
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
<div kendoDialogContainer></div>

