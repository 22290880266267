import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { Subscription } from 'rxjs';
import { Constants } from '../shared/Constants';

@Component({
  selector: 'app-update-template-file',
  templateUrl: './update-template-file.component.html',
  styleUrls: ['./update-template-file.component.scss']
})
export class UpdateTemplateFileComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadEnvironmentDetails();
  }

  private sub: Subscription;

  public templateTypes: Array<string> = ['Environment Template File', 'Customer Template Files'];
  public environmentTypes: Array<string> = [];
  public environmentTypesSource: Array<string> = [];
  title = 'Update Template File';
  buttonName = 'Update Template File';
  showKeyTextBox = true;
  showValueTextBox = false;
  showChips = false;

  public selectedKeys: Array<string> = [];

  public form = new UntypedFormGroup({
    templateType: new UntypedFormControl(),
    environmentType: new UntypedFormControl(),
    key: new UntypedFormControl(),
    value: new UntypedFormControl()
  });

  updateTemplateRequest = {
    templateType: '',
    environmentType: '',
    keyValuesToAdd: []
  };

  onDropDownChanged($event) {
    if (this.form.get('templateType').value == 'Customer Template Files') {
      this.title = 'Update Customer Template Files';
      this.buttonName = 'Update Customer Template Files';
    } else if (this.form.get('templateType').value == 'Environment Template File') {
      this.title = 'Update Environment Template File';
      this.buttonName = 'Update Environment Template File';
    } else {
      this.title = 'Update Template File';
      this.buttonName = 'Update Template File';
    }
  }

  updateTemplateFiles() {
    this.updateTemplateRequest.templateType = this.form.get('templateType').value;
    this.updateTemplateRequest.environmentType = this.form.get('environmentType').value;
    this.updateTemplateRequest.keyValuesToAdd = this.selectedKeys;

    this.loaderService.start();
    this.sub = this.gitService.updateKeyValueInTemplateFile(this.updateTemplateRequest)
      .subscribe({
        next: (result) => {
          this.toastr.success('Files updated succesfully');
          this.loaderService.stop();
          this.router.navigate([`${Constants.UPDATE_TEMPLATE_LOG_FILE_PATH}`]).then();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public onRemove(e: ChipRemoveEvent): void {
    console.log(e);
    const index = this.selectedKeys
      .map((c) => c)
      .indexOf(e.sender.label);
    this.selectedKeys.splice(index, 1);
    if (this.selectedKeys.length == 0) {
      this.showChips = false;
    } else {
      this.showChips = true;
      if (this.selectedKeys[length - 1].includes('###')) {
        this.showValueTextBox = true;
        this.showKeyTextBox = false;
      } else {
        this.showValueTextBox = false;
        this.showKeyTextBox = true;
      }
    }

  }

  AddKey() {
    this.showChips = true;
    this.selectedKeys.push(this.form.get('key').value);
    this.form.controls.key.reset();
    this.showKeyTextBox = false;
    this.showValueTextBox = true;
  }

  AddValue() {
    const selectedKey = this.selectedKeys.pop();
    const selectedKeyValue = `${selectedKey} ### ${this.form.get('value').value}`;
    this.selectedKeys.push(selectedKeyValue);
    this.form.controls.value.reset();
    this.showValueTextBox = false;
    this.showKeyTextBox = true;
  }

  isSubmitDisabled() {
    this.updateTemplateRequest.templateType = this.form.get('templateType').value;
    this.updateTemplateRequest.environmentType = this.form.get('environmentType').value;

    if (this.updateTemplateRequest.templateType && this.updateTemplateRequest.environmentType &&
      this.selectedKeys.length > 0 && this.showKeyTextBox) {
      return false;
    }

    return true;
  }

  loadEnvironmentDetails() {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getValidMTEnvironmentDetails()
      .subscribe({
        next: (result) => {
          this.environmentTypesSource = result;
          this.environmentTypes = result;
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  handleFilter(value) {
    this.environmentTypes = this.environmentTypesSource.filter(
      (s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
