<kendo-grid
  [data]="lcsEnvironmentsView"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="true"
  [pageable]="true"
  [filterable]="true"
  (dataStateChange)="dataStateChange($event)"
  kendoGridSelectBy="id"
  [selectable]="true"
  [style.height.%]="100"
  *ngIf="customer"
>
  <kendo-grid-column
    field="cloudServiceCode"
    title="Cloud Service Code"
    [width]="60"
    [minResizableWidth]="60"
    [filterable]="true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{customer.cloudServiceCode}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="environmentName"
    title="Environment Name"
    [width]="90"
    [minResizableWidth]="90"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.environmentName}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="environmentType"
    title="Environment Type"
    [width]="60"
    [minResizableWidth]="60"
    [filterable]="true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.environmentType}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="currentApplicationReleaseName"
    title="AR Version"
    [width]="50"
    [minResizableWidth]="50"
    [filterable]="true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.currentApplicationReleaseName}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="environmentEndpointBaseUrl"
    title="URL"
    [width]="280"
    [minResizableWidth]="280"
    [filterable]="true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <a href="{{dataItem.environmentEndpointBaseUrl}}">{{dataItem.environmentEndpointBaseUrl}}</a>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
