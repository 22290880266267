import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-environment-details',
  templateUrl: './new-environment-details.component.html',
  styleUrls: ['./new-environment-details.component.scss']
})
export class NewEnvironmentDetailsComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadEnvironmentTypes();
  }

  private sub: Subscription;

  @Input() public form: UntypedFormGroup;
  @Input() public environment: string;
  @Input() public showCustomerId: boolean;

  @Output() formOut = new EventEmitter<any>();
  @Output() showCustomerIdOut = new EventEmitter<any>();


  public environments: Array<string> = ['Shared', 'Enterprise'];
  public environmentTypes: Array<string> = [];
  public regions: Array<string> = ['East', 'West', 'Canada Central', 'US Central'];

  public onDropDownChanged(event) {
    if (this.form.get('environmentType') != null && this.form.get('environmentType').value == 'SvcsCustEnv') {
      this.form.controls.environment.setValue('Shared');
      this.form.controls.region.setValue('East');
      this.environments = ['Shared'];
      this.regions = ['East'];
    } else {
      this.environments = ['Shared', 'Enterprise'];
      this.regions = ['East', 'West', 'Canada Central', 'US Central'];
    }

    this.environment = this.form.get('environment') != null ? this.form.get('environment').value : '';
    const environmentType = this.form.get('environmentType') != null ? this.form.get('environmentType').value : '';
    const region = this.form.get('region') != null ? this.form.get('region').value : '';

    this.showCustomerId = this.environment == 'Enterprise';
    this.showCustomerIdOut.emit(this.showCustomerId);
    this.formOut.emit(this.form);
    if (this.environments.includes(this.environment) &&
      this.environmentTypes.includes(environmentType) &&
      this.regions.includes(region)) {
      this.setEnvironmentInstanceValue(environmentType, region);
    } else {
      this.form.controls.instance.setValue('');
      this.formOut.emit(this.form);
    }
  }

  setEnvironmentInstanceValue(type, region) {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getLatestEnvironmentInstance(region, type)
      .subscribe({
        next: (result) => {
          this.form.controls.instance.setValue(result);
        },
        error: (error) => {
          this.form.controls.instance.setValue('');
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
          this.formOut.emit(this.form);
        }
      });
  }

  loadEnvironmentTypes() {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getDistinctEnvironmentTypes()
      .subscribe({
        next: (result) => {
          this.environmentTypes = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }
}
