import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from './Constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      // navigate /delete cookies or whatever
      console.log(err);
      // this.authService.login();
      // if you've caught / handled the error, you don't want to rethrow it unless you also want
      // downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    console.log(err);
    return throwError(() => new Error(err.message));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const patToken = localStorage.getItem(Constants.PatToken);
    request = request.clone({
      setHeaders: {
        PatToken: `${patToken}`
      }
    });

    return next.handle(request)
      .pipe(
        catchError(x => this.handleAuthError(x))
      );
  }
}
