import { ProductVersion } from "./ProductWithVersions";

export class CustomerEnvRequest {
    // SEARCHTERM
    public searchTerm: string = null;
    // PLATFORMS
    public platforms: string[] = [];
    // PRODUCTVERSIONS
    public productVersions: ProductVersion[] = [];
    // COLUMNFILTERKEY
    public columnFilterKey: string[] = [];
    // COLUMNFILTERVALUE
    public columnFilterValue: string[] = [];
    // SORTFILTERKEY
    public sortFilterKey: string = null;
    // SORTFILTERVALUE
    public sortFilterValue: string = null;
    // PAGE
    public page: string = null;
    // FILTER INTERNAL ENV
    public filterInternalEnv: boolean = null;
}