<div class="px-5 summary">
    <div class="row pt-5">
        <div class="col-6">
            <b>SUMMARY</b>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col-6">
        <b>Environment: </b>{{this.envCreateRequest.environment}}
    </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Environment Type: </b>{{this.envCreateRequest.environmentType}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Region: </b>{{this.envCreateRequest.region}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Environment Instance: </b>{{this.envCreateRequest.instance}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Sql Instance: </b>{{this.envCreateRequest.sqlInstance}}
        </div>
    </div>
    
    <!-- <div class="row pt-2">
        <div class="col-6">
            <b>SQL Application Id: </b>{{this.envCreateRequest.sqlApplicationID}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>SPN Object Id: </b>{{this.envCreateRequest.spnObjectID}}
        </div>
    </div> -->
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Subscription Id(Service Connection): </b>{{this.envCreateRequest.subscriptionID}}
        </div>
    </div>
    
    <div class="row pt-2">
        <div class="col-6">
            <b>Subscription Id(Azure): </b>{{this.envCreateRequest.azureSubscriptionID}}
        </div>
    </div>   
</div>
