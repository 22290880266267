import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Constants } from '../../../shared/Constants';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-ps-integration-status',
  templateUrl: './ps-integration-status.component.html',
  styleUrls: ['./ps-integration-status.component.scss']
})
export class PsIntegrationStatusComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(public activatedRoute: ActivatedRoute) {
    super();
  }

  public title = '';
  private sub: Subscription;

  statuses = [];
  statusesView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    var path = this.activatedRoute.snapshot.routeConfig.path;
    this.startSearch();
  }

  private startSearch = () => {
    this.loaderService.start();
    this.sub = this.pipelineService.getPsIntegrationStatuses()
      .pipe(
        switchMap(items => this.populatePipelineStatus(items)),
      )
      .subscribe({
        next: (result) => {
          this.statuses = result;
          this.statusesView = process(result, this.state);
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  };

  goToTaskDetails(data){
    this.router.navigate([`${Constants.ProfessionalIntegration_Status}/${data.buildId}`])
      .then();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.statusesView = process(this.statuses, this.state);
  }

  private populatePipelineStatus(deployItems: any[]): Observable<any[]> {
    const observables: Observable<any>[] =
      deployItems.map(di => {
        return this.getPipelineStatus(di);
      });

    return forkJoin(observables);
  }

  private getPipelineStatus(item: any): Observable<any> {
    return this.pipelineService.getPipelineRunStatus(this.configService.MainProject, item.buildId)
      .pipe(
        switchMap(s => {
          console.log(s);
          item.status = s;
          return of(item);
        })
      );
  }
}
