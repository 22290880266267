import { Component, OnDestroy, OnInit } from '@angular/core';
import { Release } from '../../../../shared/models/Release';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { BaseComponent } from '../../../../components/base.component';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { PipelineLog, PipelineRunLogs } from '../../../models/PipelineRunLogs';
import { Constants } from '../../../../shared/Constants';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-pipeline-detail',
  templateUrl: './pipeline-detail.component.html',
  styleUrls: ['./pipeline-detail.component.scss']
})
export class PipelineDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  release: Release = null;
  releaseLogs: PipelineLog[];
  releaseLogsView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.releaseLogsView = process(this.releaseLogs, this.state);
  }

  private subscribeChanges() {
    this.sub = this.dataService.Release
      .pipe(
        switchMap(r => {
          return combineLatest([
              of(r),
              this.getAllReleaseLogs(r)
            ]);
        }),
        switchMap(([r, rls]) => {
          return this.getReleaseLogs(r, rls);
        })
      ).subscribe(() => console.log);
  }

  private getAllReleaseLogs(r: Release): Observable<PipelineRunLogs> {
    return this.pipelineService.getPipelineRunAllLogs(this.configService.MainProject, r.id);
  }

  private getReleaseLogs(r: Release, rls: PipelineRunLogs): Observable<string[]> {
    const observables: Observable<string>[] = [];
    rls.value.forEach(l => {
      observables.push(this.pipelineService.getPipelineRunLog(this.configService.MainProject, r.id, l.id));
    });
    return forkJoin(observables);
  }

  backToAllLogs(){
    this.router.navigate([`${Constants.PIPELINES}`])
  }
}
