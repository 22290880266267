import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { Subscription } from 'rxjs';
import { StepperComponent } from '@progress/kendo-angular-layout';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Constants } from '../shared/Constants';
import { DialogAction } from '@progress/kendo-angular-dialog';
import { AppRegistrationOverrideConfirmComponent } from '../customer-onboarding/app-registration-override-confirm/app-registration-override-confirm.component';

@Component({
  selector: 'app-finance-integration',
  templateUrl: './finance-integration.component.html',
  styleUrls: ['./finance-integration.component.scss']
})
export class FinanceIntegrationComponent extends BaseComponent {

  constructor() {
    super();
  }

  items:any;


  public checkboxes = [
    { "selected": false, "name": "Production", "value": "Prod" ,"displayName": "Production", "isDisabled":true},
    { "selected": false, "name": "Tst", "value": "Tst" ,"displayName": "Tst", "isDisabled":false},
    { "selected": false, "name": "Dev", "value": "Dev" ,"displayName": "Dev", "isDisabled":false},
    { "selected": false, "name": "Uat", "value": "Uat" ,"displayName": "Uat", "isDisabled":false},
    { "selected": false, "name": "Default", "value": "Prod" ,"displayName": "Default", "isDisabled":false}
  ];

  public validCheckbox=[];

  private sub: Subscription;

  public currentStep = 0;

  @ViewChild("stepper", { static: true })
  public stepper: StepperComponent;

  financeIntegrationRequest={
    tenantId : "",
    clientId : "",
    clientSecret :"",
    appIdUri : "",
    customerId : "",
    instance : "",
    environment : "",
    region : "",
    resourceUri : "",
    cnfiVersion:""
  };

  private shouldValidate = (index: number): boolean => {
    return this.getGroupAt(index).touched && this.currentStep >= index;
  };

  public steps = [
    {
      label: "Customer Details",
      isValid: true,
      validate: this.shouldValidate,
    },
    {
      label: "AAD Details",
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: "Summary",
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    }
  ];

  public form = new UntypedFormGroup({
    customerId: new UntypedFormControl(),
    resourceUri: new UntypedFormControl(),
    tenantId: new UntypedFormControl(),
    appRegistrationId: new UntypedFormControl(),
    appRegistrationIdUri: new UntypedFormControl(),
    secretKey: new UntypedFormControl(),
    instance: new UntypedFormControl(),
    environment: new UntypedFormControl(),
    region: new UntypedFormControl(),
    cnfVersion: new UntypedFormControl()
  });

  public next(): void {
    this.stepper.validateSteps();

    if (this.currentStep !== this.steps.length) {
      this.currentStep += 1;
      this.steps[this.currentStep].disabled= false;
      return;
    }

  }

  public prev(): void {
    this.currentStep -= 1;
  }

  public submit(): void {
    this.getFinanceIntegrationRequest();
    this.SubmitData();
  }

  private getFinanceIntegrationRequest() {
    this.financeIntegrationRequest.tenantId = this.form.get("tenantId").value;
    this.financeIntegrationRequest.clientId = this.form.get("appRegistrationId").value;
    this.financeIntegrationRequest.appIdUri = this.form.get("appRegistrationIdUri").value;
    this.financeIntegrationRequest.clientSecret = this.form.get("secretKey").value;
    this.financeIntegrationRequest.customerId = this.form.get("customerId").value;
    this.financeIntegrationRequest.environment = this.form.get("environment").value;
    this.financeIntegrationRequest.instance = this.form.get("instance").value;
    this.financeIntegrationRequest.region = this.form.get("region").value;
    this.financeIntegrationRequest.resourceUri = this.form.get("resourceUri").value;
    this.financeIntegrationRequest.cnfiVersion = this.form.get("cnfVersion").value;
  }

  private getGroupAt(index: number): UntypedFormGroup {
    const groups = Object.keys(this.form.controls).map((groupName) =>
      this.form.get(groupName)
    ) as UntypedFormGroup[];

    return groups[index];
  }


  public newCheckboxes(details){
    this.checkboxes = details;
  }

  public newValidCheckbox(details){
    this.validCheckbox = details;
  }

  public itemEmit(details){
    this.items = details;
  }

  public SubmitData(){
    this.loaderService.start();
    const customerId = this.form.get('customerId').value;
    this.sub = this.appRegistrationService.isFinanceAppRegistrationDetailsOverriden(customerId)
    .subscribe({
      next: (result) => {
        if(result != null && result.length > 0){
          this.loaderService.stop();
          const dialogRef = this.dialogService.open({
            content: AppRegistrationOverrideConfirmComponent,
          });
          const instance = dialogRef.content.instance;
          instance.Changes = result;

          this.sub = dialogRef.result.subscribe((r) => {
            const result = r as DialogAction;
            if (result.themeColor === Constants.THEME_PRIMARY) {
              this.loaderService.start();
              this.updateTemplateFiles()
            }
          });
        }
        else
        {
          this.updateTemplateFiles()
        }
      },
      error: (error) => {
        this.loaderService.stop();
      },
      complete: () => {
      }

    });
  }

  public updateTemplateFiles(){
    this.sub = this.gitService.updateFinanceIntegrationCustVarTemplate(this.financeIntegrationRequest)
      .subscribe({
        next: (result) => {
          this.RunFinanceIntegrationPipelines();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public isSubmitDisabled(){
    return !(this.form.get("tenantId").value &&
      this.form.get("appRegistrationId").value &&
      this.form.get("appRegistrationIdUri").value &&
      this.form.get("secretKey").value &&
      this.form.get("customerId").value &&
      this.form.get("environment").value &&
      this.form.get("instance").value &&
      this.form.get("region").value &&
      this.form.get("resourceUri").value &&
      this.form.get("cnfVersion").value);
  }

  public RunFinanceIntegrationPipelines(){
    const customerId = this.form.get('customerId').value;
    this.pipelineService.runFinanceIntegrationPipelines(this.financeIntegrationRequest).then();
    this.loaderService.stop();
    this.router.navigate([`/FinanceIntegrationStatus/${customerId}`]).then();
  }
}
