import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Environment } from '../../shared/models/devtestslab/environment';

@Injectable({
  providedIn: 'root'
})
export class DevTestsLabService {

  private url = `${window.location.origin}/api/DevTestsLab`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService) {
  }

  getNextEnvironmentNumber(subscriptionId: string): Observable<number> {
    return this.httpClient
      .get<number>(`${this.url}/NextEnvironmentNumber/${subscriptionId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getNextEnvironmentNumber'))
      );
  }

  getNextEnvironmentNumberFromTag(): Observable<number> {
    return this.httpClient
      .get<number>(`${this.url}/NextEnvironmentNumberFromTag`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getNextEnvironmentNumberFromTag'))
      );
  }

  getSubnets(subscriptionId: string): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/GetSubnets/${subscriptionId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getSubnets'))
      );
  }

  getEnvironments(): Observable<Environment[]> {
    return this.httpClient
      .get<Environment[]>(`${this.url}/GetEnvironments`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironment'))
      );
  }
}
