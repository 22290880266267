import { Component, OnDestroy, OnInit } from '@angular/core';
import { CancelEvent, CellClickEvent, CellCloseEvent, GridComponent, GridDataResult, SaveEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../components/base.component';
import { Subscription } from 'rxjs';
import { Constants } from '../../../shared/Constants';

@Component({
  selector: 'app-product-version',
  templateUrl: './product-version.component.html',
  styleUrls: ['./product-version.component.scss']
})
export class ProductVersionComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  public productVersionsView: GridDataResult;
  public state: State = {
    sort: [],
    skip: 0,
    take: 20,
  };

  public authentications: string[] = [
    Constants.SQL_AUTHENTICATION,
    Constants.ACTIVE_DIRECTORY,
    Constants.AZURE_ACTIVE_DIRECTORY,
  ];
  public studentAppVersions: any[] = [];
  public addOnProductVersions: any[] = [];
  public selectedStudentAppVersion: any = null;
  public selectedAuthentication: string = null;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.loadStudentAppVersions('Anthology Student');
  }

  public onStateChange(state: State): void {
    this.state = state;
    this.productVersionsView = process(this.addOnProductVersions, this.state);
  }

  public cellClickHandler({
                            sender,
                            rowIndex,
                            column,
                            columnIndex,
                            dataItem,
                            isEdited,
                          }: CellClickEvent): void {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.fb.group({
      // name: [dataItem.name, Validators.required],
      version: [dataItem.version, Validators.required],
    });
  }

  public saveChanges(grid: GridComponent): void {
    grid.closeCell();
    grid.cancelCell();
  }

  public cancelChanges(grid: GridComponent): void {
    grid.cancelCell();
  }

  public cancelHandler({ sender, rowIndex }: CancelEvent): void {
    sender.closeRow(rowIndex);
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      Object.assign(dataItem, formGroup.value);
    }
  }

  public saveHandler({ sender, formGroup, rowIndex }: SaveEvent): void {
    if (formGroup.valid) {
      sender.closeRow(rowIndex);
    }
  }

  private loadStudentAppVersions(product){
    this.loaderService.start();
    this.sub = this.adminService.getCoreProductVersions(product)
      .subscribe({
        next: (result) => {
          this.studentAppVersions = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  private loadAddOnProductData(versionId: number){
    this.loaderService.start();
    this.sub = this.adminService.getAddOnProducts(versionId)
      .subscribe({
        next: (result) => {
          this.addOnProductVersions = result;
          this.productVersionsView = process(this.addOnProductVersions, this.state);
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  studentAppVersionSelectionChange($event: any) {
    this.selectedStudentAppVersion = $event;
    this.loadAddOnProductData($event.id);
  }

  authenticationSelectionChange($event: string) {
    console.log($event);
  }
}
