import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-occupation-insight-details',
  templateUrl: './occupation-insight-details.component.html',
  styleUrls: ['./occupation-insight-details.component.scss']
})
export class OccupationInsightDetailsComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() public occupationInsightsForm: UntypedFormGroup;
}
