import { Component, Input } from '@angular/core';
import { PsInfrastructure } from '../../../shared/models/psIntegration/PsInfrastructure';

@Component({
  selector: 'app-ps-infrastructure',
  templateUrl: './ps-infrastructure.component.html',
  styleUrls: ['./ps-infrastructure.component.scss']
})
export class PsInfrastructureComponent {
  @Input() public infrastructure: PsInfrastructure;
}
