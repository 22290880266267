import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../components/base.component';
import { Release } from '../../../../../shared/models/Release';
import { ReleaseFilterModel } from '../../../../../shared/models/ReleaseFilterModel';
import { Constants } from '../../../../../shared/Constants';
import { Observable } from 'rxjs';
import { AzRelease } from '../../../../models/AzRelease';
import { DataStateChangeEvent, GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-db-restore-logs',
  templateUrl: './db-restore-logs.component.html',
  styleUrls: ['./db-restore-logs.component.scss']
})
export class DbRestoreLogsComponent extends BaseComponent implements OnInit {
  releases: Release[];
  releasesView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadDbRestoreLogs();
  }

  private loadDbRestoreLogs() {
    this.loaderService.start();
    const filter = {'batchId': -2} as ReleaseFilterModel;
    this.releaseDbService.getDbRefreshReleases(filter).subscribe({
      next: (result) => {
        this.releases = result;
        this.releasesView = process(this.releases, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  releaseSelectionChanged($event: SelectionEvent) {
    const release = $event.selectedRows[0].dataItem;

    this.loaderService.start();
    this.PopulateAzReleases(release)
      .subscribe({
        next: (l) => {
          const env = this.getEnvironment(l);

          this.dataService.updateEnvironment(env);
          this.router.navigate([`${Constants.DB_RESTORE_LOGS}/${Constants.STAGE_DETAIL}/${env.id}`]).then();

          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.releasesView = process(this.releases, this.state);
  }

  private PopulateAzReleases(release: Release): Observable<AzRelease> {
    return this.releaseService.getReleaseById(release.projectName, release.id)
  }

  getEnvironment(release: AzRelease): any {
    return release.environments.find(e => e.deploySteps.length > 0);
  }
}
