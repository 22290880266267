<div class="container wrapper pt-2 row col-12 d-flex justify-content-center flex-wrap text-uppercase fs-5">
    Template File - Commit Logs
  </div>

  <div>
    <kendo-grid
      [kendoGridBinding]="logs"
      [pageable]="true"
      [pageSize]="7"
      [sortable]="true"
      [filterable]="true"
    >
      <kendo-grid-column
        field="username"
        title="Name"
        [width]="180"
        [minResizableWidth]="250"
      >
        <ng-template kendoGridFilterMenuTemplate
                     let-filter let-column="column" let-filterService="filterService">

          <kendo-grid-string-filter-menu
            [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false"
          >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="createdDate"
        title="Date"
        [width]="180"
        [minResizableWidth]="200"
      >
        <ng-template kendoGridFilterMenuTemplate
                     let-filter let-column="column" let-filterService="filterService">

          <kendo-grid-string-filter-menu
            [column]="column" [filter]="filter" [filterService]="filterService"
            [extra]="false"
          >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
      [width]="60"
      title="Commit Details"
      [minResizableWidth]="60"
      [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button kendoButton themeColor="primary" *ngIf="dataItem.commitUrl">
                <a target="_blank" href="{{ dataItem.commitUrl }}" *ngIf="dataItem.commitUrl">
                Details
                </a>
            </button>
      </ng-template>
    </kendo-grid-column>
    </kendo-grid>
  </div>
