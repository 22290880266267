import { BlobItem } from '../BlobItem';

export class Infrastructure {
    public Subscription: InfrastructureItem;
    // public Tier: InfrastructureItem;
    public Organization: OrganizationItem;
    public ApplicationName: string;
    public Requestor: string;
    public EnvironmentNumber: string;
    public SubNetName: string;
    public StudentDatabase: BlobItem;
    public StudentPortalDatabase: BlobItem;
    public Remark: string;

    constructor() {
      this.Subscription = null;
      // this.Tier = null;
      this.Organization = null;
      this.ApplicationName = null;
      this.Requestor = null;
      this.EnvironmentNumber = null;
      this.SubNetName = null;
      this.StudentDatabase = null;
      this.StudentPortalDatabase = null;
      this.Remark = null;
    }
}

export class InfrastructureItem {
  Name: string;
  Id: string;
}

export class OrganizationItem {
  Name: string;
  OUPath: string;
}

