import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-database-refresh-target-details',
  templateUrl: './database-refresh-target-details.component.html',
  styleUrls: ['./database-refresh-target-details.component.scss']
})
export class DatabaseRefreshTargetDetailsComponent extends BaseComponent implements OnInit {

  @Input() public form: UntypedFormGroup;
  @Input() public envNameMappings: any;
  @Input() public target:any;
  @Input() public DbNames:any;
  @Output() targetDetails = new EventEmitter<UntypedFormGroup>();
  @Output() targetDbDetails = new EventEmitter<UntypedFormGroup>();
  SelectedTenant:any;
  SelectedVariableGroup:any;
  public sub:Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if(this.target != this.form.get("target").value.Code){
      this.loadDbDetails()
    }
  }

  // private loadTenantInfo(tenant) {
  //   this.loaderService.start();
  //   this.SelectedTenant = tenant;
  //   this.adminToolService.getTenantEnvironmentsByTenantIdProd(tenant.Id).pipe(
  //     switchMap((te) => {
  //       const es = this.adminToolService.getEnvironmentServicesProd(te[0]?.EnvironmentId);
  //       return forkJoin([es, of(te[0])])
  //     }),
  //     switchMap((es) => this.findStudentWebService(es)),
  //     switchMap((s) => this.getDbConnectionInfo(s[0], tenant, s[1]))
  //   )
  //     .subscribe(
  //       {
  //         next: (result) => {
  //           const dbInfo = result[0];
  //           this.SelectedVariableGroup = dbInfo;
  //           this.parseSource(this.SelectedVariableGroup)

  //         },
  //         error: (error) => {
  //           console.error(error);
  //           this.loaderService.stop();
  //         },
  //         complete: () => {
  //           this.loadDbDetails()
  //         }
  //       }
  //     );
  // }

  // private findStudentWebService(es: any[]): Observable<any> {
  //   const studentWeb = es[0]?.find(s => s.ServiceId === 4);
  //   return of([studentWeb, es[1]]);
  // }

  // private getDbConnectionInfo(es, tenant, tes): Observable<any[]> {
  //   const query = {
  //     'ServiceId': 4,   // Student Web
  //     'EnvironmentServiceId': es.Id,
  //     'TenantEnvironmentId': tes.Id,
  //   }

  //   return this.adminToolService.getDbConfigurationProd(query)
  // }

  private ExtractDetailsFromServer(value) {
    const server = value.server;

    if (!server) {
      return null;
    }

    const env = server?.substring(0, 4);
    const environmentType = this.envNameMappings.find(m => m.shortName === env)?.fullName;
    const region = this.getRegion(server);
    const instance = server?.split(',')[0]?.split('.')[0]?.split('-')[0].slice(-2);
    const subscription = value.subscription;

    return {
      'Server': server,
      'EnvironmentType': environmentType,
      'Region': region,
      'Instance': instance,
      'Subscription': subscription,
    };
  }

  private getRegion(server: string) {
    if (server?.includes(Constants.REGION_US_EST)) {
      return Constants.REGION_US_EAST;
    } else if (server?.includes(Constants.REGION_US_WST)) {
      return Constants.REGION_US_WEST;
    } else if (server?.includes(Constants.REGION_CA_CANC)) {
      return Constants.REGION_CA_CENTRAL;
    } else {
      return Constants.REGION_US_EAST;
    }
  }

  private parseServer(val) {
    const result = this.ExtractDetailsFromServer(val);

    if (!result) {
      return;
    }
    this.form.controls.TargetSqlServer.setValue(result.Server)
    this.form.controls.TargetEnvironmentType.setValue(result.EnvironmentType)
    this.form.controls.TargetRegion.setValue(result.Region)
    this.form.controls.TargetInstance.setValue(result.Instance)
    this.form.controls.TargetSubscription.setValue(result.Subscription)
    this.form.controls.TargetTenantCode.setValue(this.form.get("target")?.value?.Code)
  }

  private loadDbDetails() {
    this.loaderService.start()
    this.target = this.form.get("target").value.Code
    this.targetDetails.emit(this.target);
    this.sub = this.customerEnvironmentService.getCustomerSqlServerDbs(this.target)
      .subscribe(
        {
          next: (result) => {
            this.parseServer(result);
            this.DbNames = result.databases
            this.targetDbDetails.emit(this.DbNames)
            this.loaderService.stop();
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
          }
        }
      );
  }
}
