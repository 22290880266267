import { Component} from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent extends BaseComponent {

  avatarImg: string;

  constructor() {
    super();
  }

  private loadAvatarImage() {
    this.userService.getUserProfile()
      .pipe(
        switchMap(p => this.userService.getUserDescriptor(p.id)),
        switchMap(desc => this.userService.getUserAvatar(desc.value)),
        switchMap(result => {
            this.avatarImg = `data:image/png;base64,${result.value}`;
            return of(this.avatarImg);
          }
        )
      ).subscribe(console.log);
  }
}
