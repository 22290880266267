<div class="row">
  <button
    kendoButton
    (click)="onGoBack()"
    themeColor="primary"
    class="col-1 ms-3"
  >
    Back
  </button>
</div>
<div class="row">
  <app-task-list *ngIf="tasks.length > 0"
                 [data]="tasks"
  ></app-task-list>
  <textarea *ngIf="errorLog"
            name="errorLog"
            rows="100"
            style="{height: auto}"
            disabled="true"
  >{{errorLog}}</textarea>
</div>
