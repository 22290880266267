<div class="container-fluid g-0">
  <div class="row g-0">
    <app-fhcm-customers
      class="col-4"
      (refreshClicked)="onRefreshClicked($event)"
      (customerSelectionChanged)="onCustomerSelectionChanged($event)"
    ></app-fhcm-customers>
    <app-fhcm-environments
      #environments
      [customer]="customer"
      class="col-8">
    </app-fhcm-environments>
  </div>
</div>
