<div class="container wrapper pt-2 row col-12 d-flex justify-content-center flex-wrap text-uppercase fs-5">
  PS Integration Status
</div>
<div>
  <kendo-grid
    [resizable]="true"
    [data]="statusesView"
    [pageable]="true"
    [filter]="state.filter"
    [sortable]="true"
    [filterable]="true"
    (dataStateChange)="dataStateChange($event)"
    [pageSize]="state.take"
    [skip]="state.skip"
  >
    <kendo-grid-column
      field="name"
      title="Name"
      [width]="200"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="customerId"
      title="CustomerId"
      [width]="70"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="environment"
      title="Environment"
      [width]="80"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="region"
      title="Region"
      [width]="50"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="instance"
      title="Instance"
      [width]="50"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="status"
      title="Status"
      [width]="80"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="date"
      title="Created Date"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="createdBy"
      title="Created By"
      [width]="130"
    >
    </kendo-grid-column>
    <kendo-grid-column
      [width]="60"
      [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton themeColor="primary"
                (click)="goToTaskDetails(dataItem)"
        >
          Details
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

