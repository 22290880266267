import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PsInfrastructure } from '../../../shared/models/psIntegration/PsInfrastructure';
import { PipelineMapping } from '../../../shared/models/PipelineMapping';
import { State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-ps-summary',
  templateUrl: './ps-summary.component.html',
  styleUrls: ['./ps-summary.component.scss']
})
export class PsSummaryComponent implements OnChanges {
  @Input() public infrastructure: PsInfrastructure;
  @Input() psIntegrationPipelines: PipelineMapping[];

  pipelinesView: PipelineMapping[];
  pipelineSelections: PipelineMapping[] = [];
  state: State = {
    skip: 0,
    take: 10,
  };

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pipelinesView = this.psIntegrationPipelines;
  }

  pipelinesSelectionChange($event: any) {
    this.pipelineSelections = $event.map(idx => {
      return this.psIntegrationPipelines.find(item => item.id === idx);
    });
  }
}
