import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { StepperComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-new-environment',
  templateUrl: './new-environment.component.html',
  styleUrls: ['./new-environment.component.scss']
})
export class NewEnvironmentComponent extends BaseComponent implements OnInit {
  private sub: Subscription;
  environment = '';
  showCustomerId = false;
  selectedProducts = [];

  public form = new UntypedFormGroup({
    environment: new UntypedFormControl(),
    environmentType: new UntypedFormControl(),
    region: new UntypedFormControl(),
    instance: new UntypedFormControl(),
    sqlInstance: new UntypedFormControl(),
    sqlApplicationID: new UntypedFormControl(),
    spnObjectID: new UntypedFormControl(),
    subscriptionID: new UntypedFormControl(),
    azureSubscriptionID: new UntypedFormControl(),
    customerID: new UntypedFormControl()
  });

  envCreateRequest = {
    environment: '',
    environmentType: '',
    region: '',
    instance: '',
    sqlInstance: '',
    sqlApplicationID: '',
    spnObjectID: '',
    subscriptionID: '',
    azureSubscriptionID: '',
    environmentCode: '',
    customerID: '',
    products: []
  };

  public currentStep = 0;

  @ViewChild('stepper', {static: true})
  public stepper: StepperComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.form.controls.sqlInstance.setValue('01');
  }

  public formEvent(details) {
    this.form = details;
  }

  public showCustomerIdEvent(details) {
    this.showCustomerId = details;
  }

  public productEvent(details) {
    this.selectedProducts = details;
  }

  public envCreateRequestEvent(details) {
    this.envCreateRequest = details;
  }

  public get currentGroup(): UntypedFormGroup {
    return this.getGroupAt(this.currentStep);
  }

  public next(): void {
    this.currentGroup.markAllAsTouched();
    this.stepper.validateSteps();

    if (this.currentGroup.valid && this.currentStep !== this.steps.length) {
      //this.setFormInStorage();
      this.currentStep += 1;
      this.steps[this.currentStep].disabled = false;
    }
  }

  public prev(): void {
    this.currentStep -= 1;
  }

  private getGroupAt(index: number): UntypedFormGroup {
    const groups = Object.keys(this.form.controls).map((groupName) =>
      this.form.get(groupName)
    ) as UntypedFormGroup[];

    return groups[index];
  }

  private shouldValidate = (index: number): boolean => {
    return this.getGroupAt(index).touched && this.currentStep >= index;
  };

  public steps = [
    {
      label: 'Environment Details',
      isValid: true,
      validate: this.shouldValidate,
    },
    {
      label: 'Instance Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Subscription Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Product Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Summary',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
  ];

  public isSubmitDisabled() {
    let flag = false;
    if (!(this.envCreateRequest.environment &&
      this.envCreateRequest.environmentType &&
      this.envCreateRequest.region &&
      this.envCreateRequest.instance &&
      this.envCreateRequest.sqlInstance &&
      this.envCreateRequest.sqlApplicationID &&
      this.envCreateRequest.spnObjectID &&
      this.envCreateRequest.subscriptionID &&
      this.envCreateRequest.azureSubscriptionID &&
      this.envCreateRequest.products.length > 0)) {
      flag = true;
    }
    return flag;
  }

  submit() {
    if (this.form.valid) {
      this.loaderService.start();
      this.sub = this.customerEnvironmentService.createEnvironmentVariableTemplateFile(this.envCreateRequest)
        .subscribe({
          next: (result) => {
            this.envCreateRequest.environmentCode = result.environmentCode;
            this.envCreateRequest.region = result.region;
            this.pipelineService.runNewEnvironmentPipelines(this.envCreateRequest);
            this.loaderService.stop();
            this.router.navigate([`/CustomerOnboardingStatus/${this.envCreateRequest.environmentCode}`]);
          },
          error: (error) => {
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
          }
        });
    }
  }
}
