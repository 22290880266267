import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LcsCustomer } from '../../shared/models/dynamics/LcsCustomer';

@Injectable({
  providedIn: 'root'
})
export class LcsCustomerService {

  private url = `${window.location.origin}/api/LcsCustomer`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
  ) { }



  getCustomers(): Observable<LcsCustomer[]> {
    return this.httpClient.get<LcsCustomer[]>(this.url)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomers'))
      );
  }

  getCustomerById(customerId: number): Observable<LcsCustomer> {
    return this.httpClient.get<LcsCustomer>(`${this.url}/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerById'))
      );
  }

  addCustomer(customer: LcsCustomer): Observable<LcsCustomer> {
    return this.httpClient.post<LcsCustomer>(this.url, customer)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addCustomer'))
      );
  }

  updateCustomer(id: number, customer: LcsCustomer): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/${id}`, customer)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateCustomer'))
      );
  }

  deleteCustomer(customer: LcsCustomer): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/${customer.id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteCustomer'))
      );
  }
}
