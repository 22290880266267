export class PsInfrastructure {
    public EnvironmentType: string;
    public Region: string;
    public Instance: string;
    public CustomerID: string;

    constructor() {
      this.EnvironmentType = null;
      this.Region = null;
      this.Instance = null;
      this.CustomerID = null;
    }
}


