<div class="row">
  <div class="form-group col-12">
    <kendo-label for="subscription">Subscription</kendo-label>
    <kendo-dropdownlist
      id="subscription"
      [(ngModel)]="infrastructure.Subscription"
      (selectionChange)="subscriptionSelectionChange($event)"
      [data]="subscriptions"
      [defaultItem]="null"
      textField="Name"
      valueField="Id"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>
<!--<div class="row">-->
<!--  <div class="form-group col-12">-->
<!--    <kendo-label for="tier">Tier</kendo-label>-->
<!--    <kendo-dropdownlist-->
<!--      id="tier"-->
<!--      [(ngModel)]="infrastructure.Tier"-->
<!--      [data]="tiers"-->
<!--      [defaultItem]="null"-->
<!--      textField="Name"-->
<!--      valueField="Id"-->
<!--      required-->
<!--    >-->
<!--    </kendo-dropdownlist>-->
<!--  </div>-->
<!--</div>-->
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="organization">Organization (OUPath)</kendo-label>
    <kendo-dropdownlist
      id="organization"
      [(ngModel)]="infrastructure.Organization"
      [data]="organizations"
      [defaultItem]="null"
      textField="Name"
      valueField="OUPath"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="applicationName">Application Name</kendo-label>
    <kendo-dropdownlist
      id="applicationName"
      [(ngModel)]="infrastructure.ApplicationName"
      [data]="applicationNames"
      [defaultItem]="null"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="requestor">Requestor</kendo-label>
    <kendo-textbox
      id="requestor"
      class="form-control"
      [readonly]="true"
      [(ngModel)]="infrastructure.Requestor"
      required
    >
    </kendo-textbox>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="environmentNumber">Environment Number</kendo-label>
    <kendo-textbox
      id="environmentNumber"
      class="form-control"
      [(ngModel)]="infrastructure.EnvironmentNumber"
      required
    >
    </kendo-textbox>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="subNetName">SubNet Name</kendo-label>
    <kendo-dropdownlist
      id="subNetName"
      [(ngModel)]="infrastructure.SubNetName"
      [data]="subNetNames"
      [defaultItem]="null"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="studentDatabase">Student Database</kendo-label>
    <kendo-dropdownlist
      id="studentDatabase"
      listHeight="500"
      [filterable]="true"
      (filterChange)="handleDatabaseFilter($event)"
      [(ngModel)]="infrastructure.StudentDatabase"
      [data]="studentDatabasesFiltered"
      [defaultItem]="null"
      textField="name"
      valueField="name"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="studentPortalDatabase">Student Portal Database</kendo-label>
    <kendo-dropdownlist
      id="studentPortalDatabase"
      listHeight="500"
      [filterable]="true"
      (filterChange)="handlePortalDatabaseFilter($event)"
      [(ngModel)]="infrastructure.StudentPortalDatabase"
      [data]="studentPortalDatabasesFiltered"
      [defaultItem]="null"
      textField="name"
      valueField="name"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="remark">Remark</kendo-label>
    <kendo-textbox
      id="remark"
      class="form-control"
      [(ngModel)]="infrastructure.Remark"
      required
    >
    </kendo-textbox>
  </div>
</div>
