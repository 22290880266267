<div class="w-75 px-4">
    <ng-container [formGroup]="customerDetails">
        <kendo-formfield>
            <kendo-label [for]="customerName" text="Customer Name: "></kendo-label>
            <input
              formControlName="customerName"
              kendoTextBox
              #customerName
              required
            />
            <kendo-formerror>Error: Customer Name is required and should not contain spaces!</kendo-formerror>
            <kendo-formerror>Please replace any existing spaces with '_' character</kendo-formerror>
          </kendo-formfield>
    
          <kendo-formfield>
              <kendo-label [for]="offeringType" text="Offering Type: "></kendo-label>
              <kendo-dropdownlist
              defaultItem="Select offering type..."
              #offeringType
              formControlName="offeringType"
              [data]="offeringTypes"
              [valuePrimitive]="true"
              required
            >
            </kendo-dropdownlist>
            </kendo-formfield>
    
            <kendo-formfield>
              <kendo-label [for]="region" text="Region: "></kendo-label>
              <kendo-dropdownlist
              defaultItem="Select region..."
              #region
              formControlName="region"
              [data]="regionTypes"
              [valuePrimitive]="true"
              required
            >
            </kendo-dropdownlist>
            </kendo-formfield>
    </ng-container>
</div>
