import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Config } from '../shared/models/Config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private http: HttpClient;
  private appConfig: Config;

  configUrl = '/assets/config/config.json';

  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

  loadAppConfig() {
    return this.http.get<Config>(this.configUrl)
      .toPromise()
      .then(data => {
        this.appConfig = data;
        return this.appConfig;
      });
  }

  get Organization() {
    this.verifyConfig();

    return this.appConfig.Organization;
  }

  get MainProject() {
    this.verifyConfig();

    return this.appConfig.MainProject;
  }

  get StudentProject() {
    this.verifyConfig();

    return this.appConfig.StudentProject;
  }

  get HelpLink() {
    this.verifyConfig();

    return this.appConfig.HelpLink;
  }

  get Pipelines() {
    this.verifyConfig();

    return this.appConfig.Pipelines;
  }

  get DbRefresh() {
    this.verifyConfig();

    return this.appConfig.DbRefresh;
  }

  get AzureAd() {
    this.verifyConfig();

    return this.appConfig.AzureAd;
  }

  get AppInsights() {
    this.verifyConfig();

    return this.appConfig.AppInsights;
  }

  private verifyConfig() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
  }
}
