import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Policy } from '../../shared/models/Policy';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';


@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  private url = `${window.location.origin}/api/policy`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService
  ) {
  }
  

  getPolicies(): Observable<Policy[]> {
    return this.httpClient.get<Policy[]>(this.url)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPolicies'))
      );
  }

  getPolicyById(policyId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/${policyId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPolicyById'))
      );
  }

  addPolicy(policy: Policy): Observable<Policy> {
    return this.httpClient.post<Policy>(this.url, policy)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addPolicy'))
      );
  }

  updatePolicy(id: number, policy: Policy): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/${id}`, policy)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updatePolicy'))
      );
  }

  deletePolicy(policy: Policy): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/${policy.id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deletePolicy'))
      );
  }

  getAllApiPolicies(): Observable<any> {

    return this.httpClient.get<any>(`${this.url}/ApiPolicy/All`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllApiPolicies'))
      );
  }

  getAllApiActions(): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/PageApiMapper/All`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllApiActions'))
      );
  }

  getAllPagesFromMapper(): Observable<any> {

    return this.httpClient.get<any>(`${this.url}/PageApiMapper/Pages`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllPagesFromMapper'))
      );
  }

  getAllActionsOfPages(page): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/PageApiMapper/${page}/Action`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllActionsOfPages'))
      );
  }

  addApiPolicies(policy): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/ApiPolicy/Add`,policy)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('AddApiPolicies'))
      );
  }

  editApiPolicies(policy): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/ApiPolicy/Update`,policy)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('EditApiPolicies'))
      );
  }

  deleteApiPolicy(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/ApiPolicy/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('DeleteApiPolicy'))
      );
  }

  addApiAction(row): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/PageApiMapper/Add`,row)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addApiAction'))
      );
  }

  editApiAction(row): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/PageApiMapper/Update`,row)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editApiAction'))
      );
  }

  deleteApiAction(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/PageApiMapper/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteApiAction'))
      );
  }

}
