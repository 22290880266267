<div class="container">
  <form class="k-form" [formGroup]="formGroup">
    <kendo-formfield class="form-group col-6">
      <kendo-label [for]="patToken">Azure Personal Access Token </kendo-label>
      <input
        #patToken
        formControlName="patToken"
        kendoTextBox
        type="password"
        required
      />
    </kendo-formfield>
  </form>
  <div class="row mt-4">
    <div class="col-3 text-center">
      <button kendoButton (click)="onTestToken()" primary="true">Test Token</button>
    </div>
    <div class="col-3 text-center">
      <button kendoButton (click)="onSaveToken()" primary="true">Save Token</button>
    </div>
  </div>
  <div class="row mt-4" *ngIf="testResult">
    <pre>{{testResult | json}}</pre>
  </div>
</div>
