<div class=" row d-flex justify-content-center flex-wrap p-4">
    <form class="k-form appUserForm" [formGroup]="appUserDetails">
        <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">Application User Details From Customer</legend>
        <div class="w-50">
            <kendo-formfield>
                <kendo-label [for]="studentAdminUser" text="Student Admin User: "></kendo-label>
                <input
                  formControlName="studentAdminUser"
                  kendoTextBox
                  #studentAdminUser
                  (input)="onValueChange($event)"
                />
        
              </kendo-formfield>
        
              <kendo-formfield>
                <kendo-label [for]="studentAdminPwd" text="Student Admin Password: "></kendo-label>
                <div>
                  <input
                  formControlName="studentAdminPwd"
                  kendoTextBox
                  type = "{{ showPassword ? 'text' : 'password' }}"
                  #studentAdminPwd
                  (input)="onValueChange($event)"
                />
                <kendo-icon name="eye" class="eye" (click)="togglePassword()"></kendo-icon>
                </div>
              </kendo-formfield>

              <div class="k-form-buttons k-buttons-end">
                <div>
                  <button
                  class="k-button"
                  kendoButton
                  themeColor="primary"
                  (click)="testCredentials()"
                >
                  Test
                </button>
                </div>
              <div *ngIf = "showEditField">
                <button
                class="k-button"
                kendoButton
                themeColor="primary"
                (click)="addCredentials()"
              >
                Add
              </button>
              </div>
              </div>
        </div>
        </fieldset>
    </form>
</div>
<!-- <div class="row d-flex justify-content-center flex-wrap p-4">
  <div class ="col-2">
    <button kendoButton (click)="backToOnboarding()" themeColor="secondary" icon="arrow">Back
      <kendo-icon name="arrow-chevron-left"></kendo-icon>
    </button>
  </div>
  <div class ="col-2">
    <button kendoButton (click)="next()" themeColor="primary"  icon="arrow">Next
      <kendo-icon name="arrow-chevron-right"></kendo-icon>
    </button>
  </div>
  </div> -->
