import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PipelineMapping } from 'src/app/shared/models/PipelineMapping';
import { Observable } from 'rxjs';
import { PipelineProductRequest } from 'src/app/shared/models/PipelineProductRequest';
import { AdminNamesMapping } from 'src/app/shared/models/AdminNamesMapping';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private url = `${window.location.origin}/api/ado/admin`;

  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private authService: AuthService,
    private errorHandling: ErrorHandlerService
  ) {
  }


  getPipelinesOfProduct(product: string): Observable<PipelineMapping[]> {
    return this.httpClient
      .get<PipelineMapping[]>(`${this.url}/product/${product}/pipelines`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelinesOfProduct'))
      );
  }

  mapPipelineToProduct(request: PipelineProductRequest): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/product/pipelines/add`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('mapPipelineToProduct'))
      );
  }

  deletePipelineeMappedToProduct(id: number): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/product/pipeline/delete/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deletePipelineMappedToProduct'))
      );
  }

  getNameMappingRecords(): Observable<AdminNamesMapping[]> {
    return this.httpClient
      .get<AdminNamesMapping[]>(`${this.url}/mapping/names`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getNameMappingRecords'))
      );
  }

  getNameMappingRecordsByType(type: string): Observable<AdminNamesMapping[]> {
    return this.httpClient
      .get<AdminNamesMapping[]>(`${this.url}/mapping/names/${type}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getNameMappingRecordsByType'))
      );
  }

  getDistinctNameMappingTypes(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/mapping/types/names`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDistinctNameMappingTypes'))
      );
  }

  getEnvSeriesMappings(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/mapping/types/envseries`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvSeriesMappings'))
      );
  }

  getDistinctEnvSeriesMappingsProd(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/mapping/types/envseries/distinct/prod`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDistinctEnvSeriesMappingsProd'))
      );
  }

  getDistinctEnvSeriesMappingsNonProd(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/mapping/types/envseries/distinct/nonprod`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDistinctEnvSeriesMappingsNonProd'))
      );
  }

  getDistinctEnvSeriesMappingsInternal(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/mapping/types/envseries/distinct/internal`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDistinctEnvSeriesMappingsInternal'))
      );
  }

  getDistinctEnvSeriesMappings(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/mapping/types/envseries/distinct/all`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDistinctEnvSeriesMappings'))
      );
  }

  getCustomerOnboardingOfOfferingType(offeringType): Observable<any> {

    return this.httpClient
      .get<any>(`${this.url}/CustomerOnboarding/mapping?offeringType=${offeringType}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerOnboardingOfOfferingType'))
      );
  }

  getPatternOfEnvSeries(series:string): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/mapping/types/envseries/pattern/${series}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPatternOfEnvSeries'))
      );
  }

  addNameMapping(request: AdminNamesMapping): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/mapping/add`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addNameMapping'))
      );
  }

  addEnvSeriesMapping(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/mapping/envseries/add`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addEnvSeriesMapping'))
      );
  }

  addCustomerOnboardingPipelineMapping(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/CustomerOnboarding/mapping`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addCustomerOnboardingPipelineMapping'))
      );
  }

  deleteNameMapping(id: number): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/mapping/delete/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteNameMapping'))
      );
  }

  deleteEnvSeriesMapping(id: number): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/mapping/envseries/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteNameMapping'))
      );
  }

  deleteCustomerOnboardingPipelineMapping(id: number,offeringType): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/CustomerOnboarding/mapping/${id}?offeringType=${offeringType}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteCustomerOnboardingPipelineMapping'))
      );
  }

  editCustomerOnboardingPipelineMapping(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/CustomerOnboarding/mapping`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editCustomerOnboardingPipelineMapping'))
      );
  }

  editEnvSeriesMapping(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/mapping/envseries/edit`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editEnvSeriesMapping'))
      );
  }

  editNameMapping(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/mapping/edit`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editNameMapping'))
      );
  }

  editPipelineToProduct(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/product/pipelines/edit`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('mapPipelineToProduct'))
      );
  }

  getCoreProducts(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.url}/coreProduct`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCoreProducts'))
      );
  }

  getCoreProductDistinctNames(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/coreProduct/products`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCoreProductDistinctNames'))
      );
  }

  getMappedCoreProducts(): Observable<any[]> {

    return this.httpClient
      .get<any[]>(`${this.url}/mappedCoreProducts`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMappedCoreProducts'))
      );
  }

  getMappedCoreProductsForUpgrades(forIntegration): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.url}/mappedCoreProducts/upgrades?forIntegration=${forIntegration}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMappedCoreProductsForUpgrades'))
      );
  }

  getMappedCnfiVersions(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/mappedCnfiVersions`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('mappedCnfiVersions'))
      );
  }

  getAddOnProductsOfCoreProduct(product,version): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.url}/mappedCoreProducts/addOnProducts?product=${product}&version=${encodeURIComponent(version)}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAddOnProductsOfCoreProduct'))
      );
  }

  getAddOnProductsOfCoreProductType(product,version,type): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.url}/mappedCoreProducts/addOnProducts/${type}?product=${product}&version=${encodeURIComponent(version)}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAddOnProductsOfCoreProductType'))
      );
  }

  getCoreProductVersions(product): Observable<any[]> {

    return this.httpClient
      .get<any[]>(`${this.url}/coreProduct/${product}/versions`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCoreProductDistinctNames'))
      );
  }

  addCoreProductMaping(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/coreProduct`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addCoreProductMaping'))
      );
  }

  editCoreProductMaping(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/coreProduct`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editCoreProductMaping'))
      );
  }

  deleteCoreProductMaping(id): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/coreProduct/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteCoreProductMaping'))
      );
  }

  getAddOnProducts(productId): Observable<string[]> {

    return this.httpClient
      .get<string[]>(`${this.url}/addOnProduct/${productId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAddOnProducts'))
      );
  }

  addAddOnProductMaping(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/addOnProduct`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addAddOnProductMaping'))
      );
  }

  editAddOnProductMaping(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/addOnProduct`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editAddOnProductMaping'))
      );
  }

  deleteAddOnProductMaping(id): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/addOnProduct/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteAddOnProductMaping'))
      );
  }

  getTenantPipelines(): Observable<any[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/tenantPipelines`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getTenantPipelines'))
      );
  }

  getallTenantPipelines(): Observable<any[]> {

    return this.httpClient
      .get<string[]>(`${this.url}/allTenantPipelines`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getTenantPipelines'))
      );
  }

  addTenantPipeline(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/tenantPipelines`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addTenantPipeline'))
      );
  }

  editTenantPipeline(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/tenantPipelines`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editTenantPipeline'))
      );
  }

  deleteTenantPipeline(id): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/tenantPipelines/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteTenantPipeline'))
      );
  }

  getallFeatureFlags(): Observable<any[]> {

    return this.httpClient
      .get<string[]>(`${this.url}/featureFlags`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getallFeatureFlags'))
      );
  }

  getFeatureFlagsOfPage(page): Observable<any[]> {
    return this.httpClient
      .get<string[]>(`${this.url}/featureFlags/${page}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getFeatureFlagsOfPage'))
      );
  }

  addFeatureFlags(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/featureFlags`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addFeatureFlags'))
      );
  }

  editFeatureFlags(request): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/featureFlags`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editFeatureFlags'))
      );
  }

  deleteFeatureFlags(id): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/featureFlags/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteFeatureFlags'))
      );
  }

  getfinanceIntegrationPipelines(): Observable<any[]> {

    return this.httpClient
      .get<string[]>(`${this.url}/FinanceIntegration/mapping`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getfinanceIntegrationPipelines'))
      );
  }

  addfinanceIntegrationPipeline(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/FinanceIntegration/mapping`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addfinanceIntegrationPipeline'))
      );
  }

  editfinanceIntegrationPipeline(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/FinanceIntegration/mapping`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editfinanceIntegrationPipeline'))
      );
  }

  deletefinanceIntegrationPipeline(id): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/FinanceIntegration/mapping/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deletefinanceIntegrationPipeline'))
      );
  }

  getMailboxManagerPipelines(type): Observable<any[]> {

    return this.httpClient
      .get<string[]>(`${this.url}/MailboxManager/mapping?type=${type}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMailboxManagerPipelines'))
      );
  }

  addMailboxManagerPipeline(request): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.url}/MailboxManager/mapping`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addMailboxManagerPipeline'))
      );
  }

  editMailboxManagerPipeline(request): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${this.url}/MailboxManager/mapping`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('editMailboxManagerPipeline'))
      );
  }

  deleteMailboxManagerPipeline(id): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${this.url}/MailboxManager/mapping/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteMailboxManagerPipeline'))
      );
  }
}
