import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BaseComponent } from '../../../../../components/base.component';
import { Observable, Subscription } from 'rxjs';
import { VariableGroup } from '../../../../models/VariableGroup';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DbSelectionChangeArg } from '../../../../../shared/models/DbSelectionChangeArg';
import { Constants } from '../../../../../shared/Constants';
import { switchMap } from 'rxjs/operators';
import { GetVariableValueByKey } from '../../../../../shared/utils/VariableGroupUtils';
import { AzRelease } from '../../../../models/AzRelease';
import { Release } from '../../../../../shared/models/Release';
import { DbRestoreConfirmationComponent } from '../../db-single-tenant-panel/db-restore-confirmation/db-restore-confirmation.component';
import { DialogAction } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-db-single-tenant-restore-from-file-panel',
  templateUrl: './db-single-tenant-restore-from-file-panel.component.html',
  styleUrls: ['./db-single-tenant-restore-from-file-panel.component.scss']
})
export class DbSingleTenantRestoreFromFilePanelComponent extends BaseComponent implements OnDestroy {

  private sub: Subscription;

  @Output() completed = new EventEmitter();

  private _dcrc: boolean = false;
  private _dropTarDb: boolean = false;

  private _customerId: string = '';
  private _targetBkpName: string = '';
  private _targetDb: string = '';
  private _targetEnv: string = '';
  private _targetServer: string = '';
  private _tgtMISubscription: string = '';

  private _selectedTargetVariableGroup: VariableGroup = null;

  public formGroup: UntypedFormGroup = this.fb.group({
    dcrc: [this._dcrc],
    dropTarDb: [this._dropTarDb],

    target: this.fb.group({
      CustomerId: [this._customerId, Validators.required],
      TargetBkpName: [this._targetBkpName, Validators.required],
      Db: [this._targetDb, Validators.required],
      Env: [this._targetEnv, Validators.required],
      Server: [this._targetServer, Validators.required],
      Subscription: [this._tgtMISubscription, Validators.required]
    })
  });

  public set dcrc(value: boolean) {
    this.formGroup.controls.dcrc.setValue(value);
    this._dcrc = value;
  }

  public get dcrc() {
    return this.formGroup.controls.dcrc.value;
  }

  public set dropTarDb(value: boolean) {
    this.formGroup.controls.dropTarDb.setValue(value);
    this._dropTarDb = value;
  }

  public set customerId(value: string) {
    this.formGroup.get('target.CustomerId').setValue(value);
    this._customerId = value;
  }

  public get customerId() {
    return this.formGroup.get('target.CustomerId').value;
  }

  public set targetBkpName(value: string) {
    this.formGroup.get('target.TargetBkpName').setValue(value);
    this._targetBkpName = value;
  }

  public get targetBkpName() {
    return this.formGroup.get('target.TargetBkpName').value;
  }

  public set targetDb(value: string) {
    this.formGroup.get('target.Db').setValue(value);
    this._targetDb = value;
  }

  public get targetDb() {
    return this.formGroup.get('target.Db').value;
  }

  public set targetEnv(value: string) {
    this.formGroup.get('target.Env').setValue(value);
    this._targetEnv = value;
  }

  public get targetEnv() {
    return this.formGroup.get('target.Env').value;
  }

  public set targetServer(value: string) {
    this.formGroup.get('target.Server').setValue(value);
    this._targetServer = value;
  }

  public get targetServer() {
    return this.formGroup.get('target.Server').value;
  }

  public set tgtMISubscription(value: string) {
    this.formGroup.get('target.Subscription').setValue(value);
    this._tgtMISubscription = value;
  }

  public get tgtMISubscription() {
    return this.formGroup.get('target.Subscription').value;
  }

  public set Source(val: DbSelectionChangeArg) {
  }

  public set Target(val: DbSelectionChangeArg) {
    this.parseTarget(val);
  }

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnDestroy(): void {
    if(this.sub){
      this.sub.unsubscribe();
    }
  }

  onStartDbRestore() {
    const dialogRef = this.dialogService.open({
      content: DbRestoreConfirmationComponent,
    });

    const instance = dialogRef.content.instance;
    instance.IsTargetOnly = true;

    instance.Target = {
      'CustomerId': this.customerId,
      'TargetBkpName': this.targetBkpName,
      'Db': this.targetDb,
      'Env': this.targetEnv,
      'Server': this.targetServer,
      'Subscription': this.tgtMISubscription,
    };

    this.sub = dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        this.startDbRestore();
      }
    });
  }

  private startDbRestore() {
    const variables = {
      'CustomerID': {
        'value': this.dcrc ? '1' : '0',
      },
      'DCRC': {
        'value': this.dcrc ? '1' : '0',
      },
      'DropTarDB': {
        'value': this.dropTarDb ? '1' : '0',
      },
      'TargetBkpName': {
        'value': this.targetBkpName,
      },
      'TargetDB': {
        'value': this.targetDb,
      },
      'TargetEnv': {
        'value': this.targetEnv,
      },
      'TargetServer': {
        'value': this.targetServer,
      },
      'TargetSubscription': {
        'value': this.tgtMISubscription,
      }
    };

    const releaseRequest = {
      'definitionId': +this.configService.Pipelines.DbRestoreFromBackupFileReleaseDefinitionId,
      'description': 'Started from DevOpsPortal',
      'artifacts': [],
      'isDraft': false,
      'reason': 'none',
      'manualEnvironments': null,
      'variables': variables
    };

    this.loaderService.start();
    this.sub = this.releaseService.createRelease(this.configService.MainProject, releaseRequest)
      .pipe(
        switchMap((re) => this.addReleaseToDb(re, variables))
      )
      .subscribe({
        next: (re) => {
          this.toastr.success(`DB restore pipeline was started successfully: ${re.name}!`, 'Success!')
            .onHidden.subscribe(() => {
            this.router.navigate([`${Constants.DB_RESTORE_LOGS}`])
              .then();
          });
          this.loaderService.stop();
          this.resetForm();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
          this.resetForm();
        }
      });
  }

  private resetForm() {
    this.dcrc = false;
    this.dropTarDb = false;

    this.targetBkpName = null;
    this.targetDb = null;
    this.targetEnv = null;
    this.targetServer = null;
    this.tgtMISubscription = null;

    this.completed.emit();
  }

  private parseTarget(target: DbSelectionChangeArg) {
    const vg = target.VariableGroup as VariableGroup;
    this._selectedTargetVariableGroup = vg;
    const data = vg?.variables;
    const env = target.Environment;

    if (!data) {
      return;
    }

    const customerId = GetVariableValueByKey(data, Constants.Customer_Id);
    const meshId = GetVariableValueByKey(data, Constants.Mesh_Id);
    const offering = GetVariableValueByKey(data, Constants.Offering);
    const timeZoneAbbreviated = GetVariableValueByKey(data, Constants.TimeZoneAbbreviated);
    const instance = GetVariableValueByKey(data, Constants.Instance);

    this.customerId = `${customerId}`;
    this.targetDb = `${customerId}-${env === 'prd' ? 'live' : env}`;
    this.targetEnv = `${env}`;
    this.targetServer = `${meshId}-${offering}-${timeZoneAbbreviated}-sqlmi-${instance}`;
    this.tgtMISubscription = DbSingleTenantRestoreFromFilePanelComponent.getSubscriptionFromServerName(this._targetServer);
  }

  private static getSubscriptionFromServerName(server: string) {
    const firstChar = server[0];

    const subscriptions = {
      '1': 'Customer-Mesh-prd-01',
      '3': 'Customer-Mesh-npd-01',
      '9': 'Dev-Mesh-01'
    };

    return subscriptions[firstChar] || 'Sales-Mesh-01';
  }

  disableStartDbRefresh() {
    return this.formGroup.status === 'INVALID';
  }

  private addReleaseToDb(re: AzRelease, variables: any): Observable<Release> {
    const user = this.msalService.instance.getActiveAccount().name;
    const release: Release = {
      'id': re.id,
      'batchId': Constants.DB_RESTORE_FROM_FILE,
      'name': `${user}`,
      'batch': undefined,
      'projectName': this.configService.MainProject,
      'customerId': null,
      'customerName': JSON.stringify(variables),
      'stage': null,
      'createdBy': user,
      'createdDate': new Date(),
      'modifiedBy': user,
      'modifiedDate': new Date(),
      'azBuild': undefined
    };
    return this.releaseDbService.createRelease(release);
  }
}
