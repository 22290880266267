import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription  } from 'rxjs';
import { process, State } from "@progress/kendo-data-query";
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-database-refresh-status',
  templateUrl: './database-refresh-status.component.html',
  styleUrls: ['./database-refresh-status.component.scss']
})
export class DatabaseRefreshStatusComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(public activatedRoute:ActivatedRoute) {
    super();
  }

  public title= "";
  onThisPage=false;
  statusData = null;
  private sub: Subscription;
  public gridData: GridDataResult;
  public searchTerm = "";
  public searchTermEntered = "";
  public searchClicked = false;
  public showDbRefreshRerunFeature: boolean = false;
  public state: State = {
    skip: 0,
    take: 10
    };

    public defaultState: State = {
      skip: 0,
      take: 10
      };

  page: number = 1;
  //public hubKey:Guid;

  @HostListener('window:beforeunload')
  async ngOnDestroy()
  {
    this.onThisPage=false;
    // if(this.hubKey){
    //   this.pipelineService.deleteCustomerOnboardingStatusHubPromise(this.hubKey);
    // }
    this.statusData=null;
  }


  ngOnInit(): void {
    this.statusData=null;

    this.searchTerm = this.activatedRoute.snapshot.paramMap.get('Id');
    var path = this.activatedRoute.snapshot.routeConfig.path
    this.isFeatureEnabled();
    this.search();
  }

  public isFeatureEnabled(){
    this.loaderService.start()
    this.sub = this.adminService.getFeatureFlagsOfPage(Constants.DBRefreshLogs)
    .subscribe({
      next: (result) => {
        result.forEach(element => {
          if(element.feature == Constants.DbRefreshRerun){
            this.showDbRefreshRerunFeature = element.enable
          }
        });
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
      }
    });
  }

    public processData(data){
      if(data){
        data.forEach(element => {
          if(element.status == "Queued" && element.buildId){
            element.status = "Awaiting Action"
          }
        });
        return data
      }
    }

    public pollData(){
      var pollPage = this.page
      if(!this.searchTermEntered){
        this.searchTermEntered = "all"
      }

      this.sub = this.pipelineService.pollMTDbRefreshStatus(this.searchTermEntered, this.page)
      .subscribe({
        next: async (result) => {
          if(this.searchTermEntered != ""  && this.onThisPage){

            if(pollPage == this.page && result.searchTerm == this.searchTermEntered && result.pagedResult.results.length > 0  && (this.searchTermEntered == "all" || result.pagedResult.results[0].source.includes(this.searchTermEntered) || result.pagedResult.results[0].target.includes(this.searchTermEntered)))
            {
              this.statusData = this.processData(result.pagedResult.results);
              if(result.searchTerm == this.searchTermEntered){
                this.gridData = process(this.statusData, this.defaultState);
                this.gridData.total = result.pagedResult.rowCount;
              }

              await new Promise(f => setTimeout(f, 3000));
              this.pollData();
            }
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
        }
      });
    }

    getParamValueQueryString( paramName,url ) {
      let paramValue;
      if (url.includes('?')) {
        const httpParams = new HttpParams({ fromString: url.split('?')[1] });
        paramValue = httpParams.get(paramName);
      }
      return paramValue;
    }

    public search() {
      this.onThisPage=true;
      this.page = 1;
      if(this.searchTermEntered != this.searchTerm){
        this.searchTermEntered = this.searchTerm;
        // if(this.hubKey){
        //   this.pipelineService.deleteCustomerOnboardingStatusHubPromise(this.hubKey);
        // }
        this.statusData=null;
        //this.hubKey = Guid.create();
        this.loaderService.start();
        this.startSearch();
      }else{
        this.searchClicked = true
      }

    }

    public searchLogs() {
      this.state.skip = 0
      this.onThisPage=true;
      this.page = 1;
      //this.state=this.defaultState
      if(this.searchTermEntered != this.searchTerm){
        this.searchTermEntered = this.searchTerm;
        // if(this.hubKey){
        //   this.pipelineService.deleteCustomerOnboardingStatusHubPromise(this.hubKey);
        // }
        this.statusData=null;
        //this.hubKey = Guid.create();
        this.loaderService.start();
        this.refresh();
      }
      else{
        this.searchClicked = true
      }

    }

    private startSearch = () => {
      if(!this.searchTermEntered){
        this.searchTermEntered = "all"
      }
      this.sub = this.pipelineService.getMTDbRefreshStatus(this.searchTermEntered, this.page)
      .subscribe({
        next: (result) => {
          this.statusData = this.processData(result.pagedResult.results);
          this.gridData = process(this.statusData, this.defaultState);
          this.gridData.total = result.pagedResult.rowCount;
          this.searchClicked = true
          this.loaderService.stop();
          this.pollData();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
    }

    public clear(){
      this.searchClicked=false;
      this.state.skip = 0;
      this.state.take = 10;
      //this.searchTermEntered = this.searchTerm = "";
      this.gridData = null;
      //this.onThisPage = false;
    }

    public refresh() {
      if(!this.searchTermEntered){
        this.searchTermEntered = "all"
      }
      this.sub = this.pipelineService.getMTDbRefreshStatus(this.searchTermEntered, this.page)
      .subscribe({
        next: (result) => {
          this.statusData = this.processData(result.pagedResult.results);
          this.gridData = process(this.statusData, this.defaultState);
          this.gridData.total = result.pagedResult.rowCount;
          this.searchClicked = true
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
    }

    public rowCallback(context: RowClassArgs) {
      if (context.dataItem.status == "Created" || context.dataItem.status == "succeeded" ||context.dataItem.status == "Script Executed" ||context.dataItem.status == "completed") {   // change this condition as you need
        return {
          created: true
        };
      }
      else if (context.dataItem.status == "Queued" || context.dataItem.status == "Not Started" || context.dataItem.status == "-") {   // change this condition as you need
        return {
          notstarted: true
        };
      }
      else if (context.dataItem.status == "In Progress" || context.dataItem.status == "Script Generated") {   // change this condition as you need
        return {
          inprogress: true
        };
      }
      else if (context.dataItem.status == "Awaiting Action") {   // change this condition as you need
        return {
          awaitingaction: true
        };
      }
      else if (!context.dataItem.status) {   // change this condition as you need
        return {
          notstarted: true
        };
      }
      else{   // change this condition as you need
        return {
          failed: true
        };
      }
    }

    public pageChange(e): void {
      this.state.skip = e.skip;
      this.page = (this.state.skip / this.state.take) + 1;
      this.loaderService.start();
      this.refresh()
    }

    goToTaskDetails(data){
      this.router.navigate([`${Constants.MTDbRefreshTasks}/${data.source}/${data.target}/${data.buildId}`])
      .then();
    }

    onKeyDown(pressedKey) {
      if (pressedKey.key==="Enter") {
        this.searchLogs();
      }
    }

    public reRunBuild(dataItem){
      var buildId = dataItem.buildId;
      this.loaderService.start()
      this.pipelineService.reRunBuild(this.configService.MainProject,buildId)
      .subscribe({
        next: (re) => {
          if(re){
            dataItem.buildId = re;
            this.addReleaseToDb(dataItem);
          }else{
            this.loaderService.stop()
          }
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
        }
      });
    }

    private addReleaseToDb(re){
      const user = this.msalService.instance.getActiveAccount().name;
      const release = {
        'id': re.buildId,
        'batchId': Constants.DB_REFRESH_MULTIPLE_TENANT,
        'name': `${user}`,
        'batch': undefined,
        'projectName': this.configService.MainProject,
        'customerId': null,
        'customerName': re.source+" "+re.target,
        'stage': null,
        'createdBy': user,
        'createdDate': new Date(),
        'modifiedBy': user,
        'modifiedDate': new Date(),
        'azBuild': undefined
      };
      this.releaseDbService.createRelease(release)
      .subscribe({
        next: async (result) => {
          this.page = 1;
          this.refresh()
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
        }
      });
    }

    public cancel(dataItem){
      var buildId = dataItem.buildId;
      this.loaderService.start()
      this.pipelineService.cancelBuild(buildId)
      .subscribe({
        next: (re) => {
          if(re){
            this.refresh()
          }else{
            this.loaderService.stop()
          }
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
        }
      });
    }

}
