import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-environment-customer-details',
  templateUrl: './environment-customer-details.component.html',
  styleUrls: ['./environment-customer-details.component.scss']
})
export class EnvironmentCustomerDetailsComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.GetSeriesMappings();
    this.customerId = this.customerEnvironmentDetails.get('customerId').value;
    this.showcheckbox = this.customerId != undefined && this.customerId != null && (this.ValidProdSeries(this.customerId) || this.ValidNonProdSeries(this.customerId) || this.ValidinternalSeries(this.customerId));
    if (this.customerId != undefined && this.customerId != null && (this.ValidNonProdSeries(this.customerId) || this.ValidinternalSeries(this.customerId))) {
      this.showAdd = true;
    }
  }

  private sub: Subscription;
  public prodSeries;
  public nonProdSeries;
  public internalSeries;
  @Input() public customerEnvironmentDetails: UntypedFormGroup;
  @Input() public checkboxes;
  @Input() public validCheckbox;
  @Input() public showError;
  @Output() newCustomerEnvironmentDetails = new EventEmitter<UntypedFormGroup>();
  @Output() newCheckboxes = new EventEmitter<any>();
  @Output() newValidCheckbox = new EventEmitter<any>();
  public showOnBoardForm = true;
  public showAppUserDetails = false;
  showCheckboxError = false;
  showTextbox = false;
  customerId: string;
  showcheckbox = false;
  showAdd = false;

  public ischeckboxvalid(checkbox) {
    this.customerId = this.customerEnvironmentDetails.get('customerId').value;
    return this.customerId != null && (checkbox.name === 'Production' && this.ValidProdSeries(this.customerId)) ||
      (checkbox.name === 'Non Prod' && this.ValidNonProdSeries(this.customerId));
  }

  public onValueChange(value: any) {
    this.customerId = this.customerEnvironmentDetails.get('customerId').value;
    this.validCheckbox = [];
    if (this.customerId != null && this.ValidProdSeries(this.customerId)) {
      this.showAdd = false;
      this.checkboxes[0].selected = true;
      this.checkboxes[1].selected = false;
      this.checkboxes[2].selected = false;
      this.checkboxes[3].selected = false;
      this.customerEnvironmentDetails.controls.Production.setValue(true);
      this.customerEnvironmentDetails.controls.Production.disable();
      this.customerEnvironmentDetails.controls.Tst.setValue(false);
      this.customerEnvironmentDetails.controls.Dev.setValue(false);
      this.customerEnvironmentDetails.controls.Uat.setValue(false);
      this.validCheckbox.push(this.checkboxes[0]);
    } else if (this.customerId != null && this.ValidNonProdSeries(this.customerId)) {
      this.showAdd = true;
      this.checkboxes[1].displayName = this.customerId + '-' + this.checkboxes[1].name;
      this.checkboxes[1].selected = true;
      this.checkboxes[2].displayName = this.customerId + '-' + this.checkboxes[2].name;
      this.checkboxes[2].selected = true;
      this.checkboxes[3].displayName = this.customerId + '-' + this.checkboxes[3].name;
      this.checkboxes[3].selected = true;
      this.checkboxes[0].selected = false;
      this.customerEnvironmentDetails.controls.Production.setValue(false);
      this.customerEnvironmentDetails.controls.Tst.setValue(true);
      this.customerEnvironmentDetails.controls.Dev.setValue(false);
      this.customerEnvironmentDetails.controls.Uat.setValue(false);
      this.validCheckbox.push(this.checkboxes[1]);
      this.validCheckbox.push(this.checkboxes[2]);
      this.validCheckbox.push(this.checkboxes[3]);
    } else if (this.customerId != null && this.ValidinternalSeries(this.customerId)) {
      this.showAdd = false;
      this.checkboxes[4].displayName = this.customerId;
      this.checkboxes[4].selected = true;
      this.checkboxes[1].selected = false;
      this.checkboxes[2].selected = false;
      this.checkboxes[3].selected = false;
      this.checkboxes[0].selected = false;
      this.customerEnvironmentDetails.controls.Default.setValue(true);
      this.customerEnvironmentDetails.controls.Default.disable();
      this.customerEnvironmentDetails.controls.Production.setValue(false);
      this.customerEnvironmentDetails.controls.Tst.setValue(false);
      this.customerEnvironmentDetails.controls.Dev.setValue(false);
      this.customerEnvironmentDetails.controls.Uat.setValue(false);
      this.validCheckbox.push(this.checkboxes[4]);
    }

    this.showcheckbox = this.customerId != null && (this.ValidProdSeries(this.customerId) || this.ValidNonProdSeries(this.customerId) || this.ValidinternalSeries(this.customerId));
    this.newCheckboxes.emit(this.checkboxes);
    this.newValidCheckbox.emit(this.validCheckbox);
  }

  public ShowTextbox() {
    this.showTextbox = true;
  }

  public Addcheckbox() {
    var newCheckbox = this.customerEnvironmentDetails.get('newCheckbox').value;

    if (newCheckbox != null && newCheckbox != '') {
      this.customerEnvironmentDetails.addControl(newCheckbox, new UntypedFormControl());
      let checkBoxToAdd = {
        'selected': true,
        'name': newCheckbox,
        'value': newCheckbox,
        'displayName': this.customerId + '-' + newCheckbox,
        'isDisabled': false
      };
      this.checkboxes.push(checkBoxToAdd);
      this.validCheckbox.push(checkBoxToAdd);
      this.showTextbox = false;
      this.customerEnvironmentDetails.get('newCheckbox').reset();
      var addedCheckBox = this.customerEnvironmentDetails.get(newCheckbox);
      addedCheckBox.patchValue(true);
      this.newCustomerEnvironmentDetails.emit(this.customerEnvironmentDetails);
      this.newCheckboxes.emit(this.checkboxes);
      this.newValidCheckbox.emit(this.validCheckbox);
    }
  }

  public GetProdSeriesMappings() {
    this.sub = this.adminService.getDistinctEnvSeriesMappingsProd()
      .subscribe({
        next: (result) => {
          this.prodSeries = result;
        },
        error: (error) => {

        },
        complete: () => {
        }

      });
  }

  public ValidProdSeries(customerId) {
    var flag = false;
    this.prodSeries?.forEach(element => {
      if (customerId.startsWith(element)) {
        flag = true;
      }
    });

    return flag;
  }

  public GetNonProdSeriesMappings() {
    this.sub = this.adminService.getDistinctEnvSeriesMappingsNonProd()
      .subscribe({
        next: (result) => {
          this.nonProdSeries = result;
        },
        error: (error) => {

        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public ValidNonProdSeries(customerId) {
    var flag = false;
    this.nonProdSeries?.forEach(element => {
      if (customerId.startsWith(element)) {
        flag = true;
      }
    });

    return flag;
  }

  public GetInternalSeriesMappings() {
    this.sub = this.adminService.getDistinctEnvSeriesMappingsInternal()
      .subscribe({
        next: (result) => {
          this.internalSeries = result;
        },
        error: (error) => {

        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public GetSeriesMappings() {
    this.sub = this.adminService.getDistinctEnvSeriesMappings()
      .subscribe({
        next: (result) => {
          this.internalSeries = result.internalSeries;
          this.prodSeries = result.prodSeries;
          this.nonProdSeries = result.nonProdSeries;
        },
        error: (error) => {

        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public ValidinternalSeries(customerId) {
    let flag = false;
    this.internalSeries?.forEach(element => {
      if (customerId.startsWith(element)) {
        flag = true;
      }
    });

    return flag;
  }
}
