import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { Subscription } from 'rxjs';
import { StepperComponent } from '@progress/kendo-angular-layout';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-mailbox-manager',
  templateUrl: './mailbox-manager.component.html',
  styleUrls: ['./mailbox-manager.component.scss']
})
export class MailboxManagerComponent extends BaseComponent {

  constructor() {
    super();
  }


  public checkboxes = [
    {'selected': false, 'name': 'Production', 'value': 'Prod', 'displayName': 'Production', 'isDisabled': true},
    {'selected': false, 'name': 'Tst', 'value': 'Tst', 'displayName': 'Tst', 'isDisabled': false},
    {'selected': false, 'name': 'Dev', 'value': 'Dev', 'displayName': 'Dev', 'isDisabled': false},
    {'selected': false, 'name': 'Uat', 'value': 'Uat', 'displayName': 'Uat', 'isDisabled': false},
    {'selected': false, 'name': 'Default', 'value': 'Prod', 'displayName': 'Default', 'isDisabled': false}
  ];


  private sub: Subscription;

  public currentStep = 0;

  @ViewChild('stepper', {static: true})
  public stepper: StepperComponent;

  mailboxManagerRequest = {
    tenantId: '',
    clientId: '',
    appIdUri: '',
    customerId: '',
    instance: '',
    environment: '',
    region: '',
    type: ''
  };

  private shouldValidate = (index: number): boolean => {
    return this.getGroupAt(index).touched && this.currentStep >= index;
  };

  public steps = [
    {
      label: 'Customer Details',
      isValid: true,
      validate: this.shouldValidate,
    },
    {
      label: 'AAD Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Summary',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    }
  ];

  public form = new UntypedFormGroup({
    customerId: new UntypedFormControl(),
    resourceUri: new UntypedFormControl(),
    tenantId: new UntypedFormControl(),
    appRegistrationId: new UntypedFormControl(),
    appRegistrationIdUri: new UntypedFormControl(),
    instance: new UntypedFormControl(),
    environment: new UntypedFormControl(),
    region: new UntypedFormControl(),
    custType: new UntypedFormControl()
  });

  public next(): void {
    this.stepper.validateSteps();

    if (this.currentStep !== this.steps.length) {
      this.currentStep += 1;
      this.steps[this.currentStep].disabled = false;
      return;
    }
  }

  public prev(): void {
    this.currentStep -= 1;
  }

  public submit(): void {
    this.getMailboxManagerRequest();
    this.SubmitData();
  }

  private getMailboxManagerRequest() {
    this.mailboxManagerRequest.tenantId = this.form.get('tenantId').value;
    this.mailboxManagerRequest.clientId = this.form.get('appRegistrationId').value;
    this.mailboxManagerRequest.appIdUri = this.form.get('appRegistrationIdUri').value;
    this.mailboxManagerRequest.customerId = this.form.get('customerId').value;
    this.mailboxManagerRequest.environment = this.form.get('environment').value;
    this.mailboxManagerRequest.instance = this.form.get('instance').value;
    this.mailboxManagerRequest.region = this.form.get('region').value;
    this.mailboxManagerRequest.type = this.form.get('custType').value;
  }

  private getGroupAt(index: number): UntypedFormGroup {
    const groups = Object.keys(this.form.controls).map((groupName) =>
      this.form.get(groupName)
    ) as UntypedFormGroup[];

    return groups[index];
  }

  public SubmitData() {
    const custType = this.form.get('custType').value;
    if (custType && custType == 'MultiTenant') {
      this.loaderService.start();
      this.RunMailboxManagerPipelines();
    } else if (custType && custType == 'CNC 1.X') {
      this.loaderService.start();
      this.createTemplateFile();
    }
  }

  public createTemplateFile() {
    this.sub = this.gitService.createMailboxManagerTemplate(this.mailboxManagerRequest)
      .subscribe({
        next: (result) => {
          this.RunMailboxManagerPipelines1X();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public isSubmitDisabled() {
    return !(
      this.form.get('tenantId').value &&
      this.form.get('appRegistrationId').value &&
      this.form.get('customerId').value &&
      this.form.get('environment').value &&
      this.form.get('instance').value &&
      this.form.get('region').value
    );
  }

  public async RunMailboxManagerPipelines() {
    const customerId = this.form.get('customerId').value;
    this.pipelineService.runMailboxManagerPipelines(this.mailboxManagerRequest);
    await new Promise(f => setTimeout(f, 1000));
    this.loaderService.stop();
    this.router.navigate([`/MailboxManagerStatus/${customerId}`]);
  }

  public async RunMailboxManagerPipelines1X() {
    const customerId = this.form.get('customerId').value;
    this.pipelineService.runMailboxManagerPipelines1x(this.mailboxManagerRequest);
    await new Promise(f => setTimeout(f, 1000));
    this.loaderService.stop();
    this.router.navigate([`/MailboxManagerStatus/${customerId}`]);
  }
}
