import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { BaseComponent } from '../components/base.component';
import { StepperComponent } from '@progress/kendo-angular-layout';
import { Subscription } from 'rxjs';
import { DialogAction } from '@progress/kendo-angular-dialog';
import { Constants } from '../shared/Constants';
import { AppRegistrationOverrideConfirmComponent } from './app-registration-override-confirm/app-registration-override-confirm.component';

@Component({
  selector: 'app-customer-onboarding',
  templateUrl: './customer-onboarding.component.html',
  styleUrls: ['./customer-onboarding.component.scss']
})
export class CustomerOnboardingComponent extends BaseComponent {

  constructor() {
    super();
  }

  customerOnboardingRequest = [];
  items: any;
  //"Enterprise" to be added in next iteration

  customerOnboarding = {
    customerName: '',
    offeringType: '',
    region: '',
    customerId: '',
    environments: '',
    environmentInstance: '',
    sqlInstance: '',
    graphAPIKey: '',
    syncAPIKey: ''
  };

  public checkboxes = [
    {'selected': false, 'name': 'Production', 'value': 'Prod', 'displayName': 'Production', 'isDisabled': true},
    {'selected': false, 'name': 'Tst', 'value': 'Tst', 'displayName': 'Tst', 'isDisabled': false},
    {'selected': false, 'name': 'Dev', 'value': 'Dev', 'displayName': 'Dev', 'isDisabled': false},
    {'selected': false, 'name': 'Uat', 'value': 'Uat', 'displayName': 'Uat', 'isDisabled': false},
    {'selected': false, 'name': 'Default', 'value': 'Prod', 'displayName': 'Default', 'isDisabled': false}
  ];

  public validCheckbox = [];

  private sub: Subscription;

  public currentStep = 0;
  public showEnvError = false;

  @ViewChild('stepper', {static: true})
  public stepper: StepperComponent;

  private isStepValid = (index: number): boolean => {
    return this.getGroupAt(index).valid && this.currentGroup.untouched;
  };

  private isEnvStepValid = (index: number): boolean => {
    let flag = false;
    this.validCheckbox.forEach(x => {
      if (x.selected) {
        flag = true;
        return;
      }
    });

    if (!flag) {
      this.getGroupAt(index).setErrors({'Error': 'No Environment selected! Customer Id should start with either prod series number or non prod series number'});
      this.showEnvError = true;
      return false;
    }

    return this.getGroupAt(index).valid && this.currentGroup.untouched;
  };

  private shouldValidate = (index: number): boolean => {
    return this.getGroupAt(index).touched && this.currentStep >= index;
  };

  private shouldDisable = (index: number): boolean => {
    return this.getGroupAt(index).valid;
  };

  public steps = [
    {
      label: 'Customer Details',
      isValid: true,
      validate: this.shouldValidate,
    },
    {
      label: 'Environment Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Instance Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Occupation Insight Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Summary',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'Aad User Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    {
      label: 'App User Details',
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
  ];

  public form = new UntypedFormGroup({
    customerDetails: new UntypedFormGroup({
      customerName: new UntypedFormControl('', this.validateEmail),
      offeringType: new UntypedFormControl(),
      region: new UntypedFormControl()
    }),
    customernvironmentDetails: new UntypedFormGroup({
      customerId: new UntypedFormControl(),
      Production: new UntypedFormControl(),
      Tst: new UntypedFormControl(),
      Dev: new UntypedFormControl(),
      Uat: new UntypedFormControl(),
      Default: new UntypedFormControl(),
      newCheckbox: new UntypedFormControl()
    }),
    instanceDetails: new UntypedFormGroup({
      envInstance: new UntypedFormControl(),
      sqlInstance: new UntypedFormControl()
    }),
    occupationInsightsForm: new UntypedFormGroup({
      graphAPIKey: new UntypedFormControl(),
      syncAPIKey: new UntypedFormControl()
    }),
    summaryPlaceholder: new UntypedFormGroup({}),
    aadUserDetails: new UntypedFormGroup({}),
    appUserDetails: new UntypedFormGroup({
      studentAdminUser: new UntypedFormControl(),
      studentAdminPwd: new UntypedFormControl()
    }),
  });

  public get currentGroup(): UntypedFormGroup {
    return this.getGroupAt(this.currentStep);
  }

  public next(): void {
    this.currentGroup.markAllAsTouched();
    this.stepper.validateSteps();

    if (this.currentGroup.valid && this.currentStep !== this.steps.length) {
      if (this.currentStep == 5) {
        var credentials = [];
        this.customerOnboardingRequest.forEach(request => {
          var env = (request.environment == 'Production') ? 'Prod' : request.environment;
          var currentEnv = env;
          var credential: {
            username: string,
            password: string,
            customerId: string,
            environment: string
          } = {
            username: request.apiUser,
            password: request.apiPassword,
            customerId: request.customerId,
            environment: currentEnv
          };

          credentials.push(credential);
        });

        this.loaderService.start();
        this.sub = this.appRegistrationService.addApiUserDetailsForAppRegstration(credentials)
          .subscribe({
            next: (result) => {
              this.loaderService.stop();
            },
            error: (error) => {
              this.loaderService.stop();
            },
            complete: () => {
              this.loaderService.stop();
              this.setFormInStorage();
              this.currentStep += 1;
              if (this.currentStep == 1) {
                this.loaderService.start();
              }
              this.steps[this.currentStep].disabled = false;
              return;
            }

          });
      } else {
        this.setFormInStorage();
        this.currentStep += 1;
        if (this.currentStep == 1) {
          this.loaderService.start();
        }
        this.steps[this.currentStep].disabled = false;
        return;
      }
    }

  }

  public prev(): void {
    this.currentStep -= 1;
  }

  public submit(): void {
    if (!this.currentGroup.valid) {
      this.currentGroup.markAllAsTouched();
      this.stepper.validateSteps();
    }

    if (this.form.valid) {
      this.SubmitData();
    }
  }

  private getGroupAt(index: number): UntypedFormGroup {
    const groups = Object.keys(this.form.controls).map((groupName) =>
      this.form.get(groupName)
    ) as UntypedFormGroup[];

    return groups[index];
  }

  public updateCustomerEnvironmentDetails(details) {
    this.form.controls.customernvironmentDetails.setValue(details);
  }

  public newCheckboxes(details) {
    this.checkboxes = details;
  }

  public newValidCheckbox(details) {
    this.validCheckbox = details;
  }

  public customerOnboardingRequestEmit(details) {
    this.customerOnboardingRequest = details;
  }

  public itemEmit(details) {
    this.items = details;
  }

  public SubmitData() {
    this.loaderService.start();
    var customerId = this.form.controls.customernvironmentDetails.get('customerId').value;
    this.sub = this.appRegistrationService.isAppRegistrationDetailsOverriden(customerId)
      .subscribe({
        next: (result) => {
          if (result != null && result.length > 0) {
            this.loaderService.stop();
            const dialogRef = this.dialogService.open({
              content: AppRegistrationOverrideConfirmComponent,
            });
            const instance = dialogRef.content.instance;
            instance.Changes = result;

            this.sub = dialogRef.result.subscribe((r) => {
              const result = r as DialogAction;
              if (result.themeColor === Constants.THEME_PRIMARY) {
                this.loaderService.start();
                this.createTemplateFiles();
              }
            });
          } else {
            this.createTemplateFiles();
          }
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
        }

      });
  }

  public createTemplateFiles() {
    this.sub = this.gitService.createCustomerVariableTemplateFiles(this.customerOnboardingRequest)
      .subscribe({
        next: (result) => {
          this.RunOnBoardingPipelines();
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }

      });
  }

  public isSubmitDisabled() {
    var flag = false;
    this.items.forEach(element => {
      if (element.appRegDetails == undefined || element.appRegDetails == null || element.appRegDetails.staffTenantId == null || element.appRegDetails.studentTenantId == null) {
        flag = true;
      }
    });
    return flag;
  }

  public RunOnBoardingPipelines() {
    var customerId = this.form.controls.customernvironmentDetails.get('customerId').value;
    this.pipelineService.runOnboardingPipelines(this.customerOnboardingRequest);
    this.loaderService.stop();
    this.router.navigate([`/CustomerOnboardingStatus/${customerId}`]);
  }

  public validateEmail(c: UntypedFormControl) {
    let EMAIL_REGEXP = /^\S+$/;

    return EMAIL_REGEXP.test(c.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  public setFormInStorage() {
    this.customerOnboarding.customerName = this.form.controls.customerDetails.get('customerName').value;
    this.customerOnboarding.offeringType = this.form.controls.customerDetails.get('offeringType').value;
    this.customerOnboarding.region = this.form.controls.customerDetails.get('region').value;
    this.customerOnboarding.customerId = this.form.controls.customernvironmentDetails.get('customerId').value;
    this.customerOnboarding.environments = '';
    this.validCheckbox.forEach(element => {
      if (this.form.controls.customernvironmentDetails.get(element.name).value) {
        this.customerOnboarding.environments = this.customerOnboarding.environments + element.displayName + ', ';
      }
    });
    if (this.customerOnboarding.environments != '') {
      this.customerOnboarding.environments = this.customerOnboarding.environments.substring(0, this.customerOnboarding.environments.length - 2);
    }

    this.customerOnboarding.environmentInstance = this.form.controls.instanceDetails.get('envInstance').value;
    this.customerOnboarding.sqlInstance = this.form.controls.instanceDetails.get('sqlInstance').value;
    this.customerOnboarding.graphAPIKey = this.form.controls.occupationInsightsForm.get('graphAPIKey').value;
    this.customerOnboarding.syncAPIKey = this.form.controls.occupationInsightsForm.get('syncAPIKey').value;
    localStorage.setItem('CustOnBoardingForm', JSON.stringify(this.customerOnboarding));
  }

}
