import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { DtlDeploymentFilterModel } from '../../shared/models/DtlDeploymentFilterModel';
import { DtlDeployment } from '../../shared/models/DtlDeployment';
import { DataStateChangeEvent, GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Constants } from '../../shared/Constants';

@Component({
  selector: 'app-dev-tests-lab-logs',
  templateUrl: './dev-tests-lab-logs.component.html',
  styleUrls: ['./dev-tests-lab-logs.component.scss']
})
export class DevTestsLabLogsComponent extends BaseComponent implements OnInit {
  deployments: DtlDeployment[];
  deploymentsView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadDevTestsLabLogs();
  }

  private loadDevTestsLabLogs() {
    this.loaderService.start();
    const filter = {} as DtlDeploymentFilterModel;
    this.dtlDeploymentService.getDtlDeployments(filter).subscribe({
      next: (result) => {
        this.deployments = result;
        this.deploymentsView = process(this.deployments, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.deploymentsView = process(this.deployments, this.state);
  }

  deploymentSelectionChanged(deployment: SelectionEvent) {
    this.router.navigate([`${Constants.DEV_TESTS_LAB_LOGS}/${Constants.DEV_TESTS_LAB_LOGS_DEPLOYMENT}`, deployment?.selectedRows[0]?.dataItem?.id])
      .then();
  }
}
