<div class="w-75 px-4">
    <ng-container [formGroup]="form">
        <div class="row">
            <kendo-formfield class="form-group col-4">
              <kendo-label
                [for]="scrubMail"
                class="k-checkbox-label label-width"
                text="Scrub Mail:"
              ></kendo-label>
              <input
                type="checkbox"
                name="scrubMail"
                value="true"
                #scrubMail
                formControlName="scrubMail"
                kendoCheckBox/>
            </kendo-formfield>
            <kendo-formfield class="form-group col-4">
              <kendo-label
                [for]="scrubCredit"
                class="k-checkbox-label label-width"
                text="Scrub Credit:"
              ></kendo-label>
              <input
                type="checkbox"
                name="scrubCredit"
                value="true"
                #scrubCredit
                formControlName="scrubCredit"
                kendoCheckBox/>
            </kendo-formfield>
            <kendo-formfield class="form-group col-4">
              <kendo-label
                [for]="purgeMail"
                class="k-checkbox-label label-width"
                text="Purge Mail:"
              ></kendo-label>
              <input
                type="checkbox"
                name="purgeMail"
                value="true"
                #purgeMail
                formControlName="purgeMail"
                kendoCheckBox/>
            </kendo-formfield>
          </div>
          <kendo-formfield >
            <kendo-label [for]="srcSqlServer">Source SQL Server:</kendo-label>
            <kendo-textbox
              #srcSqlServer
              id="srcSqlServer"
              formControlName="SqlServer"
              [readonly]="true"
              required
            >
            </kendo-textbox>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="dbName" text="Source DB Name: "></kendo-label>
            <kendo-dropdownlist
            defaultItem="Select Db Name..."
            #dbName
            formControlName="DbName"
            [data]="DbNames"
            [valuePrimitive]="true"
            required
          >
          </kendo-dropdownlist>
          </kendo-formfield>

    </ng-container>
</div>
