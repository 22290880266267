<div class="w-75 px-4">
    <ng-container [formGroup]="customerEnvironmentDetails">
      <div *ngIf="!editAppreg">
        <kendo-formfield>
          <div class="d-grid gap-3">
              <div class="p-2 bg-light border">
                <span><b>Tenant Id:</b> {{appRegDetails.tenantId}}</span>
              </div>
          
              <div class="p-2 bg-light border">
                  <span><b>Client Id:</b> {{appRegDetails.appRegistrationId}}</span>
          
              </div>
  
              <div class="p-2 bg-light border">
                <div>
                  <span><b>Client Secret:</b></span>   
                </div>
                <div>              
                    <input
                      [value]="clientSecret"
                      kendoTextBox
                      readonly
                      class="col-11"
                    />
                    <button kendoButton fillMode="clear" icon="lock" (click)="togglePassword()"></button>
                </div>
              </div>
  
              <div class="p-2 bg-light border">
                  <span><b>App Id Uri:</b> {{appRegDetails.appRegistrationIdUri}}</span>
          
              </div>
  
          </div>
          </kendo-formfield>

          <div class="pt-2" *ngIf="showEditAppReg">
            <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="editFinanceIntegrationAppReg()"
        >
            Edit Settings
        </button>
  
        </div>
      </div>
        <div class="d-grid gap-1" *ngIf = "editAppreg">

          <kendo-formfield orientation="horizontal" class="mt-0">
          <kendo-label [for]="TenantId" text="Tenant Id: "></kendo-label>
          <input
          formControlName="tenantId"
            kendoTextBox
            #TenantId
            required
          />
        </kendo-formfield>

          <kendo-formfield orientation="horizontal" class="mt-0">
          <kendo-label [for]="ClientId" text="Client Id: "></kendo-label>
          <input
          formControlName="appRegistrationId"
            kendoTextBox
            #ClientId
            required
          />
        </kendo-formfield>

          <kendo-formfield orientation="horizontal" class="mt-0">
          <kendo-label [for]="ClientSecret" text="Client Secret: "></kendo-label>
          <input
          formControlName="secretKey"
            kendoTextBox
            #ClientSecret
            required
          />
        </kendo-formfield>

        <kendo-formfield orientation="horizontal" class="mt-0">
          <kendo-label [for]="AppIdUri" text="App Id Uri: "></kendo-label>
          <input
          formControlName="appRegistrationIdUri"
            kendoTextBox
            #AppIdUri
            required
          />
        </kendo-formfield>

        <div class="pt-2">
          <button
          class="k-button"
          kendoButton
          themeColor="primary"
          (click)="updateFinanceIntegrationAppReg()"
      >
          Update Settings
      </button>

      </div>
      </div>
    </ng-container>
</div>