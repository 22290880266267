<kendo-grid
  [data]="usersView"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="true"
  [pageable]="true"
  [filterable]="true"
  (dataStateChange)="dataStateChange($event)"
  kendoGridSelectBy="Id"
  [selectable]="true"
  [style.height.%]="50"
>
  <kendo-grid-column
    field="displayName"
    title="Name"
    [width]="30"
    [minResizableWidth]="30"
  ></kendo-grid-column>
  <kendo-grid-column
    field="mail"
    title="Email"
    [width]="80"
    [minResizableWidth]="80"
  ></kendo-grid-column>
  <kendo-grid-column
    field="title"
    title="Title"
    [width]="20"
    [minResizableWidth]="20"
  ></kendo-grid-column>
</kendo-grid>
