import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdGroupPolicy } from '../../shared/models/AdGroupPolicy';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyService {
  private url = `${window.location.origin}/api/grouppolicy`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService) {
  }

  getGroupPolicies(): Observable<AdGroupPolicy[]> {
    return this.httpClient.get<AdGroupPolicy[]>(this.url)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getGroupPolicies'))
      );
  }

  addGroupPolicy(groupPolicy: AdGroupPolicy): Observable<AdGroupPolicy> {
    return this.httpClient.post<AdGroupPolicy>(this.url, groupPolicy)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addGroupPolicy'))
      );
  }

  updateGroupPolicy(id: number, groupPolicy: AdGroupPolicy): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/${id}`, groupPolicy)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateGroupPolicy'))
      );
  }

  deleteGroupPolicy(groupPolicy: AdGroupPolicy): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/${groupPolicy.id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteGroupPolicy'))
      );
  }
}
