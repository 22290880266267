import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-new-environment-product-details',
  templateUrl: './new-environment-product-details.component.html',
  styleUrls: ['./new-environment-product-details.component.scss']
})
export class NewEnvironmentProductDetailsComponent extends BaseComponent implements OnInit {

  private sub: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  @Input() public form: UntypedFormGroup;

  @Input() public environment: string;

  @Input() public selectedProducts:any;

  @Output() formOut = new EventEmitter<any>();
  @Output() productOut = new EventEmitter<any>();

  products= [];
  selectedProductIds: number[] = [];
  availableProducts=[];
  type = "";


  productSelectionChange($event: any[]) {
    this.selectedProducts = $event.map(idx => {
      return this.products.find(item => item.id === idx);
    });
    this.productOut.emit(this.selectedProducts)
  }

  public onValueChange(data){
    this.loaderService.start();
    data.version = data.selectedVersion.version;
      this.sub = this.adminService.getAddOnProducts(data.selectedVersion.id)
      .subscribe({
        next: (result) => {
          data.addOnProducts = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.productOut.emit(this.selectedProducts)
          this.loaderService.stop();
        }
      });
  }


  private loadData() {
    this.loaderService.start();
    this.sub = this.adminService.getMappedCoreProductsForUpgrades(false)
    .subscribe({
      next: (result) => {
        var environmentType= this.form.get("environmentType") != null?this.form.get("environmentType").value:"";
        this.products = result;
        this.availableProducts=[]
        this.products.forEach(product=>{
          var availableProduct = (JSON.parse(JSON.stringify(product)));
          availableProduct.versionDetails=[]
          product.versionDetails.forEach(version => {
            if(!(environmentType == "NonProdEnv" || environmentType == "ProdEnv")){
              availableProduct.versionDetails.push(version)
            }
            else if((environmentType == "NonProdEnv" || environmentType == "ProdEnv") && version.name.includes(Constants.GA)){
              availableProduct.versionDetails.push(version)
            }
          });
          this.availableProducts.push(availableProduct);
        })
        this.products = this.availableProducts
        if(environmentType == "NonProdEnv" || environmentType == "ProdEnv"){
          this.type=Constants.GA
        }
        else{
          this.type=Constants.Internal
        }
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    });

  }

}
