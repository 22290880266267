import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { switchMap } from 'rxjs/operators';
import { Value } from '../../models/Artifact';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import * as moment from 'moment';
import { PipelineRunRequest } from '../../models/PipelineRunRequest';
import { Constants } from '../../../shared/Constants';
import { Batch } from '../../../shared/models/Batch';
import { Release } from '../../../shared/models/Release';
import { EnvProductPipelineRunResponse } from '../../models/PipelineRunResponse';
import { BatchType } from '../../../shared/models/BatchType';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, RowArgs, SelectionEvent, CellClickEvent, SelectableSettings, RowClassArgs } from '@progress/kendo-angular-grid';
import { AtEnvironmentEx } from 'src/app/shared/models/AtEnvironmentEx';
import { AtTenantEx } from 'src/app/shared/models/AtTenantEx';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  styleUrls: ['./app-update.component.scss']
})
export class AppUpdateComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  constructor() {
    super();
  }

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true
  };

  currentEnvironment: AtEnvironmentEx = null;
  expandedEnvironment: AtEnvironmentEx = null;
  environments: AtEnvironmentEx[] = [];
  environmentsView: GridDataResult;
  selectedEnvironments: AtEnvironmentEx[] = [];
  selectedTenants: AtTenantEx[] = [];
  pipelines: any[] = [];
  selectedPipelines: any[] = [];

  public state: State = {
    skip: 0,
    take: 10,

    filter: {
      logic: 'and',
      filters: [],
    },
  };

  static isVersionInLess60Days(c: Value): boolean {
    const lastPublishedDate = c.versions.find(v => v.isLatest && v.isListed)?.publishDate;
    const lastDate = moment(lastPublishedDate);
    return lastDate.add(60, 'day').isAfter(moment());
  }

  static getMajorVersion(version: string): string {
    return version.split('.').slice(0, 3).join('.');
  }

  static getTenantCodes(environment: AtEnvironmentEx) {
    return environment.Tenants
      .filter(t => t.IsSelected)
      .map(tn => tn.Code)
      .join(', ');
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public environmentKey(context: RowArgs): string {
    return context.dataItem;
  }

  private loadData() {
    this.loaderService.start();
    this.sub = this.adminToolService.getEnvironments()
    .subscribe({
      next: (result) => {
        this.environments = result;
        this.environmentsView = process(this.environments, this.state);
        //this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });

    this.sub = this.adminService.getTenantPipelines()
    .subscribe({
      next: (result) => {
        this.pipelines = result;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    });

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.environmentsView = process(this.environments, this.state);
  }

  onStartAction() {
    this.loaderService.start();
    this.RunAllPipelines(this.selectedEnvironments, this.selectedPipelines)
      .pipe(
        switchMap(pls => this.createBatch(pls))
      )
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.clearSelections();
          this.toastr.success(`${result.length} pipelines requests started successfully!`, 'Success!')
            .onHidden.subscribe(() => {
            this.router.navigate([`${Constants.PIPELINES}`])
              .then();
          });
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`${error}`, 'Failure!');
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  private RunAllPipelines(environments: AtEnvironmentEx[], pipelines): Observable<EnvProductPipelineRunResponse[][]> {
    const observables: Observable<EnvProductPipelineRunResponse[]>[] = [];
    const requests: PipelineRunRequest[] = [];
    environments.forEach(en => {
      pipelines.forEach(pipeline => {
        const request: PipelineRunRequest = {
          'templateParameters': {
            'environmenttype': en.Code,
            'customertenants': AppUpdateComponent.getTenantCodes(en),
            'product': pipeline.name,
            'pipelineId': pipeline.id,
            'environmentObject': en,
            'productObject': pipeline
          }
        };
        requests.push(request);
      });
    });
        const result = this.pipelineService.runPipelinesOfTenants(this.configService.MainProject, requests)
        .pipe(
            switchMap(resp => {
              let epr: EnvProductPipelineRunResponse = null;
              const eprs: EnvProductPipelineRunResponse[] = [];
              resp?.forEach(res => {
                res.result.forEach(element => {
                  epr = {
                    Response: element,
                    Environment: res.environment,
                    ProductVersion: res.product
                  };
                  eprs.push(epr);
                });
              });
              return of(eprs);
            })
          );
          observables.push(result);
    return forkJoin(observables);
  }

  private clearSelections() {
    this.selectedPipelines = [];
    this.selectedEnvironments = [];
  }

  pipelineSelectionChange($event: any[]) {
    this.selectedPipelines = $event.map(idx => {
      return this.pipelines.find(item => item.id === idx);
    });
  }

  disableStartButton(): boolean {
    return this.selectedEnvironments.length <= 0 ||
      this.selectedPipelines.length <= 0;
  }

  createBatch(responses: EnvProductPipelineRunResponse[][]): Observable<Release[]> {
    const consolidatedRes: EnvProductPipelineRunResponse[] = [];
    responses.forEach(res => {
      res.forEach(r => {
        consolidatedRes.push(r);
      });
    });
    const batch = new Batch();
    batch.name = `${this.AccountInfo?.name}-${moment().format()}`;
    batch.type = BatchType.Pipeline;
    batch.projectName = this.configService.MainProject;
    batch.createdBy = this.AccountInfo?.name;
    batch.createdDate = new Date();
    batch.modifiedBy = this.AccountInfo?.name;
    batch.modifiedDate = new Date();

    return this.batchService.createBatchForMTUpgrades(batch)
      .pipe(
        switchMap(b => this.addAllReleasesToDb(consolidatedRes, b))
      );
  }

  private addAllReleasesToDb(responses: EnvProductPipelineRunResponse[], batch: Batch): Observable<Release[]> {
    const observables: Observable<Release>[] = [];

    responses.forEach(r => {
      observables.push(this.addReleaseToDb(r, batch));
    });

    return forkJoin(observables);
  }

  private addReleaseToDb(response: EnvProductPipelineRunResponse, batch: Batch): Observable<Release> {
    response.ProductVersion.name = response.ProductVersion.name.replace('_', '')
    const release: Release = {
      'id': response.Response.id,
      'batchId': batch.id,
      'name': `${response.Environment.Name}_${response.ProductVersion.name}_${response.ProductVersion.id}`,
      'batch': undefined,
      'projectName': this.configService.MainProject,
      'customerId': AppUpdateComponent.getTenantCodes(response.Environment),
      'customerName': `${response.Environment.Name}_${response.ProductVersion.name}_${response.ProductVersion.id}`,
      'stage': null,
      'createdBy': this.AccountInfo?.name,
      'createdDate': new Date(),
      'modifiedBy': this.AccountInfo?.name,
      'modifiedDate': new Date(),
      'azBuild': undefined
    };
    return this.releaseDbService.createRelease(release);
  }

  cellClick($event: CellClickEvent) {
    this.currentEnvironment = $event.dataItem;
  }

  public onExpand(event){
    this.expandedEnvironment = event.dataItem;
    if (this.expandedEnvironment.Tenants) {
      return;
    }

    this.loaderService.start();

    this.adminToolService.getEnvironmentTenants(this.expandedEnvironment)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.expandedEnvironment.Tenants = result.flat();
          this.filterTenantsOfProdEnvironment(this.expandedEnvironment);
          //this.selectedTenants.push.apply(this.selectedTenants, this.expandedEnvironment.Tenants);
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });

    return;
  }

  selectedRowChangeEnvironment($event: SelectionEvent) {
    if ($event.selectedRows.length <= 0) {
      this.currentEnvironment = null;
      if ($event.deselectedRows.length > 0) {
        $event.deselectedRows[0].dataItem.Tenants.forEach(element => {
          this.selectedTenants = this.selectedTenants.filter(item => item.Code != element.Code);
        });

        return;
      }
      return;
    }

    // if ($event.deselectedRows.length > 0) {
    //   this.currentEnvironment = null;
    //   return;
    // }

    this.currentEnvironment = $event.selectedRows[0].dataItem;

    if (this.currentEnvironment.Tenants) {
      this.currentEnvironment.Tenants.forEach(element => {
        this.selectedTenants = this.selectedTenants.filter(item => item.Code != element.Code);
      });
      this.selectedTenants.push.apply(this.selectedTenants, this.currentEnvironment.Tenants);
      return;
    }

    this.loaderService.start();

    this.adminToolService.getEnvironmentTenants(this.currentEnvironment)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.currentEnvironment.Tenants = result.flat();
          this.filterTenantsOfProdEnvironment(this.currentEnvironment);
          this.selectedTenants.push.apply(this.selectedTenants, this.currentEnvironment.Tenants);
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  filterTenantsOfProdEnvironment(environment){
    var tempTenants:AtTenantEx[]=[];
    environment.Tenants.forEach(t=>{
      var isConfigured= false;
      t.TenantEnvironments.forEach(element => {
        if(element.EnvironmentId == environment.Id){
          isConfigured=element.IsConfigured
        }
      });
      if(!(t.Code.includes(Constants.OBSOLETE)) && isConfigured){
        tempTenants.push(t);
      }
    })
    environment.Tenants=tempTenants
  }

  public rowCallback(context: RowClassArgs) {
    if (context.dataItem.AdminToolEnv == Constants.ADMIN_TOOL_ENV_PROD && context.dataItem.Code.startsWith(Constants.NON_PROD_CODE)) {   // change this condition as you need
      return {
        highlight: true
      };
    }

  }
}
