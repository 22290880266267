import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { Release } from '../../shared/models/Release';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { ReleaseFilterModel } from '../../shared/models/ReleaseFilterModel';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class ReleaseDbService {

  private url = `${window.location.origin}/api/release`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService) {
  }


  createRelease(release: Release): Observable<Release> {
    return this.httpClient.post<Release>(this.url, release)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createRelease'))
      );
  }

  getReleases(): Observable<Release[]> {
    return this.httpClient.get<Release[]>(this.url)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getReleases'))
      );
  }

  getDbRefreshReleases(filter: ReleaseFilterModel): Observable<Release[]> {

    return this.httpClient
      .get<Release[]>(`${this.url}?BatchId=${filter.batchId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDbRefreshReleases'))
      );
  }
}
