import { Constants } from './shared/Constants';

export const Items: any[] = [
  {
    text: Constants.DEPLOYMENTS_UPGRADES,
    icon: 'k-i-grid',
    path: ``,
    id: 0
  },
  {
    text: Constants.MULTI_RELEASES,
    icon: 'k-i-grid',
    path: ``,
    id: 1,
    parentId: 0
  },
  {
    text: Constants.MULTI_TENANT_MR,
    icon: 'k-i-grid',
    path: '/app-update',
    id: 2,
    parentId: 1
  },
  {
    text: Constants.MT_LOGS,
    icon: 'k-i-graph',
    path: `/${Constants.PIPELINES}`,
    id: 3,
    parentId: 2
  },
  {
    text: Constants.MT_UPGRADE,
    icon: 'k-i-grid',
    path: ``,
    id: 4
  },
  {
    text: Constants.PRODUCT_UPGRADE,
    icon: 'k-i-grid',
    path: `/app-upgrade`,
    id: 5,
    parentId: 4
  },
  {
    text: Constants.CNF_UPGRADE,
    icon: 'k-i-grid',
    path: `/cnf-upgrade`,
    id: 6,
    parentId: 4
  },
  {
    text: Constants.PIPELINES_LOG,
    icon: 'k-i-graph',
    path: '/Pipelines',
    id: 7,
    parentId: 4
  },
  {
    text: Constants.NEW_ENVIRONMENTS,
    icon: 'k-i-grid',
    path: ``,
    id: 8
  },
  {
    text: Constants.DEV_TESTS_LAB,
    icon: 'k-i-grid',
    path: `/${Constants.DEV_TESTS_LAB}`,
    id: 9,
    parentId: 8
  },
  {
    text: Constants.DEV_TESTS_LAB_LOGS,
    icon: 'k-i-graph',
    path: `/${Constants.DEV_TESTS_LAB_LOGS}`,
    id: 10,
    parentId: 9
  },
    {
    text: Constants.MULTITENANT,
    icon: 'k-i-grid',
    path: ``,
    id: 12,
    parentId: 8
  },
  {
    text: Constants.NEW_ENVIRONMENT,
    icon: 'k-i-grid',
    path: `/${Constants.NEW_ENVIRONMENT_PATH}`,
    id: 13,
    parentId: 12
  },
  {
    text: Constants.EnvironmentStatusText,
    icon: 'k-i-grid',
    path: `/${Constants.MultiTenantEnvironmentStatus}`,
    id: 14,
    parentId: 13
  },
  {
    text: Constants.CustomerOnboardingText,
    icon: 'k-i-grid',
    path: `/${Constants.CustomerOnboarding}`,
    id: 15,
    parentId: 12
  },
  {
    text: Constants.CustomerOnboardingStatusText,
    icon: 'k-i-grid',
    path: `/${Constants.CustomerOnboardingStatus}`,
    id: 16,
    parentId: 15
  },
  {
    text: Constants.Integration,
    icon: 'k-i-grid',
    path: ``,
    id: 17,
    parentId: 12
  },
  {
    text: Constants.FinanceIntegration,
    icon: 'k-i-grid',
    path: `/${Constants.Integration}`,
    id: 18,
    parentId: 17
  },
  {
    text: Constants.FinanceIntegrationStatusName,
    icon: 'k-i-grid',
    path: `/${Constants.FinanceIntegrationStatus}`,
    id: 19,
    parentId: 17
  },
  {
    text: Constants.ProfessionalIntegration,
    icon: 'k-i-grid',
    path: `/${Constants.ProfessionalIntegration}`,
    id: 1008,
    parentId: 17
  },
  {
    text: Constants.ProfessionalIntegration_Status,
    icon: 'k-i-grid',
    path: `/${Constants.ProfessionalIntegration_Status}`,
    id: 1009,
    parentId: 17
  },
  {
    text: Constants.Mailbox,
    icon: 'k-i-grid',
    path: ``,
    id: 20,
    parentId: 12
  },
  {
    text: Constants.MailboxManagerLabel,
    icon: 'k-i-grid',
    path: `/${Constants.MailboxManager}`,
    id: 21,
    parentId: 20
  },
  {
    text: Constants.MailboxManagerStatusLabel,
    icon: 'k-i-grid',
    path: `/${Constants.MailboxManagerStatus}`,
    id: 22,
    parentId: 20
  },
  {
    text: Constants.APP_REGISTRATION,
    icon: 'k-i-grid',
    path: ``,
    id: 23,
    parentId: 12
  },
  {
    text: Constants.STUDENT_APP_REGISTRATION,
    icon: 'k-i-grid',
    path: `/${Constants.App_Registration_Script}`,
    id: 24,
    parentId: 23
  },
  {
    text: Constants.MAILBOX_APP_REGISTRATION,
    icon: 'k-i-grid',
    path: `/${Constants.Mailbox_App_Registration_Script}`,
    id: 25,
    parentId: 23
  },
  {
    text: Constants.INTEGRATION_APP_REGISTRATION,
    icon: 'k-i-grid',
    path: `/${Constants.Integration_App_Registration_Script}`,
    id: 26,
    parentId: 23
  },
  {
    text: Constants.TEMPLATE_FILE_NAME,
    icon: 'k-i-grid',
    path: ``,
    id: 27,
    parentId: 12
  },
  {
    text: Constants.UPDATE_TEMPLATE_FILE_NAME,
    icon: 'k-i-grid',
    path: `/${Constants.UPDATE_TEMPLATE_FILE_PATH}`,
    id: 28,
    parentId: 27
  },
  {
    text: Constants.UPDATE_TEMPLATE_LOG_FILE_NAME,
    icon: 'k-i-grid',
    path: `/${Constants.UPDATE_TEMPLATE_LOG_FILE_PATH}`,
    id: 29,
    parentId: 27
  },
  {
    text: Constants.Environments_Details,
    icon: 'k-i-grid',
    path: ``,
    id: 30
  },
  {
    text: Constants.SIS_Environments,
    icon: 'k-i-grid',
    path: `/${Constants.Environments_List}`,
    id: 31,
    parentId: 30
  },
  {
    text: Constants.FHCM_ENVIRONMENTS,
    icon: 'k-i-grid',
    path: `/${Constants.FhcmEnvironments}`,
    id: 32,
    parentId: 30
  },
  {
    text: Constants.DEV_TESTS_LAB_ENVS,
    icon: 'k-i-graph',
    path: `/${Constants.DEV_TESTS_LAB_ENVS}`,
    id: 11,
    parentId: 30
  },
  {
    text: Constants.DATA_MANAGEMENT,
    icon: 'k-i-grid',
    path: ``,
    id: 33
  },
  {
    text: Constants.DB_REFRESH,
    icon: 'k-i-grid',
    path: `/${Constants.DB_REFRESH}`,
    id: 34,
    parentId: 33
  },
  {
    text: Constants.DB_REFRESH_LOGS,
    icon: 'k-i-graph',
    path: `/${Constants.MTDbRefresh}`,
    id: 35,
    parentId: 34
  },

  {
    text: Constants.DB_RESTORE,
    icon: 'k-i-grid',
    path: `/${Constants.DB_RESTORE}`,
    id: 36,
    parentId: 33
  },
  {
    text: Constants.DB_RESTORE_LOGS,
    icon: 'k-i-graph',
    path: `/${Constants.DB_RESTORE_LOGS}`,
    id: 37,
    parentId: 36
  },
  {
    text: Constants.ADMIN,
    icon: 'k-i-grid',
    path: ``,
    id: 38
  },
  {
    text: Constants.PERMISSION,
    icon: 'k-i-grid',
    path: `/${Constants.PERMISSION}`,
    id: 39,
    parentId: 38
  },
  {
    text: Constants.MAPPER,
    icon: 'k-i-data',
    path: ``,
    id: 40,
    parentId: 38
  },
  {
    text: Constants.PIPELINE_NAME_MAPPING_NAME,
    icon: 'k-i-data',
    path: `/${Constants.MAPPER}`,
    id: 41,
    parentId: 40
  },
  {
    text: Constants.PRODUCT_VERSION_MAPPING_NAME,
    icon: 'k-i-data',
    path: `/${Constants.PRODUCT_VERSION_MAPPING_PATH}`,
    id: 42,
    parentId: 40
  },
  {
    text: Constants.POLICY_MAPPING_NAME,
    icon: 'k-i-data',
    path: `/${Constants.POLICY_MAPPING_PATH}`,
    id: 43,
    parentId: 40
  },
  {
    text: Constants.Inventory,
    icon: 'k-i-data',
    path: ``,
    id: 49,
    parentId: 38
  },
  {
    text: Constants.MailboxCustomerDetails,
    icon: 'k-i-data',
    path: `/${Constants.MAILBOX_CUSTOMER_PATH}`,
    id: 51,
    parentId: 49
  },
  {
    text: Constants.CncCustomerDetails,
    icon: 'k-i-data',
    path: `/${Constants.CNC_CUSTOMER_DETAILS_PATH}`,
    selected: false,
    expanded: true,
    id: 52,
    parentId: 49
  },
  {
    text: Constants.DBTableRefresh,
    icon: 'k-i-data',
    path: `/${Constants.EnvironmentTableRefreshPath}`,
    id: 44,
    parentId: 38
  },
  {
    text: Constants.AppRegistrationTable,
    icon: 'k-i-data',
    path: `/${Constants.AppRegistrationTable}`,
    id: 45,
    parentId: 38
  },
  {
    text: Constants.Tools,
    icon: 'k-i-grid',
    path: `/${Constants.AuditLogPath}`,
    id: 46,
    parentId: 38
  },
  {
    text: Constants.AuditLog,
    icon: 'k-i-grid',
    path: `/${Constants.AuditLogPath}`,
    id: 47,
    parentId: 46
  },
  {
    text: Constants.MT_FEATURE_NAME,
    icon: 'k-i-data',
    path: `/${Constants.MT_FEATURE_PATH}`,
    id: 48,
    parentId: 38
  }
];
