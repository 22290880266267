import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { Subscription } from 'rxjs';
import { DataSourceFieldComponent } from '../data-source-field/data-source-field.component';
import { DbMultiTenantPanelComponent } from '../db-multi-tenant-panel/db-multi-tenant-panel.component';
import { Constants } from '../../../../shared/Constants';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DbSelectionChangeArg } from '../../../../shared/models/DbSelectionChangeArg';
import { DbSingleTenantRestorePanelComponent } from './db-single-tenant-restore-panel/db-single-tenant-restore-panel.component';

@Component({
  selector: 'app-db-restore',
  templateUrl: './db-restore.component.html',
  styleUrls: ['./db-restore.component.scss']
})
export class DbRestoreComponent extends BaseComponent implements OnInit, OnDestroy {

  private sub: Subscription;

  @ViewChild('customerSource') customerSource: DataSourceFieldComponent;
  @ViewChild('customerTarget') customerTarget: DataSourceFieldComponent;

  @ViewChild('singleTenantPanel') singleTenantPanel: DbSingleTenantRestorePanelComponent;
  @ViewChild('multiTenantPanel') multiTenantPanel: DbMultiTenantPanelComponent;

  isSingleTenant: boolean = false;

  private _platform: string;
  private _restoreType: string = 'FromFile';
  private _source: string;
  private _target: string;
  private _sourceEnvironment: string;
  private _targetEnvironment: string;

  public platforms: string[] = [
    Constants.PLATFORM_SINGLE_TENANT,
    Constants.PLATFORM_MULTIPLE_TENANT
  ];

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    platform: new UntypedFormControl(this.platform),
    restoreType: new UntypedFormControl(this.restoreType),
    source: new UntypedFormControl(this.source),
    target: new UntypedFormControl(this.target),
    sourceEnvironment: new UntypedFormControl(this.sourceEnvironment),
    targetEnvironment: new UntypedFormControl(this.targetEnvironment)
  });

  @Input()
  public set platform(value: string) {
    this.formGroup.controls.platform.setValue(value);
    this._platform = value;
  }

  public get platform(): string {
    return this._platform;
  }

  @Input()
  public set restoreType(value: string) {
    this.formGroup.controls.restoreType.setValue(value);
    this._restoreType = value;
  }

  public get restoreType(): string {
    return this._restoreType;
  }

  get platformControl(): UntypedFormControl {
    return this.formGroup.get('platform') as UntypedFormControl;
  }

  get restoreTypeControl(): UntypedFormControl {
    return this.formGroup.get('restoreType') as UntypedFormControl;
  }

  @Input()
  public set source(value: string) {
    this.formGroup.controls.source.setValue(value);
    this._source = value;
  }

  public get source(): string {
    return this._source;
  }

  @Input()
  public set target(value: string) {
    this.formGroup.controls.target.setValue(value);
    this._target = value;
  }

  public get target(): string {
    return this._target;
  }

  @Input()
  public set sourceEnvironment(value: string) {
    this.formGroup.controls.sourceEnvironment.setValue(value);
    this._sourceEnvironment = value;
  }

  public get sourceEnvironment(): string {
    return this._sourceEnvironment;
  }

  @Input()
  public set targetEnvironment(value: string) {
    this.formGroup.controls.targetEnvironment.setValue(value);
    this._targetEnvironment = value;
  }

  public get targetEnvironment(): string {
    return this._targetEnvironment;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribePlatformSelectionChanges();
    this.subscribeRestoreTypeSelectionChanges();
  }

  private subscribePlatformSelectionChanges() {
    this.platformControl.valueChanges.subscribe(val => {
      if (this.customerSource) {
        this.customerSource.platform = val;
      }
      this.customerTarget.platform = val;
    });
  }

  private subscribeRestoreTypeSelectionChanges() {
    this.restoreTypeControl.valueChanges.subscribe(val => {
      this.singleTenantPanel.restoreType = val;
      const platform = this.formGroup.controls['platform']?.value;
      if (this.customerSource) {
        this.customerSource.platform = platform;
      }
      this.customerTarget.platform = platform;
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onSourceSelectionChange($event: DbSelectionChangeArg) {
    if (this.singleTenantPanel) {
      this.singleTenantPanel.Source = $event;
    }
    if (this.multiTenantPanel) {
      this.multiTenantPanel.Source = $event;
    }
  }

  onTargetSelectionChange($event: DbSelectionChangeArg) {
    if (this.singleTenantPanel) {
      this.singleTenantPanel.Target = $event;
    }
    if (this.multiTenantPanel) {
      this.multiTenantPanel.Target = $event;
    }
  }

  onCompleted() {
    this.customerSource.resetForm();
    this.customerTarget.resetForm();
  }

}
