import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(
    private appInsightsService: AppInsightsService,
    private http: HttpClient,
    @Inject(Injector) private injector: Injector) {
    super();
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get ToastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  /**
   * Default error handler called by global
   * @param error
   */
  handleError(error: Error) {
    this.appInsightsService.logException(error);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result.
   */
  handleAllErrors<T>(operation = 'operation', result?: T) {
    return (err: any): Observable<T> => {
      // TO-DO: send the error to remote logging infrastructure
      console.error(err); // log to console instead
      const {status, error, message} = err;
      const ErrorMessage = this.getErrorMessage(error);

      const defaultNotFoundMessage = 'Record Not Found.';

      switch (status) {
        case HttpStatusCode.Unauthorized:
          this.ToastrService.warning('Unauthorized User!', 'Warning');
          break;
        case HttpStatusCode.Forbidden:
          this.ToastrService.warning('Module not authorized for the user!', 'Warning');
          break;
        case HttpStatusCode.BadRequest:
          if (!ErrorMessage) {
            break;
          }
          this.ToastrService.warning(ErrorMessage, 'Error');
          break;
        case HttpStatusCode.NotFound:
          this.ToastrService.warning(ErrorMessage ? ErrorMessage : defaultNotFoundMessage, 'Warning');
          break;
        case HttpStatusCode.InternalServerError:
          this.ToastrService.warning(ErrorMessage, 'Error');
          break;
        case HttpStatusCode.MethodNotAllowed:
          this.ToastrService.warning(ErrorMessage ? ErrorMessage : defaultNotFoundMessage, 'Warning');
          break;
        default:
          //this.ToastrService.warning(ErrorMessage, 'Error');
          break;
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getErrorMessage(err: any) {
    if (err?.ErrorExceptionMessage) {
      return err?.ErrorExceptionMessage;
    } else if (err?.ErrorMessage) {
      return err?.ErrorMessage;
    } else if (err?.message) {
      return err?.message;
    } else if (err?.Message) {
      return err?.Message;
    }  else {
      return;
    }
  }
}
