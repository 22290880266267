import { Component as DtoComponent } from 'src/app/shared/models/Component';
import { Artifact } from '../../deployments/models/Artifact';

export class ProductVersion {
    id: number;
    name: string = null;
    version: string = null;
    versions: string[] = [];
}

export class ComponentArtifact {
    component: DtoComponent;
    artifact: Artifact;
}


