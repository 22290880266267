import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Item } from '../models/RepoItem';

@Component({
  selector: 'app-shared-resource',
  templateUrl: './shared-resource.component.html',
  styleUrls: ['./shared-resource.component.scss']
})
export class SharedResourceComponent extends BaseComponent implements OnInit {
  yamls: Item[] = [];
  filteredYamls: Item[];
  selectedYaml: Item;

  envNames: string[] = [];
  selectedEnvName = '';

  regions: string[] = [];
  selectedRegion = '';

  instances: string[] = [];
  selectedInstance = '';

  constructor() {
    super();
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    yamlName: new UntypedFormControl(this.yamlName),
    envName: new UntypedFormControl(this.envName),
    region: new UntypedFormControl(this.region),
    instance: new UntypedFormControl(this.instance),
  });

  private _yamlName: string;

  public get yamlName(): string {
    return this._yamlName;
  }

  private _envName: string;

  public get envName(): string {
    return this._envName;
  }

  private _region: string;

  public get region(): string {
    return this._region;
  }

  private _instance: string;

  public get instance(): string {
    return this._instance;
  }

  ngOnInit(): void {
    this.loadData();
  }

  onStartAction() {
    console.log(this.formGroup.controls.yamlName.value);
    console.log(this.formGroup.controls.envName.value);
    console.log(this.formGroup.controls.region.value);
    console.log(this.formGroup.controls.instance.value);
  }

  private loadData() {
    this.repositoryService.getAllSharedYamls(this.configService.MainProject)
      .subscribe(y => {
        this.yamls = y.value.filter(f => !f.isFolder && ['.yml', 'yaml'].includes(f.path.slice(-4)));
        this.filteredYamls = this.yamls;
      });
  }

  handleYamlsFilter($event: any) {
    this.filteredYamls = this.yamls.filter(
      (p) => p.path.toLowerCase().indexOf($event.toLowerCase()) !== -1
    );
  }

  yamlSelectionChange($event: any) {
    this.selectedYaml = $event;
    this.selectedEnvName = '';
    this.loadEnvNames(this.selectedYaml);
  }

  private loadEnvNames(selectedYaml: Item) {
    this.repositoryService.getPipelineYamlByPath(this.configService.MainProject, selectedYaml.path)
      .subscribe(y => {
        this.envNames = y.parameters[0].values;
        this.regions = y.parameters[1].values;
        this.instances = ['01', '02', '03'];
      });
  }
}
