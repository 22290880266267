import { Component, OnDestroy, OnInit } from '@angular/core';
import { Release } from 'src/app/shared/models/Release';
import { BaseComponent } from '../../../../components/base.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-batch-detail',
  templateUrl: './batch-detail.component.html',
  styleUrls: ['./batch-detail.component.scss']
})
export class BatchDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  releases: Release[] = [];
  private sub: Subscription;

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeChanges();
  }

  private subscribeChanges() {
    this.sub = this.dataService.Batch.subscribe(b => {
      this.releases = b.releases;
    });
  }
}
