import { Component, Input, OnInit } from '@angular/core';
import { Infrastructure } from '../../../shared/models/devtestslab/Infrastructure';
import { BlobItem } from '../../../shared/models/BlobItem';
import { BaseComponent } from '../../../components/base.component';

@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss']
})
export class InfrastructureComponent extends BaseComponent implements OnInit {
  @Input() public infrastructure: Infrastructure;

  subscriptions = [
    {
      'Id': 'a568c9a5-187f-43e1-b97c-77bd07f0538b',
      'Name': '900119-Steve_Kinney_CMI_Engineering',
      'StorageAccount': '900119dtlsharedsa'
    },
    {
      'Id': '1d8a5a02-af5d-469d-abb2-fee1ad380df2',
      'Name': '900120-Shivakumar_G_Deployment_Services',
      'StorageAccount': "900120dtlsharedsa"
    },
    {
      'Id': 'ac8c348c-bffe-4e8a-a2b8-99a5b04648e9',
      'Name': '900121-Chris_Konces_Development_Cloud',
      'StorageAccount': "900121dtlsharedsa"
    },
    {
      'Id': '6d87c39b-e6f1-4e9e-a5d3-2c127d099e92',
      'Name': '900123-Raymond_Blackwood_Product_Management',
      'StorageAccount': "900123dtlsharedsa"
    },
    {
      'Id': 'a675d608-f3e7-4971-aedd-55b995ab0754',
      'Name': '900124-Arun_Varadhan_Professional_Services_Integrations',
      'StorageAccount': "900124dtlsharedsa"
    },
    {
      'Id': 'bd2971bb-4c62-47b1-b8f4-6e541011e24f',
      'Name': '900118-Tony_Fano_Support_Envts',
      'StorageAccount': '900118dtlsharedsa'
    },
    {
      'Id': '4b9aca5d-a63f-4a7f-bd4a-994e1c8430af',
      'Name': '900141-Eric-Wellnitz-Architecture',
      'StorageAccount': '900141dtlsharedsa'
    },
  ];
  tiers = [
    {
      'Id': '1',
      'Name': 'Tier 1'
    },
    {
      'Id': '2',
      'Name': 'Tier 2 - EST'
    },
    {
      'Id': '3',
      'Name': 'Tier 2 - IST'
    },
    {
      'Id': '4',
      'Name': 'Tier 3 - EST'
    },
    {
      'Id': '5',
      'Name': 'Tier 3 - IST'
    },
  ];
  organizations = [
    {
      'Name': 'Client Services Cloud',
      'OUPath': 'OU=Client Services Cloud,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'CMI Engineering DEV',
      'OUPath': 'OU=CMI Engineering DEV,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'CMI Engineering QA',
      'OUPath': 'OU=CMI Engineering QA,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'CMI Engineering GDC',
      'OUPath': 'OU=CMI Engineering GDC,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'Development',
      'OUPath': 'OU=Development,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'Deployment Services',
      'OUPath': 'OU=Deployment Services,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'Product Development',
      'OUPath': 'OU=Product Development,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'Product Management',
      'OUPath': 'OU=Product Management,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'Professional Services Cloud',
      'OUPath': 'OU=Professional Services Cloud,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
    {
      'Name': 'Client Services TFR',
      'OUPath': 'OU=Client Services TFR,OU=Servers,OU=Custom,DC=Int,DC=CampusNexus,DC=Dev'
    },
  ];
  applicationNames = [
    'SIS',
    'DTL'
  ];
  subNetNames: string[] = [];
  studentDatabases: BlobItem[] = [];
  studentDatabasesFiltered: BlobItem[] = [];
  studentPortalDatabases: BlobItem[] = [];
  studentPortalDatabasesFiltered: BlobItem[] = [];

  constructor() {
    super();
  }

  private populateDatabaseBackups(accountName: string) {
    this.loaderService.start();
    this.storageAccountService.geDatabaseBackups(accountName)
      .subscribe({
        next: (result) => {
          this.studentDatabases = result;
          this.studentDatabasesFiltered = result;

          const portalDatabase = result;

          // NO-DATABASE selection item if no Portal Database
          portalDatabase.unshift({name: 'NO-PORTAL'} as BlobItem);

          this.studentPortalDatabases = portalDatabase;
          this.studentPortalDatabasesFiltered = portalDatabase;
          this.loaderService.stop();

          this.clearSelections();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  private populateNextEnvironmentNumber() {
    this.loaderService.start();
    this.devTestsLabService.getNextEnvironmentNumberFromTag()
      .subscribe({
        next: (result) => {
          this.infrastructure.EnvironmentNumber = result + '';
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  private populateSubnets(subscriptionId: string) {
    this.loaderService.start();
    this.devTestsLabService.getSubnets(subscriptionId)
      .subscribe({
        next: (result) => {
          this.subNetNames = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  private clearSelections() {
    this.infrastructure.StudentDatabase = null;
    this.infrastructure.StudentPortalDatabase = null;
  }

  handleDatabaseFilter($event) {
    this.studentDatabasesFiltered = this.studentDatabases.filter(
      (d) => d.name.toLowerCase().indexOf($event.toLowerCase()) !== -1
    );
  }

  handlePortalDatabaseFilter($event) {
    this.studentPortalDatabasesFiltered = this.studentPortalDatabases.filter(
      (d) => d.name.toLowerCase().indexOf($event.toLowerCase()) !== -1
    );
  }

  subscriptionSelectionChange($event: any) {
    this.populateDatabaseBackups($event.StorageAccount);
    this.populateSubnets($event.Id);
  }

  ngOnInit(): void {
    this.infrastructure.Requestor = this.AccountInfo?.name;
    this.populateNextEnvironmentNumber();
  }
}
