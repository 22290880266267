import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { EnvironmentMetaData } from '../../shared/models/dynamics/EnvironmentMetaData';
import { LcsEnvironment } from '../../shared/models/dynamics/LcsEnvironment';
import { Subscription } from 'rxjs';
import { LcsCustomer } from '../../shared/models/dynamics/LcsCustomer';

@Component({
  selector: 'app-fhcm-environments',
  templateUrl: './fhcm-environments.component.html',
  styleUrls: ['./fhcm-environments.component.scss']
})
export class FhcmEnvironmentsComponent extends BaseComponent {
  private sub: Subscription;

  @Input()
  public customer: LcsCustomer;

  lcsEnvironments: LcsEnvironment[];
  lcsEnvironmentsView: GridDataResult;

  environments: EnvironmentMetaData[];

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  public loadAllEnvironments(customerId: number) {
    this.loaderService.start();
    this.sub = this.lcsEnvironmentService.getEnvironments(customerId).subscribe({
      next: (result) => {
        this.lcsEnvironments = result;
        this.lcsEnvironmentsView = process(this.lcsEnvironments, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.lcsEnvironmentsView = process(this.lcsEnvironments, this.state);
  }

  RefreshCustomerEnvironments(customer: LcsCustomer) {
    this.loaderService.start();
    return this.dynamicsService.refreshAllEnvironments(customer.projectId, customer.id)
      .subscribe({
        next: () => {
          this.loadAllEnvironments(customer.id);
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loadAllEnvironments(customer.id);
          this.loaderService.stop();
        }
      });
  }
}
