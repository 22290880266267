import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { DtlDeploymentItem } from '../../shared/models/DtlDeploymentItem';
import { DtlDeploymentItemFilterModel } from '../../shared/models/DtlDeploymentItemFilterModel';

@Injectable({
  providedIn: 'root'
})
export class DtlDeploymentItemService {
  private url = `${window.location.origin}/api/DtlDeploymentItem`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService) {
  }

  createDtlDeploymentItem(deploymentItem: DtlDeploymentItem): Observable<DtlDeploymentItem> {
    return this.httpClient
      .post(this.url, deploymentItem)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createDtlDeploymentItem'))
      );
  }

  getDtlDeploymentItems(filter: DtlDeploymentItemFilterModel): Observable<DtlDeploymentItem[]> {

    return this.httpClient
      .get<DtlDeploymentItem[]>(`${this.url}?DeploymentId=${filter.deploymentId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getDtlDeploymentItems'))
      );
  }

  updateDtlDeploymentItem(id: number, deploymentItem: DtlDeploymentItem): Observable<DtlDeploymentItem> {
    return this.httpClient
      .put<DtlDeploymentItem>(`${this.url}/${id}`, deploymentItem)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateDtlDeploymentItem'))
      );
  }
}
