<kendo-tilelayout
  [columns]="5"
  [resizable]="false"
  [reorderable]="false"
  [gap]="0"
  id="pipeline-batch"
>

  <kendo-tilelayout-item [col]="1" [colSpan]="5">
    <kendo-tilelayout-item-body>
      <kendo-appbar positionMode="sticky" style="padding: 20px;">
        <kendo-appbar-section>
        </kendo-appbar-section>
        <kendo-appbar-section>
          <button
          kendoButton
          (click)="backToAllLogs()"
          themeColor="primary"
        >
          Back To Logs
        </button>
        </kendo-appbar-section>
      </kendo-appbar>

    </kendo-tilelayout-item-body>
    <kendo-tilelayout-item-body>
      <kendo-grid
        [data]="releasesView"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [filter]="state.filter"
        [sortable]="true"
        [pageable]="true"
        [filterable]="true"
        kendoGridSelectBy="id"
        [selectedKeys]="selectedReleaseIds"
        [selectable]="true"
        (dataStateChange)="dataStateChange($event)"
        [style.height.%]="100"
      >
        <kendo-grid-checkbox-column
          showSelectAll="true"
          [width]="15"
          [minResizableWidth]="15"
          [headerStyle]="{'text-align': 'center'}"
          [style]="{'text-align': 'center'}"
        ></kendo-grid-checkbox-column>
        <kendo-grid-column
          field="id"
          title="Id"
          [width]="20"
          [minResizableWidth]="20"
          [filterable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <a [href]="dataItem.azBuild._links.web.href" target="_blank">{{dataItem.id}}</a>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="name"
          title="Name"
          [width]="100"
          [minResizableWidth]="100"
        ></kendo-grid-column>
        <kendo-grid-column
          title="Status"
          [width]="20"
          [minResizableWidth]="20"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.azBuild.status}}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="1" [colSpan]="5">
    <kendo-tilelayout-item-body>
      <kendo-appbar positionMode="sticky" style="padding: 20px;">
        <kendo-appbar-section>
        </kendo-appbar-section>
        <kendo-appbar-spacer></kendo-appbar-spacer>
        <kendo-appbar-section>
          <button
            kendoButton
            (click)="onCancelAction()"
            themeColor="primary"
            [disabled]="disableCancelButton()"
          >
            Cancel Pipelines
          </button>
        </kendo-appbar-section>
      </kendo-appbar>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
