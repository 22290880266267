import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-environment-table-refresh',
  templateUrl: './environment-table-refresh.component.html',
  styleUrls: ['./environment-table-refresh.component.scss']
})
export class EnvironmentTableRefreshComponent extends BaseComponent {
  private sub: Subscription;
  public refreshed:boolean = false;
  public updated:boolean = false;
  public pipelineIds= [];
  public azureDomain= Constants.AZURE_DOMAIN;
  public cloudServiceProject = Constants.CLOUD_SERVICE_PROJECT;
  public studentProject = Constants.STUDENT_PROJECT;
  public pipelinesOrg = Constants.SYNC_PIPELINES_ORG;
  public cnc1PipelineId = Constants.CNC1_PIPELINE_ID;
  constructor() {
    super();
  }

  public refresh(){
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.refreshCustomerEnvironmentRecords()
    .subscribe({
      next: (result) => {
        this.loaderService.stop();
        this.refreshed = true;
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  public updateProductVersion(){
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.updateProductEnvironmentDetails()
    .subscribe({
      next: (result) => {
        this.pipelineIds= result;
        this.loaderService.stop();
        this.updated = true;
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  public backToMain(){
    this.updated = false;
  }

}
