import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-finance-integration-customer',
  templateUrl: './finance-integration-customer.component.html',
  styleUrls: ['./finance-integration-customer.component.scss']
})
export class FinanceIntegrationCustomerComponent extends BaseComponent implements OnInit {
  private sub: Subscription;
  constructor() {
    super();
  }
  cnfVersions = []

  ngOnInit(): void {
    this.loaderService.start()
    this.loadCnfiVersions()
  }
  @Input() public customerEnvironmentDetails: UntypedFormGroup;

  private loadCnfiVersions(){
    this.sub = this.adminService.getMappedCnfiVersions()
    .subscribe({
      next: (result) => {
        this.cnfVersions = result.versions;

        if(this.cnfVersions.length > 0){
          this.customerEnvironmentDetails.controls.cnfVersion.setValue(this.cnfVersions[0]);
        }

        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

}
