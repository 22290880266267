<div>
  <div class="row">
    <strong class="col-4">{{direction}}:</strong>
  </div>
  <div class="row mt-3">
    <div class="col-4">Server:</div>
    <div class="col-8">{{dbInfo?.Server}}</div>
  </div>
  <div class="row">
    <div class="col-4">Database:</div>
    <div class="col-8">{{dbInfo?.DatabaseName}}</div>
  </div>
  <div class="row">
    <div class="col-4">Environment Type:</div>
    <div class="col-8">{{dbInfo?.EnvironmentType}}</div>
  </div>
  <div class="row">
    <div class="col-4">Region:</div>
    <div class="col-8">{{dbInfo?.Region}}</div>
  </div>
  <div class="row">
    <div class="col-4">Instance:</div>
    <div class="col-8">{{dbInfo?.Instance}}</div>
  </div>
  <div class="row">
    <div class="col-4">Tenant:</div>
    <div class="col-8">{{dbInfo?.Tenant}}</div>
  </div>
  <div class="row">
    <div class="col-4">Subscription:</div>
    <div class="col-8">{{dbInfo?.Subscription}}</div>
  </div>
  <div class="row mt-2">
    <kendo-formfield class="form-group col-12" *ngIf="control">
      <kendo-label
        [for]="confirm"
        class="k-checkbox-label"
        text="Confirm:"
      >
      </kendo-label>
      <input
        #confirm
        type="checkbox"
        name="confirm"
        value="true"
        [formControl]="control"
        kendoCheckBox/>
    </kendo-formfield>
  </div>
</div>
