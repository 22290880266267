<div class="w-75 px-4">
  <ng-container [formGroup]="customerEnvironmentDetails">
    <kendo-formfield>
      <kendo-label [for]="customerId" text="Customer Id: "></kendo-label>
      <kendo-textbox
        formControlName="customerId"
        kendoTextBox
        #customerId
        required
      ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="custType" text="Type: "></kendo-label>
      <kendo-dropdownlist
        #custType
        formControlName="custType"
        [data]="custTypes"
        [valuePrimitive]="true"
        required
      >
      </kendo-dropdownlist>
    </kendo-formfield>
  </ng-container>
</div>
