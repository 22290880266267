<button kendoButton
        (click)="onAddNewClicked()"
        class="k-button"
        themeColor="primary"
>
  New
</button>
<kendo-grid
  [data]="environments"
  kendoGridSelectBy="id"
  [selectable]="true"
  [style.height.%]="100"
>
  <kendo-grid-column
    field="name"
    title="Name"
    [width]="150"
    [minResizableWidth]="200"
  ></kendo-grid-column>
  <kendo-grid-column
    field="type"
    title="Type"
    [width]="150"
    [minResizableWidth]="200"
  ></kendo-grid-column>
  <kendo-grid-column
    field="platform"
    title="Platform"
    [width]="150"
    [minResizableWidth]="200"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="50"
    [minResizableWidth]="50"
    [filterable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <button
        kendoButton
        class="k-button"
        (click)="onUpdateClicked(dataItem)"
        themeColor="primary"
      >
        Update
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="50"
    [minResizableWidth]="50"
    [filterable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <button
        kendoButton
        class="k-button"
        (click)="onDeleteClicked(dataItem)"
        themeColor="primary"
      >
        Delete
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
<div kendoDialogContainer></div>
