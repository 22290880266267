import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-product-mapping',
  templateUrl: './product-mapping.component.html',
  styleUrls: ['./product-mapping.component.scss']
})
export class ProductMappingComponent extends BaseComponent {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  private editedRowIndex: number;

  private sub: Subscription;

  coreProducts: any[];
  coreVersions: any[];

  types: any[] = ['GA', 'Internal'];

  public gridCoreProductData: any[] = [];
  public gridAddOnProductData: any[] = [];

  public dataTables: Array<{ tableName: string; tableId: number }> = [
    {tableName: 'Core Product', tableId: 1},
    {tableName: 'Add on Product', tableId: 2}
  ];
  public dataResultProduct: Array<{ productName: string; productId: number }> = [];
  public dataResultVersion: Array<{ versionName: string; versionId: number }> = [];
  public selectedTable: { tableName: string; tableId: number };
  public selectedProduct: any;
  public selectedVersion: { versionName: string; versionId: number };
  public defaultTables: { tableName: string; tableId: number } = {
    tableName: 'Select Table',
    tableId: null,
  };

  public defaultProduct: { productName: string; productId: number } = {
    productName: 'Select core product',
    productId: null,
  };

  public defaultVersion: { versionName: string; versionId: number } = {
    versionName: 'Select core product version',
    versionId: null,
  };

  public coreProductToAdd: {
    name: string,
    version: string,
    forIntegration: boolean,
    type: string
  } = {
    name: '',
    version: '',
    forIntegration: false,
    type: 'GA'
  };

  public addOnProductToAdd: {
    name: string,
    version: string,
    coreProductId: string
  } = {
    name: '',
    version: '',
    coreProductId: ''
  };

  hideProduct = true;
  hideVersion = true;
  hideCoreProdGrid = true;
  hideAddOnProdGrid = true;
  hideSelection = false;
  editMode = false;

  public coreProductMappingToBeDeleted: any;
  public coreProductMappingDialogOpened = false;

  public addOnProductMappingToBeDeleted: any;
  public addOnProductMappingDialogOpened = false;

  constructor() {
    super();
  }

  initFlagsForTable() {
    this.hideProduct = true;
    this.hideVersion = true;
    this.hideAddOnProdGrid = true;
    this.hideCoreProdGrid = true;
    this.selectedProduct = this.defaultProduct;
    this.selectedVersion = this.defaultVersion;
  }

  initFlagsForProduct() {
    this.hideVersion = true;
    this.hideAddOnProdGrid = true;
    this.hideCoreProdGrid = true;
    this.selectedVersion = this.defaultVersion;
  }

  resetInputs() {
    this.coreProductToAdd = {
      name: '',
      version: '',
      forIntegration: false,
      type: 'GA'
    };

    this.addOnProductToAdd = {
      name: '',
      version: '',
      coreProductId: ''
    };
  }

  handleTableChange(value) {
    this.selectedTable = value;
    this.initFlagsForTable();
    if (this.selectedTable.tableId && this.selectedTable.tableId != 1) {
      this.loadCoreProductDataNames();
      this.hideProduct = false;
    } else {
      this.hideProduct = true;
      this.hideVersion = true;
    }

    if (this.selectedTable.tableId && this.selectedTable.tableId == 1) {
      this.hideCoreProdGrid = false;
      this.loadCoreProductData();
    } else {
      this.hideCoreProdGrid = true;
    }
  }

  handleProductChange(value) {
    this.selectedProduct = value;
    this.initFlagsForProduct();
    if (this.selectedProduct.productId) {
      this.loadCoreProductDataVersions(this.selectedProduct.productName);
      this.hideVersion = false;
    } else {
      this.hideVersion = true;
    }
  }

  handleVersionChange(value) {
    this.selectedVersion = value;
    if (this.selectedVersion.versionId) {
      this.loadAddOnProductData();
      this.hideAddOnProdGrid = false;
    } else {
      this.hideAddOnProdGrid = true;
    }
  }

  hideShowSelection() {
    this.hideSelection = !this.hideSelection;
  }

  addCoreProductToTable() {

    this.loaderService.start();
    this.sub = this.adminService.addCoreProductMaping(this.coreProductToAdd)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          if (result) {
            this.toastr.success(`Product ${this.coreProductToAdd.name} with version ${this.coreProductToAdd.version} added successfully!`, 'Success!');
            this.resetInputs();
            this.loadCoreProductData();
          }
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.resetInputs();
          console.error(error);
        }
      });
  }

  addAddOnProductToTable() {
    this.loaderService.start();
    this.addOnProductToAdd.coreProductId = String(this.selectedVersion.versionId);
    this.sub = this.adminService.addAddOnProductMaping(this.addOnProductToAdd)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          if (result) {
            this.toastr.success(`Product ${this.addOnProductToAdd.name} with version ${this.addOnProductToAdd.version} added successfully!`, 'Success!');
            this.resetInputs();
            this.loadAddOnProductData();
          }
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.resetInputs();
          console.error(error);
        }
      });
  }

  createProductEdit(): any {
    return this.coreProductToAdd;
  }

  createAddOnProductEdit(): any {
    return this.addOnProductToAdd;
  }

  public cellClickHandler({isEdited, dataItem, rowIndex}): void {
    this.editedRowIndex = rowIndex;
  }

  edit(data, rowIndex) {
    this.editMode = true;
    this.editedRowIndex = rowIndex;
  }

  cancel(data) {
    this.editMode = false;
    this.editedRowIndex = undefined;
  }

  editCoreProductType(dataItem, $event) {
    dataItem.type = $event;
  }

  saveProductMapping(dataItem) {
    this.editMode = false;
    this.loaderService.start();
    this.sub = this.adminService.editCoreProductMaping(dataItem)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.toastr.success(`Product ${dataItem.name} with version ${dataItem.version} edited successfully!`, 'Success!');
          this.loadCoreProductData();
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          console.error(error);
        }
      });
  }

  saveAddOnProductMapping(dataItem) {
    this.editMode = false;
    this.loaderService.start();
    this.sub = this.adminService.editAddOnProductMaping(dataItem)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.toastr.success(`Product ${dataItem.name} with version ${dataItem.version} edited successfully!`, 'Success!');
          this.loadAddOnProductData();
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`Mapping failed!`, 'Error!');
          console.error(error);
        }
      });
  }

  deleteProductMappingDialogOpen(dataItem) {
    this.coreProductMappingToBeDeleted = dataItem;
    this.coreProductMappingDialogOpened = true;
  }

  deleteAddOnProductMappingDialogOpen(dataItem) {
    this.addOnProductMappingToBeDeleted = dataItem;
    this.addOnProductMappingDialogOpened = true;
  }

  public close() {
    this.coreProductMappingDialogOpened = false;
    this.addOnProductMappingDialogOpened = false;
  }

  public deleteCoreProductFromTable(value) {
    this.loaderService.start();
    this.sub = this.adminService.deleteCoreProductMaping(value.id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success(`Product ${this.coreProductMappingToBeDeleted.name} with version ${this.coreProductMappingToBeDeleted.version} edited successfully!`, 'Success!');
          this.loadCoreProductData();
        } else {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.coreProductMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.coreProductMappingDialogOpened = false;
      }
    });
  }

  public deleteAddOnProductFromTable(value) {
    this.loaderService.start();
    this.sub = this.adminService.deleteAddOnProductMaping(value.id).subscribe({
      next: (result) => {
        if (result) {
          this.toastr.success(`Product ${this.addOnProductMappingToBeDeleted.name} with version ${this.addOnProductMappingToBeDeleted.version} deleted successfully!`, 'Success!');
          this.loadAddOnProductData();
        } else {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.addOnProductMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.addOnProductMappingDialogOpened = false;
      }
    });
  }

  public loadCoreProductData() {
    this.loaderService.start();
    this.sub = this.adminService.getCoreProducts()
      .subscribe({
        next: (result) => {
          this.gridCoreProductData = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  public loadCoreProductDataNames() {
    this.loaderService.start();
    this.dataResultProduct = [];
    this.sub = this.adminService.getCoreProductDistinctNames()
      .subscribe({
        next: (result) => {
          this.coreProducts = result;
          this.coreProducts.forEach(p => {
            const i = this.dataResultProduct.length;
            this.dataResultProduct.push({productName: p, productId: i + 1});
          });
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  public loadCoreProductDataVersions(product) {
    this.loaderService.start();
    this.dataResultVersion = [];
    this.sub = this.adminService.getCoreProductVersions(product)
      .subscribe({
        next: (result) => {
          this.coreVersions = result;
          this.coreVersions.forEach(p => {
            const i = this.dataResultVersion.length;
            this.dataResultVersion.push({versionName: `${p.version} (${p.type})`, versionId: p.id});
          });
          this.dataResultVersion.sort((a, b) => b.versionName.localeCompare(a.versionName));
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  public loadAddOnProductData() {
    this.loaderService.start();
    this.sub = this.adminService.getAddOnProducts(this.selectedVersion.versionId)
      .subscribe({
        next: (result) => {
          this.gridAddOnProductData = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.nodeName === 'TD' || element.nodeName === 'TH') &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
