<div class="text-center">
    <h4>
      <small class="text-muted">Multitenanat Customer Inventory</small>
    </h4>
  </div>
<kendo-tilelayout
  [resizable]="false"
  [reorderable]="false">
    <kendo-tilelayout-item>
        <kendo-tilelayout-item-body>
            <form novalidate #myForm="ngForm">
              <div class="row p-1">
                <div class="col-6 customLabel">
                  <kendo-label text="Show internal environments">
                   
                  </kendo-label>
                </div>
                <div class="col-6">
                  <kendo-switch size="small" (valueChange)="onSwitchChange($event)"></kendo-switch>
                </div>
              </div>
                <kendo-grid
                [kendoGridTemplateEditing]="updateCustomerEdit"
                [kendoGridBinding]="customers"
                [filterable]="true"
                [pageable]="true" [pageSize]="6"
                >
                    <kendo-grid-column
                    field="customerCode"
                    title="Customer Code"
                    >
                    </kendo-grid-column>
            
                    <kendo-grid-column
                    field="environmentName"
                    title="Environment Name"

                    >
                    </kendo-grid-column>
            
            
                    <kendo-grid-column
                    field="isLive"
                    title="Is Customer Live"
                    filter="boolean"
                    >
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                            <input
                            [(ngModel)]="dataItem.isLive"
                            kendoGridFocusable
                            name="Is Customer Live"
                            type="checkbox"
                            />
                        </ng-template>  
                    </kendo-grid-column>
            
                    <kendo-grid-column
                    field="mailboxManagerConfigured"
                    title="Mailbox Configured"
                    filter="boolean"
                    >
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                            <input
                            [(ngModel)]="dataItem.mailboxManagerConfigured"
                            kendoGridFocusable
                            name="Mailbox Configured"
                            type="checkbox"
                            />
                        </ng-template>  
                    </kendo-grid-column>

                    <kendo-grid-column
                    field="hasVanityUrl"
                    title="Has Vanity Url"
                    filter="boolean"
                    >
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                            <input
                            [(ngModel)]="dataItem.hasVanityUrl"
                            kendoGridFocusable
                            name="Has Vanity Url"
                            type="checkbox"
                            />
                        </ng-template>  
                    </kendo-grid-column>

                    <kendo-grid-command-column title="command" [width]="220">
                        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                          <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                            Edit
                          </button>
                          <button
                            kendoGridSaveCommand
                            type="button"
                            [disabled]="myForm.invalid"
                            (click)="saveCustomerDetails(dataItem)"
                          >
                            {{ isNew ? "Add" : "Update" }}
                          </button>
                          <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                            {{ isNew ? "Discard changes" : "Cancel" }}
                          </button>
            
                        </ng-template>
                      </kendo-grid-command-column>
                </kendo-grid>
            </form>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>
