import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-finance-integration-summary',
  templateUrl: './finance-integration-summary.component.html',
  styleUrls: ['./finance-integration-summary.component.scss']
})
export class FinanceIntegrationSummaryComponent extends BaseComponent implements OnInit {

  private sub: Subscription;
  summaryDetails: any;
  @Input() public customerEnvironmentDetails: UntypedFormGroup;

  constructor() {
    super();
  }

  ngOnInit(): void {
    var customer = this.customerEnvironmentDetails.get("customerId").value;

    this.getCustomerDetails(customer)
  }

  public getCustomerDetails(customerId){
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getCustomerEnvironment(customerId)
      .subscribe({
        next: (result) => {
          this.summaryDetails = result;
          this.updateDataToForm();
          this.loaderService.stop();

        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  updateDataToForm(){
    this.customerEnvironmentDetails.controls.instance.setValue(this.summaryDetails.instance);
    this.customerEnvironmentDetails.controls.environment.setValue(this.summaryDetails.environment);
    this.customerEnvironmentDetails.controls.region.setValue(this.summaryDetails.region);
  }

}
