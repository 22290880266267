import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from '../../shared/models/Environment';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentDbService {
  private url = `${window.location.origin}/api/environment`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService
  ) { }

  createEnvironment(environment: Environment): Observable<Environment> {
    return this.httpClient
      .post<Environment>(this.url, environment)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createEnvironment'))
      );
  }

  updateEnvironment(id: number, environment: Environment): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/${id}`, environment)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateEnvironment'))
      );
  }

  deleteEnvironment(environment: Environment): Observable<any> {
    return this.httpClient
      .delete<any>(`${this.url}/${environment.id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deleteEnvironment'))
      );
  }

  getEnvironments(): Observable<Environment[]> {
    return this.httpClient
      .get<Environment[]>(this.url)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironments'))
      );
  }

  getEnvironmentById(id: number): Observable<Environment> {
    return this.httpClient
      .get<Environment>(`${this.url}/${id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironmentById'))
      );
  }
}
