<div class="w-75 px-4">
    <ng-container [formGroup]="customerEnvironmentDetails">
        <kendo-formfield>
            <kendo-label [for]="customerId" text="Customer Id: "></kendo-label>
            <kendo-textbox
              formControlName="customerId"
              kendoTextBox
              #customerId
              required
              (valueChange)="onValueChange($event)"
            ></kendo-textbox>
          </kendo-formfield>
          <kendo-formfield>
            <span *ngIf="showcheckbox">
              <div class="row pt-3">
                <div class="row pt-3">
                  <div class="col-6" *ngIf="!showCheckboxError">Select environments:</div>
                  <div class="text-danger col-6" *ngIf="showCheckboxError">Select environments:</div>
                  <div class="col-4">
                    <div class="k-checkbox-wrap" *ngFor="let inputcheckbox of this.validCheckbox">
                      <input
                      type="checkbox"
                      kendoCheckBox
                      formControlName="{{inputcheckbox.name}}"
                      id="{{inputcheckbox.name}}"
                    />
                      <kendo-label
                      class="k-checkbox-label"
                      for="{{inputcheckbox.name}}"
                    >{{inputcheckbox.displayName}}</kendo-label>
                    </div>
                    <div *ngIf="showAdd">
                      <kendo-icon name="plus" class="clickable" (click)="ShowTextbox()" *ngIf="!showTextbox"></kendo-icon>
                    </div>
                  </div>
                  <kendo-formerror *ngIf="showCheckboxError">
                    Please choose an environment!
                  </kendo-formerror>
                  <div *ngIf="showTextbox" class="pt-3">
                    <kendo-textbox placeholder="Enter environment name" formControlName="newCheckbox" [maxlength]="3">
                      <ng-template kendoTextBoxSuffixTemplate>
                        <kendo-textbox-separator></kendo-textbox-separator>
                      <button kendoButton look="clear" class="send-button" (click)="Addcheckbox()">Add</button>
                      </ng-template>
                    </kendo-textbox>
                  </div>
                </div>
              </div>
            </span>
          </kendo-formfield>
          <div class="text-danger" *ngIf="showError && !customerEnvironmentDetails.valid && customerEnvironmentDetails.errors.Error">{{customerEnvironmentDetails.errors.Error}}</div>
    </ng-container>
</div>
