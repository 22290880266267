import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { PipelineMapping } from 'src/app/shared/models/PipelineMapping';
import { AdminNamesMapping } from 'src/app/shared/models/AdminNamesMapping';
import { PipelineProductRequest } from 'src/app/shared/models/PipelineProductRequest';
import { PipelineAddRequest } from 'src/app/shared/models/PipelineAddRequest';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-mapper',
  templateUrl: './mapper.component.html',
  styleUrls: ['./mapper.component.scss']
})
export class MapperComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  private editedRowIndex: number;
  private sub: Subscription;
  public products = [];
  public nameMappingTypes: string[] = [];
  public gridPipelineData: PipelineMapping[] = [];
  public gridNameData: AdminNamesMapping[] = [];
  public gridEnvSeriesData = [];
  public gridOnboardData = [];
  public gridTenantPipelineData = [];
  public gridFinanceIntegrationData = [];
  public gridMailboxManagerData = [];
  public hideIdentifier = true;
  public hidePipelineGrid = true;
  public hideNameGrid = true;
  public hideEnvGrid = true;
  public hideOnboardGrid = true;
  public hideTenantPipelineGrid = true;
  public hideFinanceIntegrationGrid = true;
  public hideMailboxManagerGrid = true;

  public nameMappingDialogOpened = false;
  public pipelineMappingDialogOpened = false;
  public envSeriesMappingDialogOpened = false;
  public onBoardMappingDialogOpened = false;
  public tenantPipelineMappingDialogOpened = false;
  public financeIntegrationMappingDialogOpened = false;
  public mailboxManagerMappingDialogOpened = false;

  hideSelection = false;
  requestDetailsOpened = false;
  requestTemplate="";
  editMode = false;

  public openRequestWindow(dataItem){
    this.requestTemplate = dataItem.requestTemplate;
    this.requestDetailsOpened = true;
  }

  public closeRequestWindow(){
    this.requestDetailsOpened = false;
  }

  public hideShowSelection(){
    this.hideSelection = !this.hideSelection;
  }

  public defaultTables: { tableName: string; tableId: number } = {
    tableName: "Select Table",
    tableId: null,
  };

  public defaultIdentifier: { identifierName: string; identifierId: number } = {
    identifierName: "Select product",
    identifierId: null,
  };

  public dataTables: Array<{ tableName: string; tableId: number }> = [
    { tableName: "Pipeline/Product Mapping", tableId: 1 },
    { tableName: "Names Mapping", tableId: 2 },
    { tableName: "Environment Mapping", tableId: 3 },
    { tableName: "Onboard Pipelines Mapping", tableId: 4 },
    { tableName: "MT Update Pipeline Mapping", tableId: 5 },
    { tableName: "Integration Pipeline Mapping", tableId: 6 },
    { tableName: "Mailbox Pipeline Mapping", tableId: 7 }
  ];

  public dataIdentifier: Array<{
    identifierName: string;
    identifierId: number;
    tableId: number;
  }> = [];

  public dataResultIdentifier: Array<{
    identifierName: string;
    identifierId: number;
    tableId: number;
  }>;

  public offering = {
    standard: true,
    plus: true,
    enterprise: true,
    newEnvironment: true,
  };

  public selectedTable: { tableName: string; tableId: number };
  public selectedIdentifier: { identifierName: string; identifierId: number };
  public pipelineMappingToBeDeleted:any;
  public nameMappingToBeDeleted:any;
  public envSeriesMappingToBeDeleted:any;
  public onBoardMappingToBeDeleted:any;
  public tenantPipelineToBeDeleted:any;
  public financeIntegrationToBeDeleted:any;
  public mailboxManagerToBeDeleted:any;

  public pipelineToAdd: { pipelineId: number,
    pipelineName: string,
    productName: string,
    queueNumber: number,
    requiresTenantInfo: boolean,
    templateFlagToCheck: string,
    enable: boolean} ={
      pipelineId : null,
      pipelineName : "",
      productName: "",
      queueNumber : null,
      requiresTenantInfo : false,
      templateFlagToCheck: "",
      enable: true
    };

    public nameMappingToAdd: { shortName: string,
      fullName: string,
      type: string} ={
        shortName : "",
        fullName : "",
        type:""
      };

      public envSeriesToAdd: { series: string,
        envPattern: string,
        envType: string} ={
          series : "",
          envPattern : "",
          envType:""
        };

        public onBoardMappingToAdd: { pipelineId: string,
          pipelineName: string,
          pipelineType: string,
          offeringType: string,
        requestTemplate:string,
        queueNumber:string,} ={
            pipelineId : "",
            pipelineName : "",
            pipelineType:"",
            offeringType:"",
            requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}",
            queueNumber:"",
          };

          public tenantPipelineMappingToAdd: { pipelineId: string,
            pipelineName: string,
            runPipelineForEachTenant: boolean,
            requestTemplate:string} ={
              pipelineId : "",
              pipelineName : "",
              runPipelineForEachTenant:true,
              requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}"
            };

            public financeIntegrationToAdd: { pipelineId: string,
              pipelineName: string,
              pipelineType: string,
            requestTemplate:string,
            queueNumber:string} ={
                pipelineId : "",
                pipelineName : "",
                pipelineType:"",
                requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}",
                queueNumber:""
              };

              public mailboxManagerToAdd: { pipelineId: string,
                pipelineName: string,
                pipelineType: string,
              requestTemplate:string,
              queueNumber:string,
              runIfMailboxExistsInCustSub: boolean,
              type:string} ={
                  pipelineId : "",
                  pipelineName : "",
                  pipelineType:"",
                  requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}",
                  queueNumber:"",
                  runIfMailboxExistsInCustSub:false,
                  type:""
                };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadProductData();
    this.loadNameMappingTypes();
    this.loadEnvSeriesMappingTypes();
    this.loadOnBoardTypes();
    this.loadtenantPipelineMappingTypes();
    this.loadfinanceIntegrationMappingTypes();
    this.loadmailboxManagerMappingTypes();
  }

  public loadProductData(){
    this.loaderService.start();
    this.sub = this.adminService.getCoreProductDistinctNames()
    .subscribe({
      next: (result) => {
        this.products = result;
        this.products.forEach(p=>{
          const i = this.dataIdentifier.length;
          this.dataIdentifier.push({ identifierName: p, identifierId: i+1, tableId: 1 })
        });
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadNameMappingTypes(){
    this.loaderService.start();
    this.sub = this.adminService.getDistinctNameMappingTypes()
    .subscribe({
      next: (result) => {
        this.nameMappingTypes = result;
        this.nameMappingTypes.forEach(n=>{
          const i = this.dataIdentifier.length;
          this.dataIdentifier.push({ identifierName: n, identifierId: i+1, tableId: 2 })
        });
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadEnvSeriesMappingTypes(){
    const i = this.dataIdentifier.length;
    this.dataIdentifier.push({ identifierName: "Any", identifierId: i+1, tableId: 3 })
  }

  public loadOnBoardTypes(){
    const i = this.dataIdentifier.length;
    this.dataIdentifier.push({ identifierName: "All", identifierId: i+1, tableId: 4 })
    this.dataIdentifier.push({ identifierName: "Standard/Premium", identifierId: i+2, tableId: 4 })
    this.dataIdentifier.push({ identifierName: "Premium Plus", identifierId: i+3, tableId: 4 })
    this.dataIdentifier.push({ identifierName: "Enterprise", identifierId: i+4, tableId: 4 })
    this.dataIdentifier.push({ identifierName: "NewEnvironment", identifierId: i+5, tableId: 4 })
  }

  public loadtenantPipelineMappingTypes(){
    const i = this.dataIdentifier.length;
    this.dataIdentifier.push({ identifierName: "Any", identifierId: i+1, tableId: 5 })
  }

  public loadfinanceIntegrationMappingTypes(){
    const i = this.dataIdentifier.length;
    this.dataIdentifier.push({ identifierName: "Any", identifierId: i+1, tableId: 6 })
  }

  public loadmailboxManagerMappingTypes(){
    const i = this.dataIdentifier.length;
    this.dataIdentifier.push({ identifierName: "MT", identifierId: i+1, tableId: 7 })
    this.dataIdentifier.push({ identifierName: "1.X", identifierId: i+2, tableId: 7 })
  }

  public loadPipelineMappingData(value){
    this.sub = this.adminService.getPipelinesOfProduct(value.identifierName)
    .subscribe({
      next: (result) => {
        this.gridPipelineData = result;
        this.hidePipelineGrid = false;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadNameMappingData(value){
    this.sub = this.adminService.getNameMappingRecordsByType(value.identifierName.toLowerCase())
    .subscribe({
      next: (result) => {
        this.gridNameData = result;
        this.hideNameGrid = false;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadEnvSeriesData(value){
    this.sub = this.adminService.getEnvSeriesMappings()
    .subscribe({
      next: (result) => {
        this.gridEnvSeriesData = result;
        this.hideEnvGrid = false;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadOnboardMappingData(value){
    this.sub = this.adminService.getCustomerOnboardingOfOfferingType(value.identifierName)
    .subscribe({
      next: (result) => {
        this.gridOnboardData = result;

        this.hideOnboardGrid = false;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadTenantPipelineMappingData(value){
    this.sub = this.adminService.getallTenantPipelines()
    .subscribe({
      next: (result) => {
        this.gridTenantPipelineData = result;

        this.hideTenantPipelineGrid = false;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadFinanceIntegrationMappingData(value){
    this.sub = this.adminService.getfinanceIntegrationPipelines()
    .subscribe({
      next: (result) => {
        this.gridFinanceIntegrationData = result;

        this.hideFinanceIntegrationGrid = false;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  public loadMailboxManagerMappingData(value){
    this.sub = this.adminService.getMailboxManagerPipelines(value.identifierName)
    .subscribe({
      next: (result) => {
        this.gridMailboxManagerData = result;

        this.hideMailboxManagerGrid = false;
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
  }

  private initIdentifiers(value) {
    this.hideIdentifier = false;

    if (value.tableId === 1) {
      this.defaultIdentifier.identifierName = "Select product";
      this.hideNameGrid = true;
      this.hideEnvGrid = true;
      this.hideOnboardGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideFinanceIntegrationGrid = true;
      this.hideMailboxManagerGrid = true;
    }
    else if (value.tableId === 2) {
      this.defaultIdentifier.identifierName = "Select Type";
      this.hidePipelineGrid = true;
      this.hideEnvGrid = true;
      this.hideOnboardGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideFinanceIntegrationGrid = true;
      this.hideMailboxManagerGrid = true;
    }
    else if (value.tableId === 3) {
      this.defaultIdentifier.identifierName = "Select Environment Series";
      this.hideNameGrid = true;
      this.hidePipelineGrid = true;
      this.hideOnboardGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideFinanceIntegrationGrid = true;
      this.hideMailboxManagerGrid = true;
    }
    else if (value.tableId === 4) {
      this.defaultIdentifier.identifierName = "Select Offering Type";
      this.hideNameGrid = true;
      this.hidePipelineGrid = true;
      this.hideEnvGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideFinanceIntegrationGrid = true;
      this.hideMailboxManagerGrid = true;
    }
    else if(value.tableId === 5){
      this.defaultIdentifier.identifierName = "Select Identifier";
      this.hideNameGrid = true;
      this.hidePipelineGrid = true;
      this.hideEnvGrid = true;
      this.hideOnboardGrid = true;
      this.hideFinanceIntegrationGrid = true;
      this.hideMailboxManagerGrid = true;
    }
    else if(value.tableId === 6){
      this.defaultIdentifier.identifierName = "Select Identifier";
      this.hideNameGrid = true;
      this.hidePipelineGrid = true;
      this.hideEnvGrid = true;
      this.hideOnboardGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideMailboxManagerGrid = true;
    }
    else if(value.tableId === 7){
      this.defaultIdentifier.identifierName = "Select Identifier";
      this.hideNameGrid = true;
      this.hidePipelineGrid = true;
      this.hideEnvGrid = true;
      this.hideOnboardGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideFinanceIntegrationGrid = true;
    }

    this.dataResultIdentifier = this.dataIdentifier.filter(
      (s) => s.tableId === value.tableId
    );
  }

    clearPipelineToAdd(){
      this.pipelineToAdd = {
        pipelineId : null,
        pipelineName : "",
        productName: "",
        queueNumber: null,
        requiresTenantInfo : false,
        templateFlagToCheck: "",
        enable: true
      };
    }

    clearNameMappingToAdd(){
      this.nameMappingToAdd = {
        shortName : "",
        fullName : "",
        type:""
      };
    }

    clearEnvSeriesToAdd(){
      this.envSeriesToAdd = {
        series : "",
        envPattern : "",
        envType:""
      };
    }

    clearOnboardingToAdd(){
      this.onBoardMappingToAdd = {
        pipelineId : "",
        pipelineName : "",
        pipelineType:"",
        offeringType:"",
        requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}",
        queueNumber:"",
      };
    }

    clearTenantPipelineToAdd(){
      this.tenantPipelineMappingToAdd = {
        pipelineId : "",
        pipelineName : "",
        runPipelineForEachTenant:false,
        requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}"
      };
    }

    clearFinanceIntegrationToAdd(){
      this.financeIntegrationToAdd = {
        pipelineId : "",
        pipelineName : "",
        pipelineType:"",
        requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}",
        queueNumber:"",
      };
    }

    clearMailboxManagerToAdd(){
      this.mailboxManagerToAdd = {
        pipelineId : "",
        pipelineName : "",
        pipelineType:"",
        requestTemplate:"{\r\n    \"templateParameters\": {\r\n        \"environmenttype\": \"{{environment}}\",\r\n        \"region\": \"{{region}}\",\r\n        \"Instance\": \"{{instance}}\",\r\n\t\"customerID\": \"{{customerid}}\",\r\n\t\"customertenants\": \"[{{customerid}}]\",\r\n\t\"SqlInstance\": \"{{sqlinstance}}\",\r\n        \"resourcestodeploy\":\"{{resourcestodeploy}}\",\r\n\t\"customerid\": \"{{customerid}}\",\r\n        \"overwriteexitingvalues\":\"{{overwriteexitingvalues}}\"\r\n    }\r\n}",
        queueNumber:"",
        runIfMailboxExistsInCustSub: false,
        type: ""
      };
    }

  handleTableChange(value) {
    this.selectedTable = value;
    this.selectedIdentifier = undefined;

    if (value.tableId === this.defaultTables.tableId) {
      this.hideIdentifier = true;
      this.hidePipelineGrid = true;
      this.hideNameGrid = true;
      this.hideEnvGrid = true;
      this.hideOnboardGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideFinanceIntegrationGrid = true;
      this.hideMailboxManagerGrid = true;
      this.dataResultIdentifier = [];
    }
    else
    {
      this.initIdentifiers(value);
    }
  }

  handleIdentifierChange(value) {
    this.selectedIdentifier = value;
    if(value.tableId === 1){
      this.handleProductChange(value);
    }
    else if(value.tableId === 2){
      this.handleNameChange(value);
    }
    else if(value.tableId == 3){
      this.handleEnvSeriesChange(value);
    }
    else if(value.tableId == 4){
      this.handleOnboardChange(value);
    }
    else if(value.tableId == 5){
      this.handleTenantPipelineChange(value);
    }
    else if(value.tableId == 6){
      this.handleFinanceIntegrationChange(value);
    }
    else if(value.tableId == 7){
      this.handleMailboxManagerChange(value);
    }
    else{
      this.hidePipelineGrid = true;
      this.hideNameGrid = true;
      this.hideEnvGrid = true;
      this.hideOnboardGrid = true;
      this.hideTenantPipelineGrid = true;
      this.hideFinanceIntegrationGrid = true;
      this.hideMailboxManagerGrid = true;
    }
  }

  handleProductChange(value){
    this.loaderService.start();
    this.loadPipelineMappingData(value);
  }

  handleNameChange(value){
    this.loaderService.start();
    this.loadNameMappingData(value);
  }
  handleEnvSeriesChange(value){
    this.loaderService.start();
    this.loadEnvSeriesData(value);
  }
  handleOnboardChange(value){
    this.loaderService.start();
    this.loadOnboardMappingData(value);
  }
  handleTenantPipelineChange(value){
    this.loaderService.start();
    this.loadTenantPipelineMappingData(value);
  }
  handleFinanceIntegrationChange(value){
    this.loaderService.start();
    this.loadFinanceIntegrationMappingData(value);
  }

  handleMailboxManagerChange(value){
    this.loaderService.start();
    this.loadMailboxManagerMappingData(value);
  }

  public addPipelineMappingToProduct(){
    if(this.pipelineToAdd.pipelineId === null || this.pipelineToAdd.pipelineName === ""){
      this.toastr.error(`Pipleine Id and pipleine name are required!`, 'Error!');
    }
    else{
      this.pipelineToAdd.productName = this.selectedIdentifier.identifierName;
      this.loaderService.start();
      this.createNewPipelineMappingToProduct(this.pipelineToAdd);
      this.clearPipelineToAdd();
    }
  }

  createNewPipelineMappingToProduct(value){
    const pipelineInfo: PipelineAddRequest = {
      pipelineId: value.pipelineId,
      pipelineName: value.pipelineName,
      requiresTenantInfo: value.requiresTenantInfo,
      queueNumber: value.queueNumber,
      templateFlagToCheck: value.templateFlagToCheck,
      enable: value.enable
    };

    const request: PipelineProductRequest = {
      productName: value.productName,
      pipelineAddRequests: [pipelineInfo]
    };

    this.sub = this.adminService.mapPipelineToProduct(request).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${value.pipelineName} was mapped to product ${value.productName} successfully!`, 'Success!');
          this.loadPipelineMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  public addNameMappingToTable(){
    if(this.nameMappingToAdd.shortName === "" || this.nameMappingToAdd.fullName === ""){
      this.toastr.error(`Short name and full name are required!`, 'Error!');
    }
    else
    {
      this.nameMappingToAdd.type = this.selectedIdentifier.identifierName.toLowerCase();
      this.loaderService.start();
      this.createNewNameMapping(this.nameMappingToAdd);
      this.clearNameMappingToAdd();
    }
  }

  createNewNameMapping(value){
    const request: AdminNamesMapping = {
      shortName: value.shortName,
      fullName: value.fullName,
      type: value.type,
      id: null
    };

    this.sub = this.adminService.addNameMapping(request).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Short name ${value.shortName} was mapped to full name ${value.fullName} successfully for type ${value.Type}!`, 'Success!');
          this.loadNameMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  public addEnvSeriesMappingToTable(){
    if(this.envSeriesToAdd.series === "" || this.envSeriesToAdd.envPattern === "" || this.envSeriesToAdd.envType === ""){
      this.toastr.error(`Series, pattern and type are required!`, 'Error!');
    }
    else
    {
      this.loaderService.start();
      this.createNewEnvSeriesMapping(this.envSeriesToAdd);
      this.clearEnvSeriesToAdd();
    }
  }

  createNewEnvSeriesMapping(value){
    const request= {
      series: value.series,
      envPattern: value.envPattern,
      envType: value.envType,
      id: null
    };

    this.sub = this.adminService.addEnvSeriesMapping(request).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Environment series ${value.series} was mapped to pattern ${value.envPattern} successfully for type ${value.envType}!`, 'Success!');
          this.loadEnvSeriesData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  public addOnboardingPipelineToOfferingType(){
    if(this.onBoardMappingToAdd.pipelineId === null || this.onBoardMappingToAdd.pipelineName === "" || this.onBoardMappingToAdd.pipelineType === ""){
      this.toastr.error(`Pipleine Id, name and type are required!`, 'Error!');
    }
    else{
      if(this.selectedIdentifier.identifierName == "All"){
        this.onBoardMappingToAdd.offeringType = this.offering.standard?"Standard/Premium,":"";
        this.onBoardMappingToAdd.offeringType += this.offering.plus?"Premium Plus,":"";
        this.onBoardMappingToAdd.offeringType += this.offering.enterprise?"Enterprise,":"";
        this.onBoardMappingToAdd.offeringType += this.offering.newEnvironment?"NewEnvironment,":"";

        if(this.onBoardMappingToAdd.offeringType != ""){
          this.onBoardMappingToAdd.offeringType = this.onBoardMappingToAdd.offeringType.slice(0, -1)
        }
      }
      else{
        this.onBoardMappingToAdd.offeringType = this.selectedIdentifier.identifierName;
      }
      this.loaderService.start();
      this.createNewOnboardingMapping(this.onBoardMappingToAdd);
      this.clearOnboardingToAdd();
    }
  }

  createNewOnboardingMapping(value){
    const request = {
      pipelineId: value.pipelineId,
      pipelineName: value.pipelineName,
      pipelineType: value.pipelineType,
      offeringType: value.offeringType,
      requestTemplate: value.requestTemplate,
      queueNumber: value.queueNumber
    };

    this.sub = this.adminService.addCustomerOnboardingPipelineMapping(request).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${value.pipelineName} was mapped to offeringType ${value.offeringType} successfully!`, 'Success!');
          this.loadOnboardMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  public addTenantPipelineMapping(){
    if(this.tenantPipelineMappingToAdd.pipelineId === null || this.tenantPipelineMappingToAdd.pipelineName === ""){
      this.toastr.error(`Pipleine Id and name are required!`, 'Error!');
    }
    else{
      this.loaderService.start();
      this.createTenantPipelineMapping(this.tenantPipelineMappingToAdd);
      this.clearTenantPipelineToAdd();
    }
  }

  createTenantPipelineMapping(value){
        const request = {
      pipelineId: value.pipelineId,
      pipelineName: value.pipelineName,
      runPipelineForEachTenant: value.runPipelineForEachTenant,
      requestTemplate: value.requestTemplate
    };

    this.sub = this.adminService.addTenantPipeline(request).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${value.pipelineName} was mapped successfully!`, 'Success!');
          this.loadTenantPipelineMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  public addFinanceIntegrationPipeline(){
    if(this.financeIntegrationToAdd.pipelineId === null || this.financeIntegrationToAdd.pipelineName === "" || this.financeIntegrationToAdd.pipelineType === ""){
      this.toastr.error(`Pipleine Id, name and type are required!`, 'Error!');
    }
    else{
      this.loaderService.start();
      this.createNewFinanceIntegrationMapping(this.financeIntegrationToAdd);
      this.clearFinanceIntegrationToAdd();
    }
  }

  createNewFinanceIntegrationMapping(value){
    const request = {
      pipelineId: value.pipelineId,
      pipelineName: value.pipelineName,
      pipelineType: value.pipelineType,
      requestTemplate: value.requestTemplate,
      queueNumber: value.queueNumber
    };

    this.sub = this.adminService.addfinanceIntegrationPipeline(request).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${value.pipelineName} was mapped successfully!`, 'Success!');
          this.loadFinanceIntegrationMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  public addMailboxManagerPipeline(){
    if(this.mailboxManagerToAdd.pipelineId === null || this.mailboxManagerToAdd.pipelineName === "" || this.mailboxManagerToAdd.pipelineType === ""){
      this.toastr.error(`Pipleine Id, name and type are required!`, 'Error!');
    }
    else{
      this.loaderService.start();
      this.createNewMailboxManagerMapping(this.mailboxManagerToAdd);
      this.clearMailboxManagerToAdd();
    }
  }

  createNewMailboxManagerMapping(value){
    const request = {
      pipelineId: value.pipelineId,
      pipelineName: value.pipelineName,
      pipelineType: value.pipelineType,
      requestTemplate: value.requestTemplate,
      queueNumber: value.queueNumber,
      runIfMailboxExistsInCustSub: value.runIfMailboxExistsInCustSub,
      type: this.selectedIdentifier.identifierName
    };

    this.sub = this.adminService.addMailboxManagerPipeline(request).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${value.pipelineName} was mapped successfully!`, 'Success!');
          this.loadMailboxManagerMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  public deleteMappingDialogOpen(dataItem){
    this.nameMappingToBeDeleted = dataItem;
    this.nameMappingDialogOpened = true;
  }

  public deletePipelineMappingDialogOpen(dataItem){
    this.pipelineMappingToBeDeleted= dataItem;
    this.pipelineMappingDialogOpened = true;
  }

  public deleteEnvSeriesMappingDialogOpen(dataItem){
    this.envSeriesMappingToBeDeleted= dataItem;
    this.envSeriesMappingDialogOpened = true;
  }

  public deleteOnboardingMappingDialogOpen(dataItem){
    this.onBoardMappingToBeDeleted= dataItem;
    this.onBoardMappingDialogOpened = true;
  }

  public deleteTenantPipelineMappingDialogOpen(dataItem){
    this.tenantPipelineToBeDeleted= dataItem;
    this.tenantPipelineMappingDialogOpened = true;
  }

  public deleteFinanceIntegrationMappingDialogOpen(dataItem){
    this.financeIntegrationToBeDeleted= dataItem;
    this.financeIntegrationMappingDialogOpened = true;
  }

  public deleteMailboxManagerMappingDialogOpen(dataItem){
    this.mailboxManagerToBeDeleted= dataItem;
    this.mailboxManagerMappingDialogOpened = true;
  }

  public close(){
    this.nameMappingDialogOpened = false;
    this.pipelineMappingDialogOpened = false;
    this.envSeriesMappingDialogOpened = false;
    this.onBoardMappingDialogOpened = false;
    this.tenantPipelineMappingDialogOpened = false;
    this.financeIntegrationMappingDialogOpened = false;
    this.mailboxManagerMappingDialogOpened = false;
  }

  public deleteMappingFromTable(value){
    this.loaderService.start();
    this.sub = this.adminService.deleteNameMapping(value.id).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Short name ${value.shortName} mapped to full name ${value.fullName} deleted successfully for type ${value.Type}!`, 'Success!');
          this.loadNameMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.nameMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.nameMappingDialogOpened = false;
      }
    });
  }

  public deletePipelineMappedToProduct(value){
    this.loaderService.start();
    this.sub = this.adminService.deletePipelineeMappedToProduct(value.id).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${value.pipelineName} mapped to product ${value.productName} deleted successfully!`, 'Success!');
          this.loadPipelineMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.pipelineMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.pipelineMappingDialogOpened = false;
      }
    });
  }

  public deleteSeriesMappedToEnvironment(value){
    this.loaderService.start();
    this.sub = this.adminService.deleteEnvSeriesMapping(value.id).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Series ${value.series} mapped to pattern ${value.environmentPattern} deleted successfully!`, 'Success!');
          this.loadEnvSeriesData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.envSeriesMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.envSeriesMappingDialogOpened = false;
      }
    });
  }

  public deleteOnboardMappingToOfferingType(value){
    this.loaderService.start();
    value.offeringType = this.selectedIdentifier.identifierName;
    this.sub = this.adminService.deleteCustomerOnboardingPipelineMapping(value.pipelineId,value.offeringType).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`PipelineId ${value.pipelineId} mapped to offeringType ${value.offeringType} deleted successfully!`, 'Success!');
          this.loadOnboardMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.onBoardMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.onBoardMappingDialogOpened = false;
      }
    });
  }

  public deleteTenantPipelineMapping(value){
    this.loaderService.start();
    this.sub = this.adminService.deleteTenantPipeline(value.id).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Mapped pipelineId ${value.pipelineId} deleted successfully!`, 'Success!');
          this.loadTenantPipelineMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.tenantPipelineMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.onBoardMappingDialogOpened = false;
        this.tenantPipelineMappingDialogOpened = false;
      }
    });
  }

  public deleteFinanceIntegrationMapping(value){
    this.loaderService.start();
    this.sub = this.adminService.deletefinanceIntegrationPipeline(value.pipelineId).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`PipelineId ${value.pipelineId} mapping deleted successfully!`, 'Success!');
          this.loadFinanceIntegrationMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.financeIntegrationMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.financeIntegrationMappingDialogOpened = false;
      }
    });
  }

  public deleteMailboxManagerMapping(value){
    this.loaderService.start();
    this.sub = this.adminService.deleteMailboxManagerPipeline(value.pipelineId).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`PipelineId ${value.pipelineId} mapping deleted successfully!`, 'Success!');
          this.loadMailboxManagerMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
        this.mailboxManagerMappingDialogOpened = false;
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
        this.mailboxManagerMappingDialogOpened = false;
      }
    });
  }

  public showOnboardingCheckBox(){
    var show = this.selectedTable.tableId == 4 && this.selectedIdentifier.identifierName=="All"
    return show
  }

  createNewOnBoardMapping(): any {
    return this.onBoardMappingToAdd;
  }

  saveOnboardingPipeline(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
    this.loaderService.start();
    this.sub = this.adminService.editCustomerOnboardingPipelineMapping(data).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${data.pipelineName} mapped to offeringType ${data.offeringType} edited successfully!`, 'Success!');
          this.loadOnboardMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  createNewEnvSeriesMappingEdit(): any {
    return this.envSeriesToAdd;
  }

  saveEnvSeries(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
    this.loaderService.start();
    this.sub = this.adminService.editEnvSeriesMapping(data).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Environment series ${data.series} mapped to pattern ${data.environmentPattern} edited successfully for type ${data.environmentType}!`, 'Success!');
          this.loadEnvSeriesData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  createNewNameMappingEdit(): any {
    return this.nameMappingToAdd;
  }

  saveNameMapping(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
    this.loaderService.start();
    this.sub = this.adminService.editNameMapping(data).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Short name ${data.shortName} mapped to full name ${data.fullName} edited successfully for type ${data.type}!`, 'Success!');
          this.loadNameMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  createPipelineEdit(): any {
    return this.pipelineToAdd;
  }

  savePipelineMapping(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
    this.loaderService.start();
    this.sub = this.adminService.editPipelineToProduct(data).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Pipeline ${data.pipelineName} was mapped to product ${data.productName} edited successfully!`, 'Success!');
          this.loadPipelineMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  createTenantPipelineEdit(): any {
    return this.tenantPipelineMappingToAdd;
  }

  saveTenantPipelineMapping(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
    this.loaderService.start();
    this.sub = this.adminService.editTenantPipeline(data).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Mapped pipeline ${data.pipelineName} edited successfully!`, 'Success!');
          this.loadTenantPipelineMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  createFinanceIntegrationEdit(): any {
    return this.financeIntegrationToAdd;
  }

  saveFinanceIntegrationMapping(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
    this.loaderService.start();
    this.sub = this.adminService.editfinanceIntegrationPipeline(data).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Mapped pipeline ${data.pipelineName} edited successfully!`, 'Success!');
          this.loadFinanceIntegrationMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  createMailboxManagerEdit(): any {
    return this.mailboxManagerToAdd;
  }

  saveMailboxManagerMapping(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
    this.loaderService.start();
    this.sub = this.adminService.editMailboxManagerPipeline(data).subscribe({
      next: (result) => {
        if(result)
        {
          this.toastr.success(`Mapped pipeline ${data.pipelineName} edited successfully!`, 'Success!');
          this.loadMailboxManagerMappingData(this.selectedIdentifier);
        }
        else
        {
          this.toastr.error(`Mapping failed!`, 'Error!');
          this.loaderService.stop();
        }
      },
      error: (error) => {
        this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        this.loaderService.stop();
      }
    });
  }

  edit(data,rowIndex){
    this.editMode = true;
    this.editedRowIndex = rowIndex;
  }

  cancel(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
  }

  public cellClickHandler({ isEdited, dataItem, rowIndex }): void {
    this.editedRowIndex = rowIndex;
}

public showTooltip(e: MouseEvent): void {
  const element = e.target as HTMLElement;
  if (
    (element.nodeName === "TD" || element.nodeName === "TH") &&
    element.offsetWidth < element.scrollWidth
  ) {
    this.tooltipDir.toggle(element);
  } else {
    this.tooltipDir.hide();
  }
}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
