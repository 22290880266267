<div class="row">
  <div class="form-group col-12">
    <kendo-label for="authentication">Authentication</kendo-label>
    <kendo-dropdownlist
      id="authentication"
      [(ngModel)]="selectedAuthentication"
      [data]="authentications"
      (selectionChange)="authenticationSelectionChange($event)"
      [defaultItem]="null"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>
<div class="row">
  <div class="form-group col-12">
    <kendo-label for="anthologyStudent">Anthology Student Version</kendo-label>
    <kendo-dropdownlist
      id="anthologyStudent"
      [data]="studentAppVersions"
      (selectionChange)="studentAppVersionSelectionChange($event)"
      textField="version"
      valueField="version"
      [defaultItem]="null"
      required
    >
    </kendo-dropdownlist>
  </div>
</div>

<kendo-grid
  #grid
  [data]="productVersionsView"
  [height]="600"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [pageable]="true"
  [sortable]="true"
  (dataStateChange)="onStateChange($event)"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  [navigable]="true"
>
  <kendo-grid-column
    field="name"
    title="Product Name"
  ></kendo-grid-column>
  <kendo-grid-column
    field="version"
    title="Version"
  ></kendo-grid-column>
</kendo-grid>
