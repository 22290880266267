import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { EnvironmentMetaDataResponse } from '../../shared/models/dynamics/EnvironmentMetaData';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DynamicsService {

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService) {
  }

  getEnvironmentMetaData(projectId: number, pageId: number): Observable<EnvironmentMetaDataResponse> {
    return this.httpClient
      .get<EnvironmentMetaDataResponse>(`${window.location.origin}/api/${Constants.DYNAMICS}/EnvironmentMetaData/${projectId}/${pageId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironmentMetaData'))
      );
  }

  refreshAllEnvironments(projectId: number, customerId: number): Observable<boolean> {
    return this.httpClient
      .get<boolean>(`${window.location.origin}/api/${Constants.DYNAMICS}/RefreshAllEnvironments/${projectId}/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('refreshAllEnvironments'))
      );
  }
}
