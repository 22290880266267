<kendo-grid
  [data]="deploymentItemsView"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="true"
  [pageable]="false"
  [filterable]="true"
  (dataStateChange)="dataStateChange($event)"
  kendoGridSelectBy="id"
  [selectable]="true"
  [style.height.%]="100"
>
  <kendo-grid-column
    field="status"
    title="Status"
    [width]="100"
    [minResizableWidth]="100"
    [filterable]="true"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="buildId"
    title="BuildId"
    [width]="100"
    [minResizableWidth]="100"
    [filterable]="true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <a href="javascript:void(0)" (click)="goToPipelineDetails(dataItem)">{{dataItem.buildId}}</a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="item"
    title="Item"
    [width]="300"
    [minResizableWidth]="300"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <a *ngIf="dataItem.buildId" href="javascript:void(0)" (click)="goToPipelineDetails(dataItem)">{{dataItem.item}}</a>
      <span *ngIf="!dataItem.buildId">{{dataItem.item}}</span>
    </ng-template>
  </kendo-grid-column>
<!--  <kendo-grid-column-->
<!--    field="value"-->
<!--    title="Action"-->
<!--    [width]="50"-->
<!--    [minResizableWidth]="50"-->
<!--    [filterable]="false"-->
<!--  >-->
<!--    <ng-template kendoGridCellTemplate let-dataItem>-->
<!--      <button-->
<!--        *ngIf="dataItem.item === 'Product'"-->
<!--        [disabled]="disableProductDeploy()"-->
<!--        kendoButton (click)="startProductDeploy(dataItem)"-->
<!--        primary="true">Start-->
<!--      </button>-->
<!--    </ng-template>-->
<!--  </kendo-grid-column>-->
</kendo-grid>
