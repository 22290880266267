import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { PagedAuditLogData } from 'src/app/shared/models/PagedAuditLogData';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  private url = `${window.location.origin}/api/ado/audits`;

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService,
    private configService: AppConfigService,
    private authService: AuthService
  ) {
  }


  getAuditLogs(page: number): Observable<PagedAuditLogData> {

    return this.httpClient
      .get<PagedAuditLogData>(`${this.url}?page=${page}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAuditLogs'))
      );
  }

  getSortedAuditLogs(sortProperty: string, isAsc: boolean, page: number): Observable<PagedAuditLogData> {
    
    return this.httpClient
      .get<PagedAuditLogData>(`${this.url}/sorted/${sortProperty}?page=${page}&asc=${isAsc}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getSortedAuditLogs'))
      );
  }

}
