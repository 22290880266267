<div class="text-center">
  <h4>
    <small class="text-muted">Mailbox Manager</small>
  </h4>
</div>
<kendo-tilelayout [columns]="12">
  <kendo-tilelayout-item [col]="1" [colSpan]="3" class="border">
    <kendo-tilelayout-item-body>
      <div class="px-2 pt-2">
        <kendo-stepper
          #stepper
          [style.width.px]="100"
          [steps]="steps"
          stepType="full"
          [(currentStep)]="currentStep"
          [linear]="false"
          orientation="vertical"
        >
        </kendo-stepper>
      </div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="4" [colSpan]="9" class="border">
    <kendo-tilelayout-item-body>
      <div class="px-2 pb-2">
        <form class="k-form" [formGroup]="form">
          <app-mailbox-manager-customer
            *ngIf="currentStep === 0"
            [customerEnvironmentDetails]="form"
          >
          </app-mailbox-manager-customer>
          <app-mailbox-manager-aad
            *ngIf="currentStep === 1"
            [customerEnvironmentDetails]="form"
          >
          </app-mailbox-manager-aad>
          <app-mailbox-manager-summary
            *ngIf="currentStep === 2"
            [customerEnvironmentDetails]="form"
          >
          </app-mailbox-manager-summary>
        </form>
      </div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
<kendo-tilelayout [columns]="12">
  <kendo-tilelayout-item [col]="1" [colSpan]="12" class="border">
    <div class="p-2">
      <div class="k-form-buttons k-buttons-end" *ngIf="currentStep !== 1 && currentStep !== 2">
        <span class="page">Step {{ currentStep + 1 }} of 3</span>
        <div>
          <button
            class="k-button prev"
            kendoButton
            themeColor="secondary"
            *ngIf="currentStep !== 0"
            (click)="prev()"
          >
            Previous
          </button>
          <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="next()"
            *ngIf="currentStep !== 2"
          >
            Next
          </button>
        </div>
      </div>
      <div class="k-form-buttons k-buttons-end" *ngIf="currentStep === 1">
        <span class="page">Step {{ currentStep + 1 }} of 3</span>
        <div>
          <button
            class="k-button prev"
            kendoButton
            themeColor="secondary"
            *ngIf="currentStep !== 0"
            (click)="prev()"
          >
            Previous
          </button>
          <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="next()"
            *ngIf="currentStep !== 2"
          >
            Next
          </button>
        </div>
      </div>
      <div class="k-form-buttons k-buttons-end" *ngIf="currentStep === 2">
        <span class="page">Step {{ currentStep + 1 }} of 3</span>
        <div>
          <button
            class="k-button prev"
            kendoButton
            themeColor="secondary"
            *ngIf="currentStep !== 0"
            (click)="prev()"
          >
            Previous
          </button>
          <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="submit()"
            *ngIf="currentStep === 2"
            [disabled]="isSubmitDisabled()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </kendo-tilelayout-item>
</kendo-tilelayout>
<div kendoDialogContainer></div>
