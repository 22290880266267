import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { User } from '@microsoft/microsoft-graph-types';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-group-users',
  templateUrl: './group-users.component.html',
  styleUrls: ['./group-users.component.scss']
})
export class GroupUsersComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() public groupId: string;

  users: User[] = [];
  usersView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 15,

    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadGroupUsers();
  }

  private loadGroupUsers() {
    this.loaderService.start();
    this.graphGroupService.getGroupMembers(this.groupId).subscribe({
      next: (result) => {
        this.users = result.value;
        this.usersView = process(this.users, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.usersView = process(this.users, this.state);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadGroupUsers();
  }
}
