<kendo-grid
  [data]="tasksView"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="true"
  [pageable]="false"
  [filterable]="true"
  (dataStateChange)="dataStateChange($event)"
  kendoGridSelectBy="id"
  [selectable]="true"
  [style.height.%]="100"
>
  <kendo-grid-column
    field="status"
    title="Status"
    [width]="100"
    [minResizableWidth]="100"
    [filterable]="true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span
        class="stage-status-{{dataItem.status}} p-2"
      >{{dataItem.status}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="name"
    title="Task"
    [width]="300"
    [minResizableWidth]="300"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <a href="javascript:void(0)" (click)="onTaskClicked(dataItem)">{{dataItem.name}}</a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dateStarted"
    title="Time"
    [width]="50"
    [minResizableWidth]="50"
    [filterable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{getDuration(dataItem.dateStarted, dataItem.dateEnded)}} s
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
