import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AtEnvironmentResponse } from 'src/app/shared/models/AtEnvironment';
import { AtTenantResponse } from '../../shared/models/AtTenant';
import { Constants } from 'src/app/shared/Constants';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AtEnvironmentEx } from '../../shared/models/AtEnvironmentEx';
import { AtTenantEx } from '../../shared/models/AtTenantEx';
import { ErrorHandlerService } from '../error-handler.service';
import { TenantEnvironment, TenantEnvironmentResponse } from '../../shared/models/AdminTool/TenantEnvironment';
import { EnvironmentService, EnvironmentServiceResponse } from '../../shared/models/AdminTool/EnvironmentService';
import { ServiceConfiguration, ServiceConfigurationResponse } from '../../shared/models/AdminTool/ServiceConfiguration';
import { AdminServiceQuery } from '../../shared/models/AdminServiceQuery';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminToolService {
  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private authService: AuthService,
    private errorHandling: ErrorHandlerService) {
  }

  getEnvironments(): Observable<AtEnvironmentEx[]> {
    const nonProdEnvs = this.httpClient.get<AtEnvironmentResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_NON_PROD}/Environments?$orderby=Name&$expand=TenantEnvironments`)
      .pipe(
        switchMap(e => {
          return of(e.value);
        }),
        switchMap(es => {
          es.forEach(ev => ev.AdminToolEnv = Constants.ADMIN_TOOL_ENV_NON_PROD);
          return of(es);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironments npn prod'))
      );

    const prodEnvs = this.httpClient.get<AtEnvironmentResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_PROD}/Environments?$orderby=Name&$expand=TenantEnvironments`)
      .pipe(
        switchMap(e => {
          return of(e.value);
        }),
        switchMap(es => {
          es.forEach(ev => ev.AdminToolEnv = Constants.ADMIN_TOOL_ENV_PROD);
          return of(es);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironments prod'))
      );

    return forkJoin([nonProdEnvs, prodEnvs])
      .pipe(
        map(([non_prods, prods]) => non_prods.concat(prods))
      );
  }

  getEnvironmentById(id: number): Observable<AtEnvironmentEx[]> {
    return this.httpClient.get<AtEnvironmentResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_NON_PROD}/Environments/(${id})`)
      .pipe(
        switchMap(e => {
          return of(e.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironmentById'))
      );
  }

  getTenantsNonProd(): Observable<AtTenantEx[]> {

    return this.httpClient.get<AtTenantResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_NON_PROD}/Tenants?$orderby=Name&$skip=0&$top=500&$count=true`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getTenants non prod'))
      );
  }

  getTenantsProd(): Observable<AtTenantEx[]> {

    return this.httpClient.get<AtTenantResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_PROD}/Tenants?$orderby=Name&$skip=0&$top=500&$count=true&$expand=TenantEnvironments`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getTenants Prod'))
      );
  }

  getTenantByIdNonProd(id: number): Observable<AtTenantEx[]> {
    return this.httpClient.get<AtTenantResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_NON_PROD}/Tenants(${id})`)
      .pipe(
        switchMap(t => {
          t.value.forEach(tn => {
            tn.IsSelected = true;
            tn.AdminToolEnv = Constants.ADMIN_TOOL_ENV_NON_PROD;
          });
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getTenantByIdNonProd'))
      );
  }

  getTenantByIdProd(id: number): Observable<AtTenantEx[]> {
    return this.httpClient.get<AtTenantResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_PROD}/Tenants(${id})`)
      .pipe(
        switchMap(t => {
          t.value.forEach(tn => {
            tn.IsSelected = true;
            tn.AdminToolEnv = Constants.ADMIN_TOOL_ENV_PROD;
          });
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getTenantByIdProd'))
      );
  }

  getEnvironmentTenants(atEnvironmentEx: AtEnvironmentEx): Observable<AtTenantEx[][]> {

    let observables: Observable<AtTenantEx[]>[];

    if (atEnvironmentEx.AdminToolEnv === Constants.ADMIN_TOOL_ENV_NON_PROD) {
      observables = atEnvironmentEx.TenantEnvironments.map(te => this.getTenantByIdNonProd(te.TenantId));
    } else if (atEnvironmentEx.AdminToolEnv === Constants.ADMIN_TOOL_ENV_PROD) {
      observables = atEnvironmentEx.TenantEnvironments.map(te => this.getTenantByIdProd(te.TenantId));
    }

    return forkJoin(observables);
  }

  getTenantEnvironmentsByTenantIdNonProd(tenantId: number): Observable<TenantEnvironment[]> {

    return this.httpClient.get<TenantEnvironmentResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_NON_PROD}/TenantEnvironments(${tenantId})`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getTenantEnvironments non prod'))
      );
  }

  getTenantEnvironmentsByTenantIdProd(tenantId: number): Observable<TenantEnvironment[]> {
     return this.httpClient.get<TenantEnvironmentResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_PROD}/TenantEnvironments(${tenantId})`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getTenantEnvironments Prod'))
      );
  }

  getEnvironmentServicesNonProd(environmentId: number): Observable<EnvironmentService[]> {
    return this.httpClient.get<EnvironmentServiceResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_NON_PROD}/EnvironmentServices(${environmentId})`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironmentServices non prod'))
      );
  }

  getEnvironmentServicesProd(environmentId: number): Observable<EnvironmentService[]> {

    return this.httpClient.get<EnvironmentServiceResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_PROD}/EnvironmentServices(${environmentId})`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getEnvironmentServices Prod'))
      );
  }

  getDbConfigurationNonProd(query: AdminServiceQuery): Observable<ServiceConfiguration[]> {

    return this.httpClient.get<ServiceConfigurationResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_NON_PROD}/DbConfiguration`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getDbConfiguration non prod'))
      );
  }

  getDbConfigurationProd(query: AdminServiceQuery): Observable<ServiceConfiguration[]> {
    return this.httpClient.get<ServiceConfigurationResponse>(`${window.location.origin}/api/admin.tool/${Constants.ADMIN_TOOL_PROD}/DbConfiguration?ServiceId=${query.ServiceId}&EnvironmentServiceId=${query.EnvironmentServiceId}&TenantEnvironmentId=${query.TenantEnvironmentId}`)
      .pipe(
        switchMap(t => {
          return of(t.value);
        }),
        catchError(this.errorHandling.handleAllErrors<any>('getDbConfiguration Prod'))
      );
  }
}
