<div class="d-flex justify-content-center flex-wrap p-4">
    <form class="k-form appRegistrationForm" [formGroup]="appRegistration">
      <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">Generate Finance Integration App Registration Script</legend>
        <kendo-formfield>
          <kendo-label [for]="customerId" text="Customer Id"></kendo-label>
          <kendo-textbox formControlName="customerId"
                 kendoTextBox
                 #customerId
                 required 
                 (valueChange)="onCustomerIdChange($event)"></kendo-textbox>
  
          <kendo-formhint>Enter customer Id to generate mailbox app registration script</kendo-formhint>
          <kendo-formerror>Enter customer Id to generate mailbox app registration script</kendo-formerror>
        </kendo-formfield>
  
        <div class="row pt-3">
          <div class="col-6" *ngIf="!showCheckboxError">Select environments:</div>
          <div class="text-danger col-6" *ngIf="showCheckboxError">Select environments:</div>
          <div class="col-3">
            <div class="k-checkbox-wrap" *ngFor="let inputcheckbox of this.checkboxes">
              <input id="{{inputcheckbox.name}}"
                     type="checkbox"
                     kendoCheckBox
                     formControlName="{{inputcheckbox.name.split('(')[0]}}" />
              <kendo-label class="k-checkbox-label"
                           for="{{inputcheckbox.name}}">{{inputcheckbox.name}}</kendo-label>
            </div>
            <div>
              <kendo-icon name="plus" class="clickable" (click)="ShowTextbox()" *ngIf="!showTextbox"></kendo-icon>
            </div>
          </div>
          <kendo-formerror *ngIf="showCheckboxError">
            Please choose an environment!
          </kendo-formerror>
          <div *ngIf="showTextbox" class="pt-3">
            <kendo-textbox placeholder="Enter environment name" formControlName="newCheckbox" [maxlength]="3">
              <ng-template kendoTextBoxSuffixTemplate>
                <kendo-textbox-separator></kendo-textbox-separator>
                <button kendoButton look="clear" class="send-button" (click)="Addcheckbox()">Add</button>
              </ng-template>
            </kendo-textbox>
          </div>
        </div>
  
  
        <div class="k-form-bsuttons">
          <button class="k-button" kendoButton themeColor="primary" icon="download" (click)="generateScript()">
            Download Integration Registration Script
          </button>
        </div>
      </fieldset>
    </form>
  
    </div>
    
    