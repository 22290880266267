import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { ComponentArtifact } from '../../../shared/models/ProductWithVersions';
import { switchMap } from 'rxjs/operators';
import { Artifact, Value } from '../../models/Artifact';
import { Component as DtoComponent } from 'src/app/shared/models/Component';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import * as moment from 'moment';
import { PipelineRunRequest } from '../../models/PipelineRunRequest';
import { Constants } from '../../../shared/Constants';
import { Batch } from '../../../shared/models/Batch';
import { Release } from '../../../shared/models/Release';
import { EnvProductPipelineRunResponse } from '../../models/PipelineRunResponse';
import { BatchType } from '../../../shared/models/BatchType';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, RowArgs, SelectionEvent, CellClickEvent, SelectableSettings, RowClassArgs } from '@progress/kendo-angular-grid';
import { AtEnvironmentEx } from 'src/app/shared/models/AtEnvironmentEx';
import { AtTenantEx } from 'src/app/shared/models/AtTenantEx';
@Component({
  selector: 'app-app-upgrade',
  templateUrl: './app-upgrade.component.html',
  styleUrls: ['./app-upgrade.component.scss']
})

export class AppUpgradeComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  productVersionSectionDisabled = true;

  constructor() {
    super();
  }

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true
  };

  public isDisabled(context: RowClassArgs) {
    return {
      'k-disabled': context.dataItem.disabled
    };

  }

  public isProductDisabled(context: RowClassArgs) {
    return {
      'k-disabled': context.dataItem.disabled
    };

  }

  currentEnvironment: AtEnvironmentEx = null;
  expandedEnvironment: AtEnvironmentEx = null;
  environments: any[] = [];
  environmentsView: GridDataResult;
  selectedEnvironments: AtEnvironmentEx[] = [];
  selectedTenants: AtTenantEx[] = [];
  products= [];
  availableProducts= [];
  addOnProducts=[];
  mailboxTenants=[];
  nonMailboxTenants=[];
  selectedProductIds: number[] = [];
  selectedProducts = [];
  currentEnvType ="";
  mailboxConfiguredTenants = null;
  envTypes:any[];

  public state: State = {
    skip: 0,
    take: 10,

    filter: {
      logic: 'and',
      filters: [],
    },
  };

  static isVersionInLess60Days(c: Value): boolean {
    const lastPublishedDate = c.versions.find(v => v.isLatest && v.isListed)?.publishDate;
    const lastDate = moment(lastPublishedDate);
    return lastDate.add(60, 'day').isAfter(moment());
  }

  static getMajorVersion(version: string): string {
    return version.split('.').slice(0, 3).join('.');
  }

  public getTenantCodes(environment: AtEnvironmentEx) {
    return environment.Tenants
      .filter(t => t.IsSelected && this.selectedTenants.find(x=>x.Code == t.Code))
      .map(tn => tn.Code)
      .join(', ');
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public environmentKey(context: RowArgs): string {
    return context.dataItem;
  }

  private loadData() {
    this.loaderService.start();
    this.sub = this.adminToolService.getEnvironments()
    .subscribe({
      next: (result) => {
        this.environments = result;
        this.envTypes = this.distinct(this.environments)
        this.environmentsView = process(this.environments, this.state);
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      }
    });
    // this.sub = this.productService.getComponents()
    //   .subscribe(components =>
    //     this.sub = this.fetchArtifacts(components).subscribe(cfs => {
    //         cfs.forEach(cf => this.buildProductVersions(cf.component, cf.artifact));
    //         this.loaderService.stop();
    //       }
    //     ));
    this.sub = this.adminService.getMappedCoreProductsForUpgrades(false)
    .subscribe({
      next: (result) => {
        this.products = result;
        this.availableProducts = (JSON.parse(JSON.stringify(this.products)));
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    });

  }

  private loadMailboxData() {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getTenantsWithMailbox()
    .subscribe({
      next: (result) => {
        this.mailboxConfiguredTenants = result;
        this.configureMailboxUI();
        this.loaderService.stop();
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
          this.loaderService.stop();
        }
    });
  }

  public onValueChange(data){
    this.loaderService.start();
    data.version = data.selectedVersion.version;
      this.sub = this.adminService.getAddOnProducts(data.selectedVersion.id)
      .subscribe({
        next: (result) => {
          data.addOnProducts = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.environmentsView = process(this.environments, this.state);
  }

  private fetchArtifacts(components: DtoComponent[]): Observable<ComponentArtifact[]> {
    const observables: Observable<ComponentArtifact>[] =
      components.map(c =>
        this.artifactService.getArtifactByName(c.product.feedName, c.name)
          .pipe(
            switchMap(af => {
              const ca: ComponentArtifact = {
                artifact: af,
                component: c
              };
              return of(ca);
            })
          )
      );

    return forkJoin(observables);
  }

  private buildProductVersions(c: DtoComponent, a: Artifact): void {
    if (this.products.some(pv => pv.name === c.product.name)) {
      this.products.forEach((p, i) => {
        if (p.name === c.product.name) {
          const compVersions = this.getComponentVersions(a, c);
          this.products[i] = {
            'id': c.product.id,
            'name': c.product.name,
            'version': null,
            'versions': p.versions.filter(v => compVersions.includes(v)).sort().reverse()
          };
          return true; // stop search
        }
      });
    } else {
      this.products.push({
        'id': c.product.id,
        'name': c.product.name,
        'version': null,
        'versions': this.getComponentVersions(a, c)
      });
    }
  }

  private getComponentVersions(a: Artifact, c: DtoComponent): string[] {
    const n = c.name.replace('.', '\\.');
    const regex = new RegExp(`${n}|${n}((\\.|\\s)\\d{0,2}\\.\\d{0,2}\\.\\d{0,2}|.\\s)`, 'i');
    const versions = a.value
      .filter(comp => regex.test(comp.name))
      .filter(com => AppUpgradeComponent.isVersionInLess60Days(com))
      .map(com => AppUpgradeComponent.getMajorVersion(com.versions[0].version));

    return [...new Set(versions)];
  }

  onStartAction() {
    this.loaderService.start();
    let stopLoader = true;
    this.InitializeBatch()
      .subscribe({
        next: (result) => {
          stopLoader = false
          this.UpdateTemplateFiles(this.selectedEnvironments, this.selectedProducts,result);
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`${error}`, 'Failure!');
        },
        complete: () => {
          if(stopLoader){
            this.loaderService.stop();
          }
        }
      });
  }

  private RunAllPipelines(requests) {
    requests.forEach(pipelineRequest=>{
      const pipelineRequests = [];
      pipelineRequests.push(pipelineRequest);
      this.pipelineService.runPipelinesOfProductsFireAndForget(this.configService.MainProject, pipelineRequests);
    });
  }

  private UpdateTemplateFiles(environments: any[], products,batch: Batch) {
    const requests: PipelineRunRequest[] = [];
    environments.forEach(en => {
      products.forEach(prod => {
        const request = {
          'templateParameters': {
            'environmenttype': en.Code,
            'customertenants': this.getTenantCodes(en),
            'product': prod.name,
            'version': prod.version,
            'addOnProducts':prod.addOnProducts,
            'environmentObject': en,
            'productObject': prod
          },
          'batchId': batch.id,
          'createdName': batch.createdBy
        };
        requests.push(request);
      });
    });


    this.pipelineService.updateTemplateFileOfProducts(requests)
      .subscribe({
        next: (result) => {
          this.RunAllPipelines(result)
          this.clearSelections();
          this.loaderService.stop();
          this.toastr.success(`Starting pipelines of products...`, 'Success!')
            .onHidden.subscribe(() => {
            this.router.navigate([`${Constants.PIPELINES}`])
              .then();
          });
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`${error}`, 'Failure!');
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  private clearSelections() {
    this.selectedProducts = [];
    this.selectedProductIds = [];
    this.selectedEnvironments = [];
  }

  productSelectionChange($event: any[]) {
    this.selectedProducts = $event.map(idx => {
      return this.availableProducts.find(item => item.id === idx);
    });

    var item = this.selectedProducts.find(x => x.name == Constants.Mailbox || x.name == Constants.MailboxCNC);
    if (item) {
      if(this.mailboxConfiguredTenants != null){

        this.configureMailboxUI();
      }
      else{
        this.loadMailboxData();
      }
    }
    else {
      if (this.selectedProducts != null && this.selectedProducts.length > 0) {
        this.availableProducts.forEach(x => {
          x.disabled = x.name == Constants.Mailbox || x.name == Constants.MailboxCNC;
        });
      }
      else {
        this.availableProducts.forEach(x => {
          x.disabled = false;
        });
      }
      if (this.nonMailboxTenants.length > 0 && JSON.stringify(this.mailboxTenants) == JSON.stringify(this.selectedTenants)) {
        this.selectedTenants = JSON.parse(JSON.stringify(this.nonMailboxTenants));
      }
    }
  }

  private configureMailboxUI() {
    this.availableProducts.forEach(x => {
      if (x.name != Constants.Mailbox && x.name != Constants.MailboxCNC) {
        x.disabled = true;
      }
    });
    //var tempSelectedTenants = JSON.parse(JSON.stringify(this.selectedTenants));
    this.nonMailboxTenants = JSON.parse(JSON.stringify(this.selectedTenants));
    this.mailboxTenants = [];
    this.selectedTenants.forEach(element => {
      if (this.mailboxConfiguredTenants.includes(element.Code)) {
        this.mailboxTenants.push(element);
      }
    });
    this.selectedTenants = JSON.parse(JSON.stringify(this.mailboxTenants));
  }

  disableStartButton(): boolean {
    return this.selectedEnvironments.length <= 0 ||
      this.selectedProducts.length <= 0 ||
      this.selectedProducts.some(p => !p.version);
  }

  createBatch(responses: EnvProductPipelineRunResponse[][]): Observable<Release[]> {
    const consolidatedRes: EnvProductPipelineRunResponse[] = [];
    responses.forEach(res => {
      res.forEach(r => {
        consolidatedRes.push(r);
      });
    });
    const batch = new Batch();
    batch.name = `${this.AccountInfo?.name}-${moment().format()}`;
    batch.type = BatchType.Pipeline;
    batch.projectName = this.configService.MainProject;
    batch.createdBy = this.AccountInfo?.name;
    batch.createdDate = new Date();
    batch.modifiedBy = this.AccountInfo?.name;
    batch.modifiedDate = new Date();

    return this.batchService.createBatchForMTUpgrades(batch)
      .pipe(
        switchMap(b => this.addAllReleasesToDb(consolidatedRes, b))
      );
  }

  InitializeBatch(): Observable<Batch> {
    const batch = new Batch();
    batch.name = `${this.AccountInfo?.name}-${moment().format()}`;
    batch.type = BatchType.Pipeline;
    batch.projectName = this.configService.MainProject;
    batch.createdBy = this.AccountInfo?.name;
    batch.createdDate = new Date();
    batch.modifiedBy = this.AccountInfo?.name;
    batch.modifiedDate = new Date();

    return this.batchService.createBatchForMTUpgrades(batch);
  }

  private addAllReleasesToDb(responses: EnvProductPipelineRunResponse[], batch: Batch): Observable<Release[]> {
    const observables: Observable<Release>[] = [];

    responses.forEach(r => {
      observables.push(this.addReleaseToDb(r, batch));
    });

    return forkJoin(observables);
  }

  private addReleaseToDb(response: EnvProductPipelineRunResponse, batch: Batch): Observable<Release> {
    const release: Release = {
      'id': response.Response.id,
      'batchId': batch.id,
      'name': `${response.Environment.Name}_${response.ProductVersion.name}_${response.ProductVersion.version}`,
      'batch': undefined,
      'projectName': this.configService.MainProject,
      'customerId': this.getTenantCodes(response.Environment),
      'customerName': `${response.Environment.Name}_${response.ProductVersion.name}_${response.ProductVersion.version}`,
      'stage': null,
      'createdBy': this.AccountInfo?.name,
      'createdDate': new Date(),
      'modifiedBy': this.AccountInfo?.name,
      'modifiedDate': new Date(),
      'azBuild': undefined
    };
    return this.releaseDbService.createRelease(release);
  }

  cellClick($event: CellClickEvent) {
    this.currentEnvironment = $event.dataItem;
  }

  public onExpand(event){
    this.expandedEnvironment = event.dataItem;
    if (this.expandedEnvironment.Tenants) {
      return;
    }

    this.loaderService.start();

    this.adminToolService.getEnvironmentTenants(this.expandedEnvironment)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.expandedEnvironment.Tenants = result.flat();
          this.filterTenantsOfProdEnvironment(this.expandedEnvironment);
          //this.selectedTenants.push.apply(this.selectedTenants, this.expandedEnvironment.Tenants);
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });

    return;
  }

  selectedRowChangeEnvironment($event: SelectionEvent) {
    if ($event.selectedRows.length <= 0) {
      this.currentEnvironment = null;
      if ($event.deselectedRows.length > 0) {
        $event.deselectedRows[0].dataItem.Tenants.forEach(element => {
          this.selectedTenants = this.selectedTenants.filter(item => item.Code != element.Code);
          if(this.selectedTenants.length == 0){
            this.environments.forEach(x=>{
              x.disabled = false
            })
            this.environmentsView = process(this.environments, this.state);
            this.clearSelections();
            this.productVersionSectionDisabled = true;
          }
        });

        return;
      }
      return;
    }

    // if ($event.deselectedRows.length > 0) {
    //   this.currentEnvironment = null;
    //   return;
    // }

    this.currentEnvironment = $event.selectedRows[0].dataItem;
    if(this.selectedTenants.length == 0){
      this.environments.forEach(x=>{
        x.disabled = x.AdminToolEnv != this.currentEnvironment.AdminToolEnv;
      })
      if(this.currentEnvironment.AdminToolEnv == "Non-Prod" ){
        this.currentEnvType = "Internal"
      }
      else if(this.currentEnvironment.AdminToolEnv == "Prod" ){
        this.currentEnvType = "GA"
      }
      this.environmentsView = process(this.environments, this.state);
      this.availableProducts=[]
      this.products.forEach(product=>{
        var availableProduct = (JSON.parse(JSON.stringify(product)));
        availableProduct.versionDetails=[]
        product.versionDetails.forEach(version => {
          if(this.currentEnvironment.AdminToolEnv == "Non-Prod"){
            availableProduct.versionDetails.push(version)
          }
          if(this.currentEnvironment.AdminToolEnv == "Prod" && version.name.includes('GA')){
            availableProduct.versionDetails.push(version)
          }
        });
        this.availableProducts.push(availableProduct);
      })
      this.productVersionSectionDisabled = false;
    }

    if (this.currentEnvironment.Tenants) {
      this.currentEnvironment.Tenants.forEach(element => {
        this.selectedTenants = this.selectedTenants.filter(item => item.Code != element.Code);
      });
      this.selectedTenants.push.apply(this.selectedTenants, this.currentEnvironment.Tenants);
      return;
    }

    this.loaderService.start();

    this.adminToolService.getEnvironmentTenants(this.currentEnvironment)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.currentEnvironment.Tenants = result.flat();
          this.filterTenantsOfProdEnvironment(this.currentEnvironment);
          this.selectedTenants.push.apply(this.selectedTenants, this.currentEnvironment.Tenants);
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  filterTenantsOfProdEnvironment(environment){
    var tempTenants:AtTenantEx[]=[];
    environment.Tenants.forEach(t=>{
      var isConfigured= false;
      t.TenantEnvironments.forEach(element => {
        if(element.EnvironmentId == environment.Id){
          isConfigured=element.IsConfigured
        }
      });
      if(!(t.Code.includes(Constants.OBSOLETE)) && isConfigured){
        tempTenants.push(t);
      }
    })
    environment.Tenants=tempTenants
  }

  public rowCallback(context: RowClassArgs) {
    if (context.dataItem.AdminToolEnv == Constants.ADMIN_TOOL_ENV_PROD && context.dataItem.Code.startsWith(Constants.NON_PROD_CODE)) {   // change this condition as you need
      return {
        highlight: true
      };
    }

  }

 distinct = (data) =>
  data
    .map((x) => x)
    .filter(
      (x, idx, xs) =>
        xs.findIndex((y) => y.AdminToolEnv === x.AdminToolEnv) === idx
    );
}
