<kendo-tabstrip id="permission-manage">
  <kendo-tabstrip-tab title="Permission" [selected]="true">
    <ng-template kendoTabContent>
      <app-permission-manage></app-permission-manage>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Policy">
    <ng-template kendoTabContent>
      <app-policy></app-policy>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
