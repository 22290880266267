import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { ReleaseFilterModel } from '../../../../shared/models/ReleaseFilterModel';
import { Release } from '../../../../shared/models/Release';
import { Constants } from '../../../../shared/Constants';
import { Observable } from 'rxjs';
import { AzRelease } from '../../../models/AzRelease';
import { DataStateChangeEvent, GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-db-refresh-logs',
  templateUrl: './db-refresh-logs.component.html',
  styleUrls: ['./db-refresh-logs.component.scss']
})
export class DbRefreshLogsComponent extends BaseComponent implements OnInit {
  releases: Release[];
  releasesView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadDbRefreshLogs();
  }

  private loadDbRefreshLogs() {
    this.loaderService.start();
    const filter = {'batchId': -1} as ReleaseFilterModel;
    this.releaseDbService.getDbRefreshReleases(filter).subscribe({
      next: (result) => {
        this.releases = result;
        this.releasesView = process(this.releases, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.releasesView = process(this.releases, this.state);
  }

  releaseSelectionChanged($event: SelectionEvent) {
    const release = $event.selectedRows[0].dataItem;

    console.log(release);

    this.loaderService.start();
    this.PopulateAzReleases(release)
      .subscribe({
      next: (l) => {
        const env = this.getEnvironment(l);

        this.dataService.updateEnvironment(env);
        this.router.navigate([`${Constants.DB_REFRESH_LOGS}/${Constants.STAGE_DETAIL}/${env.id}`]).then();

        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  private PopulateAzReleases(release: Release): Observable<AzRelease> {
    return this.releaseService.getReleaseById(release.projectName, release.id)
  }

  getEnvironment(release: AzRelease): any {
    return release.environments.find(e => e.deploySteps.length > 0);
  }
}
