<div *ngIf="!viewDetailsPageActive">
  <kendo-tilelayout
    [columns]="12"
    [resizable]="true"
    [reorderable]="true"
  >
    <kendo-tilelayout-item [col]="1" [colSpan]="6">
      <kendo-tilelayout-item-body>
        <div class="textElement">Multitenant Sync Pipeline last run on: <b>{{this.mtLastRunTime}}</b></div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="7" [colSpan]="6">
      <kendo-tilelayout-item-body>
        <div class="textElement">CNC 1.x Sync Pipeline last run on: <b>{{this.cncLastRunTime}}</b></div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="1" [colSpan]="3">
      <kendo-tilelayout-item-body>
        <kendo-textbox placeholder="Search" class="w-100 h-100" [(ngModel)]="searchTerm" (keydown)="onKeyDown($event)">
          <ng-template kendoTextBoxSuffixTemplate>
            <kendo-icon name="search"></kendo-icon>
          </ng-template>
        </kendo-textbox>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item [col]="4" [colSpan]="1">
      <kendo-tilelayout-item-body>
        <button kendoButton primary="true" (click)="search()" class="w-100 h-100">Search</button>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item [col]="5" [colSpan]="1">
      <kendo-tilelayout-item-body>
        <button kendoButton (click)="reset()" class="w-100 h-100">Reset</button>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="hideSelection">
      <kendo-tilelayout-item-body>
        <button kendoButton (click)="hideShowSelection()" primary="true" class="w-100 h-100" icon="arrow">Show Platform
          & Product Filter
          <kendo-icon name="arrow-chevron-right"></kendo-icon>
        </button>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="!hideSelection">
      <kendo-tilelayout-item-body>
        <button kendoButton (click)="hideShowSelection()" primary="true" class="w-100 h-100" icon="arrow">Hide Platform
          & Product Filter
          <kendo-icon name="arrow-chevron-down"></kendo-icon>
        </button>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
  </kendo-tilelayout>

  <div class="k-block" *ngIf="filters.length > 0">
    <kendo-chip
      *ngFor="let filter of filters"
      [label]="filter"
      [removable]="true"
      (remove)="onFilterRemove($event)"
    >
    </kendo-chip>
  </div>

  <kendo-tilelayout
    [columns]="8"
    [resizable]="true"
    [reorderable]="true"
    *ngIf="!hideSelection">
    <kendo-tilelayout [rowHeight]="200">
      <kendo-tilelayout-item [col]="1" [colSpan]="12">
        <kendo-tilelayout-item-body class="px-3 pt-3 pb-3">
          <p><b>Platform</b></p>
        </kendo-tilelayout-item-body>
        <kendo-tilelayout-item-body class="px-3">
          <input type="checkbox" #cnc1 kendoCheckBox [(ngModel)]="platform.cnc1"
                 (change)="searchClicked && onValueChange($event)"/>
          <kendo-label
            class="k-checkbox-label"
            [for]="cnc1"
            text="CNC 1.x"
          ></kendo-label>
        </kendo-tilelayout-item-body>
        <!--    <kendo-tilelayout-item-body class="px-3">-->
        <!--      <input type="checkbox" #cnc2 kendoCheckBox [(ngModel)]="platfrom.cnc2" (change)="searchClicked && onValueChange($event)"/>-->
        <!--      <kendo-label-->
        <!--        class="k-checkbox-label"-->
        <!--        [for]="cnc2"-->
        <!--        text="CNC 2.x"-->
        <!--      ></kendo-label>-->
        <!--    </kendo-tilelayout-item-body>-->
        <kendo-tilelayout-item-body class="px-3">
          <input type="checkbox" [disabled]="true" #onprem kendoCheckBox [(ngModel)]="platform.onPrem"
                 (change)="searchClicked && onValueChange($event)"/>
          <kendo-label
            class="k-checkbox-label"
            [for]="onprem"
            text="On Prem"
          ></kendo-label>
        </kendo-tilelayout-item-body>
        <kendo-tilelayout-item-body class="px-3 pb-3">
          <input type="checkbox" #multitenant kendoCheckBox [(ngModel)]="platform.multiTenant"
                 (change)="searchClicked && onValueChange($event)"/>
          <kendo-label
            class="k-checkbox-label"
            [for]="multitenant"
            text="Multitenant"
          ></kendo-label>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
    <kendo-tilelayout-item [col]="3" [colSpan]="3">
      <kendo-tilelayout-item-body class="px-3 pt-3 pb-3">
        <p><b>Product & Version</b></p>
      </kendo-tilelayout-item-body>
      <kendo-tilelayout-item-body>
        <kendo-treeview
          [nodes]="products"
          [textField]="['name','']"
          kendoTreeViewHierarchyBinding
          childrenField="versions"
          kendoTreeViewExpandable
          [expandedKeys]="expandedKeys"
          kendoTreeViewCheckable
          [(checkedKeys)]="productVersionCheckedKeys"
          (checkedChange)="searchClicked && onValueChange($event)"
        >
        </kendo-treeview>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
  <div class="row p-1" *ngIf="searchClicked">
    <div class="col-6 customLabel">
      <kendo-label text="Show internal environments">

      </kendo-label>
    </div>
    <div class="col-6">
      <kendo-switch [(ngModel)]="checked" size="small" (valueChange)="onSwitchChange($event)"></kendo-switch>
    </div>
  </div>
  <kendo-grid
    kendoGridSelectBy="id"
    [resizable]="true"
    [selectable]="true"
    [data]="gridData"
    [filter]="state.filter"
    [sort]="state.sort"
    [sortable]="true"
    filterable="menu"
    [pageable]="true"
    [pageSize]="state.take"
    [skip]="state.skip"
    (filterChange)="filterChange($event)"
    (sortChange)="sortChange($event)"
    (pageChange)="pageChange($event)"
    *ngIf="searchClicked"
  >
    <ng-template kendoGridToolbarTemplate>
      <button type="button" themeColor="primary" kendoGridExcelCommand icon="file-excel">
        Export to Excel
      </button>
    </ng-template>
    <kendo-grid-column
      field="customerCode"
      title="Code"
      [width]="65"
      [minResizableWidth]="200"
    >
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="customerName"
      title="Customer Name"
      [width]="140"
      [minResizableWidth]="200"
    >
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="environmentName"
      title="Environment Name"
      [width]="120"
      [minResizableWidth]="200"
    >
      <ng-template kendoGridFilterMenuTemplate
                   let-filter let-column="column" let-filterService="filterService">

        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="environmentType"
      title="Environment Type"
      [width]="120"
      [minResizableWidth]="200"
    >
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="platform"
      title="Platform"
      [width]="75"
      [minResizableWidth]="200"
    >
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="azureDirectory"
      title="Directory"
      [width]="75"
      [minResizableWidth]="200"
    >
      <ng-template kendoGridFilterMenuTemplate
                   let-filter let-column="column" let-filterService="filterService">

        <kendo-grid-string-filter-menu
          [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      [width]="50"
      [minResizableWidth]="50"
      [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <!-- <a class="k-button k-primary" routerLink="../environmentDetails/{{ dataItem.id }}">
          Details
        </a> -->
        <!-- <a class="k-button k-primary" themeColor="primary" (click)="viewDetails(dataItem.id)">
          Details
        </a> -->
        <button kendoButton themeColor="primary" (click)="viewDetails(dataItem.id)">Details</button>

      </ng-template>
    </kendo-grid-column>
    <kendo-grid-excel fileName="Environments.xlsx" [fetchData]="allEnvironmentsExportData"
    ></kendo-grid-excel>
  </kendo-grid>
</div>

<div *ngIf="viewDetailsPageActive">
  <div class="log-wrapper">
    <kendo-window
      title="Product"
      *ngIf="productDetailsOpened"
      (close)="close()"
      [minWidth]="250"
      [width]="450"
    >
      <form class="k-form">
        <fieldset>
          <legend>Product Details:</legend>

          <label class="k-form-field">
            <span><b>Product: </b></span>
            <span>{{selectedMinorProductHistoryDetails.product}}</span>
          </label>

          <label class="k-form-field">
            <span><b>Version: </b></span>
            <span>{{selectedMinorProductHistoryDetails.version}}</span>
          </label>

          <label class="k-form-field">
            <span><b>Deployed Date: </b></span>
            <span>{{selectedMinorProductHistoryDetails.deployedDate}}</span>
          </label>

          <label class="k-form-field">
            <span><b>Is Current: </b></span>
            <span>{{selectedMinorProductHistoryDetails.isCurrent}}</span>
          </label>
        </fieldset>
        <div class="k-actions k-actions-end">
          <button kendoButton themeColor="secondary" (click)="close()">
            Close
          </button>
        </div>
      </form>
    </kendo-window>
  </div>
  <div class="row">
    <kendo-tilelayout
      [columns]="12"
      [resizable]="true"
      [reorderable]="true"
    >
      <kendo-tilelayout-item [col]="4" [colSpan]="4">
        <kendo-tilelayout-item-body>
          <button kendoButton (click)="backToSearch()" primary="true" class="w-100 h-100" icon="arrow">Back To Search
            Page
            <kendo-icon name="arrow-chevron-left"></kendo-icon>
          </button>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
    <!-- <button kendoButton primary="true" (click)="backToSearch()" class="w-25 px-3">Back to Search Page</button> -->
  </div>
  <div class="row" *ngIf="environment">
    <div class="panelbar-wrapper">
      <kendo-panelbar
        [expandMode]=kendoPanelBarExpandMode.Single>
        <kendo-panelbar-item title="Environment Details" [expanded]="true">
          <ng-template kendoPanelBarContent>
            <div>
              <div class="productDetails">
              <span class="product-info px-3">
                <h2>{{this.environment === undefined || this.environment === null ? null : this.environment.customerCode}}</h2>
                <p>Customer Code</p>
              </span>
              </div>
              <div class="productDetails">
              <span class="product-info px-3">
                <h2>{{this.environment === undefined || this.environment === null ? null : this.environment.customerName}}</h2>
                <p>Customer Name</p>
              </span>
              </div>
              <div class="productDetails">
              <span class="product-info px-3">
                <h2>{{this.environment === undefined || this.environment === null ? null : this.environment.environmentName}}</h2>
                <p>Environment Name</p>
              </span>
              </div>
              <div class="productDetails">
              <span class="product-info px-3">
                <h2>{{this.environment === undefined || this.environment === null ? null : this.environment.environmentType}}</h2>
                <p>Environment Type</p>
              </span>
              </div>
              <div class="productDetails">
              <span class="product-info px-3">
                <h2>{{this.environment === undefined || this.environment === null ? null : this.environment.platform}}</h2>
                <p>Platform</p>
              </span>
              </div>
              <div class="productDetails">
              <span class="product-info px-3">
                <h2>{{this.environment === undefined || this.environment === null ? null : this.environment.azureDirectory}}</h2>
                <p>Directory</p>
              </span>
              </div>
            </div>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </div>
    <div class="panelbar-wrapper">
      <kendo-panelbar
        [expandMode]=kendoPanelBarExpandMode.Single>
        <kendo-panelbar-item title="Products" [expanded]="true">
          <ng-template kendoPanelBarContent>
            <div class="list">
              <div [ngClass]="selected === product.product ? 'product selectedProduct' : 'product'"
                   *ngFor="let product of this.environment.currentProductVersionList">
                <a (click)="productClicked(product)">
                  <div class="k-hstack">
                    <div>
                      <h2>{{ product.product }}</h2>
                      <p>{{ product.majorVersion }}</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </div>

    <div class="panelbar-wrapper">
      <kendo-panelbar
        [expandMode]=kendoPanelBarExpandMode.Single>
        <kendo-panelbar-item title="Product History" [expanded]="true">
          <ng-template kendoPanelBarContent>
            <div class="list">
              <div class="product" *ngFor="let product of this.selectedProductHistory">
                <a (click)="productHistoryClicked(product)">
                  <div class="k-hstack">
                    <div>
                      <h2>{{ product.product }}</h2>
                      <p>{{ product.majorVersion }}</p>
                      <div
                        *ngIf="selectedProductHistoryDetails && product.majorVersion===selectedProductHistoryDetails.majorVersion">
                        <div
                          [ngClass]="selectedMinorHistory && selectedMinorHistory === productMinor.version ? 'product selectedProduct' : 'product'"
                          *ngFor="let productMinor of this.selectedProductHistoryMinor">
                          <a (click)="minorProductHistoryClicked(productMinor)">
                            <div class="k-hstack">
                              <div>
                                <p>{{ productMinor.product }}</p>
                                <p>{{ productMinor.version }}</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </div>
  </div>
</div>
