
  <kendo-appbar positionMode="sticky" style="padding: 20px;">
    <kendo-appbar-section>
    </kendo-appbar-section>
    <kendo-appbar-section>
      <button
      kendoButton
      (click)="backToAllLogs()"
      themeColor="primary"
    >
      Back To Logs
    </button>
    </kendo-appbar-section>
  </kendo-appbar>

<kendo-grid
  [data]="releaseLogsView"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="true"
  [pageable]="true"
  [filterable]="true"
  kendoGridSelectBy="id"
  [selectable]="true"
  (dataStateChange)="dataStateChange($event)"
  [style.height.%]="100"
>
  <kendo-grid-checkbox-column
    showSelectAll="true"
    [width]="15"
    [minResizableWidth]="15"
    [headerStyle]="{'text-align': 'center'}"
    [style]="{'text-align': 'center'}"
  ></kendo-grid-checkbox-column>
  <kendo-grid-column
    field="name"
    title="Name"
    [width]="100"
    [minResizableWidth]="200"
  ></kendo-grid-column>
  <kendo-grid-column
    field="projectName"
    title="projectName"
    [width]="100"
    [minResizableWidth]="100"
  ></kendo-grid-column>
  <kendo-grid-column
    field="customerId"
    title="customerId"
    [width]="100"
    [minResizableWidth]="200"
  ></kendo-grid-column>
  <kendo-grid-column
    field="id"
    title="Id"
    [width]="100"
    [minResizableWidth]="100"
    [filterable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.id}}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
