import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { Constants } from '../../shared/Constants';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { PipelineRunRequest } from '../../deployments/models/PipelineRunRequest';
import { Infrastructure } from '../../shared/models/devtestslab/Infrastructure';
import { switchMap } from 'rxjs/operators';
import { PipelineRunResponse } from '../../deployments/models/PipelineRunResponse';
import { DtlDeployment } from '../../shared/models/DtlDeployment';
import { DtlDeploymentItem } from '../../shared/models/DtlDeploymentItem';
import { ProductVersionComponent } from './product-version/product-version.component';

@Component({
  selector: 'app-new-dev-tests-lab',
  templateUrl: './new-dev-tests-lab.component.html',
  styleUrls: ['./new-dev-tests-lab.component.scss']
})
export class NewDevTestsLabComponent extends BaseComponent implements OnInit {
  @ViewChild(ProductVersionComponent) productVersionComponent: ProductVersionComponent;

  private sub: Subscription;
  public infrastructure: Infrastructure = new Infrastructure();

  readonly Constants = Constants;
  public currentStep = 0;

  productDeployVariables: any[] = [];
  productParams: any[] = [];

  variables: any = {};

  hiddenVariables: string[] = [
    // 'ConfigureAAD',
    // 'ConfigureAADPBI',
    // 'ActiveDirectory',
    // 'CustomerID'
  ];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.PopulateProductDeployVariables();
  }

  onStartAction() {
    const request: PipelineRunRequest = {
      'templateParameters': {
        'AdoPatToken': 'jyow43qwerbvo576qvpbk6djpw5r6oyvgoqncqpyuey5nuh6caqa',
        'DomainPassword': 'lv217GZLOQ4B',
        'ApplicationName': this.infrastructure.ApplicationName,
        'EnvironmentNumber': this.infrastructure.EnvironmentNumber,
        'LabInstance': 1,
        'OUPath': `\"${this.infrastructure.Organization?.OUPath}\"`,
        'PortalDatabaseFileName': this.infrastructure.StudentPortalDatabase?.name,
        'Requestor': this.infrastructure.Requestor.replace(/\s/g, '_'), //'priyanka',  //this.AccountInfo?.name,
        'StudentDatabaseFileName': this.infrastructure.StudentDatabase?.name,
        'SubscriptionName': this.infrastructure.Subscription?.Name,
        'SubnetName': this.infrastructure.SubNetName,
        'CallbackUrl': this.configService.AzureAd.RedirectUrl,
        'Remark': this.infrastructure.Remark
      }
    };

    this.loaderService.start();
    const createInfrastructure = this.pipelineService.runPipeline(this.configService.MainProject, this.configService.Pipelines.DevTestsLabCreateNewEnvDefinitionId, request);
    this.sub = createInfrastructure
      .pipe(
        switchMap(pr => this.createNewDtlDeployment(pr)),
        switchMap(dp => this.createNewInfrastructure(dp, request)),
        switchMap(dpi => this.createNewProductDeployment(dpi))
      ).subscribe(
        {
          next: (dpi) => {
            this.toastr.success(`New Environment pipeline was started successfully: ${dpi.dtlDeploymentId}!`, 'Success!')
              .onHidden.subscribe(() => {
              this.router.navigate([`${Constants.DEV_TESTS_LAB_LOGS}`])
                .then();
            });
            this.loaderService.stop();
            this.resetForm();
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
            this.resetForm();
          }
        }
      );
  }

  private getValueByNameFromArray(key: string) {
    return this.productDeployVariables.find(v => v.Name === key)?.Value;
  }

  private resetForm() {
    this.infrastructure = new Infrastructure();
  }

  disableStartButton(): boolean {
    return Object.values(this.infrastructure).some(x => x == null);
  }

  private PopulateProductDeployVariables() {
    this.loaderService.start();
    this.sub = this.releaseService.getReleaseDefinition(this.configService.StudentProject, +this.configService.Pipelines.AutomatedDeployReleaseDefinitionId)
      .subscribe({
        next: (rd) => {
          this.loaderService.stop();
          this.productDeployVariables = this.buildVariableArray(rd.variables);
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }

  private buildVariableArray(variables: any): any[] {
    const v = [];
    for (const key of Object.keys(variables)) {
      if (variables[key].allowOverride && !this.hiddenVariables.includes(key)) {
        v.push({'Name': key, 'Value': variables[key].value});
      }
    }

    return v;
  }

  public steps = [
    {
      label: 'Infrastructure Configuration',
      isValid: true,
      validate: false,
    },
    {
      label: 'Product Configuration',
      isValid: true,
      validate: false,
      disabled: false
    },
    {
      label: 'Summary',
      isValid: true,
      validate: false,
      disabled: false
    },
  ];

  private createNewDtlDeployment(pr: PipelineRunResponse): Observable<[PipelineRunResponse, DtlDeployment]> {
    const request: DtlDeployment = {
      // 'customerId': this.getValueByNameFromArray('CustomerID'),
      'customerId': this.infrastructure.EnvironmentNumber,
      'customerName': '',
      'createdBy': this.AccountInfo?.name,
      'modifiedBy': this.AccountInfo?.name
    } as DtlDeployment;
    const deployment = this.dtlDeploymentService.createDtlDeployment(request);
    return forkJoin([of(pr), deployment]);
  }

  private createNewInfrastructure(deploy: [PipelineRunResponse, DtlDeployment], request: PipelineRunRequest): Observable<DtlDeploymentItem> {
    const infrastructure: DtlDeploymentItem = {
      'dtlDeploymentId': deploy[1].id,
      'buildId': deploy[0].id,
      'item': 'Infrastructure',
      'value': JSON.stringify(request)
    } as DtlDeploymentItem;
    return this.dtlDeploymentItemService.createDtlDeploymentItem(infrastructure);
  }

  private createNewProductDeployment(deployItem: DtlDeploymentItem): Observable<DtlDeploymentItem> {
    const product: DtlDeploymentItem = {
      'dtlDeploymentId': deployItem.dtlDeploymentId,
      'item': 'Product',
      'value': JSON.stringify(this.variables)
    } as DtlDeploymentItem;
    return this.dtlDeploymentItemService.createDtlDeploymentItem(product);
  }

  private buildVariables(productDeployVariables: any[]) {
    const variables = {};
    productDeployVariables.forEach(v => NewDevTestsLabComponent.setVariableValue(variables, v.Name, v.Value));

    // Set CustomerId to Environment Number
    NewDevTestsLabComponent.setVariableValue(variables, 'CustomerID', this.infrastructure.EnvironmentNumber);

    // Set ExecuteSQL = always true
    NewDevTestsLabComponent.setVariableValue(variables, 'ExecuteSQL', 'true');

    // Set CleanArtifacts = always true
    NewDevTestsLabComponent.setVariableValue(variables, 'CleanArtifacts', 'true');

    this.SetAuthentication(variables);

    // Set Anthology Student version
    NewDevTestsLabComponent.setVariableValue(variables, 'ShortVersion', this.productVersionComponent.selectedStudentAppVersion.version);

    // Set 1098T version
    NewDevTestsLabComponent.setVariableValue(variables, '1098TShortVersion', this.getProductVersionByName(Constants.PRODUCT_MAPPING['1098TShortVersion']));

    // Set CNF version
    NewDevTestsLabComponent.setVariableValue(variables, 'CnfShortVersion', this.getProductVersionByName(Constants.PRODUCT_MAPPING['CnfShortVersion']));

    // Set FAA version
    NewDevTestsLabComponent.setVariableValue(variables, 'FAAShortVersion', this.getProductVersionByName(Constants.PRODUCT_MAPPING['FAAShortVersion']));

    // Set FormsBuilder version
    NewDevTestsLabComponent.setVariableValue(variables, 'FormsBuilderVersion', this.getProductVersionByName(Constants.PRODUCT_MAPPING['FormsBuilderVersion']));

    // Set Regulatory version
    NewDevTestsLabComponent.setVariableValue(variables, 'RegShortVersion', this.getProductVersionByName(Constants.PRODUCT_MAPPING['RegShortVersion']));

    // Set FWM version -- No FWM in pipeline?

    // Build productParams for Summary page
    this.productParams = NewDevTestsLabComponent.buildProductParams(variables);

    return variables;
  }

  previousStep() {
    this.currentStep -= 1;
  }

  nextStep() {
    // build product params
    if(this.currentStep === 1) {
      this.variables = this.buildVariables(this.productDeployVariables);
    }
    this.currentStep += 1;
  }

  private static setVariableValue(variables: {}, name: string, value: string) {
    variables[name] = {'value': value};
  }

  private getProductVersionByName(name: string) {
    return this.productVersionComponent.addOnProductVersions.find(p => p.name === name)?.version;
  }

  // Authentication dropdown - SQL Authentication, Active Directory and Azure Active Directory
  // Sql Auth - set ActiveDirectory=false and  ConfigureAAD=false
  // Active Directory - set ActiveDirectory=true and  ConfigureAAD=false
  // Azure Active Directory - set ActiveDirectory=false and  ConfigureAAD=true
  private SetAuthentication(variables: {}) {
    switch (this.productVersionComponent.selectedAuthentication) {
      case Constants.SQL_AUTHENTICATION:
        NewDevTestsLabComponent.setVariableValue(variables, 'ActiveDirectory', 'false');
        NewDevTestsLabComponent.setVariableValue(variables, 'ConfigureAAD', 'false');
        break;
      case Constants.ACTIVE_DIRECTORY:
        NewDevTestsLabComponent.setVariableValue(variables, 'ActiveDirectory', 'true');
        NewDevTestsLabComponent.setVariableValue(variables, 'ConfigureAAD', 'false');
        break;
      case Constants.AZURE_ACTIVE_DIRECTORY:
        NewDevTestsLabComponent.setVariableValue(variables, 'ActiveDirectory', 'false');
        NewDevTestsLabComponent.setVariableValue(variables, 'ConfigureAAD', 'true');
        break;
      default:
      //will use the default values of the pipeline
    }
  }

  private static buildProductParams(variables: {}): any[] {
    const v = [];
    for (const key of Object.keys(variables)) {
      v.push({'Name': key, 'Value': variables[key].value});
    }

    return v;
  }
}
