import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-instance-details',
  templateUrl: './instance-details.component.html',
  styleUrls: ['./instance-details.component.scss']
})
export class InstanceDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  constructor() {
    super();
  }

  @Input() public instanceDetails: UntypedFormGroup;
  @Input() public environmentDetails: UntypedFormGroup;
  @Input() public customerDetails: UntypedFormGroup;
  customerName:string;
  offeringType:string;
  region:string;
  customerId:string;
  isProduction:boolean;
  envInstance:string="";
  series:string="";

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    // this.instanceDetails.get('envInstance').reset();
    //this.instanceDetails.get('sqlInstance').reset();
    this.customerName=this.customerDetails.get("customerName").value;
    this.offeringType=this.customerDetails.get("offeringType").value;
    this.region=this.customerDetails.get("region").value;
    this.customerId = this.environmentDetails.get("customerId").value;
    this.isProduction = this.customerId.startsWith('1') || this.customerId.startsWith('3');
    this.series = this.customerId[0];
    this.loadEnvInstances(this.offeringType,this.region,this.isProduction);
  }

  public onEnvValueChanged(environment){
    this.loadSqlInstances(this.offeringType,environment);
  }

  public envInstanceTypes: Array<string> = [];
  public sqlInstanceTypes: Array<string> = [];

  public loadEnvInstances(offeringType,region,isProduction){
    this.loaderService.start();
      this.sub = this.customerEnvironmentService.getEnvironmentInstanceDetails(offeringType,region,isProduction, this.series)
      .subscribe({
        next: (result) => {
          this.envInstanceTypes=result;
          this.loaderService.stop()
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        }
      });
  }

  public loadSqlInstances(offeringType,environment){
    this.loaderService.start();
      this.sub = this.customerEnvironmentService.getSqlInstanceDetails(offeringType,environment)
      .subscribe({
        next: (result) => {
          this.sqlInstanceTypes=result;
          this.loaderService.stop()
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        }
      });
  }

}
