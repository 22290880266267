import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { Environment } from '../../shared/models/Environment';
import { EnvironmentInfoComponent } from './environment-info/environment-info.component';
import { DialogAction, DialogRef } from '@progress/kendo-angular-dialog';
import { Constants } from '../../shared/Constants';

@Component({
  selector: 'app-environments',
  templateUrl: './environments.component.html',
  styleUrls: ['./environments.component.scss']
})
export class EnvironmentsComponent extends BaseComponent implements OnInit {
  environments: Environment[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    this.environmentDbService.getEnvironments().subscribe(envs => this.environments = envs);
  }

  onUpdateClicked(dataItem: Environment) {
    const dialogRef = this.dialogService.open({
      content: EnvironmentInfoComponent,
    });

    const environmentInfo = dialogRef.content.instance;
    environmentInfo.name = dataItem.name;
    environmentInfo.type = dataItem.type;
    environmentInfo.platform = dataItem.platform;

    dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        this.environmentDbService.updateEnvironment(dataItem.id, {...environmentInfo.formGroup.value})
          .subscribe({
            next: () => {
              this.loadData();
              this.toastr.success(`Environment ${dataItem.name} was updated successfully!`, 'Success!');
            },
            error: (error) => {
              this.toastr.error(`Failed with error: ${error}.`, 'Error!');
            }
          });
      }
    });
  }

  onDeleteClicked(dataItem: Environment) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: `Are you sure you want to delete this environment: ${dataItem.name}?`,
      actions: [
        {text: 'No'},
        {text: 'Yes', primary: true}
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result) => {
      const r = result as DialogAction;
      if (r.text === 'Yes') {
        this.deleteEnvironment(dataItem);
      }
    });
  }

  private deleteEnvironment(dataItem: Environment) {
    this.environmentDbService.deleteEnvironment(dataItem)
      .subscribe({
        next: () => {
          this.loadData();
          this.toastr.success(`Environment ${dataItem.name} was delete successfully!`, 'Success!');
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        }
      });
  }

  onAddNewClicked() {
    const dialogRef = this.dialogService.open({
      content: EnvironmentInfoComponent,
    });

    const environmentInfo = dialogRef.content.instance;
    environmentInfo.name = '';
    environmentInfo.type = '';
    environmentInfo.platform = '';

    dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        this.environmentDbService.createEnvironment(environmentInfo.formGroup.value)
          .subscribe({
            next: (e) => {
              this.loadData();
              this.toastr.success(`Environment ${e.name} was created successfully!`, 'Success!');
            },
            error: (error) => {
              this.toastr.error(`Failed with error: ${error}.`, 'Error!');
            }
          });
      }
    });
  }
}
