import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { Subscription } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Platform } from '../shared/models/Platform';
import { CustomerEnvironment } from '../shared/models/CustomerEnvironment';
import { Constants } from '../shared/Constants';
import { ProductVersion } from '../shared/models/ProductWithVersions';
import { CustomerEnvRequest } from '../shared/models/CustomerEnvRequest';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { EnvironmentDetails } from '../shared/models/EnvironmentDetails';
import { EnvironmentProductVersionDetails } from '../shared/models/EnvironmentProductVersionDetails';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
    selector: 'app-environments-list',
    templateUrl: './environmentsList.component.html',
    styleUrls: ['./environmentsList.component.scss']
  })
  export class EnvironmentsListComponent extends BaseComponent implements OnInit, OnDestroy {
    customerEnvironments: CustomerEnvironment[];
    private sub: Subscription;
    public gridData: GridDataResult;
    public searchClicked = false;
    public showMinorVersions= false;
    public searchTerm = "";
    public filteredSearchTerm = "";
    public state: State = {
      skip: 0,
      take: 10
      };
    public checked = true;
    public platform:Platform;
    products: ProductVersion[] = [];
    filteredProducts: ProductVersion[] = [];
    public expandedKeys: any[] = [];
    public productVersionCheckedKeys: string[] = [];
    public hideSelection = false;
    public filters: string[] = [];
    public filteredPlatforms : string[] = [];
    public filteredProductLabels = new Map<string, string>();
    public request:CustomerEnvRequest = new CustomerEnvRequest();
    public currentColumnFilters:any=null;

    selected: string = '';
    selectedHistory: string = '';
    selectedMinorHistory: string = '';
    public kendoPanelBarExpandMode: any = PanelBarExpandMode;
    environment: EnvironmentDetails;
    productDetailsOpened: boolean = false;
    selectedProduct: EnvironmentProductVersionDetails;
    selectedProductHistoryDetails: EnvironmentProductVersionDetails;
    selectedMinorProductHistoryDetails: EnvironmentProductVersionDetails;
    selectedProductHistory: EnvironmentProductVersionDetails[] = [];
    selectedProductHistoryMinor: EnvironmentProductVersionDetails[] = [];
    private id;
    versions: string[];
    selectedVersion: string;
    viewDetailsPageActive:boolean = false;

    mtLastRunTime="";
    cncLastRunTime="";
    constructor() {
      super();
      this.allEnvironmentsExportData = this.allEnvironmentsExportData.bind(this);
    }

  public allEnvironmentsExportData(): ExcelExportData {
    return {
      data: process(this.customerEnvironments, {}).data
    };
  }

    ngOnDestroy(): void {
      this.sub.unsubscribe();
    }

    ngOnInit(): void {
      this.platform= new Platform();
      this.loadProductsWithVersions();
    }

    public search() {
      this.hideSelection = true;
      this.request.page="1";
      this.state.skip = 0;
      this.state.take = 10;
      this.request = this.buildCustomerEnvRequest();
      this.gridData = null;
      this.loadData(this.request);
    }

    public reset() {
      this.gridData = null;
      this.customerEnvironments = null;
      this.searchTerm = this.filteredSearchTerm = "";
      this.state = {
        skip: 0,
        take: 10
        };
      this.searchClicked = false;
      this.platform= new Platform();
      this.productVersionCheckedKeys =[];
      this.filters =[];
      this.filteredPlatforms = [];
      this.filteredProducts = [];
      this.filteredProductLabels = new Map<string, string>();
      this.hideSelection = false;
    }

    private buildCustomerEnvRequest(): CustomerEnvRequest{
      this.filteredProductLabels = new Map<string, string>();
      this.filteredProducts=[];
      this.buildProductVersionList();
      this.request.searchTerm = this.filteredSearchTerm = this.searchTerm;
      this.request.productVersions=this.filteredProducts;
      this.request.platforms = this.buildPlatformList();
      this.addFilters();
      return this.request;
    }

    private buildPlatformList(): string[]
    {
      let platforms = `${this.platform.cnc1 ? `${Constants.PLATFORM_SINGLE_TENANT_CNC1},` : ""}${this.platform.cnc2 ? `${Constants.PLATFORM_SINGLE_TENANT_CNC2},` : ""}${this.platform.multiTenant ? `${Constants.PLATFORM_MULTI_TENANT},` : ""}${this.platform.onPrem ? `${Constants.PLATFORM_ON_PREM},` : ""}`;
      let platformList = platforms.split(',');
      platformList.pop();

      let platformLabels = `${this.platform.cnc1 ? `${Constants.PLATFORM_SINGLE_TENANT_CNC1_LABEL},` : ""}${this.platform.cnc2 ? `${Constants.PLATFORM_SINGLE_TENANT_CNC2_LABEL},` : ""}${this.platform.multiTenant ? `${Constants.PLATFORM_MULTI_TENANT_LABEL},` : ""}${this.platform.onPrem ? `${Constants.PLATFORM_ON_PREM_LABEL},` : ""}`;
      let platformLabelsList = platformLabels.split(',');
      platformLabelsList.pop();
      this.filteredPlatforms = platformLabelsList;

      return platformList;
    }

    private buildProductVersionList(){
      this.productVersionCheckedKeys.forEach(p=>{
        if(p.includes('_')){
          let indexesTobeAdded=p.split('_');
          this.addVersion(indexesTobeAdded);
        }
        else{
          let parent = this.products[p];
          let filteredParent = this.filteredProducts.find(x=>x.name === parent.name);
          if(!filteredParent){
            this.filteredProducts.push(parent);
            this.filteredProductLabels.set(parent.name,p);
          }
        }
      });
    }

    private addVersion(indexes:any []){
      if(indexes.length > 1){
        let parentIndex = indexes[0];
        let childIndex = indexes[1];
        let parent = this.products[parentIndex];
        let child = parent.versions[childIndex];
        let filteredParent = this.filteredProducts.find(x=>x.name === parent.name);
        if(filteredParent)
        {
          if(!filteredParent.versions.some(x=>x===child))
          filteredParent.versions.push(child);
          this.filteredProductLabels.set(`${parent.name}_${child}`,`${parentIndex}_${childIndex}`);
        }
        else{
          let newProductVersion = new ProductVersion();
          newProductVersion.name = parent.name;
          newProductVersion.versions.push(child);
          this.filteredProducts.push(newProductVersion);
          this.filteredProductLabels.set(`${parent.name}_${child}`,`${parentIndex}_${childIndex}`);
        }
      }
    }

    private addFilters()
    {
      this.filters=[];
      if(this.filteredSearchTerm != ""){
        this.filters.push(this.filteredSearchTerm);
      }

      if(this.filteredPlatforms.length>0){
        this.filters.push.apply(this.filters,this.filteredPlatforms);
      }

      if(this.filteredProductLabels.size > 0){
        let labels = this.filteredProductLabels.keys();
        for (let label of labels) {
          this.filters.push(label);
        }
      }

      if(this.request.columnFilterKey.length > 0){
        for(let i=0;i<this.request.columnFilterKey.length;i++){
          this.filters.push(`${this.request.columnFilterKey[i]}-${this.request.columnFilterValue[i]}`)
        }
      }
    }

    private loadData(request) {
      this.loaderService.start();
      this.sub = this.customerEnvironmentService.getCustomerEnvironmentRecords(request)
      .subscribe({
        next: (result) => {
          this.customerEnvironments = result;
          console.log(this.customerEnvironments);
          this.processEnvironmentData(this.customerEnvironments);
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
    }

    public filterChange(e): void {
      this.state.filter = this.currentColumnFilters= e;
      this.gridData = process(this.customerEnvironments, this.state);
    }

    public sortChange(e): void {
      this.state.sort = e;
      this.gridData = process(this.customerEnvironments, this.state);
    }

    public pageChange(e): void {
      this.state.skip = e.skip;
      this.state.take = e.take;
      this.gridData = process(this.customerEnvironments, this.state);
    }

    private processEnvironmentData(customerEnvironments: CustomerEnvironment[]) {
      let skip= this.state.skip;
      let take = this.state.take;
      this.state.skip = 0;
      this.state.take = 10;
      this.gridData = process(customerEnvironments, this.state);
      this.state.skip= skip;
      this.state.take = take;
      this.searchClicked = true;
      this.loaderService.stop();
    }

    public loadProductsWithVersions(){
      this.loaderService.stop();
      this.loaderService.start();
      this.sub = this.customerEnvironmentService.getCustomerEnvProductVersions()
      .subscribe({
        next: (result) => {
          this.products = result;
          this.getLastRunTimes();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
        }
      });
    }

    public hideShowSelection(){
      this.hideSelection = !this.hideSelection;
    }

    public onFilterRemove(e: ChipRemoveEvent): void {
      const index = this.filters
        .map((c) => c)
        .indexOf(e.sender.label);
      let removedFilter = this.filters.splice(index, 1);
      let removedFilterLabel = removedFilter.length >0 ? removedFilter[0] : "";

      switch(removedFilterLabel) {
        case this.filteredSearchTerm:{
          this.filteredSearchTerm=this.searchTerm="";
          break;
        }
        case Constants.PLATFORM_SINGLE_TENANT_CNC1_LABEL:{
          this.platform.cnc1 = false;
          break;
        }
        case Constants.PLATFORM_SINGLE_TENANT_CNC2_LABEL:{
          this.platform.cnc2 = false;
          break;
        }
        case Constants.PLATFORM_MULTI_TENANT_LABEL:{
          this.platform.multiTenant = false;
          break;
        }
        case Constants.PLATFORM_ON_PREM_LABEL:{
          this.platform.onPrem = false;
          break;
        }
        default:{
          break;
        }
      }
      if(this.filteredProductLabels.has(removedFilterLabel)){
        const keyIndex = this.filteredProductLabels.get(removedFilterLabel);
        this.filteredProductLabels.delete(removedFilterLabel);
        const indexOfKey = this.productVersionCheckedKeys
                          .map((c) => c)
                          .indexOf(keyIndex);
        this.productVersionCheckedKeys.splice(indexOfKey, 1);
        if(keyIndex.includes('_')){
          let indexesTobeRemoved=keyIndex.split('_');
          let parentIndex = indexesTobeRemoved[0];
          const indexOfParentKey = this.productVersionCheckedKeys
          .map((c) => c)
          .indexOf(parentIndex);

          if (indexOfParentKey > -1) {
            this.productVersionCheckedKeys.splice(indexOfParentKey, 1);
          }
        }
      }

      if(removedFilterLabel.includes('-')){
        const columnfilterData = removedFilterLabel.split('-');
        const column = columnfilterData[0];
        let indexCount = 0;
        this.currentColumnFilters?.filters.forEach(f => {
          if(f.filters[0].field.toUpperCase() === column.toUpperCase()){
            return;
          }
          indexCount+=1;
        });
        this.currentColumnFilters?.filters.splice(indexCount, 1);
        this.state.filter = this.currentColumnFilters ?? this.state.filter;
        this.request.columnFilterKey=[];
        this.request.columnFilterValue=[];
        this.currentColumnFilters?.filters.forEach(filter => {
          const columnName = filter.filters[0].field.charAt(0).toUpperCase() + filter.filters[0].field.slice(1);
          const filterValue = filter.filters[0].value;
          this.request.columnFilterKey.push(columnName);
          this.request.columnFilterValue.push(filterValue);
        });
      }

      this.search();
    }

  public viewDetails(id){
    this.id= id;
    this.loadEnvironmentData();
    this.viewDetailsPageActive = true;
  }
  public backToSearch(){
    this.environment=null;
    this.viewDetailsPageActive = false;
  }

  private loadEnvironmentData() {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getCustomerEnvironmentDetails(this.id)
      .subscribe({
        next: (result) => {
          this.environment = result;
          this.selectedProduct = this.environment.productVersionHistory[0];
          this.selected = this.selectedProduct?.product;
          this.updateSelectedProductHistory();
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        }
      });
  }

  private getLastRunTimes() {
    this.sub = this.customerEnvironmentService.getLastRunTimeOfSyncPipelines()
      .subscribe({
        next: (result) => {
          let date =new Date(result.mtPipelineLastRunTime);
          this.mtLastRunTime = date.toString();
          date =new Date(result.cnC1xPipelineLastRunTime);
          this.cncLastRunTime = date.toString();
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public productClicked(data){
    this.selectedProduct = data;
    this.selected = this.selectedProduct.product;
    this.updateSelectedProductHistory();
  }

  public productHistoryClicked(data){
    this.selectedProductHistoryMinor=[];
    this.showMinorVersions = !this.showMinorVersions;
    this.selectedProductHistoryDetails = data;
    this.environment.productVersionHistory.forEach(element => {
      if (element.product == this.selectedProductHistoryDetails.product && this.selectedProductHistoryDetails.majorVersion == element.majorVersion) {
        this.selectedProductHistoryMinor.push(element);
      }
    });
    this.selectedProductHistoryMinor.sort((a, b) => (a.version > b.version ? -1 : 1));
    this.selectedProductHistoryMinor.slice(0,5);
    this.selectedHistory = this.selectedProductHistoryDetails.version;
  }

  public minorProductHistoryClicked(data){
    this.selectedMinorProductHistoryDetails = data;
    this.productDetailsOpened = true;
    this.selectedMinorHistory = this.selectedMinorProductHistoryDetails.version;
  }

  private updateSelectedProductHistory() {
    this.selectedProductHistory = [];
    this.environment.productVersionHistory.forEach(element => {
      if (element.product == this.selected && !this.selectedProductHistory.find(x=>x.majorVersion==element.majorVersion)) {
        this.selectedProductHistory.push(element);
      }
    });
    this.selectedProductHistory.sort((a, b) => (a.version > b.version ? -1 : 1));
    this.selectedProductHistory.slice(0,5);
  }
  public close(): void {
    this.productDetailsOpened = false;
  }
  public onValueChange(data){
    this.search();
  }
  onKeyDown(pressedKey) {
    if (pressedKey.key==="Enter") {
      this.search();
    }
  }
  onSwitchChange($event){
    this.request.filterInternalEnv = $event;
    this.loadData(this.request);
  }
}
