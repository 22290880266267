<div class="w-75 px-4">
    <ng-container [formGroup]="customerEnvironmentDetails">
        <div *ngIf="!editSummary">
            <kendo-formfield *ngIf="summaryDetails">
                <div class="d-grid gap-3">
                    <div class="p-2 bg-light border">
                      <span><b>Customer Id:</b> {{summaryDetails.customerId}}</span>
                    </div>
                
                    <div class="p-2 bg-light border">
                        <span><b>Environment:</b> {{summaryDetails.environment}}</span>
                
                    </div>
        
                    <div class="p-2 bg-light border">
                        <span><b>Region:</b> {{summaryDetails.region}}</span>
                
                    </div>
        
                    <div class="p-2 bg-light border">
                        <span><b>Environment Instance:</b> {{summaryDetails.instance}}</span>
                
                    </div>
        
                </div>
            </kendo-formfield>
            <div class="pt-2" *ngIf="showEditSummary">
                <button
                class="k-button"
                kendoButton
                themeColor="primary"
                (click)="editEnvironmentDetails()"
            >
                Edit Environment
            </button>
      
            </div>
        </div>
        <div class="d-grid gap-1" *ngIf = "editSummary">

            <kendo-formfield orientation="horizontal" class="mt-0">
            <kendo-label [for]="Environment" text="Environment: "></kendo-label>
            <input
            formControlName="environment"
              kendoTextBox
              #TenantId
              required
            />
          </kendo-formfield>
  
            <kendo-formfield orientation="horizontal" class="mt-0">
            <kendo-label [for]="Region" text="Region: "></kendo-label>
            <input
            formControlName="region"
              kendoTextBox
              #Region
              required
            />
          </kendo-formfield>

          <kendo-formfield orientation="horizontal" class="mt-0">
            <kendo-label [for]="Instance" text="Instance: "></kendo-label>
            <input
            formControlName="instance"
              kendoTextBox
              #Instance
              required
            />
          </kendo-formfield>
  
          <div class="pt-2">
            <button
            class="k-button"
            kendoButton
            themeColor="primary"
            (click)="updateEnvironmentDetials()"
        >
            Update Environment
        </button>
  
        </div>
        </div>
    </ng-container>
</div>