import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../components/base.component';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { DtlDeploymentItemFilterModel } from '../../../shared/models/DtlDeploymentItemFilterModel';
import { DtlDeploymentItem } from '../../../shared/models/DtlDeploymentItem';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { Constants } from '../../../shared/Constants';
import { switchMap } from 'rxjs/operators';
import { AzRelease, Environment } from '../../../deployments/models/AzRelease';
import { ReleaseUpdateEnvironmentResponse } from '../../../deployments/models/ReleaseUpdateEnvironmentResponse';

@Component({
  selector: 'app-dev-tests-lab-deployments',
  templateUrl: './dev-tests-lab-deployments.component.html',
  styleUrls: ['./dev-tests-lab-deployments.component.scss']
})
export class DevTestsLabDeploymentsComponent extends BaseComponent implements OnDestroy {
  private sub: Subscription;
  public deploymentItems: DtlDeploymentItem[] = [];
  deploymentItemsView: GridDataResult;

  private _selectedTargetEnv: Environment = null;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor(public activatedRoute: ActivatedRoute) {
    super();
    this.subscribeRoutingChange();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private subscribeRoutingChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const id = this.activatedRoute.snapshot.paramMap.get('Id');
        this.loadDeploymentItems(id);
      }
    });
  }

  private loadDeploymentItems(id: string) {
    this.loaderService.start();
    const filter = {'deploymentId': id} as unknown as DtlDeploymentItemFilterModel;
    this.sub = this.dtlDeploymentItemService.getDtlDeploymentItems(filter)
      .pipe(
        switchMap(deployItems => this.populatePipelineStatus(deployItems)),
      )
      .subscribe(
        {
          next: (result) => {
            this.deploymentItems = result;
            this.deploymentItemsView = process(this.deploymentItems, this.state);
            this.loaderService.stop();
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            this.loaderService.stop();
          }
        }
      );
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.deploymentItemsView = process(this.deploymentItems, this.state);
  }

  startProductDeploy(dataItem: DtlDeploymentItem) {
    const variables = JSON.parse(dataItem.value);
    const releaseRequest = {
      'definitionId': +this.configService.Pipelines.AutomatedDeployReleaseDefinitionId,
      'description': 'Started from DevOpsPortal',
      'artifacts': [],
      'isDraft': false,
      'reason': 'none',
      'manualEnvironments': null,
      'variables': variables
    };

    const updateRequest = {
      'status': 'InProgress',
      'scheduledDeploymentTime': null,
      'comment': 'Started from DevOpsPortal Tool.',
      'variables': null
    };

    this.loaderService.start();
    this.sub = this.releaseService.createRelease(this.configService.StudentProject, releaseRequest)
      .pipe(
        switchMap((re) => this.getEnvironmentId(re)),
        switchMap((re) => this.releaseService.updateReleaseEnvironment(this.configService.StudentProject, re.id, this._selectedTargetEnv?.id, updateRequest)),
        switchMap((re) => this.updateDeployItem(dataItem, re))
      )
      .subscribe({
        next: (re) => {
          this.toastr.success(`Deploy Product pipeline was started successfully: ${re.buildId}!`, 'Success!')
            .onHidden.subscribe(() => {
            this.loadDeploymentItems(dataItem.dtlDeploymentId.toString());
          });
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  goToPipelineDetails(dataItem: DtlDeploymentItem) {
    let url = '';
    if (dataItem.item === 'Infrastructure') {
      url = `https://${Constants.AZURE_DOMAIN}/${this.configService.Organization}/${this.configService.MainProject}/_build/results?buildId=${dataItem.buildId}&view=results`;
    } else if (dataItem.item === 'Product') {
      url = `https://${Constants.AZURE_DOMAIN}/${this.configService.Organization}/${this.configService.StudentProject}/_releaseProgress?_a=release-pipeline-progress&releaseId=${dataItem.buildId}`;
    }

    window.open(url, '_blank');
  }

  private getEnvironmentId(re: AzRelease): Observable<AzRelease> {
    this._selectedTargetEnv = re.environments[0];
    return of(re);
  }

  private updateDeployItem(deployItem: DtlDeploymentItem, re: ReleaseUpdateEnvironmentResponse): Observable<DtlDeploymentItem> {
    deployItem.buildId = re.releaseId;
    return this.dtlDeploymentItemService.updateDtlDeploymentItem(deployItem.id, deployItem);
  }

  private populatePipelineStatus(deployItems: DtlDeploymentItem[]): Observable<DtlDeploymentItem[]> {
    const observables: Observable<DtlDeploymentItem>[] =
      deployItems.map(di => {
        switch (di.item) {
          case 'Infrastructure':
            return this.getInfrastructurePipelineStatus(di);
          case 'Product':
            return this.getProductPipelineStatus(di);
          default:
            return of(di);
        }
      });

    return forkJoin(observables);
  }

  private getInfrastructurePipelineStatus(di: DtlDeploymentItem): Observable<DtlDeploymentItem> {
    return this.pipelineService.getPipelineRun(this.configService.MainProject, this.configService.Pipelines.DevTestsLabCreateNewEnvDefinitionId, di.buildId)
      .pipe(
        switchMap(af => {
          di.status = af?.state;
          return of(di);
        })
      );
  }

  private getProductPipelineStatus(di: DtlDeploymentItem): Observable<DtlDeploymentItem> {
    if (!di.buildId) {
      return of(di);
    }

    return this.releaseService.getReleaseById(this.configService.StudentProject, di.buildId)
      .pipe(
        switchMap(af => {
          di.status = af?.environments[0]?.status;
          return of(di);
        })
      );
  }

  disableProductDeploy() {
    return this.deploymentItems.length > 0 ? this.deploymentItems[0].status !== 'completed' : true;
  }
}
