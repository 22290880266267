import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-app-registration-table',
  templateUrl: './app-registration-table.component.html',
  styleUrls: ['./app-registration-table.component.scss']
})
export class AppRegistrationTableComponent extends BaseComponent {

  customerId:string = "";
  private sub: Subscription;
  constructor() {
    super();
  }
  public appRegistration: UntypedFormGroup = new UntypedFormGroup({
    customerId: new UntypedFormControl(),
  });

  public rollback(){
    this.loaderService.start();
    const customerId = this.appRegistration.get('customerId').value;
    if(customerId)
    {
      this.sub = this.appRegistrationService.rollbackAppRegistrationUpdatedInKeyVaultFlag(customerId).subscribe({
        next: (result) => {
          if(result)
          {
            this.toastr.success(`Rollback was successful!`, 'Success!');
          }
          else
          {
            this.toastr.error(`Rollback failed!`, 'Error!');
          }
          this.loaderService.stop();
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
          this.loaderService.stop();
        }
      });
    }
  }
}
