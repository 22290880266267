import { DtoBase } from './DtoBase';
import { Release } from './Release';

export class Batch extends DtoBase {
    // ID
    public id: number = 0;
    // NAME
    public name: string = null;
    // TYPE
    public type: string = null;
    // PROJECTNAME
    public projectName: string = null;
    // RELEASEID
    public releaseId: number = 0;
    // RELEASENAME
    public releaseName: string = null;
    // RELEASES
    public releases: Release[] = [];
    // PRODUCTNAMES
    public productNames: string = null;
    public environments: string = null;
}

