import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-app-user-details',
  templateUrl: './app-user-details.component.html',
  styleUrls: ['./app-user-details.component.scss']
})
export class AppUserDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  credentialTested=false;
  credentialValid=false;
  public showPassword = false;
  public type = "password";
  public showEditField = true;

  @Output() customerOnboardingRequestEmit = new EventEmitter<any>();
  constructor() {
    super();
  }

  ngOnDestroy(): void {
    if(this.sub!=undefined && this.sub != null){
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    var customerOnBoarding = JSON.parse(localStorage.getItem("CustOnBoardingForm"));

    if(customerOnBoarding.customerId.startsWith('4')){
      this.showEditField = false;
    }
    else{
      this.showEditField = true;
    }

    this.getCredentials();
  }

  @Input() public appUserDetails: UntypedFormGroup;
  @Input() public customerOnboardingRequest;

  public next(){
    this.router.navigate(['/CustomerOnboarding/AadDetails']);
  }

  public backToOnboarding(){
    this.router.navigate(['/CustomerOnboarding']);
  }

  public testCredentials(){
    var username = this.appUserDetails.get("studentAdminUser").value;
    var password = this.appUserDetails.get("studentAdminPwd").value;
    var customerOnBoarding = JSON.parse(localStorage.getItem("CustOnBoardingForm"));
    var customerId = customerOnBoarding.customerId;
    this.credentialTested = false;
    this.credentialValid = false;
    
    this.loaderService.start();
    this.sub = this.appRegistrationService.validateCredentials(username,password,customerId)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          this.toastr.success("Credential valid!");
          this.credentialValid = true;
          this.credentialTested = true;
          customerOnBoarding.adminUser = username;
          localStorage.setItem("CustOnBoardingForm",JSON.stringify(customerOnBoarding));
          this.customerOnboardingRequest.forEach(element => {
            element.adminUser = username;
            element.adminPassword = password;
          });
          this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
        },
        error: (error) => {
          this.loaderService.stop();
          this.credentialValid = false;
          this.credentialTested = true;
        },        
        complete: () => {
          this.credentialTested = true;
          this.loaderService.stop();
        }

      });

  }

  public addCredentials(){
    var username = this.appUserDetails.get("studentAdminUser").value;
    var password = this.appUserDetails.get("studentAdminPwd").value;
    this.loaderService.start();
    var customerOnBoarding = JSON.parse(localStorage.getItem("CustOnBoardingForm"));
    var customerId = customerOnBoarding.customerId;

    this.sub = this.appRegistrationService.addUserDetailsForAppRegstration(username,password,customerId,'',true)
      .subscribe({
        next: (result) => {
          this.loaderService.stop();
          customerOnBoarding.adminUser = username;
          localStorage.setItem("CustOnBoardingForm",JSON.stringify(customerOnBoarding));
          this.customerOnboardingRequest.forEach(element => {
            element.adminUser = username;
            element.adminPassword = password;
          });
          this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
        },
        error: (error) => {
          this.loaderService.stop();
        },        
        complete: () => {
          this.loaderService.stop();
        }

      });
    this.loaderService.stop();

  }

  public getCredentials(){
    this.loaderService.start();
    var customerOnBoarding = JSON.parse(localStorage.getItem("CustOnBoardingForm"));
    var customerId = customerOnBoarding.customerId;

    this.sub = this.appRegistrationService.getUserDetailsForAppRegstration(customerId,'',true)
      .subscribe({
        next: (result) => {
          if(result){
            this.appUserDetails.controls.studentAdminUser.setValue(result.appRegistrationId);
            this.appUserDetails.controls.studentAdminPwd.setValue(result.secretKey);
            var username = this.appUserDetails.get("studentAdminUser").value;
            customerOnBoarding.adminUser = username;
            var password = this.appUserDetails.get("studentAdminPwd").value;
            localStorage.setItem("CustOnBoardingForm",JSON.stringify(customerOnBoarding));
            this.customerOnboardingRequest.forEach(element => {
              element.adminUser = username;
              element.adminPassword = password;
            });
            this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
          }
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
        },        
        complete: () => {
          this.loaderService.stop();
        }

      });
    this.loaderService.stop();

  }

  public togglePassword(){
    this.showPassword = !this.showPassword;
  }
  
  public onValueChange($event){
    var username = this.appUserDetails.get("studentAdminUser").value;
    var password = this.appUserDetails.get("studentAdminPwd").value;

    this.customerOnboardingRequest.forEach(element => {
      element.adminUser = username;
      element.adminPassword = password;
    });
    this.customerOnboardingRequestEmit.emit(this.customerOnboardingRequest);
  }
}
