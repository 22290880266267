import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { Policy } from '../../shared/models/Policy';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { PolicyUpdateComponent } from './policy-update/policy-update.component';
import { DialogAction, DialogRef } from '@progress/kendo-angular-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { ReleaseDefinition } from '../../deployments/models/ReleaseDefinition';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  policies: Policy[];
  policiesView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  static buildPolicy(instance): Policy {
    return {
      name: instance.formGroup.controls.name.value,
      subject: instance.formGroup.controls.subject.value,
      action: instance.formGroup.controls.action.value,
      resource: instance.formGroup.controls.resource.value?.map(v => v.id).join()
    } as Policy;
  }

  ngOnInit(): void {
    this.loadPolicies();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private loadPolicies() {
    this.loaderService.start();
    this.sub = this.policyService.getPolicies().subscribe({
      next: (result) => {
        this.policies = result;
        this.policiesView = process(this.policies, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.policiesView = process(this.policies, this.state);
  }

  onUpdateClicked(dataItem: Policy) {
    const dialogRef = this.dialogService.open({
      content: PolicyUpdateComponent,
    });

    const instance = dialogRef.content.instance;

    if (dataItem) {
      this.PopulateItems(dataItem, instance);
    }

    dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        if (dataItem) {
          this.update(dataItem, instance);
        } else {
          this.addNew(instance);
        }
      }
    });
  }

  onCopyClicked(dataItem: Policy) {
    const dialogRef = this.dialogService.open({
      content: PolicyUpdateComponent,
    });

    const instance = dialogRef.content.instance;

    if (dataItem) {
      this.PopulateItems(dataItem, instance);
    }

    dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        this.addNew(instance);
      }
    });
  }

  onDeleteClicked(dataItem: Policy) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: `Are you sure you want to delete this policy: ${dataItem.name}?`,
      actions: [
        {text: 'No'},
        {text: 'Yes', primary: true}
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result) => {
      const r = result as DialogAction;
      if (r.text === 'Yes') {
        this.deleteEnvironment(dataItem);
      }
    });
  }

  private update(dataItem: Policy, instance) {
    const policy = PolicyComponent.buildPolicy(instance);
    this.sub = this.policyService.updatePolicy(dataItem.id, {...policy})
      .subscribe({
        next: () => {
          this.loadPolicies();
          this.toastr.success(`Policy ${dataItem.name} was updated successfully!`, 'Success!');
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        }
      });
  }

  private addNew(instance) {
    const policy = PolicyComponent.buildPolicy(instance);
    this.sub = this.policyService.addPolicy({...policy})
      .subscribe({
        next: (result) => {
          this.loadPolicies();
          this.toastr.success(`Policy ${result.name} was created successfully!`, 'Success!');
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        }
      });
  }

  private deleteEnvironment(dataItem: Policy) {
    this.sub = this.policyService.deletePolicy(dataItem)
      .subscribe({
        next: () => {
          this.loadPolicies();
          this.toastr.success(`Policy ${dataItem.name} was delete successfully!`, 'Success!');
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        }
      });
  }

  private PopulateItems(dataItem: Policy, instance: PolicyUpdateComponent): void {
    instance.name = dataItem.name;
    instance.subject = dataItem.subject;
    instance.action = dataItem.action;

    instance.loadResourceItems(dataItem.subject);

    if (dataItem.subject === 'Pipeline') {
      this.populatePipelineItems(dataItem, instance);
    } else if (dataItem.subject === 'Release') {
      this.populateReleaseItems(dataItem, instance);
    }
  }

  private populatePipelineItems(dataItem: Policy, instance: PolicyUpdateComponent) {
    this.sub = this.getSelectedPipelines(dataItem).subscribe({
      next: (result) => {
        instance.resource = result;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private populateReleaseItems(dataItem: Policy, instance: PolicyUpdateComponent) {
    this.sub = this.getSelectedReleases(dataItem).subscribe({
      next: (result) => {
        instance.resource = result;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private getSelectedPipelines(dataItem: Policy): Observable<any[]> {
    const observables: Observable<any>[] = [];

    dataItem.resource.split(',').forEach(i => {
      if (i === '0') {
        observables.push(of({id: 0, name: 'All'}));
      } else {
        observables.push(this.pipelineService.getPipelinesById(this.configService.MainProject, +i));
      }
    });

    return forkJoin(observables);
  }

  private getSelectedReleases(dataItem: Policy): Observable<ReleaseDefinition[]> {
    const observables: Observable<ReleaseDefinition>[] = [];

    dataItem.resource.split(',').forEach(i => {
      if (i === '0') {
        const star = {id: 0, name: 'All'} as ReleaseDefinition;
        observables.push(of(star));
      } else {
        observables.push(this.releaseService.getReleaseDefinition(this.configService.MainProject, +i));
      }
    });

    return forkJoin(observables);
  }
}
