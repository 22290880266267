import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private configService: AppConfigService,
              private authService: AuthService,
              private httpClient: HttpClient,
              private errorHandling: ErrorHandlerService) {
  }


  getAllAdGroups(): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/${Constants.GRAPH}/groups`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAllAdGroups'))
      );
  }

  getGroupMembers(groupId: string): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/${Constants.GRAPH}/groups/${groupId}/members`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getGroupMembers'))
      );
  }
}
