<kendo-tilelayout
[columns]="12"
[resizable]="true"
[reorderable]="true"
>

<kendo-tilelayout-item [col]="4" [colSpan]="4" *ngIf="refreshed && !updated">
    <kendo-tilelayout-item-body>
      <p class="alert alert-success"><b>Table refreshed succesfully!</b></p>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

<kendo-tilelayout-item [col]="4" [colSpan]="4" *ngIf="!updated">
  <kendo-tilelayout-item-body>
    <button kendoButton primary="true" (click)="refresh()" class ="w-100 h-100">Environment Table Sync</button>
  </kendo-tilelayout-item-body>
</kendo-tilelayout-item>

<kendo-tilelayout-item [col]="4" [colSpan]="4" *ngIf="!updated">
<kendo-tilelayout-item-body>
  <button kendoButton primary="true" (click)="updateProductVersion()" class ="w-100 h-100">Environment Product/Version Table Sync</button>
</kendo-tilelayout-item-body>
</kendo-tilelayout-item>

<kendo-tilelayout-item [col]="4" [colSpan]="4" *ngIf="updated">
  <kendo-tilelayout-item-body>
    <p class="alert alert-success"><b>Product version sync pipelines kicked off! Click on the buttons below to monitor the pipelines!</b></p>
    <p><a href="https://{{this.azureDomain}}/{{this.pipelinesOrg}}/{{this.cloudServiceProject}}/_build/results?buildId={{this.pipelineIds[0]}}&view=logs" target="_blank" class="btn btn-primary w-100 h-100">Multitenant</a></p>
    <p><a href="https://{{this.azureDomain}}/{{this.pipelinesOrg}}/{{this.cloudServiceProject}}/_build/results?buildId={{this.pipelineIds[1]}}&view=logs"target="_blank" class="btn btn-primary w-100 h-100"> CNC 2.x</a></p>
    <p><a href="https://{{this.azureDomain}}/{{this.pipelinesOrg}}/{{this.studentProject}}/_build?definitionId={{this.cnc1PipelineId}}" target="_blank" class="btn btn-primary w-100 h-100">CNC 1.x</a>
    <p><a [routerLink]="" (click)="backToMain()" class="btn btn-secondary w-30 h-50">Back</a>
  </p>
  </kendo-tilelayout-item-body>
</kendo-tilelayout-item>

</kendo-tilelayout>