<kendo-listview
  [navigable]="true"
  [data]="allPolicies"
  containerClass="k-d-flex k-flex-col k-flex-nowrap"
>
  <ng-template
    kendoListViewItemTemplate
    let-dataItem="dataItem"
  >
    <div class="p-2">
      <input
        id="chk{{dataItem.id}}"
        [(ngModel)]="dataItem.selected"
        type="checkbox"
        kendoCheckBox
      />
      <label class="k-checkbox-label" for="chk{{dataItem.id}}">{{dataItem.name}}</label>
    </div>
  </ng-template>
</kendo-listview>
