<kendo-tilelayout
  [columns]="5"
  [rowHeight]="42"
  [resizable]="false"
  [reorderable]="false"
  [gap]="0"
  id="policy-manage"
>
  <kendo-tilelayout-item [col]="1" [colSpan]="5">
    <kendo-tilelayout-item-body>
      <kendo-appbar positionMode="sticky" style="padding: 0 0;">
        <kendo-appbar-spacer></kendo-appbar-spacer>
        <kendo-appbar-section>
          <button
            kendoButton
            themeColor="primary"
            (click)="onUpdateClicked(null)"
          >
            Add Policy
          </button>
        </kendo-appbar-section>
      </kendo-appbar>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="1" [colSpan]="5" [rowSpan]="18">
    <kendo-tilelayout-item-body class="dynamic-content-height">
      <kendo-grid
        [data]="policiesView"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [filter]="state.filter"
        [sortable]="true"
        [pageable]="true"
        [filterable]="true"
        (dataStateChange)="dataStateChange($event)"
        kendoGridSelectBy="id"
        [selectable]="true"
        [style.height.%]="100"
      >
        <kendo-grid-column
          field="name"
          title="Name"
          [width]="150"
          [minResizableWidth]="200"
        ></kendo-grid-column>
        <kendo-grid-column
          field="subject"
          title="Subject"
          [width]="100"
          [minResizableWidth]="100"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.subject}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="action"
          title="Action"
          [width]="100"
          [minResizableWidth]="100"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.action}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="resource"
          title="Resource"
          [width]="300"
          [minResizableWidth]="300"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.resource}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="createdDate"
          title="Operations"
          [width]="100"
          [minResizableWidth]="100"
          [filterable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <button kendoButton
                      class="icon-button me-2"
                      icon="edit"
                      title="Modify Policy"
                      primary="true"
                      (click)="onUpdateClicked(dataItem)"
              >
              </button>
            <button kendoButton
                    class="icon-button me-2"
                    icon="delete"
                    title="Delete Policy"
                    primary="true"
                    (click)="onDeleteClicked(dataItem)"
            >
            </button>
              <button kendoButton
                      class="icon-button me-2"
                      icon="copy"
                      title="Copy Policy"
                      primary="true"
                      (click)="onCopyClicked(dataItem)"
              >
              </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
<div kendoDialogContainer></div>

