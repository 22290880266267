import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-template-file-logs',
  templateUrl: './update-template-file-logs.component.html',
  styleUrls: ['./update-template-file-logs.component.scss']
})
export class UpdateTemplateFileLogsComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadLogs();
  }

  private sub: Subscription;

  logs = [];

  loadLogs() {
    this.loaderService.start();
    this.sub = this.gitService.GetTemplateFileLogs()
      .subscribe({
        next: (result) => {
          this.logs = result;
        },
        error: (error) => {
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }
}
