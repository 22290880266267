<kendo-tilelayout
  [columns]="5"
  [rowHeight]="42"
  [resizable]="false"
  [reorderable]="false"
  [gap]="0"
  id="permission-manage"
>
  <kendo-tilelayout-item [col]="1" [colSpan]="5">
    <kendo-tilelayout-item-body>
      <kendo-appbar positionMode="sticky" style="padding: 0 0;">
        <kendo-appbar-spacer></kendo-appbar-spacer>
        <kendo-appbar-section>
          <div class="px-2">
            <button
            kendoButton
            (click)="onAddGroup()"
            themeColor="primary"
          >
            Add Group
          </button>
          </div>
          <div>
            <button
            kendoButton
            (click)="onRemoveGroup()"
            themeColor="primary"
          >
            Remove Group
          </button>
          </div>
        </kendo-appbar-section>
      </kendo-appbar>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="1" [colSpan]="1" [rowSpan]="18">
    <kendo-tilelayout-item-body class="row">
      <kendo-panelbar>
        <kendo-panelbar-item *ngFor="let dataItem of groups"
                             title="{{dataItem.displayName}}"
                             [selected]="dataItem.selected"
                             (click)="onSelectionChanged(dataItem)">
        </kendo-panelbar-item>
      </kendo-panelbar>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
  <kendo-tilelayout-item [col]="2" [colSpan]="4" [rowSpan]="18">
    <kendo-tilelayout-item-body class="dynamic-content-height">
      <kendo-tabstrip>
        <kendo-tabstrip-tab title="Policies" [selected]="true">
          <ng-template kendoTabContent>
            <app-group-policies
              *ngIf="selectedGroup"
              [group]="selectedGroup"
              [allPolicies]="allPolicies"
              (itemEmit)="itemEmit($event)">
            </app-group-policies>
            <button kendoButton class="k-button" themeColor="primary" (click)="saveGroupPolicyChanges()">Save</button>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Users">
          <ng-template kendoTabContent>
            <app-group-users
              *ngIf="selectedGroup"
              [groupId]="selectedGroup.groupId">
            </app-group-users>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
<div kendoDialogContainer></div>
<kendo-dialog
title="Please confirm"
*ngIf="removeGroupDialogOpened"
(close)="close()"
[minWidth]="250"
[width]="450"
>
<p style="margin: 30px; text-align: center;">
  Are you sure you want to delete group "<b>{{this.selectedGroup.displayName}}</b>"?
</p>
<kendo-dialog-actions>
  <button kendoButton (click)="close()">No</button>
  <button kendoButton (click)="confirmRemoveGroup(this.selectedGroup)" primary="true">
    Yes
  </button>
</kendo-dialog-actions>
</kendo-dialog>

