import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { StepperComponent } from '@progress/kendo-angular-layout';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Constants } from '../shared/Constants';
import { switchMap } from 'rxjs/operators';
import { DbRefreshConfirmationMultiComponent } from '../deployments/products/db-management/db-multi-tenant-panel/db-refresh-confirmation-multi/db-refresh-confirmation-multi.component';
import { DialogAction } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-database-refresh',
  templateUrl: './database-refresh.component.html',
  styleUrls: ['./database-refresh.component.scss']
})
export class DatabaseRefreshComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  @ViewChild("stepper", { static: true })
  public stepper: StepperComponent;

  public currentStep = 0;

  filteredCustomeIds:any[];
  customeIds:any[];
  variableGroupsSource: any[];
  variableGroupsFilteredSource: any[];
  sources:any[]=[];
  filteredSources:any[]=[];
  targets:any[]=[];
  filteredTargets:any[]=[];
  private sub: Subscription;
  envNameMappings: any[] = [];
  liveCustomers: any[] =[];
  sourceSqlServer;
  sourceDbName;
  sourceEnvironmentType;
  sourceRegion;
  sourceDbInstance;
  sourceTenantCode;
  sourceSubscription;
  targetSqlServer;
  targetDbName;
  targetEnvironmentType;
  targetRegion;
  targetDbInstance;
  targetTenantCode;
  targetSubscription;
  scrubMail;
  scrubCredit;
  purgeMail;
  source;
  target;
  sourceDbNames;
  targetDbNames;

  private shouldValidate = (index: number): boolean => {
    return this.getGroupAt(index).touched && this.currentStep >= index;
  };

  public steps = [
    {
      label: "Customer Details",
      isValid: true,
      validate: this.shouldValidate,
    },
    {
      label: "Source SQL Details",
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    // {
    //   label: "Source Details",
    //   isValid: true,
    //   validate: this.shouldValidate,
    //   disabled: true
    // },
    // {
    //   label: "Source Subscription Details",
    //   isValid: true,
    //   validate: this.shouldValidate,
    //   disabled: true
    // },
    {
      label: "Target SQL Details",
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
    // {
    //   label: "Target Subscription Details",
    //   isValid: true,
    //   validate: this.shouldValidate,
    //   disabled: true
    // },
    {
      label: "Summary",
      isValid: true,
      validate: this.shouldValidate,
      disabled: true
    },
  ];

  public form = new UntypedFormGroup({
    customerId: new UntypedFormControl(),
    source: new UntypedFormControl(),
    target: new UntypedFormControl(),
    scrubMail: new UntypedFormControl(),
    scrubCredit: new UntypedFormControl(),
    purgeMail: new UntypedFormControl(),
    SqlServer: new UntypedFormControl(),
    DbName: new UntypedFormControl(),
    TargetSqlServer: new UntypedFormControl(),
    TargetDbName: new UntypedFormControl(),
    EnvironmentType: new UntypedFormControl(),
    Region: new UntypedFormControl(),
    TargetEnvironmentType: new UntypedFormControl(),
    TargetRegion: new UntypedFormControl(),
    Subscription: new UntypedFormControl(),
    Instance: new UntypedFormControl(),
    TenantCode: new UntypedFormControl(),
    TargetSubscription: new UntypedFormControl(),
    TargetInstance: new UntypedFormControl(),
    TargetTenantCode: new UntypedFormControl(),
 });

  ngOnInit(): void {
    this.loadCustomerMultiTenant()
  }

  public get currentGroup(): UntypedFormGroup {
    return this.getGroupAt(this.currentStep);
  }

  public next(): void {
    this.currentGroup.markAllAsTouched();
    this.stepper.validateSteps();

    if (this.currentGroup.valid && this.currentStep !== this.steps.length) {
      this.currentStep += 1;

      this.steps[this.currentStep].disabled= false;
      return;
    }

  }

  public prev(): void {
    this.currentStep -= 1;
  }

  private getGroupAt(index: number): UntypedFormGroup {
    const groups = Object.keys(this.form.controls).map((groupName) =>
      this.form.get(groupName)
    ) as UntypedFormGroup[];

    return groups[index];
  }


  private loadCustomerMultiTenant() {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getMTCustomersForDatabaseRefresh()
      .subscribe(
        {
          next: (result) => {
            this.loadCustomerIds(result)
            this.variableGroupsSource = result;
            this.variableGroupsFilteredSource = this.variableGroupsSource;
            //this.loaderService.stop();
            this.loadNameMappingRecords()
          },
          error: (error) => {
            console.error(error);
            this.loaderService.stop();
          },
          complete: () => {
            //this.loaderService.stop();
          }
        }
      );
  }

  public loadCustomerIds(result){
    this.customeIds =[]
    this.filteredCustomeIds =[]
    result.forEach(element => {
      if(!(element.Code.includes('obsolete'))){
        var value = element.Code
        if(value.includes('-')){
          value = value.split('-')[0]
        }
        if(!this.customeIds.includes(value + '-' + element.Name)){
          this.customeIds.push(value + '-' + element.Name)
          this.filteredCustomeIds.push(value + '-' + element.Name)
        }
      }
    });

  }

  private loadNameMappingRecords() {
    //this.loaderService.start();
    this.adminService.getNameMappingRecords()
      .subscribe({
        next: (re) => {
          this.envNameMappings = re;
          this.loadliveCustomerRecords();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          //this.loaderService.stop();
        }
      });
  }

  private loadliveCustomerRecords() {
    //this.loaderService.start();
    this.customerEnvironmentService.getAllLiveMTEnvCustomers()
      .subscribe({
        next: (re) => {
          this.liveCustomers = re;
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }


  disableStartDbRefresh() {
    if (this.form.get("SqlServer").value &&
    this.form.get("DbName").value &&
    this.form.get("EnvironmentType").value &&
    this.form.get("Region").value &&
    this.form.get("Instance").value &&
    this.form.get("TenantCode").value &&
    this.form.get("Subscription").value &&
    this.form.get("TargetSqlServer").value &&
    this.form.get("TargetDbName").value &&
    this.form.get("TargetEnvironmentType").value &&
    this.form.get("TargetRegion").value &&
    this.form.get("TargetInstance").value &&
    this.form.get("TargetTenantCode").value &&
    this.form.get("TargetSubscription").value){
      return false
    }else{
      return true
    }
  }

  onStartDbRefresh() {
    this.setRequestValues()
    const dialogRef = this.dialogService.open({
      content: DbRefreshConfirmationMultiComponent,
    });

    const instance = dialogRef.content.instance;

    instance.Source = {
      'Server': this.sourceSqlServer,
      'DatabaseName': this.sourceDbName,
      'EnvironmentType': this.sourceEnvironmentType,
      'Region': this.sourceRegion,
      'Instance': this.sourceDbInstance,
      'Tenant': this.sourceTenantCode,
      'Subscription': this.sourceSubscription
    };

    instance.Target = {
      'Server': this.targetSqlServer,
      'DatabaseName': this.targetDbName,
      'EnvironmentType': this.targetEnvironmentType,
      'Region': this.targetRegion,
      'Instance': this.targetDbInstance,
      'Tenant': this.targetTenantCode,
      'Subscription': this.targetSubscription,
    };

    this.sub = dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        this.startDbRefresh();
      }
    });
  }

  private startDbRefresh() {
    const request = {
      'templateParameters': {
        'scrubmail': this.scrubMail ? 'Y' : 'N',
        'scrubcredit': this.scrubCredit ? 'Y' : 'N',
        'purgemail': this.purgeMail ? 'Y' : 'N',

        'srcsqlserver': this.sourceSqlServer,
        'srcdbname': this.sourceDbName,
        'srcenvironmenttype': this.sourceEnvironmentType,
        'srcregion': this.sourceRegion,
        'srcInstance': this.sourceDbInstance,
        'srcsubname': this.sourceSubscription,

        'tgtsqlserver': this.targetSqlServer,
        'tgtdbname': this.targetDbName,
        'tgtenvironmenttype': this.targetEnvironmentType,
        'tgtregion': this.targetRegion,
        'tgtInstance': this.targetDbInstance,
        'tgtsubname': this.targetSubscription,

        'customertenant': this.targetTenantCode,
      }
    };

    this.loaderService.start();
    this.sub = this.pipelineService.runPipeline(this.configService.MainProject, this.configService.Pipelines.MultiTenantDbRefreshPipelineId , request)
      .pipe(
        switchMap((re) => this.addReleaseToDb(re))
      )
      .subscribe({
        next: (re) => {
          this.toastr.success(`DB refresh pipeline was started successfully: ${re.name}!`, 'Success!')
            .onHidden.subscribe(() => {
            this.router.navigate([`${Constants.MTDbRefresh}/${this.sourceTenantCode}`])
              .then();
          });
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  private addReleaseToDb(re): Observable<any> {
    const user = this.msalService.instance.getActiveAccount().name;
    const release = {
      'id': re.id,
      'batchId': Constants.DB_REFRESH_MULTIPLE_TENANT,
      'name': `${user}`,
      'batch': undefined,
      'projectName': this.configService.MainProject,
      'customerId': null,
      'customerName': this.sourceDbName+" "+this.targetDbName,
      'stage': null,
      'createdBy': user,
      'createdDate': new Date(),
      'modifiedBy': user,
      'modifiedDate': new Date(),
      'azBuild': undefined
    };
    return this.releaseDbService.createRelease(release);
  }

  setRequestValues(){
    this.sourceSqlServer = this.form.get("SqlServer").value
    this.sourceDbName = this.form.get("DbName").value
    this.sourceEnvironmentType = this.form.get("EnvironmentType").value
    this.sourceRegion = this.form.get("Region").value
    this.sourceDbInstance = this.form.get("Instance").value
    this.sourceTenantCode = this.form.get("TenantCode").value
    this.sourceSubscription = this.form.get("Subscription").value
    this.targetSqlServer = this.form.get("TargetSqlServer").value
    this.targetDbName = this.form.get("TargetDbName").value
    this.targetEnvironmentType = this.form.get("TargetEnvironmentType").value
    this.targetRegion = this.form.get("TargetRegion").value
    this.targetDbInstance = this.form.get("TargetInstance").value
    this.targetTenantCode = this.form.get("TargetTenantCode").value
    this.targetSubscription = this.form.get("TargetSubscription").value
    this.scrubMail = this.form.get("scrubMail").value
    this.scrubCredit = this.form.get("scrubCredit").value
    this.purgeMail = this.form.get("purgeMail").value
  }

  targetDetails($event){
    this.target = $event
  }

  sourceDetails($event){
    this.source = $event
  }

  targetDbDetails($event){
    this.targetDbNames = $event
  }

  sourceDbDetails($event){
    this.sourceDbNames = $event
  }

  sourceList($event){
    this.sources = $event
  }

  targetList($event){
    this.targets = $event
  }

  filteredSourceList($event){
    this.filteredSources = $event
  }

  filteredTargetList($event){
    this.filteredTargets = $event
  }
}
