<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<kendo-tilelayout
[resizable]="true"
[reorderable]="true"
>
    <kendo-tilelayout-item title="Api/Policy Mapping" [col]="1" [colSpan]="3">
        <kendo-tilelayout-item-body>
            <div class="container-fluid example-wrapper pb-3">
                <div class="row pb-3">
                    <div class="col-xs-12 col-sm-4 example-col">
                        <div class="fs-6 fw-bold pt-3 pb-3">Select Table:</div>
                        <kendo-dropdownlist
                          [data]="dataTables"
                          [value]="selectedTable"
                          [defaultItem]="defaultTables"
                          textField="tableName"
                          valueField="tableId"
                          (valueChange)="handleTableChange($event)"
                        >
                        </kendo-dropdownlist>
                      </div>
                </div>
            </div>

            <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="hideSelection && (!hideApiPolicyGrid || !hideApiActionGrid)">
                <kendo-tilelayout-item-body>
                  <button kendoButton (click)="hideShowSelection()" primary="true" class ="w-100 h-100" icon="arrow">Show Input Fields
                    <kendo-icon name="arrow-chevron-right"></kendo-icon>
                  </button>
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>
              
              <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="!hideSelection && (!hideApiPolicyGrid || !hideApiActionGrid)">
                <kendo-tilelayout-item-body>
                  <button kendoButton (click)="hideShowSelection()" primary="true" class ="w-100 h-100" icon="arrow">Hide Input Fields
                    <kendo-icon name="arrow-chevron-down"></kendo-icon>
                  </button>
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>

              <kendo-tilelayout-item title="New Mapping" [col]="1" [colSpan]="3" *ngIf="!hideSelection && (!hideApiPolicyGrid || !hideApiActionGrid)">
                <kendo-tilelayout-item-body class="pt-3">
                    <div class = "row pb-3" *ngIf="!hideApiPolicyGrid">
                        <div class = "row pb-2">
                            <div class="col-1"></div>
                            <div class="col-4">
                                <kendo-label text="Policy Name" class="px-2"></kendo-label>
                                <kendo-textbox
                                placeholder="Enter Policy Name"
                                [(ngModel)]="apiPolicyToAdd.policyName">
                              </kendo-textbox>
                            </div>
            
                          <div class="col-4">
                            <kendo-label [for]="pageName" text="Page Name: "></kendo-label>
                            <kendo-dropdownlist
                            [filterable]="true"
                            #pageName
                            [(ngModel)]="apiPolicyToAdd.screenName"
                            [data]="pageNames"
                            [valuePrimitive]="true"
                            (valueChange)="onDropDownChanged($event)"
                            (filterChange)="handleFilter($event)"
                            required
                          >
                          </kendo-dropdownlist>
                            <!-- <kendo-label text="Page Name" class="px-2"></kendo-label>
                                <kendo-textbox
                                  placeholder="Enter Page Name"
                                  [(ngModel)]="apiPolicyToAdd.screenName">
                               </kendo-textbox> -->
                          </div>
                        </div>
                        <div class = "row pb-2">
                            <div class="col-1"></div>

                            
                          <div class="col-4">
                            <kendo-label [for]="action" text="Page Action: "></kendo-label>
                            <kendo-dropdownlist
                            #pageName
                            [filterable]="true"
                            [(ngModel)]="apiPolicyToAdd.action"
                            [data]="selectedActions"
                            [valuePrimitive]="true"
                            (filterChange)="handleActionFilter($event)"
                            required
                          >
                          </kendo-dropdownlist>
                            <!-- <kendo-label text="Api Path" class="px-2"></kendo-label>
                                <kendo-textbox
                                  placeholder="Enter Api Path"
                                  [(ngModel)]="apiPolicyToAdd.apiPath">
                               </kendo-textbox> -->
                          </div>

                            <div class="col-4">
                                <!-- <kendo-label text="Api Action" class="px-2"></kendo-label>
                                <kendo-textbox
                                placeholder="Enter Api Action"
                                [(ngModel)]="apiPolicyToAdd.apiAction">
                              </kendo-textbox> -->
                            </div>
                        </div>           
                        <div class = "row">
                            <div class="col-12">
                                <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addApiPolicyToTable()">Add Row</button>
                            </div>
                        </div>
                    </div>  
                    <div class = "row pb-3" *ngIf="!hideApiActionGrid">
                      <div class = "row pb-2">
                          <div class="col-1"></div>
                          <div class="col-4">
                              <kendo-label text="Page Name" class="px-2"></kendo-label>
                              <kendo-textbox
                              placeholder="Enter page Name"
                              [(ngModel)]="apiActionToAdd.page">
                            </kendo-textbox>
                          </div>
          
                        <div class="col-4">
                          <kendo-label text="Action Name" class="px-2"></kendo-label>
                              <kendo-textbox
                                placeholder="Enter Action Name"
                                [(ngModel)]="apiActionToAdd.action">
                             </kendo-textbox>
                        </div>
                      </div>
                      <div class = "row pb-2">
                        <div class="col-1"></div>          
                        <div class="col-4">
                          <kendo-label text="Api" class="px-2"></kendo-label>
                              <kendo-textbox
                                placeholder="Enter Api"
                                [(ngModel)]="apiActionToAdd.api">
                             </kendo-textbox>
                        </div>
                          <div class="col-4">
                              <kendo-label text="Api Action Type" class="px-2"></kendo-label>
                              <kendo-textbox
                              placeholder="Enter Api Action Type"
                              [(ngModel)]="apiActionToAdd.apiActionType">
                            </kendo-textbox>
                          </div>
                      </div>           
                      <div class = "row">
                          <div class="col-12">
                              <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addApiActionToTable()">Add Row</button>
                          </div>
                      </div>
                  </div>            
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>

              <kendo-tilelayout-item title="Existing Mapping" [col]="1" [colSpan]="3" *ngIf="!hideApiPolicyGrid || !hideApiActionGrid">
                <kendo-tilelayout-item-body>
                    <div class ="row" *ngIf="!hideApiPolicyGrid"
                    kendoTooltip
                    showOn="none"
                    [tooltipTemplate]="template"
                    filter=".k-grid td"
                    (mouseover)="showTooltip($event)"
                    >
                        <form novalidate #myForm="ngForm">
                          <kendo-grid [kendoGridTemplateEditing]="createPolicyEdit" [kendoGridBinding]="gridApiPoliciesData" (cellClick)="cellClickHandler($event)">
                            <kendo-grid-column field="policyName" title="Policy Name"> 
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.policyName"
                                  kendoGridFocusable
                                  name="Policy Name"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="screenName" title="Page Name"> 
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.screenName"
                                  kendoGridFocusable
                                  name="Page Name"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="apiPath" title="Api Path"> 
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                  <input
                                    [(ngModel)]="dataItem.apiPath"
                                    kendoGridFocusable
                                    name="Api Path"
                                    class="k-textbox k-input k-rounded-md"
                                    required
                                  />
                                </ng-template>
                              </kendo-grid-column>
                              <kendo-grid-column field="apiAction" title="Api Action"> 
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                  <input
                                    [(ngModel)]="dataItem.apiAction"
                                    kendoGridFocusable
                                    name="Api Action"
                                    class="k-textbox k-input k-rounded-md"
                                    required
                                  />
                                </ng-template>
                              </kendo-grid-column>
                          <kendo-grid-command-column title="command" [width]="220">
                            <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                              <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                                Edit
                              </button>
                              <button
                                kendoGridSaveCommand
                                type="button"
                                [disabled]="myForm.invalid"
                                (click)="savePolicyMapping(dataItem)"
                              >
                                {{ isNew ? "Add" : "Update" }}
                              </button>
                              <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                                {{ isNew ? "Discard changes" : "Cancel" }}
                              </button>
                              <button kendoButton type="button" *ngIf="!editMode" (click)="deleteApiPolicyMappingDialogOpen(dataItem)">Remove</button>
                              <kendo-dialog
                              title="Please confirm"
                              *ngIf="coreApiPolicyMappingDialogOpened"
                              (close)="close()"
                              [minWidth]="250"
                              [width]="450"
                            >
                              <p style="margin: 30px; text-align: center;">
                                Are you sure you want to delete policy "<b>{{this.coreApiPolicyMappingToBeDeleted.policyName}}</b>" with api path "<b>{{this.coreApiPolicyMappingToBeDeleted.apiPath}}</b>"?
                              </p>
                              <kendo-dialog-actions>
                                <button kendoButton (click)="close()">No</button>
                                <button kendoButton (click)="deleteApiPolicyFromTable(this.coreApiPolicyMappingToBeDeleted)" primary="true">
                                  Yes
                                </button>
                              </kendo-dialog-actions>
                            </kendo-dialog>
                            </ng-template>
                          </kendo-grid-command-column>
                          </kendo-grid>
                        </form>
                      </div>
                      <div class ="row" *ngIf="!hideApiActionGrid"
                      kendoTooltip
                      showOn="none"
                      [tooltipTemplate]="template"
                      filter=".k-grid td"
                      (mouseover)="showTooltip($event)"
                      >
                        <form novalidate #myForm="ngForm">
                          <kendo-grid [kendoGridTemplateEditing]="createActionEdit" [kendoGridBinding]="gridApiActionsData" (cellClick)="cellClickHandler($event)">
                            <kendo-grid-column field="page" title="Page"> 
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.page"
                                  kendoGridFocusable
                                  name="Page"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="action" title="Action"> 
                              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <input
                                  [(ngModel)]="dataItem.action"
                                  kendoGridFocusable
                                  name="Action"
                                  class="k-textbox k-input k-rounded-md"
                                  required
                                />
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="api" title="Api"> 
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                  <input
                                    [(ngModel)]="dataItem.api"
                                    kendoGridFocusable
                                    name="Api"
                                    class="k-textbox k-input k-rounded-md"
                                    required
                                  />
                                </ng-template>
                              </kendo-grid-column>
                              <kendo-grid-column field="apiActionType" title="Api Action Type"> 
                                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                  <input
                                    [(ngModel)]="dataItem.apiActionType"
                                    kendoGridFocusable
                                    name="Api Action Type"
                                    class="k-textbox k-input k-rounded-md"
                                    required
                                  />
                                </ng-template>
                              </kendo-grid-column>
                          <kendo-grid-command-column title="command" [width]="220">
                            <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                              <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                                Edit
                              </button>
                              <button
                                kendoGridSaveCommand
                                type="button"
                                [disabled]="myForm.invalid"
                                (click)="saveApiActionMapping(dataItem)"
                              >
                                {{ isNew ? "Add" : "Update" }}
                              </button>
                              <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                                {{ isNew ? "Discard changes" : "Cancel" }}
                              </button>
                              <button kendoButton type="button" *ngIf="!editMode" (click)="deleteApiActionMappingDialogOpen(dataItem)">Remove</button>
                              <kendo-dialog
                              title="Please confirm"
                              *ngIf="apiActionMappingDialogOpened"
                              (close)="close()"
                              [minWidth]="250"
                              [width]="450"
                            >
                              <p style="margin: 30px; text-align: center;">
                                Are you sure you want to delete action "<b>{{this.apiActionMappingToBeDeleted.action}}</b>" in page "<b>{{this.apiActionMappingToBeDeleted.page}}</b> with api "<b>{{this.apiActionMappingToBeDeleted.api}}</b>"?
                              </p>
                              <kendo-dialog-actions>
                                <button kendoButton (click)="close()">No</button>
                                <button kendoButton (click)="deleteApiActionFromTable(this.apiActionMappingToBeDeleted)" primary="true">
                                  Yes
                                </button>
                              </kendo-dialog-actions>
                            </kendo-dialog>
                            </ng-template>
                          </kendo-grid-command-column>
                          </kendo-grid>
                        </form>
                      </div>
                </kendo-tilelayout-item-body>
              </kendo-tilelayout-item>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>