import { Component, Input, NgZone, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../components/base.component';
import { Task } from '../../../../models/AzRelease';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { Constants } from '../../../../../shared/Constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent extends BaseComponent implements OnInit {

  @Input() data: Task[] = [];

  tasks: Task[];
  tasksView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor(
    public zone: NgZone,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.zone.run(() => {
      this.tasks = this.data;
      this.tasksView = process(this.tasks, this.state);
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.tasksView = process(this.tasks, this.state);
  }

  getDuration(startTime: Date, end: Date): number {
    const duration = moment.duration(moment(end).diff(moment(startTime)));
    return Math.round(duration.asSeconds());
  }

  onTaskClicked(dataItem: any) {
    this.dataService.updateTask(dataItem);
    this.router.navigate([`../../${Constants.TASK_DETAIL}`, dataItem.id], {relativeTo: this.route})
      .then();
  }
}
