import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-mailbox-manager-aad',
  templateUrl: './mailbox-manager-aad.component.html',
  styleUrls: ['./mailbox-manager-aad.component.scss']
})
export class MailboxManagerAADComponent extends BaseComponent implements OnInit {

  private sub: Subscription;
  appRegDetails: any;
  clientSecret: any = '';
  public clientSecretMasked = true;
  @Input() public customerEnvironmentDetails: UntypedFormGroup;
  public editAppreg = true;
  public showEditAppReg = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    const customer = this.customerEnvironmentDetails.get('customerId').value;
    this.showEditAppReg = !customer.startsWith('4');
    this.getAppRegistrationDetails(customer);
  }

  public getAppRegistrationDetails(customerId) {
    this.loaderService.start();
    this.sub = this.appRegistrationService.getFMailboxAppRegDetails(customerId)
      .subscribe({
        next: (result) => {
          this.appRegDetails = result;
          this.updateDataToForm();
          this.shouldAppRegBeEditable();
          this.loaderService.stop();

        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  public editMailboxAppReg() {
    this.editAppreg = true;
  }

  public shouldAppRegBeEditable() {
    if (this.appRegDetails.appRegistrationId ||
      this.appRegDetails.tenantId) {
      this.editAppreg = false;
    } else {
      this.editAppreg = true;
    }
  }

  public updateMailboxAppReg() {
    this.updateFormToData();
    this.loaderService.start();
    this.sub = this.appRegistrationService.updateMailboxAppRegDetails(this.appRegDetails)
      .subscribe({
        next: (result) => {
          this.appRegDetails = result;
          this.shouldAppRegBeEditable();
          this.loaderService.stop();

        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  updateDataToForm() {
    this.customerEnvironmentDetails.controls.tenantId.setValue(this.appRegDetails?.tenantId);
    this.customerEnvironmentDetails.controls.appRegistrationId.setValue(this.appRegDetails?.appRegistrationId);
  }

  updateFormToData() {
    if (!this.appRegDetails) {
      this.appRegDetails = {};
    }

    this.appRegDetails.tenantId = this.customerEnvironmentDetails.get('tenantId').value;
    this.appRegDetails.appRegistrationId = this.customerEnvironmentDetails.get('appRegistrationId').value;

    let customer = this.customerEnvironmentDetails.get('customerId').value;

    let env = '';
    if (customer.includes('-')) {
      env = customer.split('-')[1];
      customer = customer.split('-')[0];
    } else {
      env = 'Prod';
    }

    this.appRegDetails.customerId = customer;
    this.appRegDetails.environment = env;
  }
}
