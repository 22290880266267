import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { Artifact } from 'src/app/deployments/models/Artifact';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ArtifactService {

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService
  ) { }

  getArtifactByName(feedName: string, name: string): Observable<Artifact> {
    return this.httpClient
      .get<Artifact>(`${window.location.origin}/api/ado/artifacts/${feedName}?packageNameQuery=${name}&stateFilter=All&api-version=${Constants.API_VERSION_PREVIEW}.1`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getArtifactByName'))
      );
  }
}
