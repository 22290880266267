import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../components/base.component';
import { AuditLogData } from '../shared/models/AuditLogData';
import { Subscription } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-audit-log',
  templateUrl: './auditLog.component.html',
  styleUrls: ['./auditLog.component.scss']
})
export class AuditLogComponent extends BaseComponent implements OnInit, OnDestroy {
  auditLogData: AuditLogData[];
  selectedAuditLog: AuditLogData;
  logDetailsOpened: boolean;
  private sub: Subscription;
  public gridData: GridDataResult;
  public pageSize: number = 10;
  public page: number = 1;
  public auditLogCount: number = 0;
  public windowLeft = 50;
  public isAsc: boolean = undefined;
  public sortedParam: string = null;
  public state: State = {
    skip: 0,
    take: 10,
    sort: []
  };
  public processState: State = {
    skip: 0,
    take: 10
  };
  public skip = 0;
  public mySelection: number[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.mySelection = [];
    this.loadAuditLogData(this.page);
    this.logDetailsOpened = false;
  }

  public initialiseState(): void {
    this.state.skip = 0;
    this.state.take = 10;
  }

  private loadAuditLogData(page: number) {
    this.sub = this.auditService.getAuditLogs(page)
      .subscribe({
        next: (result) => {
          this.auditLogData = result.results;
          this.gridData = process(this.auditLogData, this.processState);
          this.auditLogCount = result.rowCount;
          this.gridData.total = this.auditLogCount;
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  private loadSortedAuditLogData(sortProperty: string, isAsc: boolean, page: number) {
    this.sub = this.auditService.getSortedAuditLogs(sortProperty, isAsc, page)
      .subscribe({
        next: (result) => {
          this.auditLogData = result.results;
          this.gridData = process(this.auditLogData, this.processState);
          this.auditLogCount = result.rowCount;
          this.gridData.total = this.auditLogCount;
        },
        error: (error) => {
          console.error(error);
        }
      });
  }


  public pageChange(event: PageChangeEvent): void {
    this.mySelection = [];
    this.state.skip = event.skip;
    this.page = (this.state.skip / this.state.take) + 1;
    this.processState.skip = 0;
    this.state.filter = this.processState.filter = null;
    if (this.isAsc === undefined) {
      this.loadAuditLogData(this.page);
    } else if (this.isAsc) {
      this.loadSortedAuditLogData(this.sortedParam, true, this.page);
    } else {
      this.loadSortedAuditLogData(this.sortedParam, false, this.page);
    }
    //this.state.skip = event.skip;
    this.logDetailsOpened = false;
  }

  public filterChange(e): void {
    this.processState.filter = this.state.filter = e;
    this.gridData = process(this.auditLogData, this.processState);
    this.gridData.total = this.auditLogCount;
  }

  public sortChange(e): void {
    this.state.filter = this.processState.filter = null;
    this.state.sort = e;
    this.sortedParam = this.state.sort[0].field.charAt(0).toUpperCase() + this.state.sort[0].field.slice(1);
    this.page = 1;

    if (this.state.sort[0].dir != undefined && this.state.sort[0].dir === 'asc') {
      this.isAsc = true;
      this.loadSortedAuditLogData(this.sortedParam, true, this.page);
    } else if (this.state.sort[0].dir != undefined && this.state.sort[0].dir === 'desc') {
      this.isAsc = false;
      this.loadSortedAuditLogData(this.sortedParam, false, this.page);
    } else {
      this.isAsc = undefined;
      this.loadAuditLogData(this.page);
    }
    this.initialiseState();
  }

  public selectionChange(e): void {
    this.logDetailsOpened = false;
    this.selectedAuditLog = e.selectedRows[0].dataItem;
    this.logDetailsOpened = true;
  }

  public close(): void {
    this.mySelection = [];
    this.logDetailsOpened = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
