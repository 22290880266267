<div class="container wrapper pt-2 row col-12 d-flex justify-content-center flex-wrap text-uppercase fs-5">
  MT Db Refresh
</div>
<div>
  <div class="px-2 fs-6">
    Search for Customer Id
  </div>
    <kendo-tilelayout
    [columns]="12"
    [resizable]="true"
    [reorderable]="true"
    >    
    <kendo-tilelayout-item [col]="1" [colSpan]="3">
      <kendo-tilelayout-item-body>
        <kendo-textbox placeholder="Search for customer ID" class ="w-100 h-100" [(ngModel)]="searchTerm" (keydown)="onKeyDown($event)">
          <ng-template kendoTextBoxSuffixTemplate>
            <kendo-icon name="search"></kendo-icon>
          </ng-template>
        </kendo-textbox>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    
    <kendo-tilelayout-item [col]="4" [colSpan]="1">
      <kendo-tilelayout-item-body>
        <button kendoButton primary="true" (click)="searchLogs()" class ="w-100 h-100">Search</button>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item [col]="5" [colSpan]="1">
      <kendo-tilelayout-item-body>
        <button kendoButton primary="true" (click)="refresh()" class ="w-100 h-100">Refresh</button>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item [col]="6" [colSpan]="1">
      <kendo-tilelayout-item-body>
        <button kendoButton primary="true" (click)="clear()" class ="w-100 h-100">Clear</button>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    
  
    </kendo-tilelayout>

    <kendo-grid
    [resizable]="true"
    [data]="gridData"

    [pageable]="true" [pageSize]="state.take" [skip]="state.skip"

    (pageChange)="pageChange($event)"
    *ngIf="searchClicked"
    [rowClass]="rowCallback"
    >
    <kendo-grid-column
      field="name"
      title="Name"
      [width]="140"
    >
    <ng-template kendoGridFilterMenuTemplate
    let-filter let-column="column" let-filterService="filterService">
    
    <kendo-grid-string-filter-menu
      [column]="column" [filter]="filter" [filterService]="filterService"
      [extra]="false"
    >
    <kendo-filter-contains-operator></kendo-filter-contains-operator>
    </kendo-grid-string-filter-menu>
    </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="source"
      title="Source"
      [width]="120"
    >
    <ng-template kendoGridFilterMenuTemplate
    let-filter let-column="column" let-filterService="filterService">
    
    <kendo-grid-string-filter-menu
      [column]="column" [filter]="filter" [filterService]="filterService"
      [extra]="false"
    >
    <kendo-filter-contains-operator></kendo-filter-contains-operator>
    </kendo-grid-string-filter-menu>
    </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
    field="target"
    title="Target"
    [width]="120"
  >
  <ng-template kendoGridFilterMenuTemplate
  let-filter let-column="column" let-filterService="filterService">
  
  <kendo-grid-string-filter-menu
    [column]="column" [filter]="filter" [filterService]="filterService"
    [extra]="false"
  >
  <kendo-filter-contains-operator></kendo-filter-contains-operator>
  </kendo-grid-string-filter-menu>
  </ng-template>
  </kendo-grid-column>
    <kendo-grid-column
      field="status"
      title="Status"
      [width]="80"
    >
    <ng-template kendoGridFilterMenuTemplate
    let-filter let-column="column" let-filterService="filterService">
    
    <kendo-grid-string-filter-menu
      [column]="column" [filter]="filter" [filterService]="filterService"
      [extra]="false"
    >
    <kendo-filter-contains-operator></kendo-filter-contains-operator>
    </kendo-grid-string-filter-menu>
    </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="date"
      title="Created Date"
      [width]="80"
    >
    <ng-template kendoGridFilterMenuTemplate
    let-filter let-column="column" let-filterService="filterService">
    
    <kendo-grid-string-filter-menu
      [column]="column" [filter]="filter" [filterService]="filterService"
      [extra]="false"
    >
    <kendo-filter-contains-operator></kendo-filter-contains-operator>
    </kendo-grid-string-filter-menu>
    </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column
      [width]="60"
      [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <button kendoButton themeColor="primary" (click)="goToTaskDetails(dataItem)" *ngIf ="dataItem.status"  
            >
                <!-- <a target="_blank" href="{{ dataItem.link }}" *ngIf="dataItem.link!=null">
                Details
                </a> -->
                Details
            </button>
        
          <!-- <a class="k-button k-primary" themeColor="primary" (click)="viewDetails(dataItem.id)">
            Details
          </a> -->
          <!-- <button kendoButton themeColor="primary" (click)="viewDetails(dataItem.id)">Details</button>
    -->
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    [width]="60"
    [filterable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    
      <button kendoButton themeColor="secondary" (click)="cancel(dataItem)" *ngIf="showDbRefreshRerunFeature && dataItem && dataItem.status && (dataItem.status.includes('Queued') || dataItem.status.includes('Awaiting Action') || dataItem.status.includes('In Progress'))">
        Cancel
    </button>

        <button kendoButton themeColor="primary" (click)="reRunBuild(dataItem)" *ngIf="showDbRefreshRerunFeature && dataItem && dataItem.status && !(dataItem.status.includes('Queued') || dataItem.status.includes('Awaiting Action') || dataItem.status.includes('In Progress'))">
          Restart
  </button>
      
        <!-- <a class="k-button k-primary" themeColor="primary" (click)="viewDetails(dataItem.id)">
          Details
        </a> -->
        <!-- <button kendoButton themeColor="primary" (click)="viewDetails(dataItem.id)">Details</button>
  -->
    </ng-template>
  </kendo-grid-column>
    
    </kendo-grid>
<!-- <div *ngFor="let refreshdata of statusData">
<legend class="d-flex justify-content-center flex-wrap p-4">{{refreshdata[0].customerId}}</legend>

</div>       -->
</div>

