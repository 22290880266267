<kendo-appbar positionMode="sticky" class="header" style="padding: 0 0;">
  <kendo-appbar-section class="nav-container">
    <span class="k-icon hamburger-icon ms-3" (click)="onButtonClick()"></span>
  </kendo-appbar-section>

  <div>
    <kendo-appbar-section class="title" >
      <div (click)="goHome()" class="clickable">DevOps Portal on {{this.Organization}} ({{currentApplicationVersion}}) - {{ selectedPage }} </div>
    </kendo-appbar-section>
  </div>

  <kendo-appbar-spacer></kendo-appbar-spacer>

  <kendo-appbar-section>
    <kendo-dropdownbutton
      [data]="loginActions"
      textField="actionName"
      *ngIf="loggedIn"
    >
      <app-avatar></app-avatar>
    </kendo-dropdownbutton>
  </kendo-appbar-section>
  <button kendoButton class="k-button" themeColor="primary" (click)="login()" *ngIf="!loggedIn">Login</button>
  <button kendoButton class="k-button" themeColor="primary" (click)="logout()" *ngIf="loggedIn">Logout</button>
</kendo-appbar>
