import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Batch } from '../shared/models/Batch';
import { Release } from '../shared/models/Release';
import { Environment, Task } from '../deployments/models/AzRelease';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private batchSource = new BehaviorSubject<Batch>(null);
  private batch = this.batchSource.asObservable();

  private releaseSource = new BehaviorSubject<Release>(null);
  private release = this.releaseSource.asObservable();

  private environmentSource = new BehaviorSubject<Environment>(null);
  private environment = this.environmentSource.asObservable();

  private taskSource = new BehaviorSubject<Task>(null);
  private task = this.taskSource.asObservable();

  get Batch() {
    return this.batch;
  }

  updateBatch(data: Batch): void {
    this.batchSource.next(data);
  }

  get Release() {
    return this.release;
  }

  updateRelease(data: Release): void {
    this.releaseSource.next(data);
  }

  get Environment() {
    return this.environment;
  }

  updateEnvironment(data: Environment): void {
    this.environmentSource.next(data);
  }

  get Task() {
    return this.taskSource.pipe(first());
  }

  updateTask(data: Task): void {
    this.taskSource.next(data);
  }
}
