import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public data;
private hubConnection: signalR.HubConnection

  constructor() { }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl(`${window.location.origin}/CustomerOnboardingHub`)
                            .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addCustomerOnboardingStatusListener = (customerId) => {
    this.hubConnection.on(`${customerId}`, (data) => {
      this.data = data;
    });
  }
}
