import { Component, Input } from '@angular/core';
import { DialogAction, DialogCloseResult, DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constants } from '../../../shared/Constants';

@Component({
  selector: 'app-environment-info',
  templateUrl: './environment-info.component.html',
  styleUrls: ['./environment-info.component.scss']
})
export class EnvironmentInfoComponent extends DialogContentBase {

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  @Input() public set type(value: string) {
    this.formGroup.controls.type.setValue(value);
    this._type = value;
  }

  @Input() public set name(value: string) {
    this.formGroup.controls.name.setValue(value);
    this._name = value;
  }

  @Input() public set platform(value: string) {
    this.formGroup.controls.platform.setValue(value);
    this._platform = value;
  }

  public get name(): string {
    return this._name;
  }

  public get type(): string {
    return this._type;
  }

  public get platform(): string {
    return this._platform;
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl(this.type),
    name: new UntypedFormControl(this.name),
    platform: new UntypedFormControl(this.platform),
  });

  private _type: string;
  private _name: string;
  private _platform: string;

  public onCancelAction(): void {
    this.dialog.close(DialogCloseResult);
  }

  public onConfirmAction(): void {
    this.dialog.close({ text: 'Submit', themeColor: Constants.THEME_PRIMARY } as DialogAction);
  }
}
