<app-header (toggle)="toggleDrawer(drawer)"
            [selectedPage]="selected"
            [loggedIn]="authService.loggedIn"
></app-header>
<kendo-drawer-container>
  <kendo-drawer
    #drawer
    [items]="drawerItems"
    [mode]="mode"
    [mini]="mini"
    [width]="275"
    [expanded]="true"
    [animation]="true"
    [autoCollapse]="false"
    (select)="onSelect($event)"
    [isItemExpanded]="isItemExpanded"
  >
  </kendo-drawer>
  <kendo-drawer-content>
    <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>
<ngx-ui-loader></ngx-ui-loader>

