import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { HttpClient } from '@angular/common/http';
import { PipelineRunRequest } from '../../deployments/models/PipelineRunRequest';
import { PipelineRunResponse } from '../../deployments/models/PipelineRunResponse';
import { PipelineRunLogs } from '../../deployments/models/PipelineRunLogs';
import { BuildCancelResponse } from '../../deployments/models/BuildCancelResponse';
import { AzBuild } from '../../deployments/models/AzBuild';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PipelineService {

  constructor(
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService) {
  }

  getPipelinesByProject(project: string): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/${project}?stateFilter=All&api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelinesByProject'))
      );
  }

  getPipelinesById(project: string, pipelineId: number): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/${project}/pipeline/${pipelineId}?stateFilter=All&api-version=${Constants.API_VERSION_PREVIEW}.1`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelinesById'))
      );
  }

  runPipeline(project: string, pipelineId: string, parameters: PipelineRunRequest): Observable<PipelineRunResponse> {
    return this.httpClient
      .post<PipelineRunResponse>(`${window.location.origin}/api/ado/pipelines/runs/${project}/pipeline/${pipelineId}?api-version=${Constants.API_VERSION_PREVIEW}.1`, parameters.templateParameters)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('runPipeline'))
      );
  }

  runPipelinesOfProducts(project: string, parameters: PipelineRunRequest[]): Observable<any[]> {
    return this.httpClient
      .post<any[]>(`${window.location.origin}/api/ado/Pipelines/runs/${project}/products?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}.1`, parameters)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('runPipelinesOfProducts'))
      );
  }

  updateTemplateFileOfProducts(parameters: PipelineRunRequest[]): Observable<any[]> {
    return this.httpClient
      .post<any[]>(`${window.location.origin}/api/ado/Pipelines/templateFile/runs`, parameters)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateTemplateFileOfProducts'))
      );
  }

  updateTemplateFileForCnfi(parameters: any[]): Observable<any[]> {
    return this.httpClient
      .post<any[]>(`${window.location.origin}/api/ado/Pipelines/cnfiTemplateFile/runs`, parameters)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateTemplateFileForCnfi'))
      );
  }

  reRunBuild(project,buildId): Observable<any[]> {
    return this.httpClient
      .post<any[]>(`${window.location.origin}/api/ado/Pipelines/rerun/${project}/${buildId}`, null)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('reRunBuild'))
      );
  }

  cancelBuild(buildId): Observable<any[]> {
    return this.httpClient
      .put<any[]>(`${window.location.origin}/api/ado/Pipelines/Cancel/${buildId}`, null)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('reRunBuild'))
      );
  }

  runPipelinesOfProductsFireAndForget(project: string, parameters){
    return new Promise<boolean>(async(resolve, reject) =>{
      this.httpClient
        .post<any[]>(`${window.location.origin}/api/ado/Pipelines/runs/${project}/products?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}.1`, parameters)
        .toPromise()
        .then(
          (data) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('runPipelinesOfProductsFireAndForget')) });
     });
  }

  runPipelinesOfIntegrationProductsFireAndForget(project: string, parameters){
    return new Promise<boolean>(async(resolve, reject) =>{
      this.httpClient
        .post<any[]>(`${window.location.origin}/api/ado/Pipelines/runs/${project}/integrationProducts?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}.1`, parameters)
        .toPromise()
        .then(
          (data) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('runPipelinesOfIntegrationProductsFireAndForget')) });
     });
  }

  runPipelinesOfPsIntegrationFireAndForget(project: string, parameters): Observable<any[]> {
    return this.httpClient
        .post<any[]>(`${window.location.origin}/api/ado/Pipelines/runs/${project}/PipelinesOfPsIntegration?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}.1`, parameters)
        .pipe(
          catchError(this.errorHandling.handleAllErrors<any>('runPipelinesOfPsIntegrationFireAndForget'))
      );
  }

  runPipelinesOfTenants(project: string, parameters: PipelineRunRequest[]): Observable<any[]> {
    return this.httpClient
      .post<any[]>(`${window.location.origin}/api/ado/Pipelines/runs/${project}/tenants?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}.1`, parameters)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('runPipelinesOfTenants'))
      );
  }

  runPipelinesOfProduct(project: string, parameters: PipelineRunRequest): Observable<PipelineRunResponse[]> {
    return this.httpClient
      .post<PipelineRunResponse[]>(`${window.location.origin}/api/ado/Pipelines/runs/${project}/product?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}.1`, parameters)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('runPipelinesOfProduct'))
      );
  }

  runOnboardingPipelines(customerOnboardingRequest) {
    return new Promise<boolean>(async(resolve, reject) =>{
      this.httpClient
        .post(`${window.location.origin}/api/ado/Pipelines/RunCustomerOnboardingPipelines`, customerOnboardingRequest)
        .toPromise()
        .then(
          (data) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('runOnboardingPipelines')) });
     });
  }

  runFinanceIntegrationPipelines(financeIntegrationRequest) {
    return new Promise<boolean>(async(resolve, reject) =>{
      this.httpClient
        .post(`${window.location.origin}/api/ado/Pipelines/RunFinanceIntegrationPipelines`, financeIntegrationRequest)
        .toPromise()
        .then(
          (data) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('runFinanceIntegrationPipelines')) });
     });
  }

  runMailboxManagerPipelines(mailboxManagerRequest) {
    return new Promise<boolean>(async(resolve, reject) =>{
      this.httpClient
        .post(`${window.location.origin}/api/ado/Pipelines/RunMailboxManagerPipelines`, mailboxManagerRequest)
        .toPromise()
        .then(
          (data) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('runMailboxManagerPipelines')) });
     });
  }

  runMailboxManagerPipelines1x(mailboxManagerRequest) {
    return new Promise<boolean>(async(resolve, reject) =>{
      this.httpClient
        .post(`${window.location.origin}/api/ado/Pipelines/RunMailboxManagerPipelines1X`, mailboxManagerRequest)
        .toPromise()
        .then(
          (data) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('runMailboxManagerPipelines1x')) });
     });
  }

  runNewEnvironmentPipelines(newEnvironmentRequest) {
    return new Promise<boolean>(async(resolve, reject) =>{
      this.httpClient
        .post(`${window.location.origin}/api/ado/Pipelines/RunNewEnvironmentPipelines`, newEnvironmentRequest)
        .toPromise()
        .then(
          (data) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('runNewEnvironmentPipelines')) });
     });
  }

  runPsIntegrationResourcePipeline(request) {
    return this.httpClient
      .post(`${window.location.origin}/api/ado/Pipelines/RunPsIntegrationResourcePipeline`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('runPsIntegrationResourcePipeline'))
      );
  }

  getPipelineRun(project: string, pipelineId: string, runId: number): Observable<PipelineRunResponse> {
    return this.httpClient
      .get<PipelineRunResponse>(`${window.location.origin}/api/ado/pipelines/runs/${project}/pipeline/${pipelineId}/run/${runId}?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}.1`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelineRun'))
      );
  }

  getCustomerOnboardingStatus(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/CustomerOnboarding/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerOnboardingStatus'))
      );
  }

  getFinanceIntegrationStatus(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/FinanceIntegration/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getFinanceIntegrationStatus'))
      );
  }

  getMailboxManagerStatus(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/MailboxManager/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMailboxManagerStatus'))
      );
  }

  getMTDbRefreshStatus(customerId: string, page): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/MTDbRefresh/${customerId}?page=${page}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMTDbRefreshStatus'))
      );
  }

  getPsIntegrationStatuses(): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/PsIntegrationStatuses`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPsIntegrationStatuses'))
      );
  }

  getMTDbRefreshLogs(page): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/appUpgradeLogs/${page}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getMTDbRefreshStatus'))
      );
  }

  pollCustomerOnboardingStatus(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/poll/CustomerOnboarding/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerOnboardingStatus'))
      );
  }

  pollFinanceIntegrationStatus(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/poll/FinanceIntegration/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('pollFinanceIntegrationStatus'))
      );
  }

  pollMailboxManagerStatus(customerId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/poll/MailboxManager/${customerId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('pollMailboxManagerStatus'))
      );
  }

  pollMTDbRefreshStatus(customerId: string, page): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/poll/MTDbRefresh/${customerId}?page=${page}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('pollMTDbRefreshStatus'))
      );
  }

  getCustomerOnboardingStatusHub(customerId: string,hubKey){
    return this.httpClient
      .get(`${window.location.origin}/api/ado/pipelines/CustomerOnboarding/Hub/${customerId}?hubKey=${hubKey}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getCustomerOnboardingStatusHub'))
      );
  }

  startCustomerOnboardingStatusHubPromise(customerId: string,hubKey){
    return new Promise<any>(async(resolve, reject) =>{
      this.httpClient
        .post(`${window.location.origin}/api/ado/pipelines/CustomerOnboarding/Hub/Run/${customerId}?hubKey=${hubKey}`,null)
        .toPromise()
        .then(
          (result) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('getCustomerOnboardingStatusHubPromise')) });
     });
  }

  deleteCustomerOnboardingStatusHubPromise(hubKey){
    return new Promise<any>(async(resolve, reject) =>{
      this.httpClient
        .delete(`${window.location.origin}/api/ado/pipelines/CustomerOnboarding/Hub/End?hubKey=${hubKey}`)
        .toPromise()
        .then(
          (result) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('getCustomerOnboardingStatusHubPromise')) });
     });
  }

  cancelCustomerOnboardingBuild(buildId: string,data,searchTerm: string): Observable<any> {
    return this.httpClient
      .patch<any>(`${window.location.origin}/api/ado/pipelines/CustomerOnboarding/Cancel?buildId=${buildId}&searchTerm=${searchTerm}`,data)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('cancelCustomerOnboardingBuild'))
      );
  }

  queueCustomerOnboardingBuild(data,searchTerm: string): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/pipelines/CustomerOnboarding/Queue?searchTerm=${searchTerm}`,data)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('queueCustomerOnboardingBuild'))
      );
  }

  startCustomerOnboardingBuild(data){
    return new Promise<any>(async(resolve, reject) =>{
      this.httpClient
        .post<any>(`${window.location.origin}/api/ado/pipelines/CustomerOnboarding/Start`,data)
        .toPromise()
        .then(
          (result) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('startCustomerOnboardingBuild')) });
     });
  }

  cancelFinanceIntegrationBuild(buildId: string,data,searchTerm: string): Observable<any> {
    return this.httpClient
      .patch<any>(`${window.location.origin}/api/ado/pipelines/FinanceIntegration/Cancel?buildId=${buildId}&searchTerm=${searchTerm}`,data)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('cancelFinanceIntegrationBuild'))
      );
  }

  queueFinanceIntegrationBuild(data,searchTerm: string): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/pipelines/FinanceIntegration/Queue?searchTerm=${searchTerm}`,data)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('queueFinanceIntegrationBuild'))
      );
  }

  startFinanceIntegrationBuild(data){
    return new Promise<any>(async(resolve, reject) =>{
      this.httpClient
        .post<any>(`${window.location.origin}/api/ado/pipelines/FinanceIntegration/Start`,data)
        .toPromise()
        .then(
          (result) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('startFinanceIntegrationBuild')) });
     });
  }

  cancelMailboxManagerBuild(buildId: string,data,searchTerm: string): Observable<any> {
    return this.httpClient
      .patch<any>(`${window.location.origin}/api/ado/pipelines/MailboxManager/Cancel?buildId=${buildId}&searchTerm=${searchTerm}`,data)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('cancelMailboxManagerBuild'))
      );
  }

  queueMailboxManagerBuild(data,searchTerm: string): Observable<any> {
    return this.httpClient
      .post<any>(`${window.location.origin}/api/ado/pipelines/MailboxManager/Queue?searchTerm=${searchTerm}`,data)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('queueMailboxManagerBuild'))
      );
  }

  startMailboxManagerBuild(data){
    return new Promise<any>(async(resolve, reject) =>{
      this.httpClient
        .post<any>(`${window.location.origin}/api/ado/pipelines/MailboxManager/Start`,data)
        .toPromise()
        .then(
          (result) => {
              resolve(true);
            })
          .catch( (errorDetails) => { catchError(this.errorHandling.handleAllErrors<any>('startMailboxManagerBuild')) });
     });
  }

  getPipelineRunStatus(project: string, buildId: string): Observable<string> {
    return this.httpClient
      .get(`${window.location.origin}/api/ado/pipelines/status/${project}/build/${buildId}?api-version=${Constants.API_VERSION}`, {responseType: 'text'})
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelineRunStatus'))
      );
  }

  getPipelineRunTimeline(project: string, buildId: string): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/timeline/${project}/build/${buildId}?api-version=${Constants.API_VERSION}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelineRunTimeline'))
      );
  }

  pollPipelineRunTimeline(project: string, buildId: string): Observable<any> {

    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/poll/timeline/${project}/build/${buildId}?api-version=${Constants.API_VERSION}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('pollPipelineRunTimeline'))
      );
  }

  getPipelineRunAllLogs(project: string, pipelineId: number): Observable<PipelineRunLogs> {
    return this.httpClient
      .get<PipelineRunLogs>(`${window.location.origin}/api/ado/pipelines/logs/${project}/build/${pipelineId}?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelineRunAllLogs'))
      );
  }

  getPipelineRunLog(project: string, pipelineId: number, logId: number): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/logs/${project}/build/${pipelineId}/log/${logId}?pipelineVersion=3&api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getPipelineRunLog'))
      );
  }

  cancelRunningBuild(project: string, buildId: number): Observable<BuildCancelResponse> {
    return this.httpClient
      .patch<BuildCancelResponse>(`${window.location.origin}/api/ado/pipelines/cancel/${project}/build/${buildId}?pipelineVersion=3&api-version=${Constants.API_VERSION}`
      , {'status': 'Cancelling'})
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('cancelRunningBuild'))
      );
  }

  getBuild(project: string, buildId: number): Observable<AzBuild> {
    return this.httpClient
      .get<AzBuild>(`${window.location.origin}/api/ado/pipelines/${project}/build/${buildId}?pipelineVersion=3&api-version=${Constants.API_VERSION}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getBuild'))
      );
  }

  getAppUpgradeStatus(batchId): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/appUpgradeStatus/${batchId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAppUpgradeStatus'))
      );
  }

  getAppUpgradeJobStatus(project,releaseId): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/pipelines/appUpgradeStatus/${project}/${releaseId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAppUpgradeJobStatus'))
      );
  }

  pollAppUpgradeStatus(batchId): Observable<any> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/poll/appUpgradeStatus/${batchId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('pollAppUpgradeStatus'))
      );
  }
}
