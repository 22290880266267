<kendo-dialog
  [minWidth]="750"
  [width]="750"
  [minHeight]="500"
  [height]="500">
  <kendo-dialog-titlebar>
    <div>
      <span class="k-icon k-i-form-element"></span> Customer
    </div>
  </kendo-dialog-titlebar>
  <div class="container g-0">
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="form-group col-6">
          <label for="projectId">ProjectId</label>
          <input
            type="text"
            class="form-control"
            id="projectId"
            [formControl]="formGroup.get('projectId')"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="cloudServiceCode">Cloud Service Code</label>
          <input
            type="text"
            class="form-control"
            id="cloudServiceCode"
            [formControl]="formGroup.get('cloudServiceCode')"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="customerName">Customer Name</label>
          <input
            type="text"
            class="form-control"
            id="customerName"
            [formControl]="formGroup.get('customerName')"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="applicationId">Application Id</label>
          <input
            type="text"
            class="form-control"
            id="applicationId"
            [formControl]="formGroup.get('applicationId')"
            required
          />
        </div>
        <div class="form-group col-6">
          <label for="clientSecret">Client Secret</label>
          <input
            type="text"
            class="form-control"
            id="clientSecret"
            [formControl]="formGroup.get('clientSecret')"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-6">
          <label for="userName">User Name</label>
          <input
            type="text"
            class="form-control"
            id="userName"
            [formControl]="formGroup.get('userName')"
            required
          />
        </div>
        <div class="form-group col-6">
          <label for="password">Password</label>
          <input
            type="text"
            class="form-control"
            id="password"
            [formControl]="formGroup.get('password')"
            required
          />
        </div>
      </div>
    </form>
  </div>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button
      kendoButton
      (click)="onConfirmAction()"
      themeColor="primary"
      [disabled]="!formGroup.valid"
    >
      Save
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
