import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../../../components/base.component';
import { Task } from '../../../models/AzRelease';
import { Location } from '@angular/common';

@Component({
  selector: 'app-stage-detail',
  templateUrl: './stage-detail.component.html',
  styleUrls: ['./stage-detail.component.scss']
})
export class StageDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  tasks: Task[] = [];
  errorLog: string;
  private sub: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private location: Location) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeChanges();
  }

  private subscribeChanges() {
    this.sub = this.dataService.Environment.subscribe(e => {
      const releaseDeployPhase = e.deploySteps[0].releaseDeployPhases[0];
      const deploymentJobs = releaseDeployPhase?.deploymentJobs;
      if(deploymentJobs?.length > 0) {
        this.tasks = deploymentJobs[0].tasks;
        this.errorLog = null;
      } else {
        this.errorLog = releaseDeployPhase?.errorLog;
        this.tasks = [];
      }
      this.cdr.detectChanges();
    });
  }

  onGoBack() {
    this.location.back();
  }
}
