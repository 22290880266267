import { Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Policy } from '../../../../shared/models/Policy';
import { BaseComponent } from '../../../../components/base.component';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SelectablePolicy } from '../../../../shared/models/SelectablePolicy';
import { AdGroup } from '../../../../shared/models/AdGroup';

@Component({
  selector: 'app-group-policies',
  templateUrl: './group-policies.component.html',
  styleUrls: ['./group-policies.component.scss']
})
export class GroupPoliciesComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() public group: AdGroup;

  @Input() allPolicies: any[] = [];
  @Output() itemEmit = new EventEmitter<any>();

  policies: any[] = [];

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loaderService.start()
    if (this.group.adGroupPolicies.length <= 0) {
      this.setPolicySelections([]);
      return;
    }

    this.getPolicies(this.group).subscribe({
      next: (result) => {
        this.setPolicySelections(result);
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop()
      },
      complete: () => {
        this.loaderService.stop()
      }
    });
  }

  private setPolicySelections(result: any[]) {
    this.policies = result;
    this.allPolicies.forEach(p => {
      p.selected = this.policies.some(pl => pl.id === p.id);
    });
    this.loaderService.stop()
  }

  ngOnInit(): void {
    this.loaderService.start();
    this.loadAllPolicies();
  }

  private loadAllPolicies() {
    this.policyService.getPolicies().subscribe({
      next: (result) => {
        this.allPolicies = result.map(p => ({...p, selected: false}));
        this.itemEmit.emit(this.allPolicies);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  private getPolicies(group: AdGroup): Observable<any[]> {

    const observables: Observable<any>[] =
      group.adGroupPolicies.map(agp =>
        this.policyService.getPolicyById(agp.policyId)
          .pipe(
            switchMap(af => {
              return of(af);
            })
          )
      );

    return forkJoin(observables);
  }
}
