import { Component, ViewChild } from '@angular/core';
import { DbSelectionChangeArg } from '../../../../../shared/models/DbSelectionChangeArg';
import { DbSingleTenantRestoreFromFilePanelComponent } from './db-single-tenant-restore-from-file-panel.component';
import {
  DbSingleTenantRestoreFromDbPanelComponent
} from './db-single-tenant-restore-from-db-panel/db-single-tenant-restore-from-db-panel.component';

@Component({
  selector: 'app-db-single-tenant-restore-panel',
  templateUrl: './db-single-tenant-restore-panel.component.html',
  styleUrls: ['./db-single-tenant-restore-panel.component.scss']
})
export class DbSingleTenantRestorePanelComponent {

  @ViewChild('restoreFromFilePanel') restoreFromFilePanel: DbSingleTenantRestoreFromFilePanelComponent;
  @ViewChild('restoreFromDbPanel') restoreFromDbPanel: DbSingleTenantRestoreFromDbPanelComponent;

  restoreType: string = 'FromFile';

  constructor() { }

  public set Source(val: DbSelectionChangeArg) {
    if (this.restoreFromFilePanel) {
      this.restoreFromFilePanel.Source = val;
    }

    if (this.restoreFromDbPanel) {
      this.restoreFromDbPanel.Source = val;
    }
  }

  public set Target(val: DbSelectionChangeArg) {
    if (this.restoreFromFilePanel) {
      this.restoreFromFilePanel.Target = val;
    }

    if (this.restoreFromDbPanel) {
      this.restoreFromDbPanel.Target = val;
    }
  }
}
