import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../components/base.component';
import { AzRelease, Environment } from '../../../models/AzRelease';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Constants } from '../../../../shared/Constants';
import { Release } from '../../../../shared/models/Release';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-release-list',
  templateUrl: './release-list.component.html',
  styleUrls: ['./release-list.component.scss']
})
export class ReleaseListComponent extends BaseComponent implements OnChanges {
  @Input() data: Release[] = [];

  releases: AzRelease[];
  releasesView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.PopulateAzReleases(this.data).subscribe(releases => {
      this.releases = releases;
      this.releasesView = process(this.releases, this.state);
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.releasesView = process(this.releases, this.state);
  }

  onEnvironmentSelected(dateItem: any, env: Environment) {
    this.dataService.updateEnvironment(env);
    this.router.navigate([`${Constants.BATCHES}/${Constants.STAGE_DETAIL}/${env.id}`]).then();
  }

  private PopulateAzReleases(releases: Release[]): Observable<AzRelease[]> {
    const observables: Observable<AzRelease>[] =
      releases.map(rel =>
        this.releaseService.getReleaseById(rel.projectName, rel.id)
      );

    return forkJoin(observables);
  }

  getEnvironments(release: AzRelease): any[] {
    return release.environments.map(e => (
      {
        'Name': e.name,
        'Environment': e
      }));
  }

  getStatusColor(status) {
    return {
      'succeeded': 'lightgreen',
      'Partially succeeded': 'lightyellow',
      'failed': 'red',
      'notStarted': 'lightgray',
      'inProgress': '#007bff'
    }[status];
  }
}
