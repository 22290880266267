import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { Observable, Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/Constants';
import { PsInfrastructure } from 'src/app/shared/models/psIntegration/PsInfrastructure';
import { PipelineMapping } from '../../shared/models/PipelineMapping';
import { Batch } from '../../shared/models/Batch';
import * as moment from 'moment/moment';
import { BatchType } from '../../shared/models/BatchType';
import { PsSummaryComponent } from './ps-summary/ps-summary.component';

@Component({
  selector: 'app-ps-integration',
  templateUrl: './ps-integration.component.html',
  styleUrls: ['./ps-integration.component.scss']
})
export class PsIntegrationComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(PsSummaryComponent) summaryComponent: PsSummaryComponent;
  public infrastructure: PsInfrastructure = new PsInfrastructure();
  public psIntegrationPipelines: PipelineMapping[];

  private sub: Subscription;

  readonly Constants = Constants;
  public currentStep = 0;

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.loadPsiPipelines();
  }

  public steps = [
    {
      label: 'Infrastructure',
      isValid: true,
      validate: false,
    },
    {
      label: 'Summary',
      isValid: true,
      validate: false,
      disabled: false
    },
  ];

  previousStep() {
    this.currentStep -= 1;
  }

  nextStep() {
    this.currentStep += 1;

    // Load customer environment
    if (this.currentStep === 1) {
      this.populateCustomerEnvironment(this.infrastructure.CustomerID);
    }
  }

  onStartAction() {
    this.loaderService.start();
    let stopLoader = true;
    this.InitializeBatch()
      .subscribe({
        next: (batch) => {
          stopLoader = false;
          const request = {
            'templateParameters': {
              'CustomerID': this.infrastructure?.CustomerID,
              'environmenttype': this.infrastructure?.EnvironmentType,
              'region': this.infrastructure?.Region,
              'Instance': this.infrastructure?.Instance,
            }
          };

          console.log(this.summaryComponent);

          const requests = this.summaryComponent
            .pipelineSelections
            .map(p => {
              return {
                ...request,
                Product: p.productName,
                PipelineName: p.pipelineName,
                PipeLineId: p.pipelineId,
                BatchId: batch.id,
                CreatedName: batch.createdBy
              };
            });

          this.pipelineService.runPipelinesOfPsIntegrationFireAndForget(this.configService.MainProject, requests)
            .subscribe({
              next: (batch) => {
                this.loaderService.stop();
                this.toastr.success(`PS Integration Pipelines started successfully!`, 'Success!');
              },
              error: (error) => {
                this.loaderService.stop();
                this.toastr.error(`${error}`, 'Failure!');
              },
              complete: () => {
                this.loaderService.stop();
              }
            });
        },
        error: (error) => {
          this.loaderService.stop();
          this.toastr.error(`${error}`, 'Failure!');
        },
        complete: () => {
          if (stopLoader) {
            this.loaderService.stop();
          }
        }
      });
  }

  InitializeBatch(): Observable<Batch> {
    const batch = new Batch();
    batch.name = `${this.AccountInfo?.name}-${moment().format()}`;
    batch.type = BatchType.PsIntegration;
    batch.projectName = this.configService.MainProject;
    batch.createdBy = this.AccountInfo?.name;
    batch.createdDate = new Date();
    batch.modifiedBy = this.AccountInfo?.name;
    batch.modifiedDate = new Date();

    return this.batchService.createBatch(batch);
  }

  disableStartButton(): boolean {
    return Object.values(this.infrastructure).some(x => x == null)
      || this.summaryComponent.pipelineSelections.length === 0;
  }

  private populateCustomerEnvironment(customerId: string) {
    this.loaderService.start();
    this.sub = this.customerEnvironmentService.getCustomerEnvironment(customerId)
      .subscribe({
        next: (result) => {
          this.infrastructure.CustomerID = customerId;
          this.infrastructure.Region = result.region;
          this.infrastructure.Instance = result.instance;
          this.infrastructure.EnvironmentType = result.environment;
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
  }

  private loadPsiPipelines() {
    this.loaderService.start();
    this.sub = this.adminService.getPipelinesOfProduct('PSI')
      .subscribe({
        next: (result) => {
          this.psIntegrationPipelines = result.map(p => ({...p, selected: false})).sort((a, b) => a.queueNumber - b.queueNumber);
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        }
      });
  }
}
