import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(@Inject(DOCUMENT) public document: HTMLDocument) {
  }
}
