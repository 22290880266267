import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { AdGroup } from '../../shared/models/AdGroup';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class AdGroupService {
  private url = `${window.location.origin}/api/adgroup`;

  constructor(
    private configService: AppConfigService,
    private authService: AuthService,
    private httpClient: HttpClient,
    private errorHandling: ErrorHandlerService) {
  }

  getAdGroups(): Observable<AdGroup[]> {
    return this.httpClient
      .get<AdGroup[]>(this.url)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAdGroups'))
      );
  }

  getAdGroupById(adGroupId: number): Observable<AdGroup> {
    return this.httpClient
      .get<AdGroup>(`${this.url}/${adGroupId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAdGroupById'))
      );
  }

  getAdGroupsByPolicy(policyName): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/GroupsWithPolicy/${policyName}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getAdGroupsByPolicy'))
      );
  }

  getGroupUsers(groupId): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/GroupUsers/${groupId}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getGroupUsers'))
      );
  }

  isUserInAuthorizedGroup(userId,policyName): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/GroupUserAuthorized/${userId}/${policyName}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('isUserInAuthorizedGroup'))
      );
  }

  addAdGroup(group: AdGroup): Observable<AdGroup> {
    return this.httpClient
      .post<AdGroup>(this.url, group)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addAdGroup'))
      );
  }

  updateAdGroup(id: number, group: AdGroup): Observable<any> {
    return this.httpClient
      .put<any>(`${this.url}/${id}`, group)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateAdGroup'))
      );
  }

  deletePolicy(group: AdGroup): Observable<any> {
    return this.httpClient
      .delete<any>(`${this.url}/${group.id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('deletePolicy'))
      );
  }

  removeAdGroup(group: AdGroup): Observable<any> {
    return this.httpClient
      .delete<any>(`${this.url}/Remove/${group.id}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('removeAdGroup'))
      );
  }
}
