import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Product } from '../../shared/models/Product';
import { Component } from 'src/app/shared/models/Component';
import { ProductVersion } from 'src/app/shared/models/ProductWithVersions';
import { AppConfigService } from '../app-config.service';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Constants } from 'src/app/shared/Constants';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private url = `${window.location.origin}/api/product`;
  private compUrl = `${window.location.origin}/api/component`;



  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private authService: AuthService,
    private errorHandling: ErrorHandlerService
  ) {
  }

  getProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.url)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getProducts'))
      );
  }

  addProduct(product: Product): Observable<Product> {
    return this.httpClient.post<Product>(this.url, product)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('addProduct'))
      );
  }

  getProductsWithVersion(): Observable<ProductVersion[]> {

    return this.httpClient.get<ProductVersion[]>(`${this.url}/versions`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getProductsWithVersion'))
      );
  }

  getComponents(): Observable<Component[]> {
    return this.httpClient.get<Component[]>(this.compUrl)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getComponents'))
      );
  }
}
