import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';
import { DialogAction, DialogRef } from '@progress/kendo-angular-dialog';
import { LcsCustomer } from '../../shared/models/dynamics/LcsCustomer';
import { BaseComponent } from '../../components/base.component';
import { FhcmCustomerUpdateComponent } from '../fhcm-customer-update/fhcm-customer-update.component';
import { Subscription } from 'rxjs';
import { Constants } from '../../shared/Constants';

@Component({
  selector: 'app-fhcm-customers',
  templateUrl: './fhcm-customers.component.html',
  styleUrls: ['./fhcm-customers.component.scss']
})
export class FhcmCustomersComponent extends BaseComponent implements OnInit {
  private sub: Subscription;

  @Output() refreshClicked = new EventEmitter<LcsCustomer>();
  @Output() customerSelectionChanged = new EventEmitter<SelectionEvent>();

  customers: LcsCustomer[];
  customersView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadCustomers();
  }

  private loadCustomers() {
    this.loaderService.start();
    this.sub = this.lcsCustomerService.getCustomers().subscribe({
      next: (result) => {
        this.customers = result;
        this.customersView = process(this.customers, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  onUpdateClicked(dataItem) {
    const dialogRef = this.dialogService.open({
      content: FhcmCustomerUpdateComponent,
    });

    const instance = dialogRef.content.instance;

    if (dataItem) {
      FhcmCustomersComponent.PopulateItems(dataItem, instance);
    }

    dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        if (dataItem) {
          this.update(dataItem, instance);
        } else {
          this.addNew(instance);
        }
      }
    });
  }

  onCopyClicked(dataItem: LcsCustomer) {
    const dialogRef = this.dialogService.open({
      content: FhcmCustomerUpdateComponent,
    });

    const instance = dialogRef.content.instance;

    if (dataItem) {
      FhcmCustomersComponent.PopulateItems(dataItem, instance);
    }

    dialogRef.result.subscribe((r) => {
      const result = r as DialogAction;
      if (result.themeColor === Constants.THEME_PRIMARY) {
        this.addNew(instance);
      }
    });
  }

  onDeleteClicked(dataItem: LcsCustomer) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: `Are you sure you want to delete this Customer: ${dataItem.customerName}? All environment meta data will also be deleted.`,
      actions: [
        {text: 'No'},
        {text: 'Yes', primary: true}
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result) => {
      const r = result as DialogAction;
      if (r.text === 'Yes') {
        this.deleteCustomer(dataItem);
      }
    });
  }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.customersView = process(this.customers, this.state);
  }

  private update(dataItem: LcsCustomer, instance) {
    const customer = FhcmCustomersComponent.buildCustomer(instance);
    this.sub = this.lcsCustomerService.updateCustomer(dataItem.id, {...customer})
      .subscribe({
        next: () => {
          this.loadCustomers();
          this.toastr.success(`Customer ${dataItem.customerName} was updated successfully!`, 'Success!');
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        }
      });
  }

  private addNew(instance) {
    const customer = FhcmCustomersComponent.buildCustomer(instance);
    this.sub = this.lcsCustomerService.addCustomer({...customer})
      .subscribe({
        next: (result) => {
          this.loadCustomers();
          this.toastr.success(`Customer ${result.customerName} was created successfully!`, 'Success!');
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        }
      });
  }

  private static PopulateItems(dataItem: LcsCustomer, instance: FhcmCustomerUpdateComponent): void {
    instance.projectId = dataItem.projectId;
    instance.cloudServiceCode = dataItem.cloudServiceCode;
    instance.customerName = dataItem.customerName;
    instance.applicationId = dataItem.applicationId;
    instance.clientSecret = dataItem.clientSecret;
    instance.userName = dataItem.userName;
    instance.password = dataItem.password;
  }

  private static buildCustomer(instance): LcsCustomer {
    return {
      projectId: instance.formGroup.controls.projectId.value,
      cloudServiceCode: instance.formGroup.controls.cloudServiceCode.value,
      customerName: instance.formGroup.controls.customerName.value,
      applicationId: instance.formGroup.controls.applicationId.value,
      clientSecret: instance.formGroup.controls.clientSecret.value,
      userName: instance.formGroup.controls.userName.value,
      password: instance.formGroup.controls.password.value,
    } as LcsCustomer;
  }

  private deleteCustomer(dataItem: LcsCustomer) {
    this.sub = this.lcsCustomerService.deleteCustomer(dataItem)
      .subscribe({
        next: () => {
          this.loadCustomers();
          this.toastr.success(`Customer ${dataItem.customerName} was delete successfully!`, 'Success!');
        },
        error: (error) => {
          this.toastr.error(`Failed with error: ${error}.`, 'Error!');
        }
      });
  }

  onRefreshClicked(dataItem: LcsCustomer) {
    this.refreshClicked.emit(dataItem)
  }

  selectedRowChangeCustomer($event: SelectionEvent) {
    this.customerSelectionChanged.emit($event);
  }
}
