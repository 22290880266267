import { Component } from '@angular/core';
import { DialogAction, DialogCloseResult, DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constants } from '../../../../../shared/Constants';
import { DatabaseInfoMulti } from '../../../../../shared/models/DatabaseInfoMulti';
import { ActionsLayout } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-db-refresh-confirmation-multi',
  templateUrl: './db-refresh-confirmation-multi.component.html',
  styleUrls: ['./db-refresh-confirmation-multi.component.scss']
})
export class DbRefreshConfirmationMultiComponent extends DialogContentBase {

  public actionsLayout: ActionsLayout = 'center';

  private _source: DatabaseInfoMulti;
  private _target: DatabaseInfoMulti;

  private _sourceConfirm: boolean;
  private _targetConfirm: boolean;

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    sourceConfirm: new UntypedFormControl(this.sourceConfirm),
    targetConfirm: new UntypedFormControl(this.targetConfirm)
  });

  public get sourceConfirm(): boolean {
    return this._sourceConfirm;
  }

  get sourceConfirmControl(): UntypedFormControl {
    return this.formGroup.get('sourceConfirm') as UntypedFormControl;
  }

  public get targetConfirm(): boolean {
    return this._targetConfirm;
  }

  get targetConfirmControl(): UntypedFormControl {
    return this.formGroup.get('targetConfirm') as UntypedFormControl;
  }

  public set Source(value: DatabaseInfoMulti) {
    this._source = value;
  }

  public get Source(): DatabaseInfoMulti {
    return this._source;
  }

  public set Target(value: DatabaseInfoMulti) {
    this._target = value;
  }

  public get Target(): DatabaseInfoMulti {
    return this._target;
  }

  public onCancelAction(): void {
    this.dialog.close(DialogCloseResult);
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Save', themeColor: Constants.THEME_PRIMARY} as DialogAction);
  }

  disableConfirm() {
    return !this.sourceConfirmControl.value || !this.targetConfirmControl.value;
  }
}
