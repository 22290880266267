<div class="d-flex justify-content-center flex-wrap p-4">
    <form class="k-form appRegistrationForm" [formGroup]="appRegistration">
      <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">Reset KeyVault flag</legend>
        <kendo-formfield>
          <kendo-label [for]="customerId" text="Customer Id"></kendo-label>
          <kendo-textbox formControlName="customerId"
                 kendoTextBox
                 #customerId
                 required ></kendo-textbox>
  
          <kendo-formhint>Enter customer Id to reset KeyVault flag</kendo-formhint>
          <kendo-formerror>Enter customer Id to reset KeyVault flag</kendo-formerror>
        </kendo-formfield>
  
        <div class="k-form-buttons">
          <button class="k-button" kendoButton themeColor="primary" (click)="rollback()">
            Reset KeyVault flag
          </button>
        </div>
      </fieldset>
    </form>
  </div>