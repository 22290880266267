<div class="row">
  <kendo-formfield class="form-group {{IsSingleTenant() ? 'col-9' : 'col-12'}}" *ngIf="control">
    <kendo-label [for]="source">{{controlLabel}}</kendo-label>
    <kendo-multicolumncombobox
      #source
      id="source"
      [formControl]="control"
      class="col-12"
      [filterable]="true"
      (filterChange)="handleSourceFilter($event)"
      [textField]="getTextField()"
      [valueField]="getValueField()"
      required
    >
      <kendo-combobox-column field="EnvType" title="Type" [width]="60" *ngIf="IsMultiTenant()">
        <ng-template
          kendoMultiColumnComboBoxColumnCellTemplate
          let-dataItem
        >
          {{ isProdMultiTenant(dataItem.Code) }}
        </ng-template>
      </kendo-combobox-column>
      <kendo-combobox-column field="Code" title="Code" [width]="60" *ngIf="IsMultiTenant()">
      </kendo-combobox-column>
      <kendo-combobox-column field="Name" title="Name" [width]="200" *ngIf="IsMultiTenant()">
      </kendo-combobox-column>
      <ng-template kendoMultiColumnComboBoxFooterTemplate *ngIf="IsMultiTenant()">
        <strong> {{ this.variableGroupsFilteredSource.length }} records in total </strong>
      </ng-template>
      <kendo-combobox-column field="EnvType" title="Type" [width]="60" *ngIf="IsSingleTenant()">
        <ng-template
          kendoMultiColumnComboBoxColumnCellTemplate
          let-dataItem
        >
          {{ isProdSingleTenant(dataItem.name) }}
        </ng-template>
      </kendo-combobox-column>
      <kendo-combobox-column field="name" title="Name" [width]="200" *ngIf="IsSingleTenant()">
      </kendo-combobox-column>
      <ng-template kendoMultiColumnComboBoxFooterTemplate *ngIf="IsSingleTenant()">
        <strong> {{ this.variableGroupsFilteredSource.length }} records in total </strong>
      </ng-template>
    </kendo-multicolumncombobox>
  </kendo-formfield>
  <kendo-formfield class="col-3" *ngIf="control && IsSingleTenant()">
    <kendo-label [for]="environment">Environments</kendo-label>
    <kendo-dropdownlist
      #environment
      id="environment"
      [formControl]="environmentControl"
      [data]="environments"
      [defaultItem]="null"
      required
    >
    </kendo-dropdownlist>
  </kendo-formfield>
</div>
