import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Constants } from '../../shared/Constants';
import { ReleaseDefinition } from '../../deployments/models/ReleaseDefinition';
import { ReleaseRequest } from '../../deployments/models/ReleaseRequest';
import { AzRelease } from '../../deployments/models/AzRelease';
import { ReleaseUpdateEnvironmentRequest } from '../../deployments/models/ReleaseUpdateEnvironmentRequest';
import { ReleaseUpdateEnvironmentResponse } from '../../deployments/models/ReleaseUpdateEnvironmentResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Release } from '../../shared/models/Release';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReleaseService {

  constructor(
    private httpClient: HttpClient,
    private configService: AppConfigService,
    private authService: AuthService,
    private errorHandling: ErrorHandlerService) {
  }

  getReleaseDefinitions(project: string, paths: string[]): Observable<any> {

    const observables: Observable<any>[] =
      paths.map(p =>
        this.httpClient.get<any>(`${window.location.origin}/api/ado/releases/definitions/${project}?path=${p}&stateFilter=All&api-version=${Constants.API_VERSION}`)
      );

    return forkJoin(observables);
  }

  getReleaseDefinition(project: string, releaseDefId: number): Observable<ReleaseDefinition> {
    return this.httpClient
      .get<any>(`${window.location.origin}/api/ado/releases/${project}/definition/${releaseDefId}?api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getReleaseDefinition'))
      );
  }

  updateReleaseDefinition(project: string, releaseDefinition: ReleaseDefinition): Observable<ReleaseDefinition> {
    return this.httpClient
      .put<ReleaseDefinition>(`${window.location.origin}/api/ado/releases/${project}/definition?api-version=${Constants.API_VERSION_PREVIEW}`, releaseDefinition)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateReleaseDefinition'))
      );
  }

  createReleaseDefinition(project: string, releaseDefinition: ReleaseDefinition): Observable<ReleaseDefinition> {
    return this.httpClient
      .post<ReleaseDefinition>(
        `${window.location.origin}/api/ado/releases/definitions/${project}?api-version=${Constants.API_VERSION}`,
        releaseDefinition)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createReleaseDefinition'))
      );
  }

  createRelease(project: string, releaseRequest: ReleaseRequest): Observable<AzRelease> {
    return this.httpClient
      .post<AzRelease>(
        `${window.location.origin}/api/ado/releases/${project}?api-version=${Constants.API_VERSION}`, releaseRequest)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('createRelease'))
      );
  }

  updateReleaseEnvironment(
    project: string,
    releaseId: number,
    environmentId: number,
    request: ReleaseUpdateEnvironmentRequest): Observable<ReleaseUpdateEnvironmentResponse> {
    return this.httpClient
      .patch<ReleaseUpdateEnvironmentResponse>(
        `${window.location.origin}/api/ado/releases/${project}/release/${releaseId}/environment/${environmentId}?api-version=${Constants.API_VERSION_PREVIEW}.6`, request)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('updateReleaseEnvironment'))
      );
  }

  getReleaseLogs(project: string, release: Release): Observable<any> {
    const headers = new HttpHeaders();
    return this.httpClient.get<any>(
      `${window.location.origin}/api/ado/releases/logs/${release.projectName}/release/${release.id}?api-version=${Constants.API_VERSION_PREVIEW}`,
      {headers, responseType: 'blob' as 'json'}
    )
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getReleaseLogs'))
      );
  }

  getReleaseById(project: string, releaseId: number): Observable<AzRelease> {
    return this.httpClient
      .get<AzRelease>(`${window.location.origin}/api/ado/releases/${project}/release/${releaseId}?api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getReleaseById'))
      );
  }

  getReleaseTaskLog(project: string, releaseId: number, environmentId: number, releaseDeployPhaseId: number, taskId: number): Observable<AzRelease> {
    return this.httpClient
      .get<AzRelease>(`${window.location.origin}/api/ado/releases/taskLog/${project}/release/${releaseId}/environment/${environmentId}/releaseDeployPhase/${releaseDeployPhaseId}/tasks/${taskId}?api-version=${Constants.API_VERSION_PREVIEW}`)
      .pipe(
        catchError(this.errorHandling.handleAllErrors<any>('getReleaseTaskLog'))
      );
  }
}
