import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { AppInjector } from '../shared/utils/AppInjector';
import { Constants } from '../shared/Constants';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  protected toastr: ToastrService;
  constructor() {
    const injector = AppInjector.getInjector();
    this.toastr = injector.get(ToastrService);
    this.toastr.toastrConfig.timeOut = 0;
  }

  getErrorMessage(err: any) {
    if (err?.ErrorExceptionMessage) {
      return err?.ErrorExceptionMessage;
    } else if (err?.ErrorMessage) {
      return err?.ErrorMessage;
    } else if (err?.message) {
      return err?.message;
    } else if (err?.Message) {
      return err?.Message;
    }  else {
      return;
    }
  }

  getErrorInfo(err: any) {
    if (err?.error?.error) {
      return err?.error?.error;
    } else {
      return;
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      return next.handle(request)
          .pipe(
              catchError((error: HttpErrorResponse) => {
                const errorMessage = this.getErrorMessage(error);
                const errorInfo = this.getErrorInfo(error);
                if (error.error && error.error.message) {
                      this.toastr.error(error.error.message);
                      console.log(error);
                  }
                  else{
                    if(errorInfo == Constants.MFA_ERROR){
                      this.toastr.warning(`Warning: ${errorInfo}`);
                    }
                    else{
                      if(errorMessage && !errorMessage.includes(Constants.TIMEOUT_STATUSCODE)){
                        this.toastr.error(`Error: ${errorMessage}`);
                      }
                      if(errorInfo && !errorInfo.includes(Constants.TIMEOUT_STATUSCODE)){
                        this.toastr.error(`Error Message: ${errorInfo}`);
                      }
                    }
                  }
                  return throwError(error);
              })
          )
  }
}
