<div class="w-75 px-4">
    <ng-container [formGroup]="customerEnvironmentDetails">
        <kendo-formfield *ngIf="summaryDetails">
        <div class="d-grid gap-3">
            <div class="p-2 bg-light border">
              <span><b>Customer Id:</b> {{summaryDetails.customerId}}</span>
            </div>
        
            <div class="p-2 bg-light border">
                <span><b>Environment:</b> {{summaryDetails.environment}}</span>
        
            </div>

            <div class="p-2 bg-light border">
                <span><b>Region:</b> {{summaryDetails.region}}</span>
        
            </div>

            <div class="p-2 bg-light border">
                <span><b>Environment Instance:</b> {{summaryDetails.instance}}</span>
        
            </div>

        </div>
    </kendo-formfield>
    </ng-container>
</div>