import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/base.component';
import { process, State } from '@progress/kendo-data-query';
import { Environment } from '../../shared/models/devtestslab/environment';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { Constants } from '../../shared/Constants';

@Component({
  selector: 'app-dev-tests-lab-envs',
  templateUrl: './dev-tests-lab-envs.component.html',
  styleUrls: ['./dev-tests-lab-envs.component.scss']
})
export class DevTestsLabEnvsComponent extends BaseComponent implements OnInit {
  environments: Environment[];
  environmentsView: GridDataResult;

  public state: State = {
    skip: 0,
    take: 20,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadDevTestsLabEnvironments();
  }

  private loadDevTestsLabEnvironments() {
      this.loaderService.start();
      this.devTestsLabService.getEnvironments().subscribe({
        next: (result) => {
          this.environments = result;
          this.environmentsView = process(this.environments, this.state);
          this.loaderService.stop();
        },
        error: (error) => {
          console.error(error);
          this.loaderService.stop();
        },
        complete: () => {
          this.loaderService.stop();
        }
      });
    }

  dataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.environmentsView = process(this.environments, this.state);
  }

  goToEnvironment(env: Environment) {
    const  url = `https://portal.azure.com/#@CampusNexus.Dev/resource/subscriptions/${env.subscriptionId}/resourcegroups/${env.resourceGroup}/overview`;
    window.open(url, '_blank');
  }
}
