<kendo-dialog
  [minWidth]="350"
  [width]="550"
  [minHeight]="250"
  [height]="350">
  <kendo-dialog-titlebar>
    <div>
      <span class="k-icon k-i-form-element"></span> Environment
    </div>
  </kendo-dialog-titlebar>
  <div class="container">
    <form [formGroup]="formGroup">
      <div class="form-group">
        <label for="name">Name</label>
        <input
          type="text"
          class="form-control"
          id="name"
          [formControl]="formGroup.get('name')"
          required
        />
      </div>
      <div class="form-group">
        <label for="type">Type</label>
        <input
          type="text"
          class="form-control"
          id="type"
          [formControl]="formGroup.get('type')"
        />
      </div>
      <div class="form-group">
        <label for="platform">Platform</label>
        <input
          type="text"
          class="form-control"
          id="platform"
          [formControl]="formGroup.get('platform')"
        />
      </div>
    </form>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="onCancelAction()">Cancel</button>
    <button
      kendoButton
      (click)="onConfirmAction()"
      themeColor="primary"
      [disabled]="!formGroup.valid"
    >
      Submit
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
