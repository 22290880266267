<div class="container-fluid g-0">
  <div class="row g-0">
    <kendo-grid
      class="col-5 g-0"
      [data]="batchesView"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="true"
      [filterable]="true"
      (dataStateChange)="dataStateChange($event)"
      (selectionChange)="releaseSelectionChanged($event)"
      kendoGridSelectBy="id"
      [selectable]="true"
      [style.height.%]="100"
    >
      <kendo-grid-column
        field="name"
        title="Name"
        [width]="80"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="projectName"
        title="Project"
        [width]="50"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="createdBy"
        title="Created By"
        [width]="80"
        [minResizableWidth]="50"
      ></kendo-grid-column>
      <kendo-grid-column
        field="createdDate"
        title="Date"
        [width]="100"
        [minResizableWidth]="50"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.createdDate | date: 'yyyy/MM/dd HH:mm:ss'}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <div class="col-7 g-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
