import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Subscription } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State,process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-enable-features',
  templateUrl: './enable-features.component.html',
  styleUrls: ['./enable-features.component.scss']
})
export class EnableFeaturesComponent extends BaseComponent implements OnInit {
  private sub: Subscription;

  featureGridData: GridDataResult;
  features:any;
  private editedRowIndex: number;
  editMode = false;

  public state: State = {
    skip: 0,
    take: 10,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public featuresToEdit: { page: string,
    feature: string,
    enable:boolean} ={
      page : "",
      feature : "",
      enable:null
    };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.loaderService.start();
    this.sub = this.adminService.getallFeatureFlags()
    .subscribe({
      next: (result) => {
        result.forEach(element => {
          element.enable = element.enable ?? false
        });
        this.features = result;
        this.featureGridData = process(this.features, this.state);
        this.loaderService.stop();
      },
      error: (error) => {
        console.error(error);
        this.loaderService.stop();
      },
      complete: () => {
        this.loaderService.stop();
      }
    });
  }

  edit(data,rowIndex){
    this.editMode = true;
    this.editedRowIndex = rowIndex;
  }

  cancel(data){
    this.editMode = false;
    this.editedRowIndex = undefined;
  }

  saveFeatureDetails(dataItem){
  this.editMode = false;
  this.loaderService.start();
  this.sub = this.adminService.editFeatureFlags(dataItem)
  .subscribe({
    next: (result) => {
      result.forEach(element => {
        element.enable = element.enable ?? false
      });
      this.features = result;
      this.featureGridData = process(this.features, this.state);
      this.loaderService.stop();
    },
    error: (error) => {
      console.error(error);
      this.loaderService.stop();
    },
    complete: () => {
      this.loaderService.stop();
    }
  });
  }

  updateFeaturesEdit(): any {
    return this.featuresToEdit;
  }
}
