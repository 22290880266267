<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<kendo-tilelayout
[resizable]="true"
[reorderable]="true"
>
<kendo-tilelayout-item title="Database Mapping" [col]="1" [colSpan]="3">
  <kendo-tilelayout-item-body>
    <div class="container-fluid example-wrapper pb-3">
        <div class="row pb-3">
          <div class="col-xs-12 col-sm-4 example-col">
            <div class="fs-6 fw-bold pt-3 pb-3">Mapping Tables:</div>
            <kendo-dropdownlist
              [data]="dataTables"
              [value]="selectedTable"
              [defaultItem]="defaultTables"
              textField="tableName"
              valueField="tableId"
              (valueChange)="handleTableChange($event)"
            >
            </kendo-dropdownlist>
          </div>
          <div class="col-xs-12 col-sm-4 example-col" *ngIf="!hideIdentifier">
            <div class="fs-6 fw-bold pt-3 pb-3">Identifier:</div>
            <kendo-dropdownlist
              [hidden]="hideIdentifier"
              [defaultItem]="defaultIdentifier"
              [data]="dataResultIdentifier"
              [value]="selectedIdentifier"
              textField="identifierName"
              valueField="identifierId"
              (valueChange)="handleIdentifierChange($event)"
            >
            </kendo-dropdownlist>
          </div>
        </div>

        <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="hideSelection && (!hidePipelineGrid || !hideNameGrid || !hideEnvGrid || !hideOnboardGrid || !hideTenantPipelineGrid || !hideFinanceIntegrationGrid || !hideMailboxManagerGrid)">
          <kendo-tilelayout-item-body>
            <button kendoButton (click)="hideShowSelection()" primary="true" class ="w-100 h-100" icon="arrow">Show Input Fields
              <kendo-icon name="arrow-chevron-right"></kendo-icon>
            </button>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
        
        <kendo-tilelayout-item [col]="7" [colSpan]="4" *ngIf="!hideSelection && (!hidePipelineGrid || !hideNameGrid || !hideEnvGrid || !hideOnboardGrid || !hideTenantPipelineGrid || !hideFinanceIntegrationGrid || !hideMailboxManagerGrid)">
          <kendo-tilelayout-item-body>
            <button kendoButton (click)="hideShowSelection()" primary="true" class ="w-100 h-100" icon="arrow">Hide Input Fields
              <kendo-icon name="arrow-chevron-down"></kendo-icon>
            </button>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>

        <kendo-tilelayout-item title="New Mapping" [col]="1" [colSpan]="3" *ngIf="!hideSelection && (!hidePipelineGrid || !hideNameGrid || !hideEnvGrid || !hideOnboardGrid || !hideTenantPipelineGrid || !hideFinanceIntegrationGrid || !hideMailboxManagerGrid)">
          <kendo-tilelayout-item-body class="pt-3">
        <div class = "row pb-3" *ngIf="!hidePipelineGrid">
            <div class = "row pb-2">
                <div class="col-4">
                    <kendo-label text="Pipeline Id" class="px-2"></kendo-label>
                    <kendo-numerictextbox
                    placeholder="Enter Pipeline Id"
                    format="string"
                    [(ngModel)]="pipelineToAdd.pipelineId"
                    [spinners]="false">
                  </kendo-numerictextbox>
                </div>

              <div class="col-5">
                <kendo-label text="Pipeline Name" class="px-2"></kendo-label>
                    <kendo-textbox
                      placeholder="Enter Pipeline Name"
                      [(ngModel)]="pipelineToAdd.pipelineName">
                   </kendo-textbox>
              </div>

              <div class="col-3">
                <kendo-label text="Requires Tenant Info" class="px-1"></kendo-label>
                <input type="checkbox" kendoCheckBox [(ngModel)]="pipelineToAdd.requiresTenantInfo"/>
             </div>
            </div>

            <div class = "row pb-2">
              <div class="col-4">
                  <kendo-label text="Queue Number" class="px-2"></kendo-label>
                  <kendo-numerictextbox
                  placeholder="Enter Queue Number"
                  format="string"
                  [(ngModel)]="pipelineToAdd.queueNumber"
                  [spinners]="false">
                </kendo-numerictextbox>
              </div>

              <div class="col-5">
                <kendo-label text="Tenant Flag Key" class="px-2"></kendo-label>
                    <kendo-textbox
                      placeholder="Enter Tenant Flag"
                      [(ngModel)]="pipelineToAdd.templateFlagToCheck">
                   </kendo-textbox>
            </div>

            <div class="col-3">
              <kendo-label text="Enable" class="px-1"></kendo-label>
              <input type="checkbox" kendoCheckBox [(ngModel)]="pipelineToAdd.enable"/>
           </div>
          </div>

            <div class = "row">
                <div class="col-12">
                    <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addPipelineMappingToProduct()">Add Row</button>
                </div>
            </div>


        </div>

        <div class = "row pb-3" *ngIf="!hideNameGrid">
            <div class = "row pb-2">
                <div class="col-4">
                    <kendo-label text="Short Name" class="px-2"></kendo-label>
                    <kendo-textbox
                    placeholder="Enter Short Name"
                    [(ngModel)]="nameMappingToAdd.shortName">
                  </kendo-textbox>
                </div>

              <div class="col-4">
                <kendo-label text="Full Name" class="px-2"></kendo-label>
                    <kendo-textbox
                      placeholder="Enter Full Name"
                      [(ngModel)]="nameMappingToAdd.fullName">
                   </kendo-textbox>
              </div>
            </div>

            <div class = "row">
                <div class="col-12">
                    <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addNameMappingToTable()">Add Row</button>
                </div>
            </div>


        </div>

        <div class = "row pb-3" *ngIf="!hideEnvGrid">
          <div class = "row pb-2">
              <div class="col-4">
                  <kendo-label text="Series" class="px-2"></kendo-label>
                  <kendo-textbox
                  placeholder="Enter Series"
                  [(ngModel)]="envSeriesToAdd.series">
                </kendo-textbox>
              </div>

            <div class="col-4">
              <kendo-label text="Env Pattern" class="px-2"></kendo-label>
                  <kendo-textbox
                    placeholder="Enter Env Pattern"
                    [(ngModel)]="envSeriesToAdd.envPattern">
                 </kendo-textbox>
            </div>

            <div class="col-4">
              <kendo-label text="Env Type" class="px-2"></kendo-label>
                  <kendo-textbox
                    placeholder="Enter Env Type"
                    [(ngModel)]="envSeriesToAdd.envType">
                 </kendo-textbox>
            </div>
          </div>

          <div class = "row">
              <div class="col-12">
                  <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addEnvSeriesMappingToTable()">Add Row</button>
              </div>
          </div>


      </div>

      <div class = "row pb-3" *ngIf="!hideOnboardGrid">
        <div class = "row pb-2">
            <div class="col-4">
                <kendo-label text="Pipeline Id" class="px-2"></kendo-label>
                <kendo-numerictextbox
                placeholder="Enter Pipeline Id"
                format="string"
                [(ngModel)]="onBoardMappingToAdd.pipelineId"
                [spinners]="false">
              </kendo-numerictextbox>
            </div>

          <div class="col-4">
            <kendo-label text="Pipeline Name" class="px-2"></kendo-label>
                <kendo-textbox
                  placeholder="Enter Pipeline Name"
                  [(ngModel)]="onBoardMappingToAdd.pipelineName">
               </kendo-textbox>
          </div>

          <div class="col-4">
            <kendo-label text="Pipeline Type" class="px-2"></kendo-label>
                <kendo-textbox
                  placeholder="Enter Pipeline Type"
                  [(ngModel)]="onBoardMappingToAdd.pipelineType">
               </kendo-textbox>
          </div>
        </div>
        <div class = "row pb-2">
        <div class="col-6">
          <kendo-label text="Request Template" class="px-2"></kendo-label>
              <kendo-textarea
                resizable="auto"
                [(ngModel)]="onBoardMappingToAdd.requestTemplate">
             </kendo-textarea>
        </div>
          <div class="col-3">
              <kendo-label text="Queue Number" class="px-2"></kendo-label>
              <kendo-numerictextbox
              placeholder="Enter Queue Number"
              format="string"
              [(ngModel)]="onBoardMappingToAdd.queueNumber"
              [spinners]="false">
            </kendo-numerictextbox>
          </div>
          <div class="col-3">
            Offering Type
            <div class="col-2" *ngIf="showOnboardingCheckBox()">
              <div class="k-checkbox-wrap">
              <kendo-label
              class="k-checkbox-label"
              [for]="standard"
              text="Standard/Premium"
            ></kendo-label>
            <input type="checkbox" #standard kendoCheckBox [(ngModel)]="offering.standard"/>
          </div>
            <div class="k-checkbox-wrap">
              <kendo-label
              class="k-checkbox-label"
              [for]="plus"
              text="Premium Plus"
            ></kendo-label>
            <input type="checkbox" #plus kendoCheckBox [(ngModel)]="offering.plus"/>
            </div>
  
            <div class="k-checkbox-wrap">
              <kendo-label
              class="k-checkbox-label"
              [for]="enterprise"
              text="Enterprise"
            ></kendo-label>
            <input type="checkbox" #enterprise kendoCheckBox [(ngModel)]="offering.enterprise"/>
            </div>

            <div class="k-checkbox-wrap">
              <kendo-label
              class="k-checkbox-label"
              [for]="newEnvironment"
              text="New Environment"
            ></kendo-label>
            <input type="checkbox" #newEnvironment kendoCheckBox [(ngModel)]="offering.newEnvironment"/>
            </div>
            </div>
          </div>
      </div>
      <div class = "row pb-2">
        <div class ="pb-2 float-end">
          <button kendoButton class= "col-3 float-end" themeColor="primary" (click)="addOnboardingPipelineToOfferingType()">Add Row</button>
        </div>
       
      </div>



    </div>
    <div class = "row pb-3" *ngIf="!hideTenantPipelineGrid">
      <div class = "row pb-2">
          <div class="col-6">
              <kendo-label text="Pipeline Id" class="px-2"></kendo-label>
              <kendo-numerictextbox
              placeholder="Enter Pipeline Id"
              format="string"
              [(ngModel)]="tenantPipelineMappingToAdd.pipelineId"
              [spinners]="false">
            </kendo-numerictextbox>
          </div>

        <div class="col-6">
          <kendo-label text="Pipeline Name" class="px-2"></kendo-label>
              <kendo-textbox
                placeholder="Enter Pipeline Name"
                [(ngModel)]="tenantPipelineMappingToAdd.pipelineName">
             </kendo-textbox>
        </div>
      </div>

      <div class = "row pb-2">
        <div class="col-6">
          <kendo-label text="Request Template" class="px-2"></kendo-label>
              <kendo-textarea
                resizable="auto"
                [(ngModel)]="tenantPipelineMappingToAdd.requestTemplate">
             </kendo-textarea>
        </div>
        <div class="col-4">
          <kendo-label text="Run Pipeline For Each Tenant" class="px-1"></kendo-label>
          <input type="checkbox" kendoCheckBox [(ngModel)]="tenantPipelineMappingToAdd.runPipelineForEachTenant"/>
       </div>
    </div>

      <div class = "row">
          <div class="col-12">
              <button kendoButton class= "float-end col-3" themeColor="primary" (click)="addTenantPipelineMapping()">Add Row</button>
          </div>
      </div>


  </div>
  <div class = "row pb-3" *ngIf="!hideFinanceIntegrationGrid">
    <div class = "row pb-2">
        <div class="col-4">
            <kendo-label text="Pipeline Id" class="px-2"></kendo-label>
            <kendo-numerictextbox
            placeholder="Enter Pipeline Id"
            format="string"
            [(ngModel)]="financeIntegrationToAdd.pipelineId"
            [spinners]="false">
          </kendo-numerictextbox>
        </div>

      <div class="col-4">
        <kendo-label text="Pipeline Name" class="px-2"></kendo-label>
            <kendo-textbox
              placeholder="Enter Pipeline Name"
              [(ngModel)]="financeIntegrationToAdd.pipelineName">
           </kendo-textbox>
      </div>

      <div class="col-4">
        <kendo-label text="Pipeline Type" class="px-2"></kendo-label>
            <kendo-textbox
              placeholder="Enter Pipeline Type"
              [(ngModel)]="financeIntegrationToAdd.pipelineType">
           </kendo-textbox>
      </div>
    </div>
    <div class = "row pb-2">
    <div class="col-6">
      <kendo-label text="Request Template" class="px-2"></kendo-label>
          <kendo-textarea
            resizable="auto"
            [(ngModel)]="financeIntegrationToAdd.requestTemplate">
         </kendo-textarea>
    </div>
      <div class="col-3">
          <kendo-label text="Queue Number" class="px-2"></kendo-label>
          <kendo-numerictextbox
          placeholder="Enter Queue Number"
          format="string"
          [(ngModel)]="financeIntegrationToAdd.queueNumber"
          [spinners]="false">
        </kendo-numerictextbox>
      </div>

  </div>
  <div class = "row pb-2">
    <div class ="pb-2 float-end">
      <button kendoButton class= "col-3 float-end" themeColor="primary" (click)="addFinanceIntegrationPipeline()">Add Row</button>
    </div>
   
  </div>



</div>
<div class = "row pb-3" *ngIf="!hideMailboxManagerGrid">
  <div class = "row pb-2">
      <div class="col-4">
          <kendo-label text="Pipeline Id" class="px-2"></kendo-label>
          <kendo-numerictextbox
          placeholder="Enter Pipeline Id"
          format="string"
          [(ngModel)]="mailboxManagerToAdd.pipelineId"
          [spinners]="false">
        </kendo-numerictextbox>
      </div>

    <div class="col-4">
      <kendo-label text="Pipeline Name" class="px-2"></kendo-label>
          <kendo-textbox
            placeholder="Enter Pipeline Name"
            [(ngModel)]="mailboxManagerToAdd.pipelineName">
         </kendo-textbox>
    </div>

    <div class="col-4">
      <kendo-label text="Pipeline Type" class="px-2"></kendo-label>
          <kendo-textbox
            placeholder="Enter Pipeline Type"
            [(ngModel)]="mailboxManagerToAdd.pipelineType">
         </kendo-textbox>
    </div>
  </div>
  <div class = "row pb-2">
  <div class="col-6">
    <kendo-label text="Request Template" class="px-2"></kendo-label>
        <kendo-textarea
          resizable="auto"
          [(ngModel)]="mailboxManagerToAdd.requestTemplate">
       </kendo-textarea>
  </div>
    <div class="col-3">
        <kendo-label text="Queue Number" class="px-2"></kendo-label>
        <kendo-numerictextbox
        placeholder="Enter Queue Number"
        format="string"
        [(ngModel)]="mailboxManagerToAdd.queueNumber"
        [spinners]="false">
      </kendo-numerictextbox>
    </div>

    <div class="col-3">
      <kendo-label text="Run If Mailbox Exists" class="px-1"></kendo-label>
      <input type="checkbox" kendoCheckBox [(ngModel)]="mailboxManagerToAdd.runIfMailboxExistsInCustSub"/>
   </div>

</div>
<div class = "row pb-2">
  <div class ="pb-2 float-end">
    <button kendoButton class= "col-3 float-end" themeColor="primary" (click)="addMailboxManagerPipeline()">Add Row</button>
  </div>
 
</div>



</div>

      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <div class="pb-3" >

    </div>

    <kendo-tilelayout-item title="Existing Mapping" [col]="1" [colSpan]="3" *ngIf="!hidePipelineGrid || !hideNameGrid || !hideEnvGrid || !hideOnboardGrid || !hideTenantPipelineGrid || !hideFinanceIntegrationGrid || !hideMailboxManagerGrid">
      <kendo-tilelayout-item-body>
        <div class ="row" *ngIf="!hidePipelineGrid"
        kendoTooltip
        showOn="none"
        [tooltipTemplate]="template"
        filter=".k-grid td"
        (mouseover)="showTooltip($event)"
        >
          <form novalidate #myForm="ngForm">
            <kendo-grid [kendoGridTemplateEditing]="createPipelineEdit" [kendoGridBinding]="gridPipelineData" (cellClick)="cellClickHandler($event)">
              <kendo-grid-column field="enable" title="Enabled">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.enable"
                    kendoGridFocusable
                    name="Enabled"
                    type="checkbox"
                  />
                </ng-template>  
              </kendo-grid-column>
              <kendo-grid-column field="pipelineId" title="PipelineId"> 
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.pipelineId"
                    kendoGridFocusable
                    name="PipelineId"
                    class="k-textbox k-input k-rounded-md"
                    required
                  />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="pipelineName" title="PipelineName"> 
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.pipelineName"
                    kendoGridFocusable
                    name="PipelineName"
                    class="k-textbox k-input k-rounded-md"
                    required
                  />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="productName" title="ProductName">
              </kendo-grid-column>
              <kendo-grid-column field="queueNumber" title="QueueNumber"> 
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.queueNumber"
                    kendoGridFocusable
                    name="QueueNumber"
                    class="k-textbox k-input k-rounded-md"
                    
                  />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="templateFlagToCheck" title="TenantFlag"> 
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.templateFlagToCheck"
                    kendoGridFocusable
                    name="TemplateFlag"
                    class="k-textbox k-input k-rounded-md"
                  />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="requiresTenantInfo" title="RequiresTenantInfo">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.requiresTenantInfo"
                    kendoGridFocusable
                    name="RequiresTenantInfo"
                    type="checkbox"
                  />
                </ng-template>  
              </kendo-grid-column>
            <kendo-grid-command-column title="command" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                  Edit
                </button>
                <button
                  kendoGridSaveCommand
                  type="button"
                  [disabled]="myForm.invalid"
                  (click)="savePipelineMapping(dataItem)"
                >
                  {{ isNew ? "Add" : "Update" }}
                </button>
                <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                  {{ isNew ? "Discard changes" : "Cancel" }}
                </button>
                <button kendoButton type="button" *ngIf="!editMode" (click)="deletePipelineMappingDialogOpen(dataItem)">Remove</button>
                <kendo-dialog
                title="Please confirm"
                *ngIf="pipelineMappingDialogOpened"
                (close)="close()"
                [minWidth]="250"
                [width]="450"
              >
                <p style="margin: 30px; text-align: center;">
                  Are you sure you want to delete pipeline "<b>{{this.pipelineMappingToBeDeleted.pipelineName}}</b>" with id "<b>{{this.pipelineMappingToBeDeleted.pipelineId}}</b>" mapped to product "<b>{{this.pipelineMappingToBeDeleted.productName}}</b>""?
                </p>
                <kendo-dialog-actions>
                  <button kendoButton (click)="close()">No</button>
                  <button kendoButton (click)="deletePipelineMappedToProduct(this.pipelineMappingToBeDeleted)" primary="true">
                    Yes
                  </button>
                </kendo-dialog-actions>
              </kendo-dialog>
              </ng-template>
            </kendo-grid-command-column>
            </kendo-grid>
          </form>
        </div>
        <div class ="row" *ngIf="!hideNameGrid"
        kendoTooltip
        showOn="none"
        [tooltipTemplate]="template"
        filter=".k-grid td"
        (mouseover)="showTooltip($event)">
          <form novalidate #myForm="ngForm">
            <kendo-grid [kendoGridTemplateEditing]="createNewNameMappingEdit" [kendoGridBinding]="gridNameData" (cellClick)="cellClickHandler($event)">
              <kendo-grid-column field="shortName" title="ShortName"> 
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.shortName"
                    kendoGridFocusable
                    name="ShortName"
                    class="k-textbox k-input k-rounded-md"
                    required
                  />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fullName" title="FullName"> 
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.fullName"
                    kendoGridFocusable
                    name="FullName"
                    class="k-textbox k-input k-rounded-md"
                    required
                  />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="type" title="Type">
              </kendo-grid-column>
            <kendo-grid-command-column title="command" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                  Edit
                </button>
                <button
                  kendoGridSaveCommand
                  type="button"
                  [disabled]="myForm.invalid"
                  (click)="saveNameMapping(dataItem)"
                >
                  {{ isNew ? "Add" : "Update" }}
                </button>
                <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                  {{ isNew ? "Discard changes" : "Cancel" }}
                </button>
                <button kendoButton type="button" *ngIf="!editMode" (click)="deleteMappingDialogOpen(dataItem)">Remove</button>
                <kendo-dialog
                title="Please confirm"
                *ngIf="nameMappingDialogOpened"
                (close)="close()"
                [minWidth]="250"
                [width]="450"
              >
                <p style="margin: 30px; text-align: center;">
                  Are you sure you want to delete short name "<b>{{this.nameMappingToBeDeleted.shortName}}</b>" mapping to full name "<b>{{this.nameMappingToBeDeleted.fullName}}</b>" of type "<b>{{this.nameMappingToBeDeleted.type}}</b>"?
                </p>
                <kendo-dialog-actions>
                  <button kendoButton (click)="close()">No</button>
                  <button kendoButton (click)="deleteMappingFromTable(this.nameMappingToBeDeleted)" primary="true">
                    Yes
                  </button>
                </kendo-dialog-actions>
              </kendo-dialog>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
          </form>
        </div>
        <div class ="row" *ngIf="!hideEnvGrid"
        kendoTooltip
        showOn="none"
        [tooltipTemplate]="template"
        filter=".k-grid td"
        (mouseover)="showTooltip($event)">
          <form novalidate #myForm="ngForm">
            <kendo-grid [kendoGridTemplateEditing]="createNewEnvSeriesMappingEdit" [kendoGridBinding]="gridEnvSeriesData" (cellClick)="cellClickHandler($event)">
              <kendo-grid-column field="series" title="Series"> 
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.series"
                    kendoGridFocusable
                    name="Series"
                    class="k-textbox k-input k-rounded-md"
                    required
                  />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="environmentPattern" title="EnvPattern">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.environmentPattern"
                    kendoGridFocusable
                    name="EnvPattern"
                    class="k-textbox k-input k-rounded-md"
                    required
                  />
                </ng-template> 
              </kendo-grid-column>
              <kendo-grid-column field="environmentType" title="EnvType">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <input
                    [(ngModel)]="dataItem.environmentType"
                    kendoGridFocusable
                    name="EnvType"
                    class="k-textbox k-input k-rounded-md"
                    required
                  />
                </ng-template> 
              </kendo-grid-column>
            <kendo-grid-command-column title="command" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
                <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                  Edit
                </button>
                <button
                  kendoGridSaveCommand
                  type="button"
                  [disabled]="myForm.invalid"
                  (click)="saveEnvSeries(dataItem)"
                >
                  {{ isNew ? "Add" : "Update" }}
                </button>
                <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                  {{ isNew ? "Discard changes" : "Cancel" }}
                </button>
                <button kendoButton type="button" *ngIf="!editMode" (click)="deleteEnvSeriesMappingDialogOpen(dataItem)">Remove</button>
                <kendo-dialog
                title="Please confirm"
                *ngIf="envSeriesMappingDialogOpened"
                (close)="close()"
                [minWidth]="250"
                [width]="450"
              >
                <p style="margin: 30px; text-align: center;">
                  Are you sure you want to delete series "<b>{{this.envSeriesMappingToBeDeleted.series}}</b>" mapping to pattern "<b>{{this.envSeriesMappingToBeDeleted.environmentPattern}}</b>" of type "<b>{{this.envSeriesMappingToBeDeleted.environmentType}}</b>"?
                </p>
                <kendo-dialog-actions>
                  <button kendoButton (click)="close()">No</button>
                  <button kendoButton (click)="deleteSeriesMappedToEnvironment(this.envSeriesMappingToBeDeleted)" primary="true">
                    Yes
                  </button>
                </kendo-dialog-actions>
              </kendo-dialog>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
          </form>
      </div>
      <div class ="row" *ngIf="!hideOnboardGrid"
      kendoTooltip
      showOn="none"
      [tooltipTemplate]="template"
      filter=".k-grid td"
      (mouseover)="showTooltip($event)">
        <form novalidate #myForm="ngForm">
          <kendo-grid [kendoGridTemplateEditing]="createNewOnBoardMapping" [kendoGridBinding]="gridOnboardData" (cellClick)="cellClickHandler($event)">
            <kendo-grid-column field="pipelineId" title="PipelineId"> 
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <input
                  [(ngModel)]="dataItem.pipelineId"
                  kendoGridFocusable
                  name="PipelineId"
                  class="k-textbox k-input k-rounded-md"
                  required
                />
              </ng-template>
            </kendo-grid-column>
            <!-- <kendo-grid-column field="pipelineName" title="PipelineName"> </kendo-grid-column> -->
            <kendo-grid-column field="pipelineName" title="PipelineName">
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <input
                  [(ngModel)]="dataItem.pipelineName"
                  kendoGridFocusable
                  name="PipelineName"
                  class="k-textbox k-input k-rounded-md"
                  required
                />
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="pipelineType" title="PipelineType">
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <input
                  [(ngModel)]="dataItem.pipelineType"
                  kendoGridFocusable
                  name="PipelineType"
                  class="k-textbox k-input k-rounded-md"
                  required
                />
              </ng-template>
            </kendo-grid-column>
            <!-- <kendo-grid-column field="requestTemplate" title="RequestTemplate">
            </kendo-grid-column> -->
            <kendo-grid-column field="queueNumber" title="QueueNumber">
              <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <input
                  [(ngModel)]="dataItem.queueNumber"
                  kendoGridFocusable
                  name="QueueNumber"
                  class="k-textbox k-input k-rounded-md"
                  required
                />
              </ng-template>
            </kendo-grid-column>
          <kendo-grid-command-column title="command" [width]="220">
            <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
              <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
                Edit
              </button>
              <button
                kendoGridSaveCommand
                type="button"
                [disabled]="myForm.invalid"
                (click)="saveOnboardingPipeline(dataItem)"
              >
                {{ isNew ? "Add" : "Update" }}
              </button>
              <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
                {{ isNew ? "Discard changes" : "Cancel" }}
              </button>
              <button kendoButton type="button" *ngIf="!editMode" (click)="deleteOnboardingMappingDialogOpen(dataItem)">Remove</button>
              <kendo-dialog
              title="Please confirm"
              *ngIf="onBoardMappingDialogOpened"
              (close)="close()"
              [minWidth]="250"
              [width]="450"
            >
              <p style="margin: 30px; text-align: center;">
                Are you sure you want to delete pipeline "<b>{{this.onBoardMappingToBeDeleted.pipelineName}}</b>" with id "<b>{{this.onBoardMappingToBeDeleted.pipelineId}}</b>""?
              </p>
              <kendo-dialog-actions>
                <button kendoButton (click)="close()">No</button>
                <button kendoButton (click)="deleteOnboardMappingToOfferingType(this.onBoardMappingToBeDeleted)" primary="true">
                  Yes
                </button>
              </kendo-dialog-actions>
            </kendo-dialog>
            </ng-template>
          </kendo-grid-command-column>
          <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
            <ng-container *ngIf="rowIndex !== editedRowIndex" >
              <p><strong>Request Template:</strong></p>
              <pre>{{ dataItem.requestTemplate }}</pre>
           </ng-container>
       <textarea class="form-control" 
       *ngIf="rowIndex === editedRowIndex"
          name="message"
          rows="8"
          [(ngModel)]="dataItem.requestTemplate"
          #message='ngModel'
          ></textarea>
          </ng-template>
          </kendo-grid>
        </form>
    </div>
    <div class ="row" *ngIf="!hideTenantPipelineGrid"
    kendoTooltip
    showOn="none"
    [tooltipTemplate]="template"
    filter=".k-grid td"
    (mouseover)="showTooltip($event)">
      <form novalidate #myForm="ngForm">
        <kendo-grid [kendoGridTemplateEditing]="createTenantPipelineEdit" [kendoGridBinding]="gridTenantPipelineData" (cellClick)="cellClickHandler($event)">
          <kendo-grid-column field="pipelineId" title="PipelineId"> 
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <input
                [(ngModel)]="dataItem.pipelineId"
                kendoGridFocusable
                name="PipelineId"
                class="k-textbox k-input k-rounded-md"
                required
              />
            </ng-template>
          </kendo-grid-column>
          <!-- <kendo-grid-column field="pipelineName" title="PipelineName"> </kendo-grid-column> -->
          <kendo-grid-column field="pipelineName" title="PipelineName">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <input
                [(ngModel)]="dataItem.pipelineName"
                kendoGridFocusable
                name="PipelineName"
                class="k-textbox k-input k-rounded-md"
                required
              />
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="runPipelineForEachTenant" title="RunPipelineForEachTenant">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <input
                [(ngModel)]="dataItem.runPipelineForEachTenant"
                kendoGridFocusable
                name="RunPipelineForEachTenant"
                type="checkbox"
              />
            </ng-template>  
          </kendo-grid-column>
        <kendo-grid-command-column title="command" [width]="220">
          <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
            <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
              Edit
            </button>
            <button
              kendoGridSaveCommand
              type="button"
              [disabled]="myForm.invalid"
              (click)="saveTenantPipelineMapping(dataItem)"
            >
              {{ isNew ? "Add" : "Update" }}
            </button>
            <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
              {{ isNew ? "Discard changes" : "Cancel" }}
            </button>
            <button kendoButton type="button" *ngIf="!editMode" (click)="deleteTenantPipelineMappingDialogOpen(dataItem)">Remove</button>
            <kendo-dialog
            title="Please confirm"
            *ngIf="tenantPipelineMappingDialogOpened"
            (close)="close()"
            [minWidth]="250"
            [width]="450"
          >
            <p style="margin: 30px; text-align: center;">
              Are you sure you want to delete pipeline "<b>{{this.tenantPipelineToBeDeleted.pipelineName}}</b>" with id "<b>{{this.tenantPipelineToBeDeleted.pipelineId}}</b>""?
            </p>
            <kendo-dialog-actions>
              <button kendoButton (click)="close()">No</button>
              <button kendoButton (click)="deleteTenantPipelineMapping(this.tenantPipelineToBeDeleted)" primary="true">
                Yes
              </button>
            </kendo-dialog-actions>
          </kendo-dialog>
          </ng-template>
        </kendo-grid-command-column>
        <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
          <ng-container *ngIf="rowIndex !== editedRowIndex" >
            <p><strong>Request Template:</strong></p>
            <pre>{{ dataItem.requestTemplate }}</pre>
         </ng-container>
     <textarea class="form-control" 
     *ngIf="rowIndex === editedRowIndex"
        name="message"
        rows="8"
        [(ngModel)]="dataItem.requestTemplate"
        #message='ngModel'
        ></textarea>
        </ng-template>
        </kendo-grid>
      </form>
  </div>
  <div class ="row" *ngIf="!hideFinanceIntegrationGrid"
  kendoTooltip
  showOn="none"
  [tooltipTemplate]="template"
  filter=".k-grid td"
  (mouseover)="showTooltip($event)">
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridTemplateEditing]="createFinanceIntegrationEdit" [kendoGridBinding]="gridFinanceIntegrationData" (cellClick)="cellClickHandler($event)">
        <kendo-grid-column field="pipelineId" title="PipelineId"> 
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <input
              [(ngModel)]="dataItem.pipelineId"
              kendoGridFocusable
              name="PipelineId"
              class="k-textbox k-input k-rounded-md"
              required
            />
          </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column field="pipelineName" title="PipelineName"> </kendo-grid-column> -->
        <kendo-grid-column field="pipelineName" title="PipelineName">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <input
              [(ngModel)]="dataItem.pipelineName"
              kendoGridFocusable
              name="PipelineName"
              class="k-textbox k-input k-rounded-md"
              required
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="pipelineType" title="PipelineType">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <input
              [(ngModel)]="dataItem.pipelineType"
              kendoGridFocusable
              name="PipelineType"
              class="k-textbox k-input k-rounded-md"
              required
            />
          </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column field="requestTemplate" title="RequestTemplate">
        </kendo-grid-column> -->
        <kendo-grid-column field="queueNumber" title="QueueNumber">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <input
              [(ngModel)]="dataItem.queueNumber"
              kendoGridFocusable
              name="QueueNumber"
              class="k-textbox k-input k-rounded-md"
              required
            />
          </ng-template>
        </kendo-grid-column>
      <kendo-grid-command-column title="command" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
          <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
            Edit
          </button>
          <button
            kendoGridSaveCommand
            type="button"
            [disabled]="myForm.invalid"
            (click)="saveFinanceIntegrationMapping(dataItem)"
          >
            {{ isNew ? "Add" : "Update" }}
          </button>
          <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
            {{ isNew ? "Discard changes" : "Cancel" }}
          </button>
          <button kendoButton type="button" *ngIf="!editMode" (click)="deleteFinanceIntegrationMappingDialogOpen(dataItem)">Remove</button>
          <kendo-dialog
          title="Please confirm"
          *ngIf="financeIntegrationMappingDialogOpened"
          (close)="close()"
          [minWidth]="250"
          [width]="450"
        >
          <p style="margin: 30px; text-align: center;">
            Are you sure you want to delete pipeline "<b>{{this.financeIntegrationToBeDeleted.pipelineName}}</b>" with id "<b>{{this.financeIntegrationToBeDeleted.pipelineId}}</b>""?
          </p>
          <kendo-dialog-actions>
            <button kendoButton (click)="close()">No</button>
            <button kendoButton (click)="deleteFinanceIntegrationMapping(this.financeIntegrationToBeDeleted)" primary="true">
              Yes
            </button>
          </kendo-dialog-actions>
        </kendo-dialog>
        </ng-template>
      </kendo-grid-command-column>
      <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="rowIndex !== editedRowIndex" >
          <p><strong>Request Template:</strong></p>
          <pre>{{ dataItem.requestTemplate }}</pre>
       </ng-container>
   <textarea class="form-control" 
   *ngIf="rowIndex === editedRowIndex"
      name="message"
      rows="8"
      [(ngModel)]="dataItem.requestTemplate"
      #message='ngModel'
      ></textarea>
      </ng-template>
      </kendo-grid>
    </form>
</div>
<div class ="row" *ngIf="!hideMailboxManagerGrid"
kendoTooltip
showOn="none"
[tooltipTemplate]="template"
filter=".k-grid td"
(mouseover)="showTooltip($event)">
  <form novalidate #myForm="ngForm">
    <kendo-grid [kendoGridTemplateEditing]="createMailboxManagerEdit" [kendoGridBinding]="gridMailboxManagerData" (cellClick)="cellClickHandler($event)">
      <kendo-grid-column field="pipelineId" title="PipelineId"> 
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.pipelineId"
            kendoGridFocusable
            name="PipelineId"
            class="k-textbox k-input k-rounded-md"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <!-- <kendo-grid-column field="pipelineName" title="PipelineName"> </kendo-grid-column> -->
      <kendo-grid-column field="pipelineName" title="PipelineName">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.pipelineName"
            kendoGridFocusable
            name="PipelineName"
            class="k-textbox k-input k-rounded-md"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="pipelineType" title="PipelineType">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.pipelineType"
            kendoGridFocusable
            name="PipelineType"
            class="k-textbox k-input k-rounded-md"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <!-- <kendo-grid-column field="requestTemplate" title="RequestTemplate">
      </kendo-grid-column> -->
      <kendo-grid-column field="queueNumber" title="QueueNumber">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.queueNumber"
            kendoGridFocusable
            name="QueueNumber"
            class="k-textbox k-input k-rounded-md"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="runIfMailboxExistsInCustSub" title="RunIfMailboxExists">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.runIfMailboxExistsInCustSub"
            kendoGridFocusable
            name="RunIfMailboxExists"
            type="checkbox"
          />
        </ng-template>  
      </kendo-grid-column>
    <kendo-grid-command-column title="command" [width]="220">
      <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew" let-rowIndex="rowIndex">
        <button kendoGridEditCommand type="button" [primary]="true" (click)="edit(dataItem,rowIndex)">
          Edit
        </button>
        <button
          kendoGridSaveCommand
          type="button"
          [disabled]="myForm.invalid"
          (click)="saveMailboxManagerMapping(dataItem)"
        >
          {{ isNew ? "Add" : "Update" }}
        </button>
        <button kendoGridCancelCommand type="button" (click)="cancel(dataItem)">
          {{ isNew ? "Discard changes" : "Cancel" }}
        </button>
        <button kendoButton type="button" *ngIf="!editMode" (click)="deleteMailboxManagerMappingDialogOpen(dataItem)">Remove</button>
        <kendo-dialog
        title="Please confirm"
        *ngIf="mailboxManagerMappingDialogOpened"
        (close)="close()"
        [minWidth]="250"
        [width]="450"
      >
        <p style="margin: 30px; text-align: center;">
          Are you sure you want to delete pipeline "<b>{{this.mailboxManagerToBeDeleted.pipelineName}}</b>" with id "<b>{{this.mailboxManagerToBeDeleted.pipelineId}}</b>""?
        </p>
        <kendo-dialog-actions>
          <button kendoButton (click)="close()">No</button>
          <button kendoButton (click)="deleteMailboxManagerMapping(this.mailboxManagerToBeDeleted)" primary="true">
            Yes
          </button>
        </kendo-dialog-actions>
      </kendo-dialog>
      </ng-template>
    </kendo-grid-command-column>
    <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
      <ng-container *ngIf="rowIndex !== editedRowIndex" >
        <p><strong>Request Template:</strong></p>
        <pre>{{ dataItem.requestTemplate }}</pre>
     </ng-container>
 <textarea class="form-control" 
 *ngIf="rowIndex === editedRowIndex"
    name="message"
    rows="8"
    [(ngModel)]="dataItem.requestTemplate"
    #message='ngModel'
    ></textarea>
    </ng-template>
    </kendo-grid>
  </form>
</div>
      </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
      </div>
</kendo-tilelayout-item-body>
</kendo-tilelayout-item>
</kendo-tilelayout>
