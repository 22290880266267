import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { Constants } from 'src/app/shared/Constants';

@Component({
  selector: 'app-app-upgrade-detailed-log',
  templateUrl: './app-upgrade-detailed-log.component.html',
  styleUrls: ['./app-upgrade-detailed-log.component.scss']
})
export class AppUpgradeDetailedLogComponent extends BaseComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  constructor(public activatedRoute:ActivatedRoute) {
    super();
  }
  onThisPage=false;
  batchId;
  appUpgradeLogs;
  public expandedDetailKeys: number[] = [1];
  title=""
  //public hubKey:Guid;

  @HostListener('window:beforeunload')
  async ngOnDestroy()
  {
    this.onThisPage=false;
    if(this.sub){
      this.sub.unsubscribe();
    }
  }


  ngOnInit(): void {
    this.onThisPage=true;
    this.batchId = this.activatedRoute.snapshot.paramMap.get('Id');
    if(this.batchId){
      this.getAppUpgradeStatus();
    }
  }

  getAppUpgradeStatus(){
    this.loaderService.start()
    this.sub = this.pipelineService.getAppUpgradeStatus(this.batchId)
    .subscribe({
      next: (result) => {
        this.appUpgradeLogs = result;
        this.loaderService.stop()
        if(result){
          this.pollAppUpgradeStatus();
        }
      },
      error: (error) => {
        this.loaderService.stop();
        console.error(error);
      },
      complete: () => {
        this.loaderService.stop();
      }
    })
  }

  pollAppUpgradeStatus(){
    this.sub = this.pipelineService.pollAppUpgradeStatus(this.batchId)
    .subscribe({
      next: async (result) => {
        if(this.onThisPage && result){
          this.appUpgradeLogs = result;
          await new Promise(f => setTimeout(f, 3000));
          this.pollAppUpgradeStatus();
        }
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
      }
    })
  }

  getAppUpgradeJobStatus(appUpgradeLog){
    if(!appUpgradeLog.jobs){
      this.loaderService.start()
      this.sub = this.pipelineService.getAppUpgradeJobStatus(appUpgradeLog.project,appUpgradeLog.id)
      .subscribe({
        next: (result) => {
          appUpgradeLog.jobs = result;
          this.loaderService.stop();
        },
        error: (error) => {
          this.loaderService.stop();
          console.error(error);
        },
        complete: () => {
          this.loaderService.stop();
        }
      })
    }
  }

  public expandDetailsBy = (dataItem): number => {
    return dataItem.id;
  };
  public rowCallback(context: RowClassArgs) {
    return {
      center: true
    };
    
  }

  public runRowCallback(context: RowClassArgs) {
    if (context.dataItem.result == "succeeded") {   // change this condition as you need
      return {
        created: true
      };
    }
    else if (context.dataItem.result == "failed") {   // change this condition as you need
      return {
        failed: true
      };
    }
    else if (context.dataItem.result == "skipped") {   // change this condition as you need
      return {
        awaitingaction: true
      };
    }
    else if (context.dataItem.result == "inProgress" ) {   // change this condition as you need
      return {
        inprogress : true
      };
    }
    else if (context.dataItem.result == "pending" || context.dataItem.result == "notStarted") {   // change this condition as you need
      return {
        notstarted: true
      };
    }
    else{   // change this condition as you need
      return {
        failed: true
      };
    }
    
  }

  public expandDetails = (dataItem) => {
    return true
  };

  public onExpand(data){
    this.getAppUpgradeJobStatus(data.dataItem)
  }

  backToAllLogs(){
    this.router.navigate([`${Constants.PIPELINES}`])
  }

}
