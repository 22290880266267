import { Component, Input } from '@angular/core';
import { DialogAction, DialogCloseResult, DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/Constants';
import { ActionsLayout } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-fhcm-customer-update',
  templateUrl: './fhcm-customer-update.component.html',
  styleUrls: ['./fhcm-customer-update.component.scss']
})
export class FhcmCustomerUpdateComponent extends DialogContentBase {

  private _projectId: number;
  private _cloudServiceCode: string;
  private _customerName: string;
  private _applicationId: string;
  private _clientSecret: string;
  private _userName: string;
  private _password: string;

  public actionsLayout: ActionsLayout = 'center';

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    projectId: new UntypedFormControl(this.projectId),
    cloudServiceCode: new UntypedFormControl(this.cloudServiceCode),
    customerName: new UntypedFormControl(this.customerName),
    applicationId: new UntypedFormControl(this.applicationId),
    clientSecret: new UntypedFormControl(this.clientSecret),
    userName: new UntypedFormControl(this.userName),
    password: new UntypedFormControl(this.password),
  });

  @Input()
  public set projectId(value: number) {
    this.formGroup.controls.projectId.setValue(value);
    this._projectId = value;
  }

  public get projectId(): number {
    return this._projectId;
  }

  @Input()
  public set cloudServiceCode(value: string) {
    this.formGroup.controls.cloudServiceCode.setValue(value);
    this._cloudServiceCode = value;
  }

  public get cloudServiceCode(): string {
    return this._cloudServiceCode;
  }

  @Input()
  public set customerName(value: string) {
    this.formGroup.controls.customerName.setValue(value);
    this._customerName = value;
  }

  public get customerName(): string {
    return this._customerName;
  }

  @Input()
  public set applicationId(value: string) {
    this.formGroup.controls.applicationId.setValue(value);
    this._applicationId = value;
  }

  public get applicationId(): string {
    return this._applicationId;
  }

  @Input()
  public set clientSecret(value: string) {
    this.formGroup.controls.clientSecret.setValue(value);
    this._clientSecret = value;
  }

  public get clientSecret(): string {
    return this._clientSecret;
  }

  @Input()
  public set userName(value: string) {
    this.formGroup.controls.userName.setValue(value);
    this._userName = value;
  }

  public get userName(): string {
    return this._userName;
  }

  @Input()
  public set password(value: string) {
    this.formGroup.controls.password.setValue(value);
    this._password = value;
  }

  public get password(): string {
    return this._password;
  }

  public onCancelAction(): void {
    this.dialog.close(DialogCloseResult);
  }

  public onConfirmAction(): void {
    this.dialog.close({text: 'Save', themeColor: Constants.THEME_PRIMARY} as DialogAction);
  }
}
