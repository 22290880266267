<div class="text-center">
    <h4>
      <small class="text-muted">Database Refresh</small>
    </h4>
</div>

<kendo-tilelayout [columns]="12">
    <kendo-tilelayout-item [col]="1" [colSpan]="3"  class="border">
        <kendo-tilelayout-item-body>
            <div class="px-2 pt-2">
                <kendo-stepper
                #stepper
                [style.width.px]="100"
                [steps]="steps"
                stepType="full"
                [(currentStep)]="currentStep"
                [linear]="false"
                orientation="vertical"
              >
              </kendo-stepper>
              </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item [col]="4" [colSpan]="9" class="border">
        <kendo-tilelayout-item-body>
          <div class="px-2 pb-2 contentBody">
            <form class="k-form" [formGroup]="form">
              <app-database-refresh-details
                *ngIf="currentStep === 0"
                [form]="form"
                [customeIds]="customeIds"
                [variableGroupsSource]="variableGroupsSource"
                [filteredCustomeIds]="filteredCustomeIds"
                [variableGroupsFilteredSource]="variableGroupsFilteredSource"
                [sources]="sources"
                [filteredSources]="filteredSources"
                [targets]="targets"
                [filteredTargets]="filteredTargets"
                [liveCustomers]="liveCustomers"
                (sourceList)="sourceList($event)"
                (targetList)="targetList($event)"
                (filteredSourceList)="filteredSourceList($event)"
                (filteredTargetList)="filteredTargetList($event)"
              >
              </app-database-refresh-details>

              <app-database-refresh-sqldetails
              *ngIf="currentStep === 1"
              [form]="form"
              [source]="source"
              [DbNames]="sourceDbNames"
              [envNameMappings]="envNameMappings"
              (sourceDetails)="sourceDetails($event)"
              (sourceDbDetails)="sourceDbDetails($event)"
            >
            </app-database-refresh-sqldetails>


            <!-- <app-database-refresh-source-details
            *ngIf="currentStep === 2"
            [form]="form"
          >
          </app-database-refresh-source-details>

          <app-database-refresh-source-sub-details
          *ngIf="currentStep === 3"
          [form]="form"
        >
        </app-database-refresh-source-sub-details> -->

        <app-database-refresh-target-details
        *ngIf="currentStep === 2"
        [form]="form"
        [target]="target"
        [DbNames]="targetDbNames"
        [envNameMappings]="envNameMappings"
        (targetDetails)="targetDetails($event)"
        (targetDbDetails)="targetDbDetails($event)"
      >
      </app-database-refresh-target-details>
      <!-- <app-database-refresh-target-sub-details
      *ngIf="currentStep === 5"
      [form]="form"
    >
    </app-database-refresh-target-sub-details> -->
    <app-database-refresh-summary-details
    *ngIf="currentStep === 3"
    [form]="form"
  >
  </app-database-refresh-summary-details>

            </form>
          </div>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
</kendo-tilelayout>
<kendo-tilelayout [columns]="12">
    <kendo-tilelayout-item [col]="1" [colSpan]="12" class="border">
      <div class="p-2">
        <div class="k-form-buttons k-buttons-end" *ngIf="currentStep !== 2 && currentStep !== 3">
          <span class="page">Step {{ currentStep + 1 }} of 4</span>
          <div>
            <button
              class="k-button prev"
              kendoButton
              themeColor="secondary"
              *ngIf="currentStep !== 0"
              (click)="prev()"
            >
              Previous
            </button>
            <button
              class="k-button"
              kendoButton
              themeColor="primary"
              (click)="next()"
              *ngIf="currentStep !== 3"
            >
              Next
            </button>
          </div>
        </div>

        <div class="k-form-buttons k-buttons-end" *ngIf="currentStep === 2">
          <span class="page">Step {{ currentStep + 1 }} of 4</span>

          <div>
            <button
              class="k-button prev"
              kendoButton
              themeColor="secondary"
              *ngIf="currentStep !== 0"
              (click)="prev()"
            >
              Previous
            </button>
            <button
              class="k-button"
              kendoButton
              themeColor="primary"
              (click)="next()"
              *ngIf="currentStep !== 3"
            >
              Next
            </button>
          </div>
        </div>
        <div class="k-form-buttons k-buttons-end" *ngIf="currentStep === 3">
          <span class="page">Step {{ currentStep + 1 }} of 4</span>

          <div>
            <button
              class="k-button prev"
              kendoButton
              themeColor="secondary"
              *ngIf="currentStep !== 0"
              (click)="prev()"
            >
              Previous
            </button>
            <button
            class="k-button"
            kendoButton
            themeColor="primary"
            [disabled]="disableStartDbRefresh()"
            (click)="onStartDbRefresh()"
            *ngIf="currentStep === 3"
          >
            Start DB Refresh
          </button>
          </div>
        </div>
      </div>
    </kendo-tilelayout-item>
  </kendo-tilelayout>
  <div kendoDialogContainer></div>
