<div class="w-75 px-4">
    <ng-container [formGroup]="form">
        
        <kendo-formfield>
            <kendo-label [for]="subscriptionID" text="Subscription Id(Service Connection):"></kendo-label>
            <kendo-textbox formControlName="subscriptionID"
                   kendoTextBox
                   #subscriptionID
                   required>
            </kendo-textbox>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="azureSubscriptionID" text="Subscription Id(Azure):"></kendo-label>
            <kendo-textbox formControlName="azureSubscriptionID"
                   kendoTextBox
                   #azureSubscriptionID
                   required>
            </kendo-textbox>
        </kendo-formfield>

    </ng-container>
</div>
